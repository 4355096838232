import React from "react";
import "../../../styles/modal.scss";
import { Subscribe, UnSubscribe, updateList, compare } from "../../../components/utilities";
import ModalSearch from "../../../components/modalSearch";
import ComponentTypes from "./componentTypes";
import { Api as Assets } from "../../../services/asset";
import { connect } from "react-redux";

class ComponentClassTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "__ComponentClassTypes",
      name: "JS_TYPES",
      show: false,
      rows: 30,
      data: {
        list: [],
        search: "",
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      select: { _id: "-1", caption: "" },
      change: false,
      update: {}
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleSetData = (e) => {
    this.handleUpdate(e);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleData = (scroll) => {
    const project_id = this.props.project_id;
    const class_id = this.props.class_id;
    const search = this.state.data.search;
    const state = 'ALL';
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Assets.tipos(project_id, class_id, state, search, page, rows, this.state.data.list).then((result) => {
        const data = result.data;
        this.setState({
          data: data,
        });
      });
    } else {
      Assets.tipos(project_id, class_id, state, search, 1, rows, [this.state.select]).then((result) => {
        const data = result.data;
        this.setState({
          data: data,
          change: false,
        });
      });
    }
  };

  handleSearch = () => {
    this.handleData(false);
  };

  handleUpdate = (e) => {
    if (compare(this.state.update, e)) {
      const data = updateList(this.state.data, e);
      this.setState({ data: data, change: true, update: e });
    }
  };

  componentDidMount() {
    Subscribe(`${this.state.name}/${this.props.project_id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show) {
      if (prevProps.show !== this.props.show) {
        this.handleData(false);
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.name}/${this.props.project_id}`, (event) => this.eventSetData(event));
  }

  render() {
    return (
      <React.Fragment>
        <div className="modalList single pt-0 pb-0">
          <div className="modalListFind">
            <ModalSearch
              name="search"
              value={this.state.data.search}
              state={this.props._state}
              onChange={this.handleChange}
              onSearch={this.handleSearch}
            />
          </div>
          <div className="modalListList">
            <ComponentTypes
              id={`${this.state._id}_scroll`}
              height={this.props.height}
              chk={!this.props.only}
              _state={this.props._state}
              list={this.state.data.list}
              select={this.state.select}
              getData={this.handleData}
              onSetData={this.handleSetData}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    user_id: state.sistem.profile._id || "-1",
    _view: state.sistem.folder._view || "",
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ComponentClassTypes);
