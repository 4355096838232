import React from "react";
import ReactDOM from "react-dom";
import "../../../styles/modal.scss";
import "../../../styles/suport.scss";
import {
  showModal,
  hideModal,
  getValue,
  clone,
  getData,
  formatNumber,
  formatMoney,
  div,
  genId,
} from "../../../components/utilities";
import { Count } from "../../../components/inputs";
import { TpActivity } from "./../listTpActivities";
import Td from "../../../components/td";
import ModalHeader from "../../../components/modalHeader";
import ModalFooter from "../../../components/modalFooter";
import ModalTpActivity from "../modalTpActivity";

class ModalActivity extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme();
    this.state = {
      _id: "__ModalActivity",
      title: "Actividad",
      show: false,
      old: data,
      data: data,
      change: false,
    };
  }

  handleScheme = () => {
    const now = new Date();
    return {
      date_of: now,
      project_id: this.props.project_id,
      _state: "0",
      _id: genId("-1"),
      operation_id: this.props.operation_id,
      odt_id: this.props.odt_id,
      activity_tp: "-1",
      code: "",
      caption: "",
      unity: "und",
      quantity: 0,
      unitary: 0,
      cost: 0,
      _data: {
        caption: "und",
        activity_base: 0,
      },
    };
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeTpActivity = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        activity_tp: getValue(e, "_id", "-1"),
        code: getValue(e, "code", ""),
        caption: getValue(e, "caption", ""),
        unity: getValue(e, "unity", "und"),
        unitary: getValue(e, "unitary", 0),
        _data: {
          ...this.state.data._data,
          caption: getValue(e, "caption", ""),
          activity_base: getValue(e, "_activity", 0),
        },
      },
      change: true,
    });
  };

  handleChangeValue = (e) => {
    const value = Number(e.value);
    const data = this.state.data;
    if (e.name === "quantity") {
      data.quantity = value;
      data.cost = data.quantity * data.unitary;
    } else if (e.name === "unitary") {
      data.unitary = value;
      data.cost = data.quantity * data.unitary;
    } else if (e.name === "cost") {
      data.cost = value;
      data.unitary = div(data.cost, data.quantity);
    }
    this.setState({
      data: {
        ...this.state.data,
        quantity: data.quantity,
        unitary: data.unitary,
        cost: data.cost,
      },
      change: true,
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
    this.handleHide();
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    const _state = this.props._state || "0";
    if (_state === "0") {
      this.setState({
        show: true,
        change: false,
      });
    }
  };

  handleClose = () => {
    if (this.state.change) {
      this.setState({ data: this.state.old, change: false });
    }
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.change) {
      const params = this.state.data;
      this.handleExecute(params);
    } else {
      this.handleHide();
    }
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      change: false,
    });
    showModal(this.state._id);
  };

  handleData = () => {
    const data = clone(this.props.data);
    this.handleSetData({ data, change: false });
  };

  handleNew = () => {
    const data = this.handleScheme();
    this.handleSetData({ data, change: true });
  };

  handleRemove = (e) => {
    if (e) {
      const data = {
        ...this.state.data,
        reference_id: "-1",
        quantity: 0,
        unitary: 0,
      };
      this.handleExecute(data);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        if (this.props.isNew) {
          this.handleNew();
        } else {
          this.handleData();
        }
      }
    }
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog xs" role="dialog">
                <div className="modal-content">
                  <ModalHeader title={this.state.title} handleClose={this.handleClose} />
                  <div className="modal-body">
                    <div className="modal-box">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group input-group-sm">
                            <label>
                              <ModalTpActivity
                                className="link"
                                _class="KEEP_ACTIVITY_TYPE"
                                isFind={true}
                                _id={this.state.data.activity_tp}
                              >
                                Actividad
                              </ModalTpActivity>
                            </label>
                            <TpActivity
                              className="form-control sp ctr-sm t-m"
                              classNameBtn="input-group-text sp"
                              placeholder="Actividad"
                              project_id={this.state.data.project_id}
                              _class="KEEP_ACTIVITY_TYPE"
                              _state={this.state.data._state}
                              select={{
                                _id: this.state.data.activity_tp,
                                code: this.state.data.code,
                                caption: this.state.data.caption,
                              }}
                              onChange={this.handleChangeTpActivity}
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>Cantidad</label>
                            <Count
                              className="input-group input-group-sm"
                              _state={this.state.data._state}
                              name="quantity"
                              unity={this.state.data._data.unity}
                              value={this.state.data.quantity}
                              increase={1}
                              precision={2}
                              handleValue={this.handleChangeValue}
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>Unitario</label>
                            <Count
                              className="input-group input-group-sm"
                              _state={this.props._state}
                              name="unitary"
                              unity="$"
                              value={this.state.data.unitary}
                              increase={1000}
                              precision={0}
                              handleValue={this.handleChangeValue}
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>Total</label>
                            <Count
                              className="input-group input-group-sm"
                              _state={this.props._state}
                              name="cost"
                              unity="$"
                              value={this.state.data.cost}
                              increase={1000}
                              precision={2}
                              handleValue={this.handleChangeValue}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooter
                      title="¿Retirar actividad?"
                      description="¿Desea retirar la actividad, sobre el componente? este cambio no podrá revertirse."
                      _state={this.props._state}
                      onRemove={this.handleRemove}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function ComponentActivities(props) {
  const activities = props.activities || [];

  return (
    <React.Fragment>
      <div className="suport-group border-0 mb-4">
        <div className="suport-group">
          <Td className="suport-head" cols="auto 35px 90px 90px 94px">
            <div className="suport-td">Actividad</div>
            <div className="suport-td">Und</div>
            <div className="suport-td">Cantidad</div>
            <div className="suport-td">Unitario</div>
            <div className="suport-td">Total</div>
          </Td>
          <div className="suport-scroll" style={{ height: props.height || "280px" }}>
            {activities.map((item, i) => {
              return (
                <ModalActivity
                  key={i}
                  project_id={props.project_id}
                  _state={props._state}
                  operation_id={props.operation_id}
                  odt_id={props.odt_id}
                  vale_id={props.vale_id}
                  data={item}
                  onSetData={props.onSetData}
                >
                  <Td className="suport-item" cols="25px auto 35px 90px 90px 90px">
                    <div className="suport-td t-c">{i + 1}</div>
                    <div className="suport-td">{getData(item, "caption", "")}</div>
                    <div className="suport-td t-c">{item.unity}</div>
                    <div className="suport-td t-r">{formatNumber(item.quantity)}</div>
                    <div className="suport-td t-r">{formatMoney(item.unitary)}</div>
                    <div className="suport-td t-r">{formatMoney(item.cost)}</div>
                  </Td>
                </ModalActivity>
              );
            })}
            <ModalActivity
              project_id={props.project_id}
              _state={props._state}
              operation_id={props.operation_id}
              odt_id={props.odt_id}
              vale_id={props.vale_id}
              isNew={true}
              onSetData={props.onSetData}
            >
              <Td className="suport-item" cols="25px auto 35px 90px 90px 90px">
                <div className="suport-td"></div>
                <div className="suport-td"></div>
                <div className="suport-td t-c"></div>
                <div className="suport-td t-r"></div>
                <div className="suport-td t-r"></div>
                <div className="suport-td t-r"></div>
              </Td>
            </ModalActivity>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ComponentActivities;
