import React from "react";
import Td from "../../../components/td";
import { formatNumber, formatMoney, getRow, getNumber, getMoney, getValue } from "../../../components/utilities";
import ModalKardex from "../modalKardex";

function ComponentMovements(props) {
  const _state = props._state;
  const rows = props.rows || 30;
  const movements = props.movements;
  const handlePrintMovements = (e) => {
    const items = movements || [];
    let result = [];

    for (let i = 0; i < e; i++) {
      const item = getRow(items, i, {});
      const int_quantity = getValue(item, "int_quantity", "");
      const int_cost = getValue(item, "int_cost", "");
      const end_quantity = getValue(item, "end_quantity", "");
      const end_cost = getValue(item, "end_cost", "");
      if (int_quantity === "") {
        result.push(
          <Td key={i} className={_state === "0" ? "suport-item" : "suport-item disabled"} cols="25px 75px auto 35px 80px 95px 80px 125px">
            <div className="suport-td t-c">{i + 1}</div>
            <div className="suport-td t-c"></div>
            <div className="suport-td"></div>
            <div className="suport-td t-c"></div>
            <div className="suport-td t-r"></div>
            <div className="suport-td t-r"></div>
            <div className="suport-td t-r"></div>
            <div className="suport-td t-r"></div>
          </Td>
        );
      } else {
        result.push(
          <ModalKardex key={i} isFind={true} _id={getValue(item, "kardex_id", 0)} data={item}>
            <Td key={i} className={_state === "0" ? "suport-item" : "suport-item disabled"} cols="25px 75px auto 35px 80px 95px 80px 125px">
              <div className="suport-td t-c">{i + 1}</div>
              <div className="suport-td t-c">{getValue(item, "code", "")}</div>
              <div className="suport-td">{getValue(item, "description", "")}</div>
              <div className="suport-td t-c">{getValue(item, "unity", "")}</div>
              <div className="suport-td t-r">{int_quantity > 0 ? formatNumber(int_quantity) : formatNumber(end_quantity * -1)}</div>
              <div className="suport-td t-r">{int_quantity > 0 ? formatMoney(int_cost) : formatMoney(end_cost * -1)}</div>
              <div className="suport-td t-r">{getNumber(item, "balance_quantity", "")}</div>
              <div className="suport-td t-r">{getMoney(item, "balance_cost", "")}</div>
            </Td>
          </ModalKardex>
        );
      }
    }
    return result;
  };

  return (
    <React.Fragment>
      <div className="suport">
        <div className="suport-group">
          <Td className="suport-head" cols="100px auto 35px 380px">
            <div className="suport-td m">Referencia</div>
            <div className="suport-td m">Descripción</div>
            <div className="suport-td m">Und</div>
            <div className="suport-td c">
              <Td className="suport-head" cols="175px 205px">
                <div className="suport-td">Movimientos</div>
                <div className="suport-td">Saldo</div>
              </Td>
              <Td className="suport-head" cols="80px 95px 80px 125px">
                <div className="suport-td">Cantidad</div>
                <div className="suport-td">Costo</div>
                <div className="suport-td">Cantidad</div>
                <div className="suport-td">Costo</div>
              </Td>
            </div>
          </Td>
          {handlePrintMovements(rows)}
        </div>
      </div>
    </React.Fragment>
  );
}

export default ComponentMovements;
