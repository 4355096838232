import React from "react";
import Td from "../../../components/td";

function ComponentCheck(props) {
  const id = props.id || "-1";
  const data = props.data || {};

  return (
    <React.Fragment>
      <hr className="mb-2 mt-2" />
      <Td className="suport-row" cols="auto">
        <div className="suport-td">
          <div className={!data.equipment ? "d-none" : "custom-control custom-switch"}>
            <input
              className="custom-control-input"
              type="checkbox"
              id={`${id}_Checkbox1`}
              name="install"
              checked={data.install}
              onChange={props.onChangeChk}
            />
            <label className="custom-control-label custom-label" htmlFor={`${id}_Checkbox1`}>
              {data.install ? "Instalado" : "Desinstado"}
            </label>
          </div>
          <div className="custom-control custom-switch">
            <input
              className="custom-control-input"
              type="checkbox"
              id={`${id}_Checkbox2`}
              name="running"
              checked={data.running}
              onChange={props.onChangeChk}
            />
            <label className="custom-control-label custom-label" htmlFor={`${id}_Checkbox2`}>
              {data.running ? "En funcionamiento" : "Pausado"}
            </label>
          </div>
          <div className="custom-control custom-switch">
            <input
              className={data.failure ? "custom-control-input is-invalid" : "custom-control-input"}
              type="checkbox"
              id={`${id}_Checkbox3`}
              name="failure"
              checked={data.failure}
              onChange={props.onChangeChk}
            />
            <label
              className={data.failure ? "custom-control-label custom-label danger" : "custom-control-label custom-label success"}
              htmlFor={`${id}_Checkbox3`}
            >
              {data.failure ? "Reportado en falla" : "En buen estado"}
            </label>
          </div>
          <div className="custom-control custom-switch">
            <input
              className={data.reported ? "custom-control-input is-invalid" : "custom-control-input"}
              type="checkbox"
              id={`${id}_Checkbox4`}
              name="reported"
              checked={data.reported}
              onChange={props.onChangeChk}
            />
            <label
              className={data.reported ? "custom-control-label custom-label danger" : "custom-control-label custom-label success"}
              htmlFor={`${id}_Checkbox4`}
            >
              {data.reported ? "Reportado" : "Sin reportes"}
            </label>
          </div>
          <div className="custom-control custom-switch">
            <input
              className={data.preventive ? "custom-control-input is-valid" : "custom-control-input"}
              type="checkbox"
              id={`${id}_Checkbox5`}
              name="preventive"
              checked={data.preventive}
              onChange={props.onChangeChk}
            />
            <label className="custom-control-label custom-label" htmlFor={`${id}_Checkbox5`}>
              {data.preventive ? "Reportado para mantenimiento preventivo" : "Sin mantenimiento preventivo programado"}
            </label>
          </div>
        </div>
      </Td>
      <div className={!data.equipment ? "d-none" : ""}>
        <hr className="mt-2 mb-2" />
        <Td className="suport-row" cols="auto">
          <div className="custom-control custom-switch">
            <input
              className={data.low ? "custom-control-input is-invalid" : "custom-control-input"}
              type="checkbox"
              id={`${id}_Checkbox6`}
              name="low"
              checked={data.low}
              onChange={props.onChangeChk}
            />
            <label
              className={data.low ? "custom-control-label custom-label danger" : "custom-control-label custom-label success"}
              htmlFor={`${id}_Checkbox6`}
            >
              <strong>{data.low ? "Dado de baja" : "En operación"}</strong>
            </label>
          </div>
        </Td>
      </div>
    </React.Fragment>
  );
}

export default ComponentCheck;
