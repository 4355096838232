import React from "react";
import { Redirect } from "react-router-dom";
import "../../styles/view.scss";
import { Loading, Subscribe, UnSubscribe, formatMoney, setFocus, chart, getValue, updateList, compare } from "../../components/utilities";
import { Input, Textarea, Section, City, Button } from "../../components/inputs";
import ModalPrint from "../../modals/modalPrint";
import ComponentStock from "./components/componentStock";
import ComponentSuports from "./components/componentSuports";
import ComponentReferences from "./components/componentReferences";
import { Api as WareHouse } from "../../services/warehouse";
import { connect } from "react-redux";

class ViewWarehouse extends React.Component {
  constructor(props) {
    super(props);
    Loading();
    const data = this.handleScheme();
    this.state = {
      _id: "__ViewWarehouse",
      _view: {},
      old: data,
      data: data,
      select: {},
      tabs: [],
      printParams: {
        _class: "WAREHOUSE",
        _id: "-1",
        single: true,
      },
      change: false,
    };
  }

  handleScheme = () => {
    return {
      project_id: this.props.project_id,
      storer_id: "-1",
      _id: "-1",
      _state: "0",
      username: "",
      caption: "",
      description: "",
      cost: 0,
      header_foto: "",
      city_id: "-1",
      city: "",
      _data: {
        city: "",
      },
      stock: {
        list: [],
        search: "",
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      cellars: {
        list: [],
        state: "0",
        search: "",
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      update: {}
    };
  };

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  eventNotifyStock = (e) => {
    setTimeout(() => {
      this.handleNotifyStock(e);
    }, 500);
  };

  handleSetData = (e) => {
    this.handleUpdate(e);
    this.handleSelect(e);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeCity = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        city_id: e._id,
        city: e.caption,
      },
      change: true,
    });
  };

  handleChangeStock = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        stock: {
          ...this.state.data.stock,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  handleChangeBodegaState = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        cellars: {
          ...this.state.data.cellars,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  handleData = () => {
    const id = this.props.project_id;
    WareHouse.warehouse(id).then((result) => {
      if (result.msg === "") {
        const data = result.data;
        this.setState({
          data: data,
          old: data,
          printParams: {
            project_id: data.project_id,
            _class: data._class,
            _id: data._id,
            single: true,
          },
        });
        setFocus(`${this.state._id}_caption`);
      }
    });
  };

  handleDetails = (scroll) => {
    const _id = this.state.data._id;
    const stock = this.state.data.stock;
    const search = stock.search;
    const rows = stock.rows;
    if (scroll) {
      const page = stock.page + 1;
      WareHouse.warehouseStock(_id, search, page, rows, stock.list).then((result) => {
        const data = result.data;
        this.setState({
          data: {
            ...this.state.data,
            stock: data,
          },
        });
      });
    } else {
      WareHouse.warehouseStock(_id, search, 1, rows).then((result) => {
        const data = result.data;
        this.setState({
          data: {
            ...this.state.data,
            stock: data,
          },
        });
      });
    }
  };

  handleCellars = (e) => {
    const _id = this.state.data._id;
    let cellars = this.state.data.cellars;
    const state = cellars.state;
    const search = cellars.search;
    WareHouse.warehouseCellars(_id, state, search, 1, 30, []).then((result) => {
      cellars = result.data;
      this.setState({
        data: {
          ...this.state.data,
          cellars: cellars,
        },
      });
    });
  };

  handleOk = () => {
    if (this.state.change) {
      WareHouse.setWarehouse(this.state.data._id, this.state.data).then((result) => {
        const data = result.data;
        this.setState({
          data: data,
          old: data,
          change: false,
        });
      });
    }
  };

  handleSearch = () => {
    this.handleDetails(false);
  };

  handleScroll = (e) => {
    const h = e.target.scrollTop + e.target.clientHeight + 1;
    if (e.target.id === `${this.state._id}_scroll` && h >= e.target.scrollHeight) {
      this.handleDetails(true);
    }
  };

  handleSelected = (e) => {
    this.setState({ select: e });
  };

  handleUpdate = (e) => {
    if (compare(this.state.update, e)) {
      const data = updateList(this.state.data, e);
      this.setState({ data: data, update: e });
    }
  };

  handleNotifyStock = (e) => {
    let data = this.state.data;
    let stock = data.stock;
    let page = stock.page;
    if (page <= 1) {
      this.handleDetails(false);
    }
  };

  handleCellar = (e) => {
    this.props.history.push(`./cellar`, {
      _id: e,
      project_id: this.state.data.project_id,
      warehouse_id: this.state.data._id,
    });
  };

  handleTab = (e) => {
    const tabs = this.state.tabs;
    if (!tabs[e]) {
      tabs[e] = true;
      this.setState({ tabs: tabs });
    }
  };

  componentDidMount() {
    Subscribe(`${this.state.name}/${this.props.project_id}`, (event) => this.eventSetData(event));
    this.handleData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps._view === this.props._view && this.props.project_id !== "-1") {
      if (prevProps.project_id !== this.props.project_id) {
        UnSubscribe(`${this.state.name}/${prevProps.project_id}`, (event) => this.eventSetData(event));
        Subscribe(`${this.state.name}/${this.props.project_id}`, (event) => this.eventSetData(event));
        this.handleData();
      } else if (prevState.data.state !== this.state.data.state) {
        this.handleData();
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.name}/${this.props.project_id}`, (event) => this.eventSetData(event));
  }

  render() {
    if (this.props._view !== this.props.location.pathname) {
      return <Redirect to={this.props._view} push={true} />;
    }
    return (
      <React.Fragment>
        <div className={this.state._id}>
          <div className="viewcontainer">
            <div className="view-box">
              <div className="view-object">
                <div className="view-detail">
                  <div className="view-detail-title">
                    <div className="text">{this.state.data.caption}</div>
                  </div>
                  <div className="view-detail-list">
                    <nav>
                      <div className="nav nav-tabs" role="tablist">
                        <a
                          className="nav-item nav-link active"
                          id={`${this.state._id}-warehouse-1-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-warehouse-1`}
                          role="tab"
                          aria-selected="true"
                        >
                          Stock
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-warehouse-2-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-warehouse-2`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(2)}
                        >
                          Soportes
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-warehouse-3-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-warehouse-3`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(3)}
                        >
                          Referencias
                        </a>
                      </div>
                    </nav>
                    <div className="tab-content view-main">
                      <div
                        className="tab-pane fade show active"
                        id={`${this.state._id}-warehouse-1`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-warehouse-1-tab`}
                      >
                        <ComponentStock
                          _id={this.state._id}
                          data={this.state.data}
                          select={this.state.select}
                          printParams={this.state.printParams}
                          changeStock={this.handleChangeStock}
                          search={this.handleSearch}
                          scrolling={this.handleScroll}
                          selected={this.handleSelected}
                          onChange={this.handleSetData}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-warehouse-2`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-warehouse-2-tab`}
                      >
                        <ComponentSuports
                          project_id={this.state.data.project_id}
                          warehouse_id={this.state.data._id}
                          cellar_id="ALL"
                          cellar=""
                          _class="ALL"
                          state="ALL"
                          _state={this.state.data._state}
                          show={this.state.tabs[2] || false}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-warehouse-3`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-warehouse-3-tab`}
                      >
                        <ComponentReferences
                          project_id={this.state.data.project_id}
                          state="0"
                          _state={this.state.data._state}
                          show={this.state.tabs[3] || false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="view-master">
                  <nav>
                    <div className="nav nav-tabs" role="tablist">
                      <a
                        className={
                          getValue(this.props.location.state, "origen", "") !== "cellar" ? "nav-item nav-link active" : "nav-item nav-link"
                        }
                        id={`${this.state._id}-nav-1-tab`}
                        data-toggle="tab"
                        href={`#${this.state._id}-nav-1`}
                        role="tab"
                        aria-selected="true"
                      >
                        General
                      </a>
                      <a
                        className={
                          getValue(this.props.location.state, "origen", "") === "cellar" ? "nav-item nav-link active" : "nav-item nav-link"
                        }
                        id={`${this.state._id}-nav-2-tab`}
                        data-toggle="tab"
                        href={`#${this.state._id}-nav-2`}
                        role="tab"
                        aria-selected="false"
                      >
                        Bodegas
                      </a>
                    </div>
                  </nav>
                  <div className="tab-content view">
                    <div
                      className={
                        getValue(this.props.location.state, "origen", "") !== "cellar" ? "tab-pane fade show active" : "tab-pane fade show"
                      }
                      id={`${this.state._id}-nav-1`}
                      role="tabpanel"
                      aria-labelledby={`${this.state._id}-nav-1-tab`}
                    >
                      <div className="tab-group">
                        <div className="row">
                          <h5 className="col-12 text-center p-1">Datos de almacén</h5>
                        </div>
                        <div className="form-group input-group-sm mb-2">
                          <label>Nombre</label>
                          <Section
                            id={`${this.state._id}_caption`}
                            type="text"
                            className="form-control"
                            placeholder="Nombre completo"
                            name="caption"
                            _state={this.state.data._state}
                            value={this.state.data.caption}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group input-group-sm mb-2">
                          <label>Descripción</label>
                          <Textarea
                            className="form-control notresize"
                            rows={4}
                            name="description"
                            _state={this.state.data._state}
                            value={this.state.data.description}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group input-group-sm mb-2">
                          <label>Ciudad</label>
                          <City
                            type="text"
                            className="form-control"
                            classNameBtn="input-group-text"
                            _state={this.state.data._state}
                            value={this.state.data.city}
                            city_id={this.state.data.city_id}
                            onChange={this.handleChangeCity}
                          />
                        </div>
                        <div className="form-group input-group-sm">
                          <label>Valor del inventario</label>
                          <Input
                            type="text"
                            readOnly={true}
                            className="form-control t-r"
                            placeholder="Nombre completo"
                            name="cost"
                            _state={this.state.data._state}
                            value={formatMoney(this.state.data.cost)}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group input-group-sm float-right">
                          <ModalPrint className="btn btn-outline-secondary btn-sm mr-2" params={this.state.printParams}>
                            Imprimir
                          </ModalPrint>
                          <Button className="btn btn-primary btn-sm" onClick={this.handleOk}>
                            Aceptar
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        getValue(this.props.location.state, "origen", "") === "cellar" ? "tab-pane fade show active" : "tab-pane fade show"
                      }
                      id={`${this.state._id}-nav-2`}
                      role="tabpanel"
                      aria-labelledby={`${this.state._id}-nav-2-tab`}
                    >
                      <div className="tab-list">
                        <div className="input-group-sm ml-3 mt-2 mb-2 mr-2">
                          <select
                            className="custom-select"
                            name="state"
                            value={this.state.data.cellars.state}
                            onChange={this.handleChangeBodegaState}
                          >
                            <option value="0">Activo</option>
                            <option value="1">Archivados</option>
                            <option value="2">Cancelados</option>
                          </select>
                        </div>
                        {this.state.data.cellars.list.map((item, i) => {
                          return (
                            <React.Fragment key={i}>
                              <div className="tab-item pointer" onClick={() => this.handleCellar(item._id)}>
                                <div className="tab-item-logo">
                                  <div className="avatar green">
                                    <div className="chart">
                                      <div>{chart(item.caption, 0)}</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="tab-item-detail">
                                  <div className="name">{item.caption}</div>
                                  <div className="label">{item.description}</div>
                                  <div className="label">
                                    Costo:
                                    <strong className="ml-1">{formatMoney(item.cost)}</strong>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                        <div className="input-group-sm ml-3 mt-3 mb-2 mr-2">
                          <Button className="btn btn-primary btn-sm btn-block" onClick={() => this.handleCellar("-1")}>
                            Nueva bodega
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    _view: state.sistem.folder._view || "",
    view_rows: state.sistem.view_rows,
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ViewWarehouse);
