import { http } from "../components/http";
import { ShowAlert, getValue, join, setValue } from "../components/utilities";
import environment from "../env/env";
const apiUrl = environment.url;

export const Api = {
  report: async function (id, project_id) {
    return await http("GET", `${apiUrl}/keep/reports/${id}?project_id=${project_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setReport: async function (id, params, background) {
    return await http("POST", `${apiUrl}/keep/reports/${id}`, params, background)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateReport: async function (id, state) {
    return await http("PATCH", `${apiUrl}/keep/reports/${id}`, { state })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  listReports: async function (project_id, state, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/keep/reports/?project_id=${project_id}&state=${state}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setUserReport: async function (id, params) {
    return await http("POST", `${apiUrl}/integrations/userReport/${id}`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  findReport: async function (code, project_id) {
    return await http("GET", `${apiUrl}/integrations/findReport/${code}?project_id=${project_id}`, {})
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  odt: async function (id, project_id) {
    return await http("GET", `${apiUrl}/keep/odts/${id}?project_id=${project_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setOdt: async function (id, params, background) {
    return await http("POST", `${apiUrl}/keep/odts/${id}`, params, background)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateOdt: async function (id, state) {
    return await http("PATCH", `${apiUrl}/keep/odts/${id}`, { state })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  listODTs: async function (project_id, state, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/keep/odts/?project_id=${project_id}&state=${state}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  listODTResponsables: async function (id, search) {
    return await http("GET", `${apiUrl}/keep/odts/responsables/${id}?search=${search}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", []);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  vale: async function (id, project_id) {
    return await http("GET", `${apiUrl}/keep/vales/${id}?project_id=${project_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setVale: async function (id, params, background) {
    return await http("POST", `${apiUrl}/keep/vales/${id}`, params, background)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateVale: async function (id, state) {
    return await http("PATCH", `${apiUrl}/keep/vales/${id}`, { state })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  listVales: async function (project_id, state, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/keep/vales/?project_id=${project_id}&state=${state}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  valeSuports: async function (_id, state, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/keep/vales/suports/${_id}?state=${state}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  delivery: async function (id, project_id) {
    return await http("GET", `${apiUrl}/keep/deliveries/${id}?project_id=${project_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setDelivery: async function (id, params, background) {
    return await http("POST", `${apiUrl}/keep/deliveries/${id}`, params, background)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateDelivery: async function (id, state) {
    return await http("PATCH", `${apiUrl}/keep/deliveries/${id}`, { state })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  refund: async function (id, project_id) {
    return await http("GET", `${apiUrl}/keep/refunds/${id}?project_id=${project_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setRefund: async function (id, params, background) {
    return await http("POST", `${apiUrl}/keep/refunds/${id}`, params, background)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateRefund: async function (id, state) {
    return await http("PATCH", `${apiUrl}/keep/refunds/${id}`, { state })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  transfer: async function (id, project_id) {
    return await http("GET", `${apiUrl}/keep/transfers/${id}?project_id=${project_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setTransfer: async function (id, params) {
    return await http("POST", `${apiUrl}/keep/transfers/${id}`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateTransfer: async function (id, state) {
    return await http("PATCH", `${apiUrl}/keep/transfers/${id}`, { state })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stock: async function (vale_id, reference_id) {
    return await http("GET", `${apiUrl}/keep/stocks/${vale_id}&reference_id=${reference_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stocks: async function (vale_id, class_id, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/keep/stocks/?vale_id=${vale_id}&class_id=${class_id}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  summary: async function (vale_id, class_id, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/keep/summary/?vale_id=${vale_id}&class_id=${class_id}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  consumptions: async function (_class, _id, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/keep/consumptions/?_class=${_class}&_id=${_id}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  activities: async function (_class, _id, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/keep/activities/?_class=${_class}&_id=${_id}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  typeactivity: async function (id, project_id) {
    return await http("GET", `${apiUrl}/keep/typeactivity/${id}?project_id=${project_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setTypeActivity: async function (id, params) {
    return await http("POST", `${apiUrl}/keep/typeactivity/${id}`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateTypeActivity: async function (id, state) {
    return await http("PATCH", `${apiUrl}/keep/typeactivity/${id}`, { state })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  listTypeActivity: async function (project_id, _class, state, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/keep/typeactivity/?project_id=${project_id}&_class=${_class}&state=${state}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  operation: async function (id, project_id) {
    return await http("GET", `${apiUrl}/keep/operations/${id}?project_id=${project_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setOperation: async function (id, params, background) {
    return await http("POST", `${apiUrl}/keep/operations/${id}`, params, background)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateOperation: async function (id, state) {
    return await http("PATCH", `${apiUrl}/keep/operations/${id}`, { state })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  listOperations: async function (_class, id, state, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/keep/operations/?_class=${_class}&id=${id}&state=${state}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  getTypeComponents: async function (project_id, id) {
    return await http("GET", `${apiUrl}/assets/type/components/${id}?project_id=${project_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  inform: async function (id, project_id) {
    return await http("GET", `${apiUrl}/keep/informs/${id}?project_id=${project_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setInform: async function (id, params) {
    return await http("POST", `${apiUrl}/keep/informs/${id}`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateInform: async function (id, state) {
    return await http("PATCH", `${apiUrl}/keep/informs/${id}`, { state })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  calcInform: async function (id) {
    return await http("POST", `${apiUrl}/keep/informs/calc/${id}`, {})
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  informReports: async function (id, state, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/keep/informs/reports/${id}?state=${state}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  informOdts: async function (id, state, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/keep/informs/odts/${id}?state=${state}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  informVales: async function (id, state, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/keep/informs/vales/${id}?state=${state}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  informOperations: async function (id, state, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/keep/informs/operations/${id}?state=${state}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  informAssets: async function (id, state, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/keep/informs/assets/${id}?state=${state}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  informConsumptions: async function (id, state, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/keep/informs/consumptions/${id}?state=${state}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  informActivities: async function (id, state, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/keep/informs/activities/${id}?state=${state}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
};
