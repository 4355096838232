import React from "react";
import "../../styles/view.scss";
import "../../styles/suport.scss";
import TabReports from "./tabReports";
import TabOdts from "./tabOdts";
import TabOpertaions from "./tabOpertaions";

class TabDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project_id: props.project_id,
      rows: 10,
      change: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="ibox mb-0">
          <TabReports project_id={this.state.project_id} />
          <TabOdts project_id={this.state.project_id} />
          <TabOpertaions project_id={this.state.project_id} />
        </div>
      </React.Fragment>
    );
  }
}

export default TabDocuments;
