import React from "react";
import ReactDOM from "react-dom";
import "../../styles/modal.scss";
import "../../styles/suport.scss";
import {
  showModal,
  hideModal,
  ShowInfo,
  msg,
  getValue,
  getRowValue,
  getRowNumber,
  genId,
  formatNumber,
  clone,
  getRow,
  div,
  ShowAlert,
  formId,
  formatDate,
  getSource,
  compareObject,
  UnSubscribe,
  Subscribe
} from "../../components/utilities";
import { ALERT001, ALERT002 } from "../../components/msg";
import { Input, Textarea, City, Contact, Select, User, Count } from "../../components/inputs";
import { Reference } from "./listReferences";
import ModalHeaderSuport from "../../components/modalHeaderSuport";
import ModalFooterSuport from "../../components/modalFooterSuport";
import ModalHeader from "../../components/modalHeader";
import ModalFooter from "../../components/modalFooter";
import ModalContact from "../modalContact";
import ModalReference from "./modalReference";
import Td from "../../components/td";
import DateTime from "../../components/dateTime";
import { Api as Warehouse } from "../../services/warehouse";
import { connect } from "react-redux";

class ModalPurchase extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme(props.data);
    this.state = {
      form: "__ModalPurchase",
      _id: "__ModalPurchase",
      title: "Compra",
      name: "purchase",
      show: false,
      old: data,
      data: data,
      isNew: props.isNew || false,
      change: false,
      printParams: {
        project_id: "-1",
        _class: "SUPORT-WAREHOUSE-ORDER",
        _id: "-1",
        single: true,
      },
      pay: [
        { _id: "A 5 días", caption: "A 5 días" },
        { _id: "A 10 días", caption: "A 10 días" },
        { _id: "A 15 días", caption: "A 15 días" },
        { _id: "A 30 días", caption: "A 30 días" },
        { _id: "A 45 días", caption: "A 45 días" },
        { _id: "A 60 días", caption: "A 60 días" },
        { _id: "A 90 días", caption: "A 90 días" },
        { _id: "A 120 días", caption: "A 120 días" },
        { _id: "Contra entega", caption: "Contra entega" },
      ],
    };
  }

  handleScheme = (data) => {
    const now = new Date();
    return {
      date_of: formatDate(now, 'yyyy-MM-dd HH:mm:ss'),
      project_id: this.props.project_id,
      _class: "SUPORT-WAREHOUSE-ORDER",
      _state: "0",
      _id: genId("-1"),
      code: "",
      caption: "Orden de compra",
      description: "",
      observations: "",
      cost: 0,
      taxes: 0,
      total: 0,
      request_id: "-1",
      provider_id: "-1",
      authorize_id: "-1",
      details: [],
      _data: {
        project: this.props.project,
        pay: "A 30 días",
        rut: "",
        city: "",
        email: "",
        phone: "",
        address: "",
        request: "",
        provider: "",
        authorize: "",
        cellphone: "",
        username: "",
      },
      next_id: "-1",
      prior_id: "-1",
    };
  };

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeData = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          [e.target.name]: e.target.value,
        },
      },
      change: true,
    });
  };

  handleChangeDate = (e) => {
    const value = formatDate(e.target.value, 'yyyy-MM-dd HH:mm:ss');
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: value,
      },
      change: true,
    });
  };

  handleChangeProvider = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        provider_id: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          provider: getValue(e, "caption", ""),
          address: getValue(e, "address", ""),
          city_id: getValue(e, "city_id", "-1"),
          city: getValue(e, "city", ""),
          rut: getValue(e, "identification", ""),
          email: getValue(e, "email", ""),
          phone: getValue(e, "phone", ""),
          cellphone: getValue(e, "cellphone", ""),
        },
      },
      change: true,
    });
  };

  handleChangeCity = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          city_id: getValue(e, "city_id", "-1"),
          city: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeRequest = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        request_id: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          request: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeAuthorize = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        authorize_id: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          authorize: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeDetail = (e) => {
    const details = this.state.data.details;
    details[e.index] = e.item;
    this.setState({
      data: {
        ...this.state.data,
        details: details,
      },
      change: true,
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    const _state = this.props._state || "0";
    if (_state === "main_not_exist") {
      ShowAlert(ALERT001);
    } else {
      if (this.props.isFind) {
        const _id = getValue(this.props, "_id", "-1");
        if (_id !== "-1") {
          const form_id = formId(`${this.state.form}${_id}`);
          this.setState({ _id: form_id, show: true, change: false });
        }
      } else if (this.props.isNew) {
        const form_id = `${this.state.form}${Date.now()}`;
        this.setState({ _id: form_id, show: true, change: false });
      } else {
        const _id = getValue(this.props.data, "_id", "-1");
        if (_id !== "-1") {
          const form_id = formId(`${this.state.form}${_id}`);
          this.setState({ _id: form_id, show: true, change: false });
        }
      }
    }
  };

  handleClose = (e) => {
    if (this.state.change && e) {
      this.handleOk();
    } else {
      this.handleHide();
    }
  };

  handleOk = () => {
    if (this.state.change) {
      const id = this.state.data._id;
      const params = this.state.data;
      Warehouse.setPurchase(id, params).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false });
          this.handleExecute(data);
        }
      });
    } else {
      this.handleHide();
    }
  };

  handleState = (e) => {
    if (this.state.change) {
      ShowAlert(ALERT002);
    } else {
      const _state = getValue(this.state.data, "_state");
      if (_state !== e) {
        const id = getValue(this.state.data, "_id");
        Warehouse.statePurchase(id, e).then((result) => {
          if (result.msg === "") {
            const data = result.data;
            this.handleSetData({ data, isNew: false, change: false });
            this.handleExecute(data);
          }
        });
      }
    }
  };

  handlePrior = (e) => {
    const _id = getValue(this.state.data, "prior_id", "-1");
    if (_id === "-1") {
      ShowInfo(msg("MSG-FIRST-ROW"));
    } else {
      this.handleFind(_id);
    }
  };

  handleNext = (e) => {
    const _id = getValue(this.state.data, "next_id", "-1");
    if (_id === "-1") {
      ShowInfo(msg("MSG-END-ROW"));
    } else {
      this.handleFind(_id);
    }
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      isNew: e.isNew,
      change: e.change,
      tabs: [],
      printParams: {
        project_id: e.data.project_id,
        _class: e.data._class,
        _id: e.data._id,
        single: true,
      },
    });
    showModal(this.state._id);
  };

  handleUpdate = (e) => {
    if (compareObject(this.state.data, e)) {
      this.handleSetData({
        data: e,
        isNew: false,
        change: false,
      });
    }
  };

  handleData = () => {
    const _id = getSource(this.props.data, ["_id"], "-1");
    if (_id === "-1") {
      this.handleSetData({ data: this.props.data, isNew: false, change: false });
    } else {
      this.handleFind(_id);
    }
  };

  handleNew = () => {
    const data = this.handleScheme(this.props.data);
    this.handleSetData({ data, isNew: true, change: true, focusInt: true });
  };

  handleFind = (id) => {
    if (this.state.change) {
      ShowInfo(msg("MSG-CHANGE"));
    } else if (id !== "-1") {
      Warehouse.purchase(id, this.props.project_id).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false });
        }
      });
    }
  };

  handleSend = (e) => {
    console.log(`handleSend ${e}`);
  };

  handlePrintDetail = (e) => {
    const items = this.state.data.details || [];
    let result = [];

    for (let i = 0; i < e; i++) {
      const item = getRow(items, i);
      result.push(
        <Td
          key={i}
          className={this.state.data._state === "0" ? "suport-item" : "suport-item disabled"}
          cols="25px 75px 75px 35px auto 110px 35px 85px 114px"
        >
          <div className="suport-td t-c">{i + 1}</div>
          <div className="suport-td t-c">{getRowValue(items, i, "code", "")}</div>
          <div className="suport-td t-r">{getRowNumber(items, i, "quantity", "")}</div>
          <div className="suport-td t-c">{getRowValue(items, i, "unity", "")}</div>
          <div className="suport-td">{getRowValue(items, i, "description", "")}</div>
          <div className="suport-td">{getRowValue(items, i, "manufacturer", "")}</div>
          <div className="suport-td t-c">{getRowNumber(items, i, "tax", "")}</div>
          <div className="suport-td t-r">{getRowNumber(items, i, "unitary", "")}</div>
          <div className="suport-td t-r bt">
            {getRowNumber(items, i, "cost", "")}
            <ModalDetail
              className="input-group-text sp"
              project_id={this.state.data.project_id}
              purchase_id={this.state.data._id}
              _state={this.state.data._state}
              index={i}
              data={item}
              onSetData={this.handleChangeDetail}
            >
              <i className="fa fa-ellipsis-h"></i>
            </ModalDetail>
          </div>
        </Td>
      );
    }
    return result;
  };

  componentDidMount() {
    Subscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.data._id !== this.state.data._id) {
        UnSubscribe(`${prevState.data._class}/${prevState.data._id}`, (event) => this.eventSetData(event));
        Subscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
      }
      if (prevState.show !== this.state.show) {
        if (this.props.isNew) {
          this.handleNew();
        } else if (this.props.isFind) {
          this.handleFind(this.props._id);
        } else {
          this.handleData();
        }
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog __modalObject sm" role="document">
                <div className="modal-content">
                  <ModalHeaderSuport
                    title={this.state.data.caption}
                    _state={this.state.data._state}
                    _id={this.state.data._id}
                    isNew={this.state.isNew}
                    authorized={this.state.data.authorized}
                    alerts={this.state.data.alerts}
                    change={this.state.change}
                    handleClose={this.handleClose}
                    handlePrior={this.handlePrior}
                    handleNext={this.handleNext}
                  />
                  <div className="modal-body-tabs">
                    <nav>
                      <div className="nav nav-tabs" role="tablist">
                        <a
                          className="nav-item nav-link active"
                          id={`${this.state._id}-nav-1-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-1`}
                          role="tab"
                          aria-selected="true"
                        >
                          General
                        </a>
                      </div>
                    </nav>
                    <div className="tab-content modal">
                      <div
                        className="tab-pane fade show active"
                        id={`${this.state._id}-nav-1`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-1-tab`}
                      >
                        <div className="suport">
                          <div className="suport-group">
                            <Td className="suport-row" cols="90px auto 90px 160px">
                              <div className="suport-td caption link">
                                <ModalContact
                                  project_id={this.state.data.project_id}
                                  _id={this.state.data.provider_id}
                                  _state={this.state.data._state}
                                  isFind={true}
                                  onSetData={this.handleChangeProvider}
                                >
                                  Proveedor
                                </ModalContact>
                              </div>
                              <div className="suport-td">
                                <Contact
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  select={{
                                    _id: this.state.data.provider_id,
                                    caption: this.state.data._data.provider,
                                  }}
                                  onChange={this.handleChangeProvider}
                                />
                              </div>
                              <div className="suport-td caption">Despacho</div>
                              <div className="suport-td">
                                <DateTime
                                  className="form-control sp form-control-sm t-c"
                                  popperClassName="datepicker-right"
                                  name="date_of"
                                  value={this.state.data.date_of}
                                  _state={this.state.data._state}
                                  onChange={this.handleChangeDate}
                                  placeholder="Fecha de despacho"
                                  dateFormat="MMMM d, yyyy"
                                />
                              </div>
                            </Td>
                            <Td className="suport-row" cols="90px auto 90px 160px 90px 160px">
                              <div className="suport-td caption">Dirección</div>
                              <div className="suport-td">
                                <Input
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="address"
                                  value={this.state.data._data.address}
                                  onChange={this.handleChangeData}
                                />
                              </div>
                              <div className="suport-td caption">Ciudad</div>
                              <div className="suport-td">
                                <City
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  _state={this.state.data._state}
                                  value={this.state.data._data.city}
                                  city_id={this.state.data._data.city_id}
                                  onChange={this.handleChangeCity}
                                />
                              </div>
                              <div className="suport-td caption">RUT</div>
                              <div className="suport-td">
                                <Input
                                  className="form-control sp ctr-sm t-c"
                                  _state={this.state.data._state}
                                  name="rut"
                                  value={this.state.data._data.rut}
                                  onChange={this.handleChangeData}
                                />
                              </div>
                            </Td>
                            <Td className="suport-row" cols="90px auto 90px 160px 90px 160px">
                              <div className="suport-td caption">Correo</div>
                              <div className="suport-td">
                                <Input
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="email"
                                  value={this.state.data._data.email}
                                  onChange={this.handleChangeData}
                                />
                              </div>
                              <div className="suport-td caption">Teléfono</div>
                              <div className="suport-td">
                                <Input
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="phone"
                                  value={this.state.data._data.phone}
                                  onChange={this.handleChangeData}
                                />
                              </div>
                              <div className="suport-td caption">Celular</div>
                              <div className="suport-td">
                                <Input
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="cellphone"
                                  value={this.state.data._data.cellphone}
                                  onChange={this.handleChangeData}
                                />
                              </div>
                            </Td>
                          </div>
                          <div className="suport-group">
                            <Td className="suport-row" cols="90px auto 90px 160px 90px 160px">
                              <div className="suport-td caption">Solicita</div>
                              <div className="suport-td">
                                <User
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  _state={this.state.data._state}
                                  select={{
                                    _id: this.state.data.request_id,
                                    caption: this.state.data._data.request,
                                  }}
                                  project_id={this.state.data.project_id}
                                  onChange={this.handleChangeRequest}
                                />
                              </div>
                              <div className="suport-td caption">Autoriza</div>
                              <div className="suport-td">
                                <User
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  _state={this.state.data._state}
                                  select={{
                                    _id: this.state.data.authorize_id,
                                    caption: this.state.data._data.authorize,
                                  }}
                                  project_id={this.state.data.project_id}
                                  onChange={this.handleChangeAuthorize}
                                />
                              </div>
                              <div className="suport-td caption">Pago</div>
                              <div className="suport-td">
                                <Select
                                  className="form-control sp"
                                  _state={this.state.data._state}
                                  name="profile_tp"
                                  value={this.state.data._data.pay}
                                  onChange={this.handleChangeData}
                                  list={this.state.pay}
                                />
                              </div>
                            </Td>
                          </div>
                          <div className="suport-group">
                            <Td className="suport-head" cols="100px 75px 35px auto 110px 35px 85px 114px">
                              <div className="suport-td">Referencia</div>
                              <div className="suport-td">Cantidad</div>
                              <div className="suport-td">Und</div>
                              <div className="suport-td">Descripción</div>
                              <div className="suport-td">Fabricante</div>
                              <div className="suport-td">IVA</div>
                              <div className="suport-td">Unitario</div>
                              <div className="suport-td">Total</div>
                            </Td>
                            {this.handlePrintDetail(23)}
                            <Td className="suport-bottom" cols="auto 114px">
                              <div className="suport-td t-r">Subtotal</div>
                              <div className="suport-td t-r">{formatNumber(this.state.data.cost)}</div>
                            </Td>
                            <Td className="suport-bottom" cols="auto 114px">
                              <div className="suport-td t-r">IVA</div>
                              <div className="suport-td t-r">{formatNumber(this.state.data.taxes)}</div>
                            </Td>
                            <Td className="suport-bottom" cols="auto 114px">
                              <div className="suport-td t-r">Total</div>
                              <div className="suport-td t-r">{formatNumber(this.state.data.total)}</div>
                            </Td>
                          </div>
                          <div className="suport-group">
                            <Td className="suport-row" cols="auto">
                              <div className="suport-td d">
                                <Textarea
                                  className="form-control sp notresize"
                                  _state={this.state.data._state}
                                  rows={4}
                                  name="observations"
                                  value={this.state.data.observations}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </Td>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ModalFooterSuport
                    data={this.state.data}
                    change={this.state.change}
                    _state={this.state.data._state}
                    send={true}
                    isNew={this.state.isNew}
                    printParams={this.state.printParams}
                    handleState={this.handleState}
                    handleNew={this.handleNew}
                    handleClose={this.handleClose}
                    handleOk={this.handleOk}
                    handleSend={this.handleSend}
                  />
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    project: state.sistem.project,
    user_id: state.sistem.profile._id || "-1",
    user_name: state.sistem.profile.caption || "",
    _view: state.sistem.folder._view || "",
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ModalPurchase);

class ModalDetail extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme();
    this.state = {
      _id: "__modalPurchaseDetail",
      title: "Detalle",
      show: false,
      old: data,
      data: data,
      change: false,
    };
  }

  handleScheme = (data) => {
    return {
      purchase_id: this.props.purchase_id,
      _state: "0",
      _id: genId("-1"),
      reference_id: "-1",
      code: "",
      description: "",
      manufacturer_id: "-1",
      manufacturer: "",
      unity: "und",
      quantity: 0,
      unitary: 0,
      cost: 0,
      tax: 0,
      taxes: 0,
      total: 0,
      _data: {
        code: "",
        description: "",
        manufacturer_id: "-1",
        manufacturer: "",
        username: "",
      },
    };
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeReference = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        reference_id: getValue(e, "_id", "-1"),
        code: getValue(e, "code", ""),
        description: getValue(e, "caption", ""),
        unity: getValue(e, "unity", ""),
        manufacturer_id: getValue(e, "manufacturer_id", "-1"),
        manufacturer: getValue(e, "manufacturer", ""),
        tax: getValue(e, "tax", 0),
        _data: {
          ...this.state.data._data,
          code: getValue(e, "code", ""),
          description: getValue(e, "caption", ""),
          manufacturer_id: getValue(e, "manufacturer_id", "-1"),
          manufacturer: getValue(e, "manufacturer", ""),
        },
      },
      change: true,
    });
  };

  handleChangeValue = (e) => {
    const data = this.state.data;
    if (e.name === "quantity") {
      data.quantity = e.value;
      data.cost = data.quantity * data.unitary;
      data.taxes = data.cost * data.tax;
      data.total = data.cost + data.taxes;
    } else if (e.name === "unitary") {
      data.unitary = e.value;
      data.cost = data.quantity * data.unitary;
      data.taxes = data.cost * data.tax;
      data.total = data.cost + data.taxes;
    } else if (e.name === "tax") {
      data.tax = e.value;
      data.cost = data.quantity * data.unitary;
      data.taxes = data.cost * data.tax;
      data.total = data.cost + data.taxes;
    } else if (e.name === "cost") {
      data.cost = e.value;
      data.unitary = div(data.cost, data.unitary);
      data.taxes = data.cost * data.tax;
      data.total = data.cost + data.taxes;
    }
    this.setState({
      data: {
        ...this.state.data,
        quantity: data.quantity,
        cost: data.cost,
        tax: data.tax,
        taxes: data.taxes,
        total: data.total,
      },
      change: true,
    });
  };

  handleExecute = (e) => {
    if (typeof this.props.onSetData === "function") {
      this.props.onSetData({ index: this.props.index, item: e });
    }
    this.handleHide();
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({
        show: true,
        change: false,
      });
  };

  handleClose = () => {
    if (this.state.change) {
      this.setState({ data: this.state.old, change: false });
    }
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.change) {
      this.handleExecute(this.state.data);
    } else {
      this.handleHide();
    }
  };

  handleRemove = (e) => {
    if (e) {
      const data = {
        ...this.state.data,
        reference_id: "-1",
        code: "",
        description: "",
        unity: "",
        manufacturer_id: "-1",
        manufacturer: "",
        quantity: 0,
        unitary: 0,
        cost: 0,
        tax: 0,
        taxes: 0,
        total: 0,
        _data: {
          ...this.state.data._data,
          code: "",
          description: "",
          manufacturer_id: "-1",
          manufacturer: "",
        },
      };
      this.handleExecute(data);
    }
  };

  handleData = () => {
    let data = clone(this.props.data);
    const _id = getValue(data, "_id", "-1");
    if (_id === "-1") {
      data = this.handleScheme();
    }
    this.setState({ old: data, data: data, change: false });
    showModal(this.state._id);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        this.handleData();
      }
    }
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog xs" role="dialog">
                <div className="modal-content">
                  <ModalHeader
                    title={`${this.state.title}${this.state.data.code === "" ? "" : " - "}${this.state.data.code}`}
                    handleClose={this.handleClose}
                  />
                  <div className="modal-body">
                    <div className="modal-box">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group input-group-sm">
                            <label>
                              <ModalReference
                                className="link"
                                isFind={true}
                                project_id={this.props.project_id}
                                _id={this.state.data.reference_id}
                                onSetData={this.handleChangeReference}
                              >
                                Referencia
                              </ModalReference>
                            </label>
                            <Reference
                              className="form-control textarea"
                              placeholder="Referencia"
                              project_id={this.props.project_id}
                              _state={this.props._state}
                              select={{
                                _id: this.state.data.reference_id,
                                code: this.state.data.code,
                                caption: this.state.data.description,
                              }}
                              onChange={this.handleChangeReference}
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>Cantidad</label>
                            <Count
                              id={`${this.state._id}_caption`}
                              className="input-group input-group-sm"
                              _state={this.props._state}
                              name="quantity"
                              unity={this.state.data.unity}
                              value={this.state.data.quantity}
                              increase={1}
                              handleValue={this.handleChangeValue}
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>Unitario</label>
                            <Count
                              className="input-group input-group-sm"
                              _state={this.props._state}
                              name="unitary"
                              unity="$"
                              value={this.state.data.unitary}
                              increase={1000}
                              precision={0}
                              handleValue={this.handleChangeValue}
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>Total</label>
                            <Count
                              className="input-group input-group-sm"
                              _state={this.props._state}
                              name="cost"
                              unity="$"
                              value={this.state.data.cost}
                              increase={1000}
                              precision={2}
                              handleValue={this.handleChangeValue}
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>IVA</label>
                            <Count
                              className="input-group input-group-sm"
                              _state={this.props._state}
                              name="tax"
                              unity="%"
                              value={this.state.data.tax}
                              increase={0.1}
                              precision={2}
                              handleValue={this.handleChangeValue}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooter
                      title="¿Retirar detalle?"
                      description="¿Desea retirar el detalle de la compra? este cambio no podrá revertirse."
                      _state={this.props._state}
                      onRemove={this.handleRemove}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}
