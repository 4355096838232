import React from "react";
import "../../../styles/modal.scss";
import "../../../styles/suport.scss";
import { getSource, Subscribe, UnSubscribe, updateList } from "../../../components/utilities";
import Td from "../../../components/td";
import ModalAsset from "../modalAsset";
import { Api as Asset } from "../../../services/asset";

class ComponentConnecteds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "__ComponentConnecteds",
      title: "Conectados",
      name: "SUPORT-KEEP-OPERATION",
      show: false,
      rows: 30,
      data: {
        list: [],
        search: "",
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      select: { _id: "-1", caption: "" },
      change: false,
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(false);
    }, 500);
  };

  handleChangeItem = (e) => {
    console.log(e);
  };

  handleData = (scroll) => {
    const _id = this.props._id;
    const search = this.state.data.search;
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Asset.getConnecteds(_id, search, page, rows, this.state.data.list).then((result) => {
        const data = result.data;
        this.setState({
          show: this.props.show,
          data: data,
        });
      });
    } else {
      Asset.getConnecteds(_id, search, 1, rows, []).then((result) => {
        const data = result.data;
        this.setState({
          show: this.props.show,
          data: data,
          change: false,
        });
      });
    }
  };

  handleScroll = (e) => {
    const h = e.target.scrollTop + e.target.clientHeight + 1;
    if (e.target.id === `${this.state._id}_scroll` && h >= e.target.scrollHeight) {
      this.handleData(true);
    }
  };

  handleSelect = (e) => {
    this.setState({ select: e, change: true });
  };

  handleUpdate = (e) => {
    if (this.state.show) {
      const data = updateList(this.state.data, e);
      this.setState({ data: data, change: true });
    }
  };

  componentDidMount() {
    Subscribe(`${this.props._class}/${this.props._id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show) {
      if (prevProps._class !== this.props._class || prevProps._id !== this.props._id) {
        UnSubscribe(`${prevProps._class}/${prevProps._id}`, (event) => this.eventSetData(event));
        Subscribe(`${this.props._class}/${this.props._id}`, (event) => this.eventSetData(event));
      }
      if (prevProps.show !== this.props.show) {
        this.handleData(false);
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.props._class}/${this.props._id}`, (event) => this.eventSetData(event));
    this.setState({ show: false });
  }

  render() {
    return (
      <React.Fragment>
        <div className="suport-group">
          <Td className="suport-head" cols="75px 175px auto 165px 169px">
            <div className="suport-td">Código</div>
            <div className="suport-td">Tipo</div>
            <div className="suport-td">Referencia</div>
            <div className="suport-td">Área</div>
            <div className="suport-td">Sitio</div>
          </Td>
          <div
            className="suport-scroll small"
            id={`${this.state._id}_scroll`}
            style={{ height: this.props.height || "430px" }}
            onScroll={this.handleScroll}
          >
            {this.state.data.list.map((item, i) => {
              return (
                <ModalAsset
                  key={i}
                  project_id={this.props.project_id}
                  equipment={true}
                  isFind={true}
                  _id={item._id}
                  onSetData={this.handleChangeItem}
                >
                  <Td className="suport-item" cols="75px 175px auto 165px 165px">
                    <div className="suport-td t-c">{item.code}</div>
                    <div className="suport-td">{getSource(item, ["type"], "")}</div>
                    <div className="suport-td">{item.reference}</div>
                    <div className="suport-td">{item.area}</div>
                    <div className="suport-td">{item.address}</div>
                  </Td>
                </ModalAsset>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ComponentConnecteds;
