import React, { useState } from "react";
import ReactDOM from "react-dom";
import "../../../styles/modal.scss";
import { showModal, hideModal, ShowInfo, normalizeName, setFocus, clone } from "../../../components/utilities";
import { Section, Textarea, Select, SelectType } from "../../../components/inputs";
import ModalHeader from "../../../components/modalHeader";
import ModalFooter from "../../../components/modalFooter";
import Td from "../../../components/td";

class ModalScheme extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme();
    this.state = {
      _id: "__ModalScheme",
      title: "Atributo",
      project_id: props.project_id,
      show: false,
      old: data,
      data: data,
      types: [
        { _id: "text", caption: "Texto" },
        { _id: "number", caption: "Número" },
        { _id: "date", caption: "Fecha" },
        { _id: "datetime", caption: "Fecha y hora" },
        { _id: "list", caption: "Lista de valores" },
        { _id: "description", caption: "Descripción" },
        { _id: "power", caption: "Potencia" },
      ],
      change: false,
    };
  }

  handleScheme = (e) => {
    return {
      name: "",
      caption: "",
      description: "",
      type: "text",
      values: "",
      unity: "",
      increase: 1,
      precision: 0,
      _default: "",
    };
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeName = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        name: normalizeName(e.target.value),
        caption: e.target.value,
      },
      change: true,
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData({
        type: this.props.type,
        data: e.data,
        action: e.action,
      });
    }
    this.handleHide();
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    const _state = this.props._state || "0";
    if (_state === "0") {
      if (this.props.isNew) {
        this.setState({ show: true, change: false });
      } else {
        this.setState({ show: true, change: false });
      }
    }
  };

  handleClose = () => {
    if (this.state.change) {
      this.setState({ data: this.state.old, change: false });
    }
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.data.caption.length <= 0) {
      ShowInfo("Nombre requerido");
    } else if (this.state.change) {
      this.handleExecute({ data: this.state.data, action: "set" });
    } else {
      this.handleHide();
    }
  };

  handleFinish = (e) => {
    if (e) {
      this.handleExecute({ data: this.state.data, action: "del" });
    }
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      isNew: e.isNew,
      change: e.change,
    });
    showModal(this.state._id);
    setFocus(`${this.state._id}_caption`, e.focusInt);
  };

  handleData = (focusInt) => {
    const data = clone(this.props.data);
    this.handleSetData({
      data,
      isNew: false,
      change: false,
      focusInt
    });
  };

  handleNew = () => {
    const data = this.handleScheme();
    this.handleSetData({data, isNew: true, change: true, focusInt: true });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        if (this.props.isNew) {
          this.handleNew();
        } else {
          this.handleData(true);
        }
      }
    }
  }

  componentWillUnmount() {
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog xs" role="document">
                <div className="modal-content">
                  <ModalHeader title={this.state.title} handleClose={this.handleClose} />
                  <div className="modal-body">
                    <div className="modal-box">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group input-group-sm">
                            <label>Atributo</label>
                            <Section
                              type="text"
                              className="form-control name"
                              placeholder="Atributo"
                              name="name"
                              _state={this.props._state}
                              value={this.state.data.name}
                              readOnly
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>Nombre</label>
                            <Section
                              type="text"
                              id={`${this.state._id}_caption`}
                              className="form-control name"
                              placeholder="Nombre"
                              name="caption"
                              _state={this.props._state}
                              value={this.state.data.caption}
                              onChange={this.handleChangeName}
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>Tipo</label>
                            <Select
                              className="form-control sp select blod"
                              _state={this.props._state}
                              name="type"
                              value={this.state.data.type}
                              onChange={this.handleChange}
                              list={this.state.types}
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>Valores</label>
                            <Textarea
                              className="form-control notresize"
                              placeholder="Descripción"
                              _state={this.props._state}
                              rows={4}
                              name="values"
                              value={this.state.data.values}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>Unidades</label>
                            <SelectType
                              className="form-control"
                              project_id={this.props.project_id}
                              _class="UNITY"
                              _state={this.props._state}
                              name="unity"
                              value={this.state.data.unity}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooter
                      single="atributo"
                      plural="el atributo"
                      _state={this.props._state}
                      isNew={this.props.isNew}
                      onFinish={this.handleFinish}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function ComponentScheme(props) {
  const [type, setType] = useState(true);
  const _state = props._state || "0";
  const scheme_type = props.data.scheme_type || [];
  const scheme_asset = props.data.scheme_asset || [];

  const listScheme = (e) => {
    let result = [];
    let list = e ? scheme_type : scheme_asset;
    if (typeof list !== "object") {
      list = [];
    }

    list.forEach((element, i) => {
      result.push(
        <Td key={i} className="suport-item" cols="auto 70px 206px">
          <div className="suport-td link">
            <ModalScheme
              project_id={props.project_id || "-1"}
              _state={_state}
              data={element}
              type={type}
              onSetData={props.onSetData}
            >
              {element.caption}
            </ModalScheme>
          </div>
          <div className="suport-td t-c">{element.type}</div>
          <div className="suport-td t-r">{element.values}</div>
        </Td>
      );
    });

    return result;
  };

  return (
    <React.Fragment>
      <div className="modalList single pt-0 pb-0">
        <div className="modalListFind">
          <div className="suport-td border-0">
            <div className="custom-control custom-radio custom-control-inline class-scheme">
              <input
                type="radio"
                className="custom-control-input"
                id="scheme_type"
                name="scheme_type"
                value={true}
                checked={type}
                onChange={() => setType(true)}
              />
              <label className="custom-control-label" htmlFor="scheme_type">
                Atributos por tipo
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline class-scheme">
              <input
                type="radio"
                className="custom-control-input"
                id="scheme_asset"
                name="scheme_type"
                value={false}
                checked={!type}
                onChange={() => setType(false)}
              />
              <label className="custom-control-label" htmlFor="scheme_asset">
                Atributos por equipo
              </label>
            </div>
            <ModalScheme
              className="btn btn-primary btn-sm float-right"
              project_id={props.project_id || "-1"}
              _state={_state}
              isNew={true}
              type={type}
              onSetData={props.onSetData}
            >
              Nuevo
            </ModalScheme>
          </div>
        </div>
        <div className="modalListList">
          <div className="suport-group">
            <Td className="suport-head" cols="auto 70px 210px">
              <div className="suport-th">Atributo</div>
              <div className="suport-th">Tipo</div>
              <div className="suport-th">Valores</div>
            </Td>
            <div className="suport-scroll small" style={{ height: props.height || "300px" }}>
              {listScheme(type)}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ComponentScheme;
