import React from "react";
import { css } from "@emotion/core";
import { BeatLoader } from "react-spinners";
import { useSelector } from "react-redux";

const override = css`
  grid-column-end: 2;
  grid-column-start: 2;
  grid-row-end: 2;
  grid-row-start: 2;
  margin: auto;
`;

function Spinner(props) {
  const loading = useSelector((state) => {
    return state.sistem.loading;
  });

  if (loading.show) {
    return (
      <React.Fragment>
        <div className="__spinner">
          <BeatLoader css={override} size={20} color={"rgb(0,151,254)"} loading={loading.show} />
        </div>
      </React.Fragment>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
}

export default Spinner;
