import { http } from "../components/http";
import { App, ShowAlert } from "../components/utilities";
import environment from "../env/env";
const apiUrl = environment.url;

export const Api = {
  signIn: async function (username, password) {
    const params = {
      username: username,
      password: password,
      app: App.app,
    };
    return await http("POST", `${apiUrl}/signin`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  validUser: async function (username) {
    return await http("GET", `${apiUrl}/valid/${username}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  signup: async function (username, password, confirmation, caption, project, module_id, city_id, code) {
    const params = {
      username: username,
      password: password,
      confirmation: confirmation,
      caption: caption,
      project: project,
      module_id: module_id,
      city_id: city_id,
      code: code,
      app: App.app,
    };
    return await http("POST", `${apiUrl}/signup`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  forgot: async function (username, password, confirmation, code) {
    const params = {
      username: username,
      password: password,
      confirmation: confirmation,
      code: code,
      app: App.app,
    };
    return await http("POST", `${apiUrl}/forgot`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  issues: async function (username, access, use) {
    return await http("POST", `${apiUrl}/issues`, { username, access, use })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
};
