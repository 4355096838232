import React from "react";
import ItemFolder from "../components/itemFolder";
import { connect } from "react-redux";
import { getSource } from "./utilities";

class MenuFolders extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.folders.map((folder, i) => {
          return <ItemFolder key={i} folder={folder} select_id={this.props.folder._id || "-1"} index={i} />;
        })}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    project_id: getSource(state.sistem, ["project", "_id"], "-1"),
    folders: getSource(state.sistem, ["folders"], []),
    folder: getSource(state.sistem, ["folder"], {}),
  };
}

export default connect(mapStateToProps)(MenuFolders);
