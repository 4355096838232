import React from "react";
import ReactDOM from "react-dom";
import "../../../styles/modal.scss";
import "../../../styles/suport.scss";
import { showModal, hideModal, genId, getValue, clone, getData, getNumber } from "../../../components/utilities";
import { Count } from "../../../components/inputs";
import Td from "../../../components/td";
import ModalFooter from "../../../components/modalFooter";
import ModalHeader from "../../../components/modalHeader";
import { ActivityStock } from "../listActivityStock";

class ModalConsumption extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme();
    this.state = {
      _id: "__ModalConsumption",
      title: "Consumo de material",
      show: false,
      old: data,
      data: data,
      maximum: 0,
      change: false,
    };
  }

  handleScheme = (data) => {
    const now = new Date();
    return {
      cost: 0,
      date_of: now,
      _state: "0",
      operation_id: this.props.operation_id,
      _id: genId("-1"),
      odt_id: this.props.odt_id,
      vale_id: this.props.vale_id,
      detail_id: "-1",
      reference_id: "-1",
      quantity: 0,
      unitary: 0,
      _data: {
        code: "",
        unity: "und",
        reference: "",
      },
    };
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeReference = (e) => {
    this.setState({
      maximum: getValue(e, "stock", 0),
      unitary: getValue(e, "unitary", 0),
      data: {
        ...this.state.data,
        reference_id: getValue(e, "reference_id", "-1"),
        _data: {
          ...this.state.data._data,
          code: getValue(e, "code", ""),
          unity: getValue(e, "unity", "und"),
          reference: getValue(e, "caption", ""),
          stock: getValue(e, "stock", 0),
        },
      },
      change: true,
    });
  };

  handleChangeQuantity = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.name]: e.value,
      },
      change: true,
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
    this.handleHide();
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    const _state = this.props._state || "0";
    if (_state === "0") {
      this.setState({
        show: true,
        change: false,
      });
    }
  };

  handleClose = () => {
    if (this.state.change) {
      this.setState({ data: this.state.old, change: false });
    }
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.change) {
      const params = this.state.data;
      this.handleExecute(params);
    } else {
      this.handleHide();
    }
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      change: false,
    });
    showModal(this.state._id);
  };

  handleData = () => {
    const data = clone(this.props.data);
    this.handleSetData({ data, change: false });
  };

  handleNew = () => {
    const data = this.handleScheme();
    this.handleSetData({ data, change: true });
  };

  handleRemove = (e) => {
    if (e) {
      const data = {
        ...this.state.data,
        reference_id: "-1",
        quantity: 0,
        unitary: 0,
      };
      this.handleExecute(data);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        if (this.props.isNew) {
          this.handleNew();
        } else {
          this.handleData();
        }
      }
    }
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog xs" role="dialog">
                <div className="modal-content">
                  <ModalHeader title={this.state.title} handleClose={this.handleClose} />
                  <div className="modal-body">
                    <div className="modal-box">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group input-group-sm">
                            <label>Referencia</label>
                            <ActivityStock
                              className="form-control sp ctr-sm t-m"
                              classNameBtn="input-group-text sp"
                              placeholder="Referencia"
                              project_id={this.props.project_id}
                              odt_id={this.props.odt_id}
                              vale_id={this.props.vale_id}
                              class_id="ALL"
                              activity_base={1}
                              _state={this.state.data._state}
                              select={{
                                _id: this.state.data.reference_id,
                                caption: this.state.data._data.reference,
                              }}
                              onChange={this.handleChangeReference}
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>Cantidad</label>
                            <Count
                              className="input-group input-group-sm"
                              _state={this.state.data._state}
                              name="quantity"
                              unity={this.state.data._data.unity}
                              value={this.state.data.quantity}
                              increase={1}
                              precision={2}
                              maximum={this.state.maximum}
                              handleValue={this.handleChangeQuantity}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooter
                      title="¿Retirar actividad?"
                      description="¿Desea retirar la actividad, sobre el componente? este cambio no podrá revertirse."
                      _state={this.props._state}
                      onRemove={this.handleRemove}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function ComponentConsumptions(props) {
  const consumptions = props.consumptions || [];

  return (
    <React.Fragment>
      <div className="suport-group border-0 mb-4">
        <div className="suport-group">
          <Td className="suport-head" cols="75px auto 35px 85px">
            <div className="suport-td">Código</div>
            <div className="suport-td">Referencia</div>
            <div className="suport-td">Und</div>
            <div className="suport-td">Cantidad</div>
          </Td>
          <div className="suport-scroll" style={{ height: props.height || "280px" }}>
            {consumptions.map((item, i) => {
              const detail_id = getValue(item, "detail_id", "-1");
              if (detail_id === "-1") {
                return (
                  <ModalConsumption
                    key={i}
                    project_id={props.project_id}
                    _state={props._state}
                    operation_id={props.operation_id}
                    odt_id={props.odt_id}
                    vale_id={props.vale_id}
                    data={item}
                    onSetData={props.onSetData}
                  >
                    <Td className="suport-item" cols="75px auto 35px 81px">
                      <div className="suport-td link t-c">{getData(item, "code", "")}</div>
                      <div className="suport-td link">{getData(item, "reference", "")}</div>
                      <div className="suport-td t-c">{getData(item, "unity", "")}</div>
                      <div className="suport-td t-r">{getNumber(item, "quantity", 0)}</div>
                    </Td>
                  </ModalConsumption>
                );
              } else {
                return (
                  <Td key={i} className="suport-item" cols="75px auto 35px 81px">
                    <div className="suport-td t-c">{getData(item, "code", "")}</div>
                    <div className="suport-td">{getData(item, "reference", "")}</div>
                    <div className="suport-td t-c">{getData(item, "unity", "")}</div>
                    <div className="suport-td t-r">{getNumber(item, "quantity", 0)}</div>
                  </Td>
                );
              }
            })}
            <ModalConsumption
              project_id={props.project_id}
              _state={props._state}
              operation_id={props.operation_id}
              odt_id={props.odt_id}
              vale_id={props.vale_id}
              isNew={true}
              onSetData={props.onSetData}
            >
              <Td className="suport-item" cols="75px auto 35px 81px">
                <div className="suport-td link t-c"></div>
                <div className="suport-td link"></div>
                <div className="suport-td t-c"></div>
                <div className="suport-td t-r"></div>
              </Td>
            </ModalConsumption>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ComponentConsumptions;
