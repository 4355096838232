import React from "react";
import {
  toolTip,
  getDateDifference,
  ShowAlert,
  getValue,
  section,
  capitalize,
  phone,
  float,
  formatDHM,
  getRow,
  more,
  less,
} from "./utilities";
import ModalUpload from "../modals/modalUpload";
import ListCitys from "../modals/listCitys";
import ListTypes from "../modals/listTypes";
import ListContacts from "../modals/listContacts";
import ListUsers from "../modals/listUsers";
import { Api as Project } from "../services/project";

function Input(props) {
  const handleChange = (e) => {
    props.onChange(e);
  };

  return (
    <React.Fragment>
      <input
        id={props.id}
        type={props.type}
        className={props.className}
        autoComplete={props.autoComplete || "nope"}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        name={props.name}
        value={props.value || ""}
        onChange={handleChange}
        onKeyDown={props.onKeyDown}
        disabled={!(props._state === "0")}
      />
    </React.Fragment>
  );
}

function Textarea(props) {
  const handleChange = (e) => {
    props.onChange(e);
  };

  return (
    <React.Fragment>
      <textarea
        className={props.className}
        autoComplete={props.autoComplete || "nope"}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        rows={props.rows || 3}
        name={props.name}
        value={props.value}
        onChange={handleChange}
        disabled={!(props._state === "0")}
      />
    </React.Fragment>
  );
}

function Name(props) {
  const handleChange = (e) => {
    const old = e.target.value;
    const index = e.target.selectionStart;
    e.target.value = capitalize(e.target.value);
    const n = e.target.value.length - old.length;
    e.target.selectionStart = index + n;
    e.target.selectionEnd = index + n;
    props.onChange(e);
  };

  return (
    <React.Fragment>
      <Input
        id={props.id}
        type={props.type}
        className={props.className}
        autoComplete={props.autoComplete}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        name={props.name}
        value={props.value}
        _state={props._state}
        onChange={handleChange}
      />
    </React.Fragment>
  );
}

function Section(props) {
  const handleChange = (e) => {
    const old = e.target.value;
    const index = e.target.selectionStart;
    e.target.value = section(e.target.value);
    const n = e.target.value.length - old.length;
    e.target.selectionStart = index + n;
    e.target.selectionEnd = index + n;
    props.onChange(e);
  };

  return (
    <React.Fragment>
      <Input
        id={props.id}
        type={props.type}
        className={props.className}
        autoComplete={props.autoComplete}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        name={props.name}
        value={props.value}
        _state={props._state}
        onChange={handleChange}
      />
    </React.Fragment>
  );
}

function Phone(props) {
  const handleChange = (e) => {
    const old = e.target.value;
    const index = e.target.selectionStart;
    e.target.value = phone(e.target.value);
    const n = e.target.value.length - old.length;
    e.target.selectionStart = index + n;
    e.target.selectionEnd = index + n;
    props.onChange(e);
  };

  return (
    <React.Fragment>
      <Input
        id={props.id}
        type={props.type}
        className={props.className}
        autoComplete={props.autoComplete}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        _state={props._state}
        name={props.name}
        value={props.value}
        onChange={handleChange}
      />
    </React.Fragment>
  );
}

function Select(props) {
  const list = props.list || [];
  const handleChange = (e) => {
    const item = list.find((item) => item._id === e.target.value);
    const result = {
      target: {
        name: e.target.name,
        value: e.target.value,
        caption: getValue(item, "caption"),
      },
    };
    props.onChange(result);
  };
  return (
    <React.Fragment>
      <select
        className={props.className}
        readOnly={props.readOnly}
        name={props.name}
        value={props.value}
        onChange={handleChange}
        disabled={props._state !== "0"}
      >
        {list.map((item, i) => {
          return (
            <option key={i} value={item._id}>
              {item.caption}
            </option>
          );
        })}
      </select>
    </React.Fragment>
  );
}

function Button(props) {
  toolTip();
  const state = props._state || "0";
  return (
    <React.Fragment>
      <button
        type="button"
        className={props.className || "btn btn-outline-secondary btn-sm"}
        data-toggle="tooltip"
        data-placement={props.placement || "bottom"}
        title={props.title}
        disabled={state !== "0"}
        onClick={props.onClick}
      >
        {props.children}
      </button>
    </React.Fragment>
  );
}

function State(props) {
  toolTip();
  const display = props._state !== props.newState;
  const handleChange = (e) => {
    props.onClick(props.newState);
  };

  return (
    <React.Fragment>
      <div
        type="button"
        className={props.className}
        data-toggle="tooltip"
        data-placement={props.placement || "bottom"}
        title={props.title}
        style={{ display: display ? "block" : "none" }}
        onClick={handleChange}
      >
        {props.children}
      </div>
    </React.Fragment>
  );
}

function ToolTip(props) {
  toolTip();
  const state = props._state === undefined ? "0" : props._state;
  const display = props.display === undefined ? "block" : props.display;

  return (
    <React.Fragment>
      <div
        className={props.className || "btn btn-icon btn-sm"}
        data-toggle="tooltip"
        data-placement={props.placement || "bottom"}
        title={props.title}
        disabled={state !== "0"}
        style={{ display: display }}
        onClick={props.onClick}
      >
        {props.children}
      </div>
    </React.Fragment>
  );
}

function Avatar(props) {
  const path = props.path || "";

  return (
    <React.Fragment>
      <ModalUpload
        project_id={props.project_id || "-1"}
        _class="IMAGE"
        object_id={props.object_id}
        main_id="AVATAR"
        _state={props._state}
        path={props.path || ""}
        onChange={props.onChange}
      >
        <img
          className={props.className || "rounded-circle img-fluid"}
          alt=""
          src={path === "" ? "/avatar.svg" : path}
        />
      </ModalUpload>
    </React.Fragment>
  );
}

function Image(props) {
  const path = props.path || "";

  return (
    <React.Fragment>
      <ModalUpload
        project_id={props.project_id || "-1"}
        _class="IMAGE"
        object_id={props.object_id}
        main_id={props.main_id || "-1"}
        _state={props._state}
        path={props.path || ""}
        onChange={props.onChange}
      >
        <img className={props.className || "rounded-circle img-fluid"} alt="" src={path === "" ? "/image.svg" : path} />
      </ModalUpload>
    </React.Fragment>
  );
}

function Document(props) {
  const path = props.path || "";

  return (
    <React.Fragment>
      <ModalUpload
        project_id={props.project_id || "-1"}
        _class="DOCUMENT"
        object_id={props.object_id}
        main_id={props.main_id || "-1"}
        _state={props._state}
        path={props.path || ""}
        onChange={props.onChange}
      >
        <img className={props.className || "rounded-circle img-fluid"} alt="" src={path === "" ? "/logo.svg" : path} />
      </ModalUpload>
    </React.Fragment>
  );
}

class Typehead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      list: [],
      count: 0,
      autoSelect: getValue(props, "autoSelect", false),
      _id: getValue(props, "_id", "_id"),
      caption: getValue(props, "caption", "search"),
    };
  }

  handleClear = () => {
    this.setState({
      list: [],
      count: 0,
    });
  };

  handleIndex = (e) => {
    this.setState({
      index: e,
    });
  };

  handleSelect = (e) => {
    this.handleClear();
    this.props.onSelect(e);
  };

  handleBlur = (e) => {
    if (this.state.autoSelect && this.state.count > 0) {
      const item = getRow(this.state.list, this.state.index);
      this.handleSelect(item);
    } else {
      setTimeout(() => {
        this.handleClear();
      }, 200);
    }
  };

  handleKeyDown = (e) => {
    if (e.key === "ArrowUp") {
      this.handleIndex(this.state.index - 1);
    } else if (e.key === "ArrowDown") {
      this.handleIndex(this.state.index + 1);
    } else if (e.key === "Enter") {
      const item = getRow(this.state.list, this.state.index);
      this.handleSelect(item);
    }
  };

  handleClick = (e) => {
    this.handleSelect(e);
  };

  hadleSearch = (query) => {
    this.props.source(query).then((result) => {
      const data = getValue(result, "data", "{}");
      const list = getValue(data, "list", []);
      const count = getValue(data, "count", 0);
      this.setState({
        list: list,
        count: count,
        index: 0,
      });
    });
  };

  handleChange = (e) => {
    if (e.target.value.length >= 2) {
      this.hadleSearch(e.target.value);
    } else {
      this.setState({
        count: 0,
      });
    }
    this.props.onChange(e);
  };

  render() {
    return (
      <React.Fragment>
        <input
          id={this.props.id}
          type={this.props.type}
          className={this.props.className}
          autoComplete={this.props.autoComplete || "off"}
          placeholder={this.props.placeholder}
          readOnly={this.props.readOnly}
          disabled={this.props._state !== "0"}
          name={this.props.name}
          value={this.props.value}
          _state={this.props._state}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
        />
        <ul className={this.state.count > 0 ? "typeahead dropdown-menu wall show" : "typeahead dropdown-menu wall"}>
          {this.state.list.map((item, i) => {
            return (
              <li key={i} onClick={() => this.handleClick(item)}>
                <div
                  className={
                    getRow(this.state.list, this.state.index)[this.state._id] === item[this.state._id]
                      ? "dropdown-item active"
                      : "dropdown-item"
                  }
                >
                  {item[this.state.caption]}
                </div>
              </li>
            );
          })}
        </ul>
      </React.Fragment>
    );
  }
}

class SelectType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project_id: props.project_id || "-1",
      _class: props._class,
      className: props.className || "",
      readOnly: props.readOnly || false,
      name: props.name || "",
      _state: props._state || "0",
      list: [],
    };
  }

  handleChange = (e) => {
    const list = this.state.list;
    const item = list.find((item) => item._id === e.target.value);
    const result = {
      target: {
        name: e.target.name,
        value: e.target.value,
        caption: getValue(item, "caption"),
      },
    };
    if (typeof this.props.onChange === "function") {
      this.props.onChange(result);
    }
  };

  handleData = (e) => {
    const project_id = this.state.project_id;
    const _class = this.state._class;
    Project.types(project_id, _class, "0", "", 1, 100, [
      {
        _id: "-1",
        caption: "",
      },
    ]).then((result) => {
      const list = getValue(result.data, "list", []);
      this.setState({
        list: list,
      });
    });
  };

  componentDidMount() {
    this.handleData();
  }

  render() {
    return (
      <React.Fragment>
        <select
          className={this.state.className}
          readOnly={this.state.readOnly}
          name={this.state.name}
          value={this.props.value || "-1"}
          onChange={this.handleChange}
          disabled={this.state._state !== "0"}
        >
          {this.state.list.map((item, i) => {
            return (
              <option key={i} value={item._id}>
                {item.caption}
              </option>
            );
          })}
        </select>
      </React.Fragment>
    );
  }
}

function Type(props) {
  const select = getValue(props, "select", { _id: "-1", caption: "" });

  return (
    <React.Fragment>
      <div className="input-group input-group-sm">
        <input className={props.className || "form-control"} readOnly value={select.caption || ""} />
        <div className="input-group-append">
          <ListTypes
            className={props.classNameBtn || "input-group-text"}
            project_id={props.project_id}
            _class={props._class}
            _state={props._state}
            title={props.title}
            select={select}
            onSetData={props.onChange}
          >
            <i className="fa fa-ellipsis-h"></i>
          </ListTypes>
        </div>
      </div>
    </React.Fragment>
  );
}

function City(props) {
  return (
    <React.Fragment>
      <div className="input-group input-group-sm">
        <input className={props.className || "form-control"} readOnly value={props.value || ""} />
        <div className="input-group-append">
          <ListCitys
            className={props.classNameBtn || "input-group-text"}
            _state={props._state}
            onSetData={props.onChange}
            city_id={props.city_id}
          >
            <i className="fa fa-ellipsis-h"></i>
          </ListCitys>
        </div>
      </div>
    </React.Fragment>
  );
}

function Contact(props) {
  const select = getValue(props, "select", { _id: "-1", caption: "" });

  return (
    <React.Fragment>
      <div className="input-group input-group-sm">
        <input className={props.className || "form-control"} readOnly value={select.caption || ""} />
        <div className="input-group-append">
          <ListContacts
            className={props.classNameBtn || "input-group-text"}
            project_id={props.project_id}
            _class={props._class}
            _state={props._state}
            select={select}
            onSetData={props.onChange}
            disabled={props._state !== "0"}
          >
            <i className="fa fa-ellipsis-h"></i>
          </ListContacts>
        </div>
      </div>
    </React.Fragment>
  );
}

function User(props) {
  const select = getValue(props, "select", { _id: "-1", caption: "" });

  return (
    <React.Fragment>
      <div className="input-group input-group-sm">
        <input className={props.className || "form-control"} readOnly value={select.caption || ""} />
        <div className="input-group-append">
          <ListUsers
            className={props.classNameBtn || "input-group-text"}
            project_id={props.project_id}
            _class={props._class}
            _state={props._state}
            select={select}
            onSetData={props.onChange}
            disabled={props._state !== "0"}
          >
            <i className="fa fa-ellipsis-h"></i>
          </ListUsers>
        </div>
      </div>
    </React.Fragment>
  );
}

function Group(props) {
  return (
    <React.Fragment>
      <div className="input-group input-group-sm">
        <Input
          id={props.id}
          type="text"
          className="form-control sp ctr-sm"
          autoComplete={props.autoComplete}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          name={props.name}
          value={props.value}
          _state={props._state}
          onChange={props.onChange}
        />
        <span className="input-group-btn">
          <Button type="button" className="btn sp btn-secondary btn-sm" _state={props._state}>
            <i className="fa fa-ellipsis-h"></i>
          </Button>
        </span>
      </div>
    </React.Fragment>
  );
}

function Message(props) {
  return (
    <React.Fragment>
      <div className="input-group input-group-sm">
        <Input
          id={props.id}
          type="text"
          className="form-control ctr-sm"
          autoComplete={props.autoComplete}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          name={props.name}
          value={props.value}
          _state={props._state}
          onChange={props.onChange}
        />
        <span className="input-group-append">
          <Button type="button" className="btn btn-secondary btn-sm" _state={props._state}>
            <i className="fas fa-paper-plane"></i>
          </Button>
        </span>
      </div>
    </React.Fragment>
  );
}

function Tools(props) {
  return (
    <React.Fragment>
      <div className="input-group input-group-sm">
        <Input
          id={props.id}
          type="text"
          className="form-control ctr-sm"
          autoComplete={props.autoComplete}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          name={props.name}
          value={props.value}
          _state={props._state}
          onChange={props.onChange}
        />
        <span className="input-group-append">
          <Button type="button" className="btn btn-secondary btn-sm" _state={props._state}>
            <i className="fa fa-search"></i>
          </Button>
          <Button type="button" className="btn btn-secondary btn-sm" _state={props._state}>
            <i className="fas fa-paperclip"></i>
          </Button>
          <Button type="button" className="btn btn-secondary btn-sm w32" _state={props._state}>
            <i className="fa fa-ellipsis-v"></i>
          </Button>
        </span>
      </div>
    </React.Fragment>
  );
}

function Count(props) {
  const classGroupText = props.classGroupText || "input-group-text";
  const write = props.write === undefined ? true : props.write;
  const min = props.minimum || 0;
  const max = props.maximum || 0;
  const _state = props._state || "0";
  const name = props.name || "";
  const unit = props.unity || "und";
  const increase = props.increase || 0;
  let value = props.value || 0;

  const handleChange = (e) => {
    if (_state === "0") {
      if (props.handleValue !== null) {
        props.handleValue(e.target);
      }
    }
  };

  const handleBlur = (e) => {
    e.target.value = float(e.target.value);
    if (props.handleValue !== null) {
      props.handleValue(e.target);
    }
  };

  const handleValue = (e) => {
    if (_state === "0") {
      e = float(e);
      if (max > 0 && e > max) {
        ShowAlert(`Cantidad excede el máximo establecido - (${max})`);
        e = max;
      } else if (e < min) {
        ShowAlert(`Cantidad excede el minimo establecido - (${min})`);
        e = min;
      }
      if (props.handleValue !== null) {
        props.handleValue({ name: name, value: e });
      }
    }
  };

  if (write) {
    return (
      <React.Fragment>
        <div className={props.className || "input-group input-group-sm"}>
          <div className="input-group-prepend">
            <div className={props.classNameUnity || "input-group-text unity"} disabled={false}>
              {unit}
            </div>
          </div>
          <input
            type="text"
            className={props.classNameInput || "form-control number t-r"}
            placeholder={props.placeholder || "Cantidad"}
            autoComplete={props.autoComplete || "nope"}
            disabled={!(_state === "0")}
            name={name}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div className={props.vertical ? "input-group-append vertical" : "input-group-append"}>
            <div
              className={props.vertical ? `${classGroupText} vertical` : `${classGroupText}`}
              disabled={!(_state === "0")}
              onClick={() => handleValue(more(value, increase))}
            >
              <i className="fas fa-plus"></i>
            </div>
            <div
              className={props.vertical ? `${classGroupText} vertical` : `${classGroupText}`}
              disabled={!(_state === "0")}
              onClick={() => handleValue(less(value, increase))}
            >
              <i className="fas fa-minus"></i>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className={props.className || "input-group input-group-sm"}>
          <div className="input-group-prepend">
            <div className={props.classNameUnity || "input-group-text unity"} disabled={false}>
              {unit}
            </div>
          </div>
          <div className="form-control number t-r mr-60" name={name} value={value}>
            {value}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function Time(props) {
  const date_int = props.date_int || "date_int";
  const date_end = props.date_end || "date_end";
  const data = props.data || {};
  const time = getDateDifference(data, date_int, date_end, true);
  return <div className="form-control sp ctr-sm t-m-c">{formatDHM(time)}</div>;
}

function Checkbox(props) {
  const handleChange = (e) => {
    props.onChange(e);
  };

  return (
    <React.Fragment>
      <input
        className={props.className || "form-check-input"}
        type="checkbox"
        disabled={!(props._state === "0")}
        id={`${props._id}_Checkbox1`}
        name={props.name}
        checked={props.value || false}
        onChange={handleChange}
      />
    </React.Fragment>
  );
}

export {
  Input,
  Textarea,
  Name,
  Section,
  Phone,
  Select,
  Button,
  State,
  ToolTip,
  Avatar,
  Image,
  Document,
  Typehead,
  SelectType,
  Type,
  City,
  Contact,
  User,
  Group,
  Message,
  Tools,
  Count,
  Time,
  Checkbox,
};
