import React from "react";
import "../styles/document.scss";
import TopBar from "../components/topbar";
import { Loading } from "../components/utilities";
import * as Scroll from "react-scroll";
import ModalObject from "../modals/modalObject";
const Element = Scroll.Element;

class HomeVoice extends React.Component {
  constructor(props) {
    super(props);
    Loading();
    this.title = "Reto 17691";
  }

  render() {
    return (
      <React.Fragment>
        <TopBar></TopBar>
        <div className="blog">
          <div className="container-fluid">
            <header className="blog-header py-3">
              <div className="row flex-nowrap justify-content-between align-items-center">
                <div className="col-12 text-center">
                  <label className="blog-header-logo text-dark">{this.title}</label>
                </div>
              </div>
            </header>
          </div>
          <main role="main" className="container">
            <div className="row">
              <div className="col-md-8 blog-main">
                <div className="blog-post">
                  <Element id="reto">
                    <h2 className="blog-post-title">Home voice</h2>
                    <p className="blog-post-meta">Septiembre 27, 2020</p>

                    <p>
                      Es nuestra propuesta para la implementación de un sistema voice picking para el mejoramiento del proceso de picking,
                      packing y despacho de la comercializadora Santander.
                    </p>
                    <hr></hr>
                    <p>
                      <strong>Voice picking</strong> es un sistema inteligente de gestión que emplea comandos de voz para recibir y dar
                      información al sistema de gestión del almacén, se basa en las herramientas <strong>SRC</strong>
                      <strong className="font-italic"> (Speech Recognition Commands)</strong> comandos de reconocimiento de voz que permiten
                      controlar una interfaz o aplicación utilizando únicamente la voz y <strong>TTS</strong>
                      <strong className="font-italic"> (Text to Speech)</strong> comandos de texto a voz que permiten la interacción con
                      usuarios.
                    </p>

                    <h3>Objetivos</h3>
                    <p>
                      El objetivo principal es implementar un sistema de voice picking a través de una aplicación móvil y web inhouse que se
                      integre al ERP del cliente, para aumentar la capacidad de despacho, reduciendo los tiempos de alistamiento y empaque.
                    </p>

                    <h3>Objetivos específicos</h3>
                    <p>Como objetivos específicos del proyecto, se plantean:</p>

                    <ul>
                      <li>
                        Búsqueda y asignación del documento general de separación, a través de SRC, desde una aplicación móvil, para reducir
                        los tiempos de alistamiento.
                      </li>
                      <li>
                        Implementación del dictado de ubicación y cantidades, a través de SRC y TTS, para la reducción de tiempo de
                        alistamiento y control de ejecución.
                      </li>
                      <li>
                        Implementar un tablero de cálculo y control de tiempos web inhouse, para el mejoramiento del control en el proceso
                        de picking, packing y despacho.
                      </li>
                    </ul>

                    <h3>Alcances de la propuesta</h3>
                    <p>Como alcances del proyecto, se plantea:</p>
                    <ul>
                      <li>SRC, desde aplicación móvil, para la asignación del documento general de separación.</li>
                      <li>SRC, desde aplicación móvil, para el dictado de la ubicación y referencias de la mercancía.</li>
                      <li>TTS, desde aplicación móvil, para confirmación de ubicación y cantidades de la mercancía.</li>
                      <li>SRC, desde aplicación móvil de confirmación y validación de picking y packing.</li>
                      <li>Reducción de tiempos en el proceso de picking y packing.</li>
                      <li>Tablero de control y seguimiento del documento general de separación.</li>
                      <li>Cálculo de tiempos de asignación, búsqueda y confirmación por referencia y documento general de separación.</li>
                      <li>Consulta web y móvil del proceso.</li>
                      <li>Informes de rotación de referencias para el mejoramiento del proceso de almacenamiento.</li>
                      <li>
                        Videos explicativos del uso y funcionalidad de la aplicación, para reducir el impacto del proceso de implementación.
                      </li>
                    </ul>
                  </Element>
                </div>

                <div className="blog-post">
                  <Element id="mockups">
                    <h2 className="blog-post-title">Mockups</h2>

                    <p>Un mockup es una maqueta o modelo que busca enseñar o demostrar de manera gráfica el resultado esperado.</p>

                    <h2>App móvil</h2>
                    <p>
                      <strong>Mobile first</strong>
                      <em> (móviles primero)</em> es una filosofía de desarrollo, que se refiere a un modo de diseñar que tenga en cuenta,
                      en primera instancia, un dispositivo móvil.
                    </p>

                    <p>Bajo esta filosofía, planteamos nuestros mockup maketando de manera grafica el alcance de los objetivos</p>

                    <h3>Voice picking</h3>
                    <div className="row mb-2">
                      <div className="col-md-7">
                        <img className="mockup-mobile" src="images/HV1.jpg" alt="" />
                      </div>
                      <div className="col-md-5 mt-4">
                        <p>
                          Tomando como partida el <strong>documento general de separación</strong>, y la implementación de la tecnología
                          voice picking, se presenta un mockup con un encabezado que identifique el documento, la lista de los artículos con
                          su ubicación y con el uso de <strong>código de verificación</strong>, se confirma que el picker está en la
                          ubicación del producto y comunica y confirma las cantidades a tomar, esta labor se realiza de manera individual
                          por producto, hasta completar la lista de productos. En el mockup se puede identificar el tab <strong>3</strong>,
                          que señala con el ícono <strong>voice</strong> la referencia de productos sobre la que se esta interactuando.
                        </p>
                      </div>
                    </div>

                    <h3>Tablero de resumen de pickers</h3>
                    <div className="row mb-2">
                      <div className="col-md-7">
                        <img className="mockup-mobile" src="images/HV2.jpg" alt="" />
                      </div>
                      <div className="col-md-5 mt-4">
                        <p>
                          Los <strong>tableros de resumen o control</strong>, presentan variables cuantificables que permiten medir el
                          rendimiento como elemento de mejoramiento continuo, se presenta un mockup que lista en una
                          <strong> fecha determinada</strong> el <strong>total de pedidos</strong> y los <strong>tiempos promedios</strong>{" "}
                          de <strong>alistamiento</strong> y <strong>empaque</strong> por cada
                          <strong> picker</strong>.
                        </p>

                        <p>Las variable a evaluar serán revisadas y definidas sobre la ejecución.</p>
                      </div>
                    </div>

                    <h3>Tablero de resumen de picking</h3>
                    <div className="row mb-2">
                      <div className="col-md-7">
                        <img className="mockup-mobile" src="images/HV3.jpg" alt="" />
                      </div>
                      <div className="col-md-5 mt-4">
                        <p>
                          Se presenta un mockup que lista en una <strong>fecha determinada</strong> el total de <strong>pedidos</strong> y
                          los <strong>tiempos promedio</strong> de alistamiento de la jornada y las cantidades por{" "}
                          <stron>refencias de producto</stron> que se movieron durante la jornada.
                        </p>

                        <p>Las variable a evaluar serán revisadas y definidas sobre la ejecución.</p>
                      </div>
                    </div>

                    <h3>Auriculares</h3>

                    <div className="row mb-2">
                      <div className="col-md-6">
                        <p>
                          Uno de los puntos importantes en la implementación de la tecnología de <strong>voice picking</strong> es la
                          escogencia de los auriculares los cuales deben contar con algunos elementos clave:
                        </p>

                        <ul>
                          <li>Conexión bluetooth para evitar el riesgo de que los cables se enrreden con el entorno de trabajo.</li>
                          <li>
                            Mínimo consumo y máxima capacidad de batería que permita cubrir una jornada completa de trabajo sin necesidad de
                            hacer pausas para recarga.
                          </li>
                          <li>Anulación de ruido para mitigar el impacto de ruido externos en el entendimiento de los comandos de voz.</li>
                          <li>
                            Uso de un solo auricular que permite percibir el entorno y evitar accidentes por vehículos u obstáculos en el
                            área de trabajo.
                          </li>
                          <li>Diadema flexible que permita un ajuste suave a la cabeza.</li>
                          <li>Micrófono extensible que mejore la captura de sonido y minimice el ruido del exterior.</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <img className="element m mb-4" src="images/auricular1.jpg" alt="" />
                      </div>
                    </div>

                    <h3>Estrategia de capacitación</h3>
                    <p>
                      Uno de los retos en la implementación de soluciones tecnológicas es la capacitación, para dismonuir el impacto se
                      plantea el uso de <strong>videos explicativos</strong> del proyecto y el uso de las herramientas que ayuden a los
                      usuarios a comprender el funcionamiento de la solución.
                    </p>

                    <p>Ejemplo de estos videos, utilizados con buenos resultados en nuestra experiencia.</p>

                    <iframe
                      className="mb-4"
                      title="Nutrilate"
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/tuMLvWp7Kf0"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    />

                    <h3>Web de escritorio</h3>

                    <p>
                      La <strong>web</strong> tiene como concepto de diseño un entorno de administración minimalista, que permite la
                      integración de colores corporativos, con barras de botones con funcionalidades estandarizadas, que reducen la curva de
                      aprendizaje.
                    </p>

                    <p>
                      Ejemplo <strong>Ilumina</strong> <em>(Sistema de gestión de mantenimiento de alumbrado público)</em>
                    </p>

                    <img className="mockup-main mb-4" src="images/web_main.png" alt="" />

                    <p>
                      El mockup <strong>web</strong> cuenta con los siguientes elementos:
                    </p>

                    <ol>
                      <li>Árbol de navegación.</li>
                      <li>Lista principal.</li>
                      <li>Formularios modales.</li>
                      <li>Tableros de resumen y control.</li>
                    </ol>

                    <h3>Árbol de navegación</h3>
                    <div className="row mb-2 mt-3">
                      <div className="col-md-5">
                        <p>
                          El <strong>árbol de navegación</strong> lista por perfil las opciones a las que tiene acceso el usuario. sobre las
                          cuales se listan los elementos y permite la consulta o administración según el perfil.
                        </p>
                      </div>
                      <div className="col-md-7 mt-0">
                        <img className="mockup-web" src="images/web_tree.png" alt="" />
                      </div>
                    </div>

                    <h3>Lista principal</h3>

                    <p>
                      Se presenta un <strong>listaDO</strong>, con elementos de identificación resaltado <em>(En negrilla)</em>, resumen de
                      detalles, indicadores de fechas, aleRtas y estados, barra de herramienta con filtros de estado, busqueda, filtros,
                      impresión y creación de nuevos elementos, la paginación por scroll en paquetes de información configurable{" "}
                      <em>(30 o 50 registros por página)</em>, que agilizan tiempos de respuesta.
                    </p>

                    <img className="mockup-web mb-4" src="images/web_list.png" alt="" />

                    <h3>Formularios modales</h3>

                    <p>
                      Los <strong>formularios modales</strong> presentan en detalle la informacion, que se puede organizar en pestañas, con
                      barras de menus estandarizadas superiores e inferiores, con herramientas que permiten el paso entre elementos, la
                      impresión y la creación de nuevos elementos; asi como manejo de estados.
                    </p>

                    <p>
                      <ModalObject
                        className="btn btn-primary btn-sm"
                        onChange={this.handleExecute}
                        _state="0"
                        data={{ _id: "1", _state: "0" }}
                      >
                        Click para el ejemplo
                      </ModalObject>
                    </p>

                    <img className="mockup-web mb-4" src="images/web_modal.png" alt="" />

                    <h3>Tableros de resumen y control.</h3>

                    <p>
                      Los <strong>tableros de resumen y control</strong> permiten la cuantificación de variables en tiempo real, de diversos
                      elementos y evaluar parámetros de información y convertirce en soporte de decisiones.
                    </p>

                    <div className="row mb-1">
                      <div className="col-md-12">
                        <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                          <div className="col p-4 d-flex flex-column position-static">
                            <h3 className="mb-0">Olla Eléctrica Multifuncional</h3>
                            <div className="mb-1 text-muted">SKU 123456789</div>
                            <p className="card-text mb-1">
                              Pasillo: <strong>3</strong>
                            </p>
                            <p className="card-text mb-auto">
                              Posición: <strong>12</strong>
                            </p>
                            <p>
                              Código de verificación:
                              <strong className="d-inline-block mb-2 ml-2 text-primary">51</strong>
                            </p>
                          </div>
                          <div className="col-auto d-none d-lg-block">
                            <img className="element" src="images/element_1.png" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                          <div className="col p-4 d-flex flex-column position-static">
                            <strong className="d-inline-block mb-2 text-success">Producto más vendido del día</strong>
                            <h3 className="mb-0">Freidora de Aire 3.2 litros</h3>
                            <div className="mb-1 text-muted">SKU 12345788</div>
                            <p className="card-text mb-1">
                              Total de productos empacados:
                              <strong className="d-inline-block mb-2 ml-2 text-primary">32</strong>
                            </p>
                            <p className="card-text mb-0">
                              Medellín: <strong>12</strong>
                            </p>
                            <p className="card-text mb-0">
                              San Gil: <strong>1</strong>
                            </p>
                            <p className="card-text mb-0">
                              Bogotá: <strong>19</strong>
                            </p>
                            <p></p>
                          </div>
                          <div className="col-auto d-none d-lg-block">
                            <img className="element" src="images/element_2.jpg" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row d-none">
                      <div className="col-md-4">
                        <h2>Heading</h2>
                        <p>
                          Donec id elit non mi porta gravida at eget metus. Fusce dapibus, tellus ac cursus commodo, tortor mauris
                          condimentum nibh, ut fermentum massa justo sit amet risus. Etiam porta sem malesuada magna mollis euismod. Donec
                          sed odio dui.{" "}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <h2>Heading</h2>
                        <p>
                          Donec id elit non mi porta gravida at eget metus. Fusce dapibus, tellus ac cursus commodo, tortor mauris
                          condimentum nibh, ut fermentum massa justo sit amet risus. Etiam porta sem malesuada magna mollis euismod. Donec
                          sed odio dui.{" "}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <h2>Heading</h2>
                        <p>
                          Donec sed odio dui. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Vestibulum id ligula porta felis
                          euismod semper. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo
                          sit amet risus.
                        </p>
                      </div>
                    </div>
                  </Element>
                </div>

                <div className="blog-post d-none">
                  <h2 className="blog-post-title">New feature</h2>
                  <p className="blog-post-meta">December 14, 2013 by Chris</p>

                  <p>
                    Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Aenean lacinia bibendum nulla sed
                    consectetur. Etiam porta sem malesuada magna mollis euismod. Fusce dapibus, tellus ac cursus commodo, tortor mauris
                    condimentum nibh, ut fermentum massa justo sit amet risus.
                  </p>
                  <ul>
                    <li>Praesent commodo cursus magna, vel scelerisque nisl consectetur et.</li>
                    <li>Donec id elit non mi porta gravida at eget metus.</li>
                    <li>Nulla vitae elit libero, a pharetra augue.</li>
                  </ul>
                  <p>
                    Etiam porta <em>sem malesuada magna</em> mollis euismod. Cras mattis consectetur purus sit amet fermentum. Aenean
                    lacinia bibendum nulla sed consectetur.
                  </p>
                  <p>Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue.</p>
                </div>

                <nav className="blog-pagination d-none">
                  <button className="btn btn-outline-primary m-r-8">Older</button>
                  <button className="btn btn-outline-secondary disabled" aria-disabled="true">
                    Newer
                  </button>
                </nav>
              </div>
              <aside className="col-md-4 blog-sidebar">
                <div className="p-4 mb-3 bg-light rounded">
                  <img className="element" src="images/home_voice.png" alt="" />
                  <p className="mb-0">
                    Sistema de <strong>Voice picking</strong> para optimizar y maximizar los procesos de picking y packing de la operación
                    logística.
                  </p>
                </div>
              </aside>
            </div>
          </main>
          <footer className="blog-footer">
            <small>© 2016-2020 Josphine Labs.</small>
          </footer>
        </div>
      </React.Fragment>
    );
  }
}

export default HomeVoice;
