import React from "react";
import "../styles/404.scss";
import { getSource, Loading } from "../components/utilities";
import { connect } from "react-redux";

class NotProject extends React.Component {
  constructor(props) {
    super(props);
    Loading();
  }

  render() {
    return (
      <React.Fragment>
        <div className="__404Page">
          <div className="__404">
            <div className="__Nproject">No tienes proyectos asignados</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    signin: getSource(state.sistem, ["signin"], false),
    project: getSource(state.sistem, ["project"], {}),
  };
}

export default connect(mapStateToProps)(NotProject);

