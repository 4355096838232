import React from "react";
import "../styles/view.scss";
import { Redirect } from "react-router-dom";
import { compare, Loading, Subscribe, UnSubscribe, updateList } from "../components/utilities";
import ModalProject from "../modals/modalProject";
import ViewNavbar from "../components/viewNavbar";
import TabProjects from "../components/tabProjects";
import { Api as Project } from "../services/project";
import { connect } from "react-redux";

class ViewProjects extends React.Component {
  constructor(props) {
    super(props);
    Loading();
    this.state = {
      _class: "ENTERPRICE",
      _id: "__ViewProjects",
      name: "projects",
      title: "Proyectos",
      rows: this.props.view_rows,
      data: {
        project_id: "-1",
        int: 0,
        end: 0,
        list: [],
        page: 1,
        rows: 30,
        count: 0,
        state: "0",
        all: 0,
        search: "",
      },
      printParams: {
        _class: "ENTERPRICE",
        _id: "-1",
        single: false,
      },
      update: {},
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleSetData = (e) => {
    if (e.action === "del") {
      const data = e.data;
      const list = this.state.data.list;
      const index = list.findIndex((element) => element._id === data._id);
      if (index > -1) {
        list.splice(index, 1);
        this.setState({
          data: {
            ...this.state.data,
            list: list,
          },
        });
      }
    } else {
      this.handleUpdate(e.data);
    }
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleData = (scroll) => {
    const state = this.state.data.state;
    const search = this.state.data.search;
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Project.projects(state, search, page, rows, this.state.data.list).then((result) => {
        const data = result.data;
        this.setState({
          data: data,
        });
      });
    } else {
      Project.projects(state, search, 1, rows).then((result) => {
        const data = result.data;
        this.setState({
          data: data,
        });
      });
    }
  };

  handleScroll = (e) => {
    const h = e.target.scrollTop + e.target.clientHeight + 1;
    if (e.target.id === `${this.state._id}_scroll` && h >= e.target.scrollHeight) {
      this.handleData({ scroll: true });
    }
  };

  handleSearch = () => {
    this.handleData(false);
  };

  handleState = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        search: "",
        state: e,
      },
    });
  };

  handleUpdate = (e) => {
    if (compare(this.state.update, e)) {
      const data = updateList(this.state.data, e);
      this.setState({ data: data, update: e });
    }
  };

  componentDidMount() {
    Subscribe(`${this.state._class}/${this.props.project_id}`, (event) => this.eventSetData(event));
    this.handleData(false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps._view === this.props._view && this.props.project_id !== "-1") {
      if (prevProps.project_id !== this.props.project_id) {
        UnSubscribe(`${this.state._class}/${prevProps.project_id}`, (event) => this.eventSetData(event));
        Subscribe(`${this.state._class}/${this.props.project_id}`, (event) => this.eventSetData(event));
        this.handleData(false);
      } else if (prevState.data.state !== this.state.data.state) {
        this.handleData(false);
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state._class}/${this.props.project_id}`, (event) => this.eventSetData(event));
  }

  render() {
    if (this.props._view !== this.props.location.pathname) {
      return <Redirect to={this.props._view} push={true} />;
    }
    return (
      <React.Fragment>
        <div className={this.state._id}>
          <div className="viewcontainer">
            <ViewNavbar
              title={this.state.title}
              _class={this.state._class}
              single={false}
              data={this.state.data}
              Modal={ModalProject}
              states={[
                { _state: "0", caption: "Activos" },
                { _state: "1", caption: "Terminados" },
              ]}
              printParams={this.state.printParams}
              onChange={this.handleChange}
              onState={this.handleState}
              onSearch={this.handleSearch}
              onSetData={this.handleSetData}
            />
            <div className="view-scrolling" id={`${this.state._id}_scroll`} onScroll={this.handleScroll}>
              <div className="row view-boxed">
                <TabProjects data={this.state.data} onSetData={this.handleSetData} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    _view: state.sistem.folder._view || "",
    view_rows: state.sistem.view_rows,
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ViewProjects);
