import React from "react";
import "../../../styles/modal.scss";
import { Subscribe, UnSubscribe, compare, updateList } from "../../../components/utilities";
import ModalSearch from "../../../components/modalSearch";
import { Api as Assets } from "../../../services/asset";
import Td from "../../../components/td";
import ModalClass from "../../assets/modalClass";
import { connect } from "react-redux";

class ComponentReferenceClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "__ComponentReferenceClass",
      name: "JS_CLASS",
      show: false,
      rows: 30,
      data: {
        list: [],
        search: "",
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      select: { _id: "-1", caption: "" },
      change: false,
      update: {}
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleSetData = (e) => {
    this.handleUpdate(e);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleData = (scroll) => {
    const project_id = this.props.project_id;
    const reference_id = this.props._id;
    const only = this.props.only;
    const search = this.state.data.search;
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Assets.referenceClass(project_id, reference_id, only, search, page, rows, this.state.data.list).then((result) => {
        const data = result.data;
        this.setState({
          data: data,
        });
      });
    } else {
      Assets.referenceClass(project_id, reference_id, only, search, 1, rows, [this.state.select]).then((result) => {
        const data = result.data;
        this.setState({
          data: data,
          change: false,
        });
      });
    }
  };

  handleSearch = () => {
    this.handleData(false);
  };

  handleUpdate = (e) => {
    if (compare(this.state.update, e)) {
      const data = updateList(this.state.data, e);
      this.setState({ data: data, change: true, update: e });
    }
  };

  handleCheck = (e) => {
    const referece_id = this.props._id;
    const item = e.item;
    const _id = item._id;
    const chk = !item.chk;
    const user_id = this.props.user_id;
    Assets.chkReference(_id, referece_id, chk, user_id).then((result) => {
      if (result.msg === "") {
        let list = this.state.data.list;
        list[e.i].chk = chk;
        this.setState({
          data: {
            ...this.state.data,
            list: list,
          },
        });
      }
    });
  };

  handleScroll = (e) => {
    const h = e.target.scrollTop + e.target.clientHeight + 1;
    if (e.target.id === `${this.state._id}_scroll` && h >= e.target.scrollHeight) {
      this.handleData(true);
    }
  };

  handleSelected = (e) => {
    this.setState({ select: e });
  };

  componentDidMount() {
    Subscribe(`${this.state.name}/${this.props.project_id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show) {
      if (prevProps.show !== this.props.show) {
        this.handleData(false);
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.name}/${this.props.project_id}`, (event) => this.eventSetData(event));
  }

  render() {
    return (
      <React.Fragment>
        <div className="modalList single pt-0 pb-0">
          <div className="modalListFind">
            <ModalSearch
              name="search"
              value={this.state.data.search}
              state={this.props._state}
              onChange={this.handleChange}
              onSearch={this.handleSearch}
            />
          </div>
          <div className="modalListList">
            <div className="suport-group">
              <Td className="suport-head" cols="auto">
                <div className="suport-td m">Descripción</div>
              </Td>
              <div
                className="suport-scroll small"
                id={`${this.state._id}_scroll`}
                onScroll={this.handleScroll}
                style={{ height: this.props.height || "280px" }}
              >
                {this.state.data.list.map((item, i) => {
                  let className = item._id === "-1"
                    ? "d-none"
                    : "suport-td t-l";
                  if (item._state === '-1') {
                    className = `${className} system`;
                  } else if (item._state === '2') {
                    className = `${className} cancel`;
                  }
                  if (item.project_id === '-1') {
                    className = `${className} system`;
                  }
                  return (
                    <React.Fragment key={i}>
                      <Td
                        className={item._id === "-1"
                          ? "d-none"
                          : "suport-item"}
                        selectedId={this.state.select._id}
                        _id={item._id}
                        cols="25px auto"
                        onClick={() => this.handleSelected(item)}
                      >
                        <div className="suport-td form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="chk"
                            checked={item.chk}
                            onChange={() => this.handleCheck({ item, i })}
                          />
                        </div>
                        <ModalClass className={className} project_id={item.project_id} _id={item._id} isFind={true}>
                          {item.caption}
                        </ModalClass>
                      </Td>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    user_id: state.sistem.profile._id || "-1",
    _view: state.sistem.folder._view || "",
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ComponentReferenceClass);
