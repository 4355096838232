import React from "react";
import "../../styles/document.scss";
import { getRoutParams, Loading } from "../../components/utilities";
import ComponentQr from "./components/componentQr";
import ComponentFloor from "./components/componentFloor";
import ComponentOrder from "./components/componentOrder";
import ComponentLine from "./components/componentLine";

class UsaOrder extends React.Component {
  constructor(props) {
    super(props);
    Loading();
    this.title = "Production order";
    const area = getRoutParams(this.props);
    this.state = {
      area: area,
      tab: 1,
      tabs: [],
      urlAccess: "https://www.dploy.site/usaorder/A",
      alert: {
        show: false,
        message: "",
      },
    };
  }

  handleTab = (e) => {
    const tab = e;
    const tabs = this.state.tabs;
    if (!tabs[e]) {
      tabs[e] = true;
      this.setState({ tab, tabs: tabs });
    } else {
      this.setState({ tab });
    }
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  render() {
    return (
      <React.Fragment>
        <div className="viewcontainer">
          <div className="view-box p-0">
            <div className="view-detail">
              <div className="view-detail-title">
                <div className="text">Production order</div>
              </div>
              <div className="view-detail-list">
                <nav>
                  <div className="nav nav-tabs" role="tablist">
                    <a
                      className="nav-item nav-link active"
                      id={`usaOrder-1-tab`}
                      data-toggle="tab"
                      href={`#usaOrder-1`}
                      role="tab"
                      aria-selected="true"
                      onClick={() => this.handleTab(1)}
                    >
                      Mach360
                    </a>
                    <a
                      className="nav-item nav-link"
                      id={`usaOrder-2-tab`}
                      data-toggle="tab"
                      href={`#usaOrder-2`}
                      role="tab"
                      aria-selected="false"
                      onClick={() => this.handleTab(2)}
                    >
                      Floor
                    </a>
                    <a
                      className="nav-item nav-link"
                      id={`usaOrder-3-tab`}
                      data-toggle="tab"
                      href={`#usaOrder-3`}
                      role="tab"
                      aria-selected="false"
                      onClick={() => this.handleTab(3)}
                    >
                      Orders
                    </a>
                    <a
                      className="nav-item nav-link"
                      id={`usaOrder-4-tab`}
                      data-toggle="tab"
                      href={`#usaOrder-4`}
                      role="tab"
                      aria-selected="false"
                      onClick={() => this.handleTab(4)}
                    >
                      Line
                    </a>
                  </div>
                </nav>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id={`usaOrder-1`}
                    role="tabpanel"
                    aria-labelledby={`usaOrder-1-tab`}
                  >
                    <ComponentQr url={this.state.urlAccess} />
                  </div>
                  <div className="tab-pane fade" id={`usaOrder-2`} role="tabpanel" aria-labelledby={`usaOrder-2-tab`}>
                    <ComponentFloor area={this.state.area} tab={this.state.tab} />
                  </div>
                  <div className="tab-pane fade" id={`usaOrder-3`} role="tabpanel" aria-labelledby={`usaOrder-3-tab`}>
                    <ComponentOrder area={this.state.area} tab={this.state.tab} />
                  </div>
                  <div className="tab-pane fade" id={`usaOrder-4`} role="tabpanel" aria-labelledby={`usaOrder-4-tab`}>
                    <ComponentLine area={this.state.area} tab={this.state.tab} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UsaOrder;
