import React from "react";
import ReactDOM from "react-dom";
import "../../../styles/modal.scss";
import "../../../styles/suport.scss";
import { showModal, hideModal, genId, clone, getValue, formatNumber, ShowAlert } from "../../../components/utilities";
import Td from "../../../components/td";
import ModalHeader from "../../../components/modalHeader";
import ModalFooter from "../../../components/modalFooter";
import ModalAsset from "../modalAsset";
import { Asset } from "../listAssets";
import { ToolTip } from "../../../components/inputs";
import ModalClass from "../modalClass";
import ModalProfile from "../modalProfile";
import { Api } from "../../../services/asset";

class ModalEditComponent extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme();
    this.state = {
      form: "__ModalEditComponent",
      _id: "__ModalEditComponent",
      title: "Componente",
      show: false,
      old: data,
      data: data,
      change: false,
    };
  }

  handleScheme = (data) => {
    return {
      _id: genId("-1"),
      class_id: getValue(data, "class_id", "-1"),
      class: getValue(data, "class", ""),
      connection_id: "-1",
      code: "",
      quantity: getValue(data, "quantity", 0),
      required: getValue(data, "required", false),
    };
  };

  handleChangeAsset = (asset) => {
    this.setState({
      data: {
        ...this.state.data,
        class_id: getValue(asset, "class_id", "-1"),
        _id: getValue(asset, "_id", "-1"),
        code: getValue(asset, "code", ""),
        serie: getValue(asset, "serie", ""),
        rfidcode: getValue(asset, "rfidcode", ""),
        class: getValue(asset, "class", ""),
        equipment: getValue(asset, "equipment", false),
        reference_id: getValue(asset, "cost", "-1"),
        reference_code: getValue(asset, "cost", "reference_code"),
        reference: getValue(asset, "reference", ""),
        failure: getValue(asset, "failure", false),
        install: getValue(asset, "install", false),
        preventive: getValue(asset, "preventive", false),
        reported: getValue(asset, "reported", false),
        running: getValue(asset, "running", false),
        quantity: getValue(asset, "quantity", 0),
      },
      change: true,
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
    this.handleHide();
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    const _state = this.props._state || "0";
    if (_state === "main_not_exist") {
      ShowAlert("Debe guardar primero el equipo que estás creando");
    } else {
      const _id = `${this.state.form}${Date.now()}`;
      this.setState({ _id: _id, show: true, change: false });
    }
  };

  handleClose = () => {
    if (this.state.change) {
      this.setState({ data: this.state.old, change: false });
    }
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.change) {
      const params = this.state.data;
      this.handleExecute(params);
    } else {
      this.handleHide();
    }
  };

  handleRemove = (e) => {
    if (e) {
      const data = {
        ...this.state.data,
        reference_id: "-1",
        quantity: 0,
        unitary: 0,
      };
      this.handleExecute(data);
    }
  };

  handleData = () => {
    let data = clone(this.props.data || {});
    this.setState({
      old: data,
      data: data,
      change: false,
    });
    showModal(this.state._id);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        this.handleData();
      }
    }
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog xs" role="dialog">
                <div className="modal-content">
                  <ModalHeader title={this.state.title} handleClose={this.handleClose} />
                  <div className="modal-body">
                    <div className="modal-box">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group input-group-sm">
                            <ModalClass
                              className="link"
                              project_id={this.props.project_id}
                              _state={this.props._state}
                              _id={this.state.data.class_id}
                              isFind={true}
                            >
                              Clase
                            </ModalClass>
                            <div className="form-control" readOnly>
                              {this.state.data.class}
                            </div>
                          </div>
                          <div className="form-group input-group-sm">
                            <ModalAsset
                              className="link"
                              project_id={this.props.project_id}
                              equipment={true}
                              isFind={true}
                              _id={this.state.data._id}
                              onSetData={this.handleChangeAsset}
                            >
                              Equipo
                            </ModalAsset>
                            <Asset
                              className="form-control sp ctr-sm t-m"
                              classNameBtn="input-group-text sp"
                              project_id={this.props.project_id}
                              _state={this.props._state}
                              caption="caption"
                              select={{
                                class_id: this.state.data.class_id,
                                class: this.state.data.class,
                                _id: this.state.data._id,
                                code: this.state.data.code,
                                caption: getValue(this.state.data, "caption", this.state.data.code),
                                unity: getValue(this.state.data, "unity", "und"),
                              }}
                              create={true}
                              onChange={this.handleChangeAsset}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooter
                      title="¿Retirar actividad?"
                      description="¿Desea retirar la actividad, sobre el componente? este cambio no podrá revertirse."
                      _state={this.props._state}
                      onRemove={this.handleRemove}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function ComponentComponents(props) {
  const isNew = props.isNew || false;
  const _state = props._state || "0";
  const data = props.data || {};
  const list = data.components || [];
  const handleChange = (e) => {
    const asset = e.asset;
    const item = e.item;
    Api.installAsset(asset._id, item.main_id, item.installation, "-1", "").then((result) => {
      if (result.msg === "") {
        const data = result.data;
        item.class_id = getValue(data, "class_id", "-1");
        item._id = getValue(data, "_id", "-1");
        item.code = getValue(data, "code", "");
        item.serie = getValue(data, "serie", "");
        item.rfidcode = getValue(data, "rfidcode", "");
        item.class = getValue(data, "class", "");
        item.equipment = getValue(data, "equipment", false);
        item.reference_id = getValue(data, "cost", "-1");
        item.reference_code = getValue(data, "cost", "reference_code");
        item.reference = getValue(data, "reference", "");
        item.failure = getValue(data, "failure", false);
        item.install = getValue(data, "install", false);
        item.preventive = getValue(data, "preventive", false);
        item.reported = getValue(data, "reported", false);
        item.running = getValue(data, "running", false);
        item.quantity = getValue(data, "quantity", 0);
        props.onChange(item);
      }
    });
  };
  const handleChangeAsset = (e) => {
    props.onChange(e);
  };
  const handleChangeProfile = (e) => {
    props.onChangeProfile(e);
  };

  return (
    <React.Fragment>
      <div className="suport-group mb-4">
        <Td className="suport-head" cols="auto">
          <div className="suport-th">
            Componentes
            <ModalProfile
              className="link float-right pr-1"
              project_id={props.project_id}
              _id={props.profile_id}
              isFind={true}
              onSetData={handleChangeProfile}
            >
              Perfil
            </ModalProfile>
          </div>
        </Td>
        <Td className="suport-head" cols="auto 85px 75px 74px">
          <div className="suport-th">Componente</div>
          <div className="suport-th">Cantidad</div>
          <div className="suport-th">Código</div>
          <div className="suport-th">Estatus</div>
        </Td>
        <div className="suport-scroll small" style={{ height: props.height || "413px" }}>
          {list.map((item, i) => {
            if (!item.installation || item.equipment) {
              return (
                <ModalEditComponent
                  key={i}
                  project_id={props.project_id}
                  equipment={item.equipment}
                  _state={isNew ? "main_not_exist" : _state}
                  data={item}
                  onSetData={(asset) => handleChange({ asset, item })}
                >
                  <Td key={i} className="suport-item" cols="auto 85px 75px 70px">
                    <div className="suport-td">{item.class}</div>
                    <div className="suport-td t-r">
                      {formatNumber(item.quantity)}
                      {item.unity === "und" ? "" : ` ${item.unity}`}
                    </div>
                    <div className="suport-td t-c">{item.code}</div>
                    <div className="suport-td status">
                      <ToolTip className={!item.install ? "fas fa-unlink" : "d-none"} title="Desinstalado" />
                      <ToolTip className={item.failure ? "fas fa-exclamation-triangle" : "d-none"} title="En falla" />
                      <ToolTip
                        className={item._id === "-1" ? "d-none" : item.running ? "fas fa-bolt" : "fas fa-pause"}
                        title={item._id === "-1" ? "" : item.running ? "En funcionamiento" : "En pausa"}
                      />
                      <ToolTip className={item.reported ? "far fa-clipboard" : "d-none"} title="Reportado" />
                      <ToolTip className={item.low ? "fas fa-ban" : "d-none"} title="Dado de baja" />
                      <ToolTip className={item.preventive ? "fas fa-wrench" : "d-none"} title="Mantenimiento preventivo" />
                      <ToolTip className={item.alerts ? "fas fa-exclamation-circle" : "d-none"} title="Alertas" />
                    </div>
                  </Td>
                </ModalEditComponent>
              );
            } else {
              return (
                <ModalAsset
                  key={i}
                  isFind={item._id === "-1" ? false : true}
                  isNew={item._id === "-1" ? true : false}
                  project_id={props.project_id}
                  equipment={item.equipment}
                  _id={item._id}
                  _state={isNew ? "main_not_exist" : _state}
                  data={{
                    project_id: props.project_id,
                    class_id: item.class_id,
                    class: item.class,
                    main_id: data._id,
                    owner_id: data.owner_id,
                    running: data.running,
                    _data: {
                      owner: data._data.owner,
                    },
                  }}
                  onSetData={(asset) => handleChangeAsset(asset)}
                >
                  <Td key={i} className="suport-item" cols="auto 85px 75px 70px">
                    <div className="suport-td">{item.class}</div>
                    <div className="suport-td t-r">
                      {formatNumber(item.quantity)}
                      {item.unity === "und" ? "" : ` ${item.unity}`}
                    </div>
                    <div className="suport-td t-c">{item.code}</div>
                    <div className="suport-td status">
                      <ToolTip className={!item.install ? "fas fa-unlink" : "d-none"} title="Desinstalado" />
                      <ToolTip className={item.failure ? "fas fa-exclamation-triangle" : "d-none"} title="En falla" />
                      <ToolTip
                        className={item._id === "-1" ? "d-none" : item.running ? "fas fa-bolt" : "fas fa-pause"}
                        title={item._id === "-1" ? "" : item.running ? "En funcionamiento" : "En pausa"}
                      />
                      <ToolTip className={item.reported ? "far fa-clipboard" : "d-none"} title="Reportado" />
                      <ToolTip className={item.low ? "fas fa-ban" : "d-none"} title="Dado de baja" />
                      <ToolTip className={item.preventive ? "fas fa-wrench" : "d-none"} title="Mantenimiento preventivo" />
                    </div>
                  </Td>
                </ModalAsset>
              );
            }
          })}
        </div>
      </div>
    </React.Fragment>
  );
}

export default ComponentComponents;
