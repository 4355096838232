import React from "react";
import ReactDOM from "react-dom";
import "../../styles/modal.scss";
import { showModal, hideModal, compare, emptyValue, getValue, updateList } from "../../components/utilities";
import ModalHeaderList from "../../components/modalHeaderList";
import ModalList from "../../components/modalList";
import ModalFooterList from "../../components/modalFooterList";
import ModalSelect from "../../components/modalSelect";
import ModalSearch from "../../components/modalSearch";
import ModalUser from "../modalUser";
import { Api as Keep } from "../../services/keep";
import { Api as Project } from "../../services/project";

class ListOdtResponsable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "__ListOdtResponsable",
      title: "ODT personal",
      name: "SUPORT-KEEP-ODT",
      show: false,
      data: {
        list: [],
        search: "",
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      select: { _id: "-1", caption: "" },
      change: false,
      update: {}
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleSetData = (e) => {
    this.handleUpdate(e);
    this.handleSelect(e);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
    this.handleHide();
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({
      data: {
        ...this.state.data,
        search: "",
      },
      show: true,
    });
  };

  handleClose = () => {
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.change) {
      this.handleExecute(this.state.select);
    } else {
      this.handleHide();
    }
  };

  handleRemove = () => {
    this.handleSelect({});
    this.handleExecute({});
    this.handleHide();
  };

  handleData = (scroll) => {
    const odt_id = this.props.odt_id;
    const search = this.state.data.search;
    Keep.listODTResponsables(odt_id, search).then((result) => {
      const data = result.data;
      this.setState({
        data: {
          ...this.state.data,
          list: data.list,
          search: data.search,
          int: data.int,
          end: data.end,
          count: data.count,
          all: data.all,
        },
        change: false,
      });
    });
  };

  handleInit = () => {
    const _id = getValue(this.props.select, "_id", "-1");
    if (_id === "-1") {
      this.handleData();
    } else {
      Project.contact(_id, this.props.project_id).then((result) => {
        const select = emptyValue(result, "data", { _id: "-1", caption: "" });
        this.setState({
          select: select,
          change: false
        });
        this.handleData();
      });
    }
  };

  handleSearch = () => {
    this.handleData();
  };

  handleSelect = (e) => {
    this.setState({ select: e, change: true });
  };

  handleUpdate = (e) => {
    if (compare(this.state.update, e)) {
      const data = updateList(this.state.data, e);
      this.setState({ data: data, change: true, update: e });
    }
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        this.handleInit();
        showModal(this.state._id);
      } else if (prevState.data.state !== this.state.data.state) {
        this.handleData();
      }
    }
  }

  componentWillUnmount() {
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog xs __modalSingleList" role="document">
                <div className="modal-content">
                  <ModalHeaderList
                    title={this.state.title}
                    handleClose={this.handleClose}
                    state={this.state.data.state}
                    rows={this.state.data.end}
                    all={this.state.data.all}
                  />
                  <div className="modal-body">
                    <div className="modalList">
                      <div className="modalListFind">
                        <ModalSearch
                          name="search"
                          value={this.state.data.search}
                          onChange={this.handleChange}
                          onSearch={this.handleSearch}
                        />
                      </div>
                      <div className="modalListList">
                        <ModalList
                          id={`${this.state._id}_scroll`}
                          _state={this.state.data.state}
                          list={this.state.data.list}
                          select={this.state.select}
                          getData={this.handleData}
                          onSelect={this.handleSelect}
                        />
                      </div>
                      <div className="modalListSelect">
                        <ModalSelect
                          project_id={this.props.project_id}
                          select={this.state.select}
                          onSetData={this.handleSetData}
                          Modal={ModalUser}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooterList
                      project_id={this.props.project_id}
                      _state={this.state.data._state}
                      onSetData={this.handleSetData}
                      onRemove={this.handleRemove}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function Responsable(props) {
  const select = getValue(props, "select", { _id: "-1", caption: "" });
  return (
    <React.Fragment>
      <div className="input-group input-group-sm">
        <input className={props.className || "form-control"} readOnly value={select.caption || ""} />
        <div className="input-group-append">
          <ListOdtResponsable
            className={props.classNameBtn || "input-group-text"}
            project_id={props.project_id}
            odt_id={props.odt_id}
            _state={props._state}
            select={select}
            onSetData={props.onChange}
            disabled={props._state !== "0"}
          >
            <i className="fa fa-ellipsis-h"></i>
          </ListOdtResponsable>
        </div>
      </div>
    </React.Fragment>
  );
}

export { ListOdtResponsable, Responsable };
