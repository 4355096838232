import React from "react";
import ReactDOM from "react-dom";
import "../../styles/modal.scss";
import "../../styles/suport.scss";
import {
  showModal,
  hideModal,
  getValue,
  getRowValue,
  getRowNumber,
  genId,
  ShowInfo,
  msg,
  formatMoney,
  formatDate,
  getData,
  setFocus,
  Subscribe,
  UnSubscribe,
  ShowAlert,
  formId,
  compareObject,
  getSource,
} from "../../components/utilities";
import { Button, Section, User, Type } from "../../components/inputs";
import { ALERT002 } from "../../components/msg";
import ModalHeaderSuport from "../../components/modalHeaderSuport";
import ModalFooterSuport from "../../components/modalFooterSuport";
import Td from "../../components/td";
import DateTime from "../../components/dateTime";
import ModalExit from "../warehouse/modalExit";
import ModalRefund from "../warehouse/modalRefund";
import ModalValeDelivery from "./modalValeDelivery";
import ModalValeRefund from "./modalValeRefund";
import ModalValeTransfer from "./modalValeTransfer";
import Operations from "./components/operations";
import Consumptions from "./components/consumptions";
import Stocks from "./components/stocks";
import { Api as Keep } from "../../services/keep";
import { connect } from "react-redux";

class ModalVale extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme(props.data);
    this.state = {
      form: "__ModalVale",
      _id: "__ModalVale",
      title: "Vale",
      name: "odt",
      show: false,
      rows: 30,
      old: data,
      data: data,
      isNew: props.isNew || false,
      change: false,
      tabs: [],
      warehouse: false,
      select: { _id: "-1", caption: "" },
      printParams: {
        project_id: "-1",
        _class: "SUPORT-KEEP-VALE",
        _id: "-1",
        single: true,
      },
    };
  }

  handleScheme = (data) => {
    const now = new Date();
    return {
      date_make: now,
      date_update: now,
      project_id: this.props.project_id,
      _class: getValue(data, "_class", "SUPORT-KEEP-VALE"),
      _state: "0",
      _id: genId("-1"),
      date_of: formatDate(now, "yyyy-MM-dd HH:mm:ss"),
      code: "",
      caption: "Vale",
      description: "",
      observations: "",
      type_tp: getValue(data, "type_tp", "-1"),
      responsable_id: getValue(data, "responsable_id", "-1"),
      cost: 0,
      count_operations: 0,
      stock: 0,
      chat_id: "-1",
      details: {
        list: [],
        search: "",
        state: "ALL",
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      _data: {
        class_name: "",
        project: this.props.project,
        destiny: "",
        type: getValue(data, "type", ""),
        responsable: "",
        responsable_mobile: "",
        responsable_email: "",
      },
      prior_id: "-1",
      next_id: "-1",
      _v: 1,
    };
  };

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeData = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          [e.target.name]: e.target.value,
        },
      },
      change: true,
    });
  };

  handleChangeDate = (e) => {
    const value = formatDate(e.target.value, "yyyy-MM-dd HH:mm:ss");
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: value,
      },
      change: true,
    });
  };

  handleChangeResponsable = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        responsable_id: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          responsable: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeType = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        type_tp: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          type: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeDetail = (e) => {
    const details = this.state.data.details;
    const list = details.list;
    const result = {
      caption: getValue(e, "caption", ""),
      cellar: getValue(e, "cellar", ""),
      code: getValue(e, "code", ""),
      cost: getValue(e, "cost", 0),
      date_of: getValue(e, "date_of", ""),
      project_id: getValue(e, "project_id", "-1"),
      responsable: getValue(e, "responsable", ""),
      search: getValue(e, "search", ""),
      vale_id: getValue(e, "vale_id", "-1"),
      _class: getValue(e, "_class", "-1"),
      _id: getValue(e, "_id", "-1"),
      _state: getValue(e, "_state", "0"),
    };
    const index = list.findIndex((element) => element._id === e._id);
    if (index === -1) {
      list.unshift(result);
      details.all = details.all + 1;
      details.count = details.count + 1;
      details.end = details.end + 1;
      details.list = list;
    } else {
      list[index] = result;
      details.list = list;
    }
    this.setState({
      data: {
        ...this.state.data,
        details: details,
      },
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    if (this.props.isFind) {
      const _id = getValue(this.props, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    } else if (this.props.isNew) {
      const form_id = `${this.state.form}${Date.now()}`;
      this.setState({ _id: form_id, show: true, change: false });
    } else {
      const _id = getValue(this.props.data, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    }
  };

  handleClose = (e) => {
    if (this.state.change && e) {
      this.handleOk();
    } else {
      this.handleHide();
    }
  };

  handleOk = () => {
    if (this.state.change) {
      const id = this.state.data._id;
      const params = this.state.data;
      Keep.setVale(id, params).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false });
          this.handleExecute(data);
        }
      });
    } else {
      this.handleHide();
    }
  };

  handleState = (e) => {
    if (this.state.change) {
      ShowAlert(ALERT002);
    } else {
      const _state = getValue(this.state.data, "_state");
      if (_state !== e) {
        const id = getValue(this.state.data, "_id");
        Keep.stateVale(id, e).then((result) => {
          if (result.msg === "") {
            const data = result.data;
            this.handleSetData({ data, change: false });
            this.handleExecute(data);
          }
        });
      }
    }
  };

  handlePrior = (e) => {
    const _id = getValue(this.state.data, "prior_id", "-1");
    if (_id === "-1") {
      ShowInfo(msg("MSG-FIRST-ROW"));
    } else {
      this.handleFind(_id);
    }
  };

  handleNext = (e) => {
    const _id = getValue(this.state.data, "next_id", "-1");
    if (_id === "-1") {
      ShowInfo(msg("MSG-END-ROW"));
    } else {
      this.handleFind(_id);
    }
  };

  handleDetails = (scroll) => {
    const data = this.state.data;
    let details = data.details || {};
    const _id = data._id;
    const state = "ALL";
    const search = getValue(details, "search", "");
    const rows = this.state.rows;
    if (scroll) {
      const page = details.page + 1;
      Keep.valeSuports(_id, state, search, page, rows, details.list).then((result) => {
        details = result.data;
        this.setState({
          data: {
            ...this.state.data,
            details: details,
          },
        });
      });
    } else {
      Keep.valeSuports(_id, state, search, 1, rows, []).then((result) => {
        details = result.data;
        this.setState({
          data: {
            ...this.state.data,
            details: details,
          },
        });
      });
    }
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      isNew: e.isNew,
      change: e.change,
      tabs: [],
      warehouse: this.handleModuleWarehouse(),
      printParams: {
        project_id: e.data.project_id,
        _class: e.data._class,
        _id: e.data._id,
        single: true,
      },
    });
    showModal(this.state._id);
    setFocus(`${this.state._id}_caption`, e.focusInt);
  };

  handleUpdate = (e) => {
    if (compareObject(this.state.data, e)) {
      this.handleSetData({
        data: e,
        isNew: false,
        change: false,
      });
    }
  };

  handleData = () => {
    const _id = getSource(this.props.data, ["_id"], "-1");
    if (_id === "-1") {
      this.handleSetData({ data: this.props.data, isNew: false, change: false, focusInt: true });
    } else {
      this.handleFind(_id);
    }
  };

  handleNew = () => {
    const data = this.handleScheme(this.props.data);
    this.handleSetData({ data, isNew: true, change: true, focusInt: true });
  };

  handleFind = (id) => {
    if (this.state.change) {
      ShowInfo(msg("MSG-CHANGE"));
    } else if (id !== "-1") {
      Keep.vale(id, this.props.project_id).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false, focusInt: true });
        }
      });
    }
  };

  handleModuleWarehouse = () => {
    const modules = this.props.project.modules || [];
    const index = modules.findIndex((element) => element._id === "MODULE-WAREHOUSE" && element.chk === true);
    return index > -1;
  };

  handlePrintSuports = (e) => {
    const items = this.state.data.details;
    let result = [];

    for (let i = 0; i < e; i++) {
      result.push(
        <Td key={i} className="suport-item" cols="24px 75px 75px 35px auto 114px">
          <div className="suport-td t-c">{i + 1}</div>
          <div className="suport-td t-c">{getRowValue(items, i, "code", "")}</div>
          <div className="suport-td t-r">{getRowNumber(items, i, "quantity", "")}</div>
          <div className="suport-td t-c">{getRowValue(items, i, "unity", "")}</div>
          <div className="suport-td">{getRowValue(items, i, "description", "")}</div>
          <div className="suport-td t-r bt">
            {getRowNumber(items, i, "total", "")}
            <Button type="button" className="btn btn-secondary btn-sm" _state={this.state.data._state}>
              <i className="fa fa-ellipsis-h"></i>
            </Button>
          </div>
        </Td>
      );
    }
    return result;
  };

  handleTab = (e) => {
    const tabs = this.state.tabs;
    if (!tabs[e]) {
      tabs[e] = true;
      this.setState({ tabs: tabs });
    }
  };

  handleScroll = (e) => {
    const h = e.target.scrollTop + e.target.clientHeight + 1;
    if (e.target.id === `${this.state._id}_scroll` && h >= e.target.scrollHeight) {
      this.handleDetails(true);
    }
  };

  componentDidMount() {
    Subscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.data._id !== this.state.data._id) {
        UnSubscribe(`${prevState.data._class}/${prevState.data._id}`, (event) => this.eventSetData(event));
        Subscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
      }
      if (prevState.show !== this.state.show) {
        if (this.props.isNew) {
          this.handleNew();
        } else if (this.props.isFind) {
          this.handleFind(this.props._id);
        } else {
          this.handleData();
        }
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog __modalObject sm" role="document">
                <div className="modal-content">
                  <ModalHeaderSuport
                    title={this.state.data.caption}
                    _state={this.state.data._state}
                    _id={this.state.data._id}
                    isNew={this.state.isNew}
                    alerts={this.state.data.alerts}
                    change={this.state.change}
                    handleClose={this.handleClose}
                    handlePrior={this.handlePrior}
                    handleNext={this.handleNext}
                  />
                  <div className="modal-body-tabs">
                    <nav>
                      <div className="nav nav-tabs" role="tablist">
                        <a
                          className="nav-item nav-link active"
                          id={`${this.state._id}-nav-1-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-1`}
                          role="tab"
                          aria-selected="true"
                        >
                          General
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-2-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-2`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(2)}
                        >
                          Stock
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-3-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-3`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(3)}
                        >
                          Operaciones
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-4-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-4`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(4)}
                        >
                          Consumos de mataterial
                        </a>
                      </div>
                    </nav>
                    <div className="tab-content modal">
                      <div
                        className="tab-pane fade show active"
                        id={`${this.state._id}-nav-1`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-1-tab`}
                      >
                        <div className="suport">
                          <div className="suport-group">
                            <Td className="suport-row" cols="100px auto 90px 160px">
                              <div className="suport-td caption">Destino</div>
                              <div className="suport-td">
                                <Section
                                  id={`${this.state._id}_caption`}
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="destiny"
                                  value={this.state.data._data.destiny}
                                  onChange={this.handleChangeData}
                                />
                              </div>
                              <div className="suport-td caption">Código</div>
                              <div className="suport-td">
                                <Section
                                  className="form-control sp ctr-sm t-c"
                                  _state={this.state.warehouse ? "1" : this.state.data._state}
                                  name="code"
                                  value={this.state.data.code}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </Td>
                            <Td className="suport-row" cols="100px auto 90px 160px">
                              <div className="suport-td caption">Responsable</div>
                              <div className="suport-td">
                                <User
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  select={{
                                    _id: this.state.data.responsable_id,
                                    caption: this.state.data._data.responsable,
                                  }}
                                  onChange={this.handleChangeResponsable}
                                />
                              </div>
                              <div className="suport-td caption">Fecha</div>
                              <div className="suport-td">
                                <DateTime
                                  className="form-control sp form-control-sm t-c"
                                  popperClassName="datepicker-right"
                                  name="date_of"
                                  value={this.state.data.date_of}
                                  _state={this.state.data._state}
                                  onChange={this.handleChangeDate}
                                  placeholder="Fecha de creación"
                                  dateFormat="MMM d, yyyy"
                                />
                              </div>
                            </Td>
                            <Td className="suport-row" cols="100px auto 90px 160px">
                              <div className="suport-td caption">Observaciones</div>
                              <div className="suport-td">
                                <Section
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="observations"
                                  value={this.state.data.observations}
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div className="suport-td caption">Tipo</div>
                              <div className="suport-td">
                                <Type
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  placeholder="Tipo"
                                  project_id={this.state.data.project_id}
                                  _class="KEEP_VALE_TIPO"
                                  _state={this.state.data._state}
                                  title="Tipo de ODT"
                                  select={{
                                    _id: this.state.data.type_tp,
                                    caption: this.state.data._data.type,
                                  }}
                                  onChange={this.handleChangeType}
                                />
                              </div>
                            </Td>
                          </div>
                          <ComponentDetails
                            isNew={this.state.isNew}
                            _state={this.state.data._state}
                            _id={this.state.data._id}
                            data={this.state.data}
                            warehouse={this.state.warehouse}
                            select={this.state.select}
                            changeDetail={this.handleChangeDetail}
                            scrolling={this.handleScroll}
                          />
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-2`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-2-tab`}
                      >
                        <Stocks
                          project_id={this.state.data.project_id}
                          _state={this.state.data._state}
                          _class={this.state.data._class}
                          _id={this.state.data._id}
                          show={this.state.tabs[2] || false}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-3`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-3-tab`}
                      >
                        <Operations
                          project_id={this.state.data.project_id}
                          _state={this.state.data._state}
                          _class={this.state.data._class}
                          _id={this.state.data._id}
                          master={{
                            vale_id: getSource(this.state.data, ["_id"], "-1"),
                            vale: getSource(this.state.data, ["code"], ""),
                          }}
                          isNew={true}
                          show={this.state.tabs[3] || false}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-4`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-4-tab`}
                      >
                        <Consumptions
                          project_id={this.state.data.project_id}
                          _state={this.state.data._state}
                          _class={this.state.data._class}
                          _id={this.state.data._id}
                          show={this.state.tabs[4] || false}
                        />
                      </div>
                    </div>
                  </div>
                  <ModalFooterSuport
                    data={this.state.data}
                    change={this.state.change}
                    _state={this.state.data._state}
                    send={false}
                    isNew={this.state.isNew}
                    printParams={this.state.printParams}
                    handleState={this.handleState}
                    handleNew={this.handleNew}
                    handleClose={this.handleClose}
                    handleOk={this.handleOk}
                  />
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    project: state.sistem.project,
    _view: state.sistem.folder._view || "",
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ModalVale);

function ComponentDetails(props) {
  const isNew = props.isNew || false;
  const _state = props._state;
  const _id = props._id;
  const data = props.data;
  const details = data.details || {};
  const list = details.list || [];
  const warehouse = props.warehouse || false;
  const select = props.select || { _id: "", caption: "" };

  return (
    <React.Fragment>
      <div className="suport-menu">
        <ModalExit
          className={warehouse ? "btn btn-primary btn-sm mr-1" : "d-none"}
          data={{
            project_id: data.project_id,
            cellar_id: "-1",
            cellar: "",
            document_tp: data._class,
            document_id: data._id,
            document_code: data.code,
            document_type: getData(data, "class_name", "vale"),
            solicito_id: getValue(data, "responsable_id", "-1"),
            solicito: getData(data, "responsable", ""),
          }}
          _state={isNew ? "main_not_exist" : _state}
          isNew={_state === "0" ? true : false}
          onSetData={props.changeDetail}
        >
          Salida
        </ModalExit>
        <ModalRefund
          className={warehouse ? "btn btn-primary btn-sm mr-1" : "d-none"}
          data={{
            project_id: data.project_id,
            cellar_id: "-1",
            cellar: "",
            document_tp: data._class,
            document_id: data._id,
            document_code: data.code,
            document_type: getData(data, "class_name", "vale"),
            reintegra_id: getValue(data, "responsable_id", "-1"),
            reintegra: getData(data, "responsable", ""),
          }}
          _state={isNew ? "main_not_exist" : _state}
          isNew={_state === "0" ? true : false}
          onSetData={props.changeDetail}
        >
          Reintegro
        </ModalRefund>
        <ModalValeDelivery
          className={warehouse ? "d-none" : "btn btn-primary btn-sm mr-1"}
          data={{
            project_id: data.project_id,
            document_tp: data._class,
            document_id: data._id,
            document_code: data.code,
            document_type: getData(data, "class_name", "Vale"),
            responsable_id: getValue(data, "responsable_id", "-1"),
            responsable: getData(data, "responsable", ""),
          }}
          _state={isNew ? "main_not_exist" : _state}
          isNew={_state === "0" ? true : false}
          onSetData={props.changeDetail}
        >
          Entrega
        </ModalValeDelivery>
        <ModalValeRefund
          className={warehouse ? "d-none" : "btn btn-primary btn-sm mr-1"}
          data={{
            project_id: data.project_id,
            document_tp: data._class,
            document_id: data._id,
            document_code: data.code,
            document_type: getData(data, "class_name", "Vale"),
            responsable_id: getValue(data, "responsable_id", "-1"),
            responsable: getData(data, "responsable", ""),
          }}
          _state={isNew ? "main_not_exist" : _state}
          isNew={_state === "0" ? true : false}
          onSetData={props.changeDetail}
        >
          Reintegro
        </ModalValeRefund>
        <ModalValeTransfer
          className={warehouse ? "d-none" : "btn btn-primary btn-sm mr-1"}
          data={{
            project_id: data.project_id,
            document_tp: data._class,
            document_id: data._id,
            document_code: data.code,
            document_type: getData(data, "class_name", "Vale"),
            responsable_id: getValue(data, "responsable_id", "-1"),
            responsable: getData(data, "responsable", ""),
          }}
          _state={isNew ? "main_not_exist" : _state}
          isNew={_state === "0" ? true : false}
          onSetData={props.changeDetail}
        >
          Tranferencia
        </ModalValeTransfer>
      </div>
      <div className="suport-group" style={{ marginBottom: "20px" }}>
        <Td className="suport-head" cols={warehouse ? "115px 180px 140px auto 120px" : "115px 180px auto 120px"}>
          <div className="suport-td">Fecha</div>
          <div className="suport-td">Documento</div>
          <div className={warehouse ? "suport-td" : "d-none"}>Bodega</div>
          <div className="suport-td">Responsable</div>
          <div className="suport-td">Valor</div>
        </Td>
        <div className="suport-scroll small" id={`${_id}_scroll`} onScroll={props.scrolling}>
          {list.map((item, i) => {
            if (item._class === "SUPORT-WAREHOUSE-REFUND") {
              return (
                <ModalRefund
                  key={i}
                  project_id={item.project_id}
                  isFind={true}
                  _id={item._id}
                  onSetData={props.changeDetail}
                >
                  <Td
                    className={`${
                      data._state === "0"
                        ? item._state === "0"
                          ? "suport-item _state0"
                          : "suport-item"
                        : "suport-item disabled"
                    } ${item._id === select._id ? "active" : ""}`}
                    cols="25px 90px 180px 140px auto 116px"
                  >
                    <div className="suport-td t-c">{i + 1}</div>
                    <div className="suport-td t-c">{formatDate(item.date_of, "MMM d, yyyy")}</div>
                    <div className="suport-td">{item.caption}</div>
                    <div className="suport-td">{item.cellar}</div>
                    <div className="suport-td">{item.responsable}</div>
                    <div className="suport-td t-r">{formatMoney(item.cost)}</div>
                  </Td>
                </ModalRefund>
              );
            } else if (item._class === "SUPORT-WAREHOUSE-EXIT") {
              return (
                <ModalExit
                  key={i}
                  project_id={item.project_id}
                  isFind={true}
                  _id={item._id}
                  onSetData={props.changeDetail}
                >
                  <Td
                    className={`${
                      data._state === "0"
                        ? item._state === "0"
                          ? "suport-item _state0"
                          : "suport-item"
                        : "suport-item disabled"
                    } ${item._id === select._id ? "active" : ""}`}
                    cols="25px 90px 180px 140px auto 116px"
                  >
                    <div className="suport-td t-c">{i + 1}</div>
                    <div className="suport-td t-c">{formatDate(item.date_of, "MMM d, yyyy")}</div>
                    <div className="suport-td">{item.caption}</div>
                    <div className="suport-td">{item.cellar}</div>
                    <div className="suport-td">{item.responsable}</div>
                    <div className="suport-td t-r">{formatMoney(item.cost)}</div>
                  </Td>
                </ModalExit>
              );
            } else if (item._class === "SUPORT-KEEP-DELIVERY") {
              return (
                <ModalValeDelivery
                  key={i}
                  project_id={item.project_id}
                  isFind={true}
                  _id={item._id}
                  onSetData={props.changeDetail}
                >
                  <Td
                    className={`${
                      data._state === "0"
                        ? item._state === "0"
                          ? "suport-item _state0"
                          : "suport-item"
                        : "suport-item disabled"
                    } ${item._id === select._id ? "active" : ""}`}
                    cols={warehouse ? "25px 90px 180px 140px auto 116px" : "25px 90px 180px auto 116px"}
                  >
                    <div className="suport-td t-c">{i + 1}</div>
                    <div className="suport-td t-c">{formatDate(item.date_of, "MMM d, yyyy")}</div>
                    <div className="suport-td">{item.caption}</div>
                    <div className="suport-td">{item.responsable}</div>
                    <div className="suport-td t-r">{formatMoney(item.cost)}</div>
                  </Td>
                </ModalValeDelivery>
              );
            } else if (item._class === "SUPORT-KEEP-TRANSFER") {
              return (
                <ModalValeTransfer
                  key={i}
                  project_id={item.project_id}
                  isFind={true}
                  _id={item._id}
                  onSetData={props.changeDetail}
                >
                  <Td
                    className={`${
                      data._state === "0" ? "suport-item" : "suport-item disabled"
                    }${` _state${item._state}`}${item._id === select._id ? " active" : ""}`}
                    cols={warehouse ? "25px 90px 180px 140px auto 116px" : "25px 90px 180px auto 116px"}
                  >
                    <div className="suport-td t-c">{i + 1}</div>
                    <div className="suport-td t-c">{formatDate(item.date_of, "MMM d, yyyy")}</div>
                    <div className="suport-td">{item.caption}</div>
                    <div className={warehouse ? "suport-td" : "d-none"}>{item.cellar}</div>
                    <div className="suport-td">{item.responsable}</div>
                    <div className="suport-td t-r">{formatMoney(item.cost)}</div>
                  </Td>
                </ModalValeTransfer>
              );
            } else {
              return (
                <ModalValeRefund
                  key={i}
                  project_id={item.project_id}
                  isFind={true}
                  _id={item._id}
                  onSetData={props.changeDetail}
                >
                  <Td
                    className={`${
                      data._state === "0"
                        ? item._state === "0"
                          ? "suport-item _state0"
                          : "suport-item"
                        : "suport-item disabled"
                    } ${item._id === select._id ? "active" : ""}`}
                    cols={warehouse ? "25px 90px 180px 140px auto 116px" : "25px 90px 180px auto 116px"}
                  >
                    <div className="suport-td t-c">{i + 1}</div>
                    <div className="suport-td t-c">{formatDate(item.date_of, "MMM d, yyyy")}</div>
                    <div className="suport-td">{item.caption}</div>
                    <div className="suport-td">{item.responsable}</div>
                    <div className="suport-td t-r">{formatMoney(item.cost)}</div>
                  </Td>
                </ModalValeRefund>
              );
            }
          })}
        </div>
      </div>
    </React.Fragment>
  );
}
