import React from "react";
import ReactDOM from "react-dom";
import "../../../styles/modal.scss";
import "../../../styles/suport.scss";
import { hideModal, showModal, ShowInfo, getValue, genId, formatNumber } from "../../../components/utilities";
import Td from "../../../components/td";
import ModalHeader from "../../../components/modalHeader";
import ModalFooter from "../../../components/modalFooter";
import ModalClass from "../modalClass";
import { AssetClass } from "../listClass";
import { Count } from "../../../components/inputs";

class ModalProfileConnection extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme();
    this.state = {
      _id: "__ModalProfileConnection",
      title: "Conexión",
      project_id: props.project_id,
      show: false,
      old: data,
      data: data,
      change: false,
    };
  }

  handleScheme = (e) => {
    return {
      project_id: this.props.project_id,
      _id: genId("-1"),
      profile_id: this.props.profile_id,
      class_id: "-1",
      class: "",
      equipment: false,
      quantity: 0,
      isdefault: false,
      required: false,
      _order: false,
      _data: {},
    };
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeClase = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        class_id: getValue(e, "_id", "-1"),
        class: getValue(e, "caption", ""),
        equipment: getValue(e, "equipment", false),
      },
      change: true,
    });
  };

  handleChangeValue = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.name]: Number(e.value),
      },
      change: true,
    });
  };

  handleChangeChk = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: Boolean(e.target.checked),
      },
      change: true,
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
    this.handleHide();
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    const _state = this.props._state || "0";
    if (_state === "0") {
      if (this.props.isNew) {
        this.setState({ show: true, change: false });
      } else if (getValue(this.props, "_id", "-1") !== "-1") {
        this.setState({ show: true, change: false });
      }
    }
  };

  handleClose = () => {
    if (this.state.change) {
      this.setState({ data: this.state.old, change: false });
    }
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.data.class_id === "-1") {
      ShowInfo("Clase requerida");
    } else if (this.state.change) {
      this.handleExecute({ data: this.state.data, action: "set" });
    } else {
      this.handleHide();
    }
  };

  handleFinish = (e) => {
    if (e) {
      this.handleExecute({ data: this.state.data, action: "del" });
    }
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      isNew: e.isNew,
      change: e.change,
    });
    showModal(this.state._id);
  };

  handleData = () => {
    const data = this.props.data;
    this.handleSetData({ data, isNew: false, change: false });
  };

  handleNew = () => {
    const data = this.handleScheme();
    this.handleSetData({ data, isNew: true, change: true });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        if (this.props.isNew) {
          this.handleNew();
        } else {
          this.handleData();
        }
      }
    }
  }

  componentWillUnmount() {
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog xs" role="dialog">
                <div className="modal-content">
                  <ModalHeader title={this.state.title} handleClose={this.handleClose} />
                  <div className="modal-body">
                    <div className="modal-box">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group input-group-sm">
                            <label>
                              <ModalClass
                                className="link"
                                project_id={this.props.project_id}
                                _state={this.props._state}
                                _id={this.state.data.class_id}
                                isFind={true}
                                onSetData={this.handleChangeClase}
                              >
                                Clase
                              </ModalClass>
                            </label>
                            <AssetClass
                              project_id={this.props.project_id}
                              _state={this.props._state}
                              equipment={this.state.data.equipment}
                              select={{
                                _id: this.state.data.class_id,
                                caption: this.state.data.class,
                              }}
                              onChange={this.handleChangeClase}
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>Cantidad</label>
                            <Count
                              id={`${this.state._id}_caption`}
                              className="input-group input-group-sm"
                              _state={this.props._state}
                              name="quantity"
                              unity="und"
                              value={this.state.data.quantity}
                              increase={1}
                              handleValue={this.handleChangeValue}
                            />
                          </div>
                          <div className="form-group input-group-sm border-top pt-2">
                            <div className="custom-control custom-switch">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id={`${this.state._id}_Checkbox1`}
                                name="isdefault"
                                checked={this.state.data.isdefault}
                                onChange={this.handleChangeChk}
                              />
                              <label className="custom-control-label pt-px-2 ml-2 pl-2" htmlFor={`${this.state._id}_Checkbox1`}>
                                Preconectado
                              </label>
                            </div>
                            <div className="custom-control custom-switch">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id={`${this.state._id}_Checkbox2`}
                                name="required"
                                checked={this.state.data.required}
                                onChange={this.handleChangeChk}
                              />
                              <label className="custom-control-label pt-px-2 ml-2 pl-2" htmlFor={`${this.state._id}_Checkbox2`}>
                                Requerido
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooter
                      single="Conexión"
                      plural="la conexión"
                      _state={this.props._state}
                      isNew={this.props.isNew}
                      onFinish={this.handleFinish}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function ComponentProfileConnections(props) {
  const data = props.data || {};
  const list = data.connections || [];

  return (
    <React.Fragment>
      <div className="modalList single pt-0 pb-0">
        <div className="modalListFind">
          <div className="suport-td border-0">
            <ModalProfileConnection
              className="btn btn-primary btn-sm float-right"
              project_id={props.project_id}
              profile_id={props.profile_id}
              _state={props._state}
              isNew={true}
              onSetData={props.onChange}
            >
              Nuevo
            </ModalProfileConnection>
          </div>
        </div>
        <div className="modalListList">
          <div className="suport-group">
            <Td className="suport-head" cols="auto 99px">
              <div className="suport-th">Componente</div>
              <div className="suport-th">Cantidad</div>
            </Td>
            <div className="suport-scroll small" style={{ height: props.height || "440px" }}>
              {list.map((item, i) => {
                return (
                  <ModalProfileConnection
                    key={i}
                    project_id={props.project_id}
                    profile_id={props.profile_id}
                    _state={props._state}
                    data={item}
                    onSetData={props.onChange}
                  >
                    <Td key={i} className={item.profile_id === "-1" ? "d-none" : "suport-item"} cols="auto 95px">
                      <div className="suport-td">{item.class}</div>
                      <div className="suport-td t-r">{formatNumber(item.quantity)}</div>
                    </Td>
                  </ModalProfileConnection>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ComponentProfileConnections;
