import React from "react";
import ReactDOM from "react-dom";
import "../../../styles/modal.scss";
import "../../../styles/suport.scss";
import { showModal, hideModal, getValue, genId, clone } from "../../../components/utilities";
import { Input, Count } from "../../../components/inputs";
import { AssetClass } from "../../assets/listClass";
import { AssetType } from "../../assets/listAssetTypes";
import { AssetReference } from "../../assets/listAssetReferences";
import ModalHeader from "../../../components/modalHeader";
import ModalFooter from "../../../components/modalFooter";
import ModalClass from "../../assets/modalClass";
import ModalType from "../../assets/modalType";
import ModalReference from "../../warehouse/modalReference";

class ModalInventory extends React.Component {
  constructor(props) {
    super(props);
    let data = this.handleScheme();
    this.state = {
      _id: "__ModalInventory",
      title: "Inventario",
      show: false,
      old: data,
      data: data,
      change: false,
    };
  }

  handleScheme = () => {
    return {
      project_id: this.props.project_id,
      operation_id: this.props.operation_id,
      _state: this.props._state,
      _id: genId("-1"),
      vale_id: this.props.vale_id,
      main_id: this.props.main_id,
      activity_tp: "-1",
      class_id: this.props.class_id || "-1",
      old_type_id: "-1",
      old_reference_id: "-1",
      old_asset_id: "-1",
      old_code: "",
      old_serie: "",
      old_quantity: 0,
      old_cost: 0,
      type_id: "-1",
      reference_id: "-1",
      asset_id: "-1",
      code: "",
      serie: "",
      quantity: 0,
      unitary: 0,
      maximum: 0,
      _data: {
        old_asset_type: "",
        old_reference_code: "",
        old_reference: "",
        old_reference_unity: "",
        activity: "",
        asset_class: "",
        asset_type: "",
        asset_reference_code: "",
        asset_reference: "",
        unity: "",
      },
    };
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeData = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          [e.target.name]: e.target.value,
        },
      },
      change: true,
    });
  };

  handleChangeClase = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        class_id: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          asset_class: getValue(e, "caption", ""),
          unity: getValue(e, "unity", ""),
        },
      },
      change: true,
    });
  };

  handleChangeAssetType = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        old_type_id: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          old_asset_type: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeReference = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        old_reference_id: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          old_reference: getValue(e, "caption", ""),
          old_reference_code: getValue(e, "code", ""),
          unity: getValue(e, "unity", ""),
        },
      },
      change: true,
    });
  };

  handleChangeQuantity = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.name]: e.value,
      },
      change: true,
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData({
        index: this.props.index,
        item: e,
      });
    }
    this.handleHide();
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({
      show: true,
      change: false,
    });
  };

  handleClose = () => {
    if (this.state.change) {
      this.setState({
        data: this.state.old,
        change: false,
      });
    }
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.change) {
      this.handleExecute(this.state.data);
    } else {
      this.handleHide();
    }
  };

  handleRemove = (e) => {
    if (this.props.main_id === "-1") {
      if (e) {
        let data = {
          ...this.state.data,
          activity_tp: "-1",
          class_id: "-1",
          old_type_id: "-1",
          old_reference_id: "-1",
          old_asset_id: "-1",
          old_code: "",
          old_serie: "",
          old_quantity: 0,
          old_cost: 0,
          _data: {
            ...this.state.data._data,
            old_asset_type: "",
            old_reference_code: "",
            old_reference: "",
            activity: "",
            asset_class: "",
            asset_type: "",
            asset_reference_code: "",
            asset_reference: "",
            unity: "",
          },
        };
        this.handleExecute(data);
      }
    } else {
      if (e) {
        let data = {
          ...this.state.data,
          old_type_id: "-1",
          old_reference_id: "-1",
          old_asset_id: "-1",
          old_code: "",
          old_serie: "",
          old_quantity: 0,
          old_cost: 0,
          _data: {
            ...this.state.data._data,
            old_asset_type: "",
            old_reference_code: "",
            old_reference: "",
          },
        };
        this.handleExecute(data);
      }
    }
  };

  handleData = () => {
    let data = clone(this.props.data);
    let _id = getValue(data, "_id", "-1");
    if (_id === "-1") {
      data = this.handleScheme();
    }
    this.setState({ old: data, data: data, change: false });
    showModal(this.state._id);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        this.handleData();
      }
    }
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog xs" role="dialog">
                <div className="modal-content">
                  <ModalHeader
                    title={this.state.data._data.asset_class === "" ? this.state.title : this.state.data._data.asset_class}
                    handleClose={this.handleClose}
                  />
                  <div className="modal-body">
                    <div className="modal-box">
                      <div className="row">
                        <div className="col-12">
                          <div className={this.state.data.class_id === "-1" ? "form-group input-group-sm" : "d-none"}>
                            <label>
                              <ModalClass
                                className="link"
                                project_id={this.state.data.project_id}
                                _state={this.state.data._state}
                                _id={this.state.data.class_id}
                                isFind={true}
                                onSetData={this.handleChangeClase}
                              >
                                Componente
                              </ModalClass>
                            </label>
                            <AssetClass
                              className="form-control sp ctr-sm t-m"
                              classNameBtn="input-group-text sp"
                              project_id={this.state.data.project_id}
                              _state={this.state.data._state}
                              equipment={false}
                              select={{
                                _id: this.state.data.class_id || "-1",
                                caption: this.state.data._data.asset_class || "",
                              }}
                              onChange={this.handleChangeClase}
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>
                              <ModalType
                                className="link"
                                project_id={this.state.data.project_id}
                                _state={this.state.data._state}
                                _id={this.state.data.old_type_id}
                                isFind={true}
                                onSetData={this.handleChangeAssetType}
                              >
                                Tipo
                              </ModalType>
                            </label>
                            <AssetType
                              className="form-control sp ctr-sm t-m"
                              classNameBtn="input-group-text sp"
                              project_id={this.props.project_id}
                              class_id={this.props.class_id}
                              _state={this.state.data._state}
                              select={{
                                _id: this.state.data.old_type_id,
                                caption: this.state.data._data.old_asset_type,
                              }}
                              onChange={this.handleChangeAssetType}
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>
                              <ModalReference
                                className="link"
                                isFind={true}
                                project_id={this.state.data.project_id}
                                _id={this.state.data.old_reference_id}
                              >
                                Referencia
                              </ModalReference>
                            </label>
                            <AssetReference
                              type="text"
                              className="form-control sp ctr-sm t-m"
                              classNameBtn="input-group-text sp"
                              placeholder="Referencia"
                              project_id={this.props.project_id}
                              class_id={this.state.data.class_id}
                              class_name={this.state.data._data.asset_class || ""}
                              _state={this.state.data._state}
                              select={{
                                _id: this.state.data.old_reference_id,
                                caption: this.state.data._data.old_reference,
                              }}
                              onSetData={this.handleChangeReference}
                            />
                          </div>
                          <div className="form-row input-group-sm mb-0">
                            <div className="form-group col-md-5 input-group-sm mb-0">
                              <label>Código</label>
                              <Input
                                className="form-control"
                                id={`${this.state._id}_caption`}
                                project_id={this.state.data.project_id}
                                _state={this.state.data._state}
                                name="old_code"
                                value={this.state.data.old_code}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="form-group col-md-7 input-group-sm mb-0">
                              <label>Cantidad</label>
                              <Count
                                className="input-group input-group-sm"
                                classNameUnity="input-group-text unity-sm"
                                _state={this.state.data._state}
                                name="old_quantity"
                                unity={this.state.data._data.unity || "und"}
                                value={this.state.data.old_quantity}
                                increase={1}
                                precision={2}
                                handleValue={this.handleChangeQuantity}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooter
                      title="¿Retirar componente?"
                      description="¿Desea retirar el componente? este cambio no podrá revertirse."
                      _state={this.props._state}
                      onRemove={this.handleRemove}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

export default ModalInventory;
