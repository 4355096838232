import React from "react";
import ReactDOM from "react-dom";
import "../../styles/modal.scss";
import {
  showModal,
  hideModal,
  genId,
  ShowInfo,
  msg,
  getValue,
  getData,
  getSource,
  formId,
} from "../../components/utilities";
import { Section, Textarea } from "../../components/inputs";
import ModalHeader from "../../components/modalHeader";
import ModalFooter from "../../components/modalFooter";
import ComponentAtribs from "./components/componentAtribs";
import ModalClass from "./modalClass";
import ModalProfile from "./modalProfile";
import { AssetClass } from "./listClass";
import { AssetProfile } from "./listProfiles";
import { Api as Asset } from "../../services/asset";
import { connect } from "react-redux";

class ModalType extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme();
    this.state = {
      form: "__ModalType",
      _id: "__ModalType",
      title: "Tipo",
      rows: 15,
      show: false,
      old: data,
      data: data,
      references: {
        end: 0,
        all: 0,
      },
      printParams: {
        project_id: "-1",
        _class: "TYPE",
        _id: "-1",
        single: true,
      },
      change: false,
    };
  }

  handleScheme = (e) => {
    return {
      project_id: this.props.project_id,
      _class: "JS_TYPES",
      _state: "0",
      _id: genId("-1"),
      name: "",
      caption: "",
      description: "",
      class_id: "-1",
      equipment: false,
      profile_id: "-1",
      scheme: [],
      atribs: {},
      _data: {
        short_name: "",
      },
      username: "",
    };
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeData = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          [e.target.name]: e.target.value,
        },
      },
      change: true,
    });
  };

  handleChangeClase = (e) => {
    const scheme = getData(e, "scheme_type", []);
    this.setState({
      data: {
        ...this.state.data,
        class_id: getValue(e, "_id", "-1"),
        equipment: getValue(e, "equipment", false),
        scheme: scheme,
        _data: {
          ...this.state.data._data,
          class: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeProfile = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        profile_id: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          profile: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeAtrib = (e) => {
    if (e.action === "set") {
      let atrib = e.data;
      let data = this.state.data;
      let atribs = data.atribs;
      atribs[atrib.name] = atrib.value;
      this.setState({
        data: {
          ...this.state.data,
          atribs: atribs,
        },
        change: true,
      });
    }
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    if (this.props.isFind) {
      const _id = getValue(this.props, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    } else if (this.props.isNew) {
      const form_id = `${this.state.form}${Date.now()}`;
      this.setState({ _id: form_id, show: true, change: false });
    } else {
      const _id = getValue(this.props.data, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    }
  };

  handleClose = (e) => {
    if (this.state.change && e) {
      this.handleOk();
    } else {
      this.handleHide();
    }
  };

  handleOk = () => {
    if (this.state.change) {
      const id = this.state.data._id;
      const params = this.state.data;
      Asset.setType(id, params).then((result) => {
        if (result.msg === "") {
          let data = result.data;
          this.handleSetData({ data, isNew: false, change: false, focusInt: false });
          this.handleExecute(result.data);
        }
      });
    } else {
      this.handleHide();
    }
  };

  handleFinish = (e) => {
    if (e) {
      const id = this.state.data._id;
      Asset.stateType(id, "1").then((result) => {
        if (result.msg === "") {
          this.handleExecute(result.data);
          this.handleHide();
        }
      });
    }
  };

  handleRestore = (e) => {
    if (e) {
      const id = this.state.data._id;
      Asset.stateType(id, "0").then((result) => {
        if (result.msg === "") {
          this.handleExecute(result.data);
        }
      });
    }
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      isNew: e.isNew,
      change: e.change,
      printParams: {
        project_id: e.data.project_id,
        _class: e.data._class,
        _id: e.data._id,
        single: true,
      },
    });
    showModal(this.state._id);
  };

  handleData = () => {
    const _id = getSource(this.props.data, ["_id"], "-1");
    if (_id === "-1") {
      this.handleSetData({ data: this.props.data, isNew: false, change: false });
    } else {
      this.handleFind(_id);
    }
  };

  handleNew = () => {
    const data = this.handleScheme();
    this.handleSetData({ data, isNew: true, change: true });
  };

  handleFind = (id) => {
    if (this.state.change) {
      ShowInfo(msg("MSG-CHANGE"));
    } else if (id !== "-1") {
      Asset.type(id, this.props.project_id).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false });
        }
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        if (this.props.isNew) {
          this.handleNew();
        } else if (this.props.isFind) {
          this.handleFind(this.props._id);
        } else {
          this.handleData();
        }
      }
    }
  }

  componentWillUnmount() {
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog xs __modalObject" role="dialog">
                <div className="modal-content">
                  <ModalHeader title={this.state.title} handleClose={this.handleClose} />
                  <div className="modal-body-tabs" style={{ height: "435px" }}>
                    <nav>
                      <div className="nav nav-tabs" role="tablist">
                        <a
                          className="nav-item nav-link active"
                          id={`${this.state._id}-nav-1-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-1`}
                          role="tab"
                          aria-selected="true"
                        >
                          General
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-2-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-2`}
                          role="tab"
                          aria-selected="false"
                        >
                          Atributos
                        </a>
                      </div>
                    </nav>
                    <div className="tab-content modal not-scroll">
                      <div
                        className="tab-pane fade show active"
                        id={`${this.state._id}-nav-1`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-1-tab`}
                      >
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group input-group-sm">
                              <label>
                                <ModalClass
                                  className="link"
                                  project_id={this.props.project_id}
                                  _state={this.state.data._state}
                                  _id={this.state.data.class_id}
                                  isFind={true}
                                  onSetData={this.handleChangeClase}
                                >
                                  Clase
                                </ModalClass>
                              </label>
                              <AssetClass
                                project_id={this.props.project_id}
                                _state={this.state.data._state}
                                equipment={this.state.data.equipment}
                                select={{
                                  _id: this.state.data.class_id,
                                  caption: this.state.data._data.class,
                                }}
                                onChange={this.handleChangeClase}
                              />
                            </div>
                            <div className="form-group input-group-sm">
                              <label>Nombre</label>
                              <Section
                                id={`${this.state._id}_caption`}
                                type="text"
                                className="form-control name"
                                placeholder="Nombre"
                                _state={this.state.data._state}
                                name="caption"
                                value={this.state.data.caption}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="form-group input-group-sm">
                              <label>Nombre corto</label>
                              <Section
                                type="text"
                                className="form-control name"
                                placeholder="Nombre corto"
                                _state={this.state.data._state}
                                name="short_name"
                                value={this.state.data._data.short_name}
                                onChange={this.handleChangeData}
                              />
                            </div>
                            <div className="form-group input-group-sm">
                              <label>Descripción</label>
                              <Textarea
                                className="form-control notresize"
                                placeholder="description"
                                rows={4}
                                _state={this.state.data._state}
                                name="description"
                                value={this.state.data.description}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="form-group input-group-sm">
                              <label>
                                <ModalProfile
                                  className="link"
                                  project_id={this.props.project_id}
                                  _id={this.state.data.profile_id}
                                  isFind={true}
                                >
                                  Perfil de mantenimiento
                                </ModalProfile>
                              </label>
                              <AssetProfile
                                project_id={this.props.project_id}
                                class_id={this.state.data.class_id}
                                class={this.state.data.class}
                                _state={this.state.data._state}
                                select={{
                                  _id: this.state.data.profile_id,
                                  caption: this.state.data._data.profile,
                                }}
                                onChange={this.handleChangeProfile}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-2`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-2-tab`}
                      >
                        <ComponentAtribs
                          project_id={this.props.project_id}
                          class_id={this.state.data.class_id}
                          _state={this.state.data._state}
                          scheme={this.state.data.scheme}
                          atribs={this.state.data.atribs}
                          onSetData={this.handleChangeAtrib}
                          onChangeClase={this.handleChangeClase}
                          height="310px"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooter
                      single="Tipo"
                      plural="el tipo"
                      project_id={this.state.data.project_id}
                      _state={this.state.data._state}
                      change={this.state.change}
                      onFinish={this.handleFinish}
                      onRestore={this.handleRestore}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    project: state.sistem.project,
    _view: state.sistem.folder._view || "",
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ModalType);
