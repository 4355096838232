import React from "react";
import ReactDOM from "react-dom";
import "../../styles/modal.scss";
import "../../styles/suport.scss";
import {
  showModal,
  hideModal,
  ShowInfo,
  msg,
  getValue,
  getData,
  genId,
  setFocus,
  ShowAlert,
  getRowValue,
  getRowInt,
  formId,
  formatDate,
  getSource,
  compareObject,
  UnSubscribe,
  Subscribe,
} from "../../components/utilities";
import { ALERT002 } from "../../components/msg";
import ModalHeaderSuport from "../../components/modalHeaderSuport";
import ModalFooterSuport from "../../components/modalFooterSuport";
import Td from "../../components/td";
import InformReports from "./components/informReports";
import InformOdts from "./components/informOdts";
import InformVales from "./components/informVales";
import InformOperations from "./components/informOperations";
import InformAssets from "./components/informAssets";
import InformConsumptions from "./components/informConsumptions";
import InformActivities from "./components/informActivities";
import { Api as Keep } from "../../services/keep";
import { connect } from "react-redux";
import { Count, Section, Textarea, Time, Type } from "../../components/inputs";
import DateTime from "../../components/dateTime";

class ModalInform extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme(props.data);
    this.state = {
      form: "__ModalInform",
      _id: "__ModalInform",
      title: "Informe",
      name: "inform",
      show: false,
      old: data,
      data: data,
      isNew: props.isNew || false,
      change: false,
      tabs: [],
      printParams: {
        project_id: "-1",
        _class: "SUPORT-KEEP-INFORM",
        single: true,
      },
    };
  }

  handleScheme = (data) => {
    const now = new Date();
    return {
      date_of: formatDate(now, "yyyy-MM-dd HH:mm:ss"),
      project_id: this.props.project_id,
      _class: "SUPORT-KEEP-INFORM",
      _state: "0",
      _id: genId("-1"),
      code: "",
      caption: "Reporte",
      description: "",
      observations: "",
      type_tp: getValue(data, "type_tp", "-1"),
      time_max_urban: getValue(data, "time_max_urban", 48),
      time_max_rural: getValue(data, "time_max_rural", 48),
      exclude_holidays: getValue(data, "exclude_holidays", false),
      date_int: formatDate(now, "yyyy-MM-dd HH:mm:ss"),
      date_end: null,
      indexs: [],
      chat_id: "-1",
      _data: {
        project: this.props.project,
        class_name: "",
        asunto: "",
        type: getData(data, "type", ""),
        username: "",
      },
      next_id: "-1",
      prior_id: "-1",
      _v: 1,
    };
  };

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeData = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          [e.target.name]: e.target.value,
        },
      },
      change: true,
    });
  };

  handleChangeType = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        type_tp: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          type: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeDate = (e) => {
    const value = formatDate(e.target.value, "yyyy-MM-dd HH:mm:ss");
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: value,
      },
      change: true,
    });
  };

  handleChangeValue = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.name]: Number(e.value),
      },
      change: true,
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    if (this.props.isFind) {
      const _id = getValue(this.props, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    } else if (this.props.isNew) {
      const form_id = `${this.state.form}${Date.now()}`;
      this.setState({ _id: form_id, show: true, change: false });
    } else {
      const _id = getValue(this.props.data, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    }
  };

  handleClose = (e) => {
    if (this.state.change && e) {
      this.handleOk();
    } else {
      this.handleHide();
    }
  };

  handleOk = () => {
    if (this.state.change) {
      const id = this.state.data._id;
      const params = this.state.data;
      Keep.setInform(id, params).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false });
          this.handleExecute(data);
        }
      });
    } else {
      this.handleHide();
    }
  };

  handleState = (e) => {
    if (this.state.change) {
      ShowAlert(ALERT002);
    } else {
      const _state = getValue(this.state.data, "_state");
      if (_state !== e) {
        const id = getValue(this.state.data, "_id");
        Keep.stateInform(id, e).then((result) => {
          if (result.msg === "") {
            const data = result.data;
            this.handleSetData({ data, change: false });
            this.handleExecute(data);
          }
        });
      }
    }
  };

  handleClacIndex = (e) => {
    const id = getValue(this.state.data, "_id");
    Keep.calcInform(id).then((result) => {
      if (result.msg === "") {
        const data = result.data;
        this.handleSetData({ data, change: false });
      }
    });
  };

  handlePrior = (e) => {
    const _id = getValue(this.state.data, "prior_id", "-1");
    if (_id === "-1") {
      ShowInfo(msg("MSG-FIRST-ROW"));
    } else {
      this.handleFind(_id);
    }
  };

  handleNext = (e) => {
    const _id = getValue(this.state.data, "next_id", "-1");
    if (_id === "-1") {
      ShowInfo(msg("MSG-END-ROW"));
    } else {
      this.handleFind(_id);
    }
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      isNew: e.isNew,
      change: e.change,
      tabs: [],
      printParams: {
        project_id: e.data.project_id,
        _class: e.data._class,
        _id: e.data._id,
        single: true,
      },
    });
    showModal(this.state._id);
    setFocus(`${this.state._id}_caption`, e.focusInt);
  };

  handleUpdate = (e) => {
    if (compareObject(this.state.data, e)) {
      this.handleSetData({
        data: e,
        isNew: false,
        change: false,
      });
    }
  };

  handleData = () => {
    const _id = getSource(this.props.data, ["_id"], "-1");
    if (_id === "-1") {
      this.handleSetData({ data: this.props.data, isNew: false, change: false, focusInt: true });
    } else {
      this.handleFind(_id);
    }
  };

  handleNew = () => {
    const data = this.handleScheme(this.props.data);
    this.handleSetData({ data, isNew: true, change: true, focusInt: true });
  };

  handleFind = (id) => {
    if (this.state.change) {
      ShowInfo(msg("MSG-CHANGE"));
    } else if (id !== "-1") {
      Keep.inform(id, this.props.project_id).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false, focusInt: true });
        }
      });
    }
  };

  handlePrintIndex = (e) => {
    let min = 4;
    let index = this.state.data.index || [];
    index = index.find((element) => element.type_tp === e) || {};
    let items = getValue(index, "_data", []);
    if (min < items.length) {
      min = items.length;
    }
    let total = 0;
    let total_operaciones = 0;
    let result = [];

    if (e === "KEEP_INFORM_INDEX01" || e === "KEEP_INFORM_INDEX02") {
      for (let i = 0; i < min; i++) {
        total = total + getValue(items[i], "_count", 0);
        total_operaciones = total_operaciones + getValue(items[i], "operaciones", 0);
        result.push(
          <Td key={i} className="suport-item" cols="24px auto 85px 85px">
            <div className="suport-td t-c">{i + 1}</div>
            <div className="suport-td">{getRowValue(items, i, "caption", "")}</div>
            <div className="suport-td t-r">{getRowInt(items, i, "_count", "")}</div>
            <div className="suport-td t-r">{getRowInt(items, i, "operaciones", "")}</div>
          </Td>
        );
      }
      result.push(
        <Td key="total" className="suport-item b-g" cols="auto 85px 85px">
          <div className="suport-td t-c">TOTAL</div>
          <div className="suport-td t-r">{total}</div>
          <div className="suport-td t-r">{total_operaciones}</div>
        </Td>
      );
    } else {
      for (let i = 0; i < min; i++) {
        total = total + getValue(items[i], "_count", 0);
        result.push(
          <Td key={i} className="suport-item" cols="24px auto 85px">
            <div className="suport-td t-c">{i + 1}</div>
            <div className="suport-td">{getRowValue(items, i, "caption", "")}</div>
            <div className="suport-td t-r">{getRowInt(items, i, "_count", "")}</div>
          </Td>
        );
      }
      result.push(
        <Td key="total" className="suport-item b-g" cols="auto 85px">
          <div className="suport-td t-c">TOTAL</div>
          <div className="suport-td t-r">{total}</div>
        </Td>
      );
    }
    return result;
  };

  handleTab = (e) => {
    const tabs = this.state.tabs;
    if (!tabs[e]) {
      tabs[e] = true;
      this.setState({ tabs: tabs });
    }
  };

  componentDidMount() {
    Subscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.data._id !== this.state.data._id) {
        UnSubscribe(`${prevState.data._class}/${prevState.data._id}`, (event) => this.eventSetData(event));
        Subscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
      }
      if (prevState.show !== this.state.show) {
        if (this.props.isNew) {
          this.handleNew();
        } else if (this.props.isFind) {
          this.handleFind(this.props._id);
        } else {
          this.handleData();
        }
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog __modalObject sm" role="document">
                <div className="modal-content">
                  <ModalHeaderSuport
                    title={this.state.data.caption}
                    _state={this.state.data._state}
                    _id={this.state.data._id}
                    isNew={this.state.isNew}
                    authorized={this.state.data.authorized}
                    change={this.state.change}
                    handleClose={this.handleClose}
                    handlePrior={this.handlePrior}
                    handleNext={this.handleNext}
                  />
                  <div className="modal-body-tabs">
                    <nav>
                      <div className="nav nav-tabs" role="tablist">
                        <a
                          className="nav-item nav-link active"
                          id={`${this.state._id}-nav-1-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-1`}
                          role="tab"
                          aria-selected="true"
                        >
                          General
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-2-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-2`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(2)}
                        >
                          Reportes
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-3-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-3`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(3)}
                        >
                          ODTs
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-4-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-4`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(4)}
                        >
                          Vales
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-5-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-5`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(5)}
                        >
                          Operaciones
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-6-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-6`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(6)}
                        >
                          Infraestructuras
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-7-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-7`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(7)}
                        >
                          Consumos
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-8-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-8`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(8)}
                        >
                          Actividades
                        </a>
                      </div>
                    </nav>
                    <div className="tab-content modal">
                      <div
                        className="tab-pane fade show active"
                        id={`${this.state._id}-nav-1`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-1-tab`}
                      >
                        <div className="suport">
                          <div className="suport-group">
                            <Td className="suport-row" cols="90px auto 90px 160px">
                              <div className="suport-td caption">Asunto</div>
                              <div className="suport-td">
                                <Section
                                  id={`${this.state._id}_caption`}
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="asunto"
                                  value={this.state.data._data.asunto}
                                  onChange={this.handleChangeData}
                                />
                              </div>
                              <div className="suport-td caption">Tipo</div>
                              <div className="suport-td">
                                <Type
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  placeholder="Tipo"
                                  project_id={this.state.data.project_id}
                                  _class="KEEP_INFORM_TIPO"
                                  _state={this.state.data._state}
                                  title="Tipo de informe"
                                  select={{
                                    _id: this.state.data.type_tp,
                                    caption: this.state.data._data.type,
                                  }}
                                  onChange={this.handleChangeType}
                                />
                              </div>
                            </Td>
                            <Td className="suport-row" cols="auto 250px">
                              <div className="suport-td">
                                <Textarea
                                  className="form-control sp notresize"
                                  _state={this.state.data._state}
                                  rows={3}
                                  name="observations"
                                  value={this.state.data.observations}
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div className="suport-td c">
                                <Td className="suport-row" cols="auto">
                                  <div className="suport-td caption t-c b-g">Maximos tiempos de atención</div>
                                </Td>
                                <Td className="suport-row" cols="auto 160px">
                                  <div className="suport-td caption">Urbano</div>
                                  <div className="suport-td">
                                    <Count
                                      className="input-group input-group-sm"
                                      classNameInput="form-control sp ctr-sm value"
                                      classNameUnity="input-group-text sp unity-m"
                                      classGroupText="input-group-text sp"
                                      _state={this.props._state}
                                      name="time_max_urban"
                                      unity="Horas"
                                      value={this.state.data.time_max_urban}
                                      increase={1}
                                      handleValue={this.handleChangeValue}
                                    />
                                  </div>
                                </Td>
                                <Td className="suport-row" cols="auto 160px">
                                  <div className="suport-td caption">Rural</div>
                                  <div className="suport-td">
                                    <Count
                                      className="input-group input-group-sm"
                                      classNameInput="form-control sp ctr-sm value"
                                      classNameUnity="input-group-text sp unity-m"
                                      classGroupText="input-group-text sp"
                                      _state={this.props._state}
                                      name="time_max_rural"
                                      unity="Horas"
                                      value={this.state.data.time_max_rural}
                                      increase={1}
                                      handleValue={this.handleChangeValue}
                                    />
                                  </div>
                                </Td>
                              </div>
                            </Td>
                          </div>
                          <div className="suport-group">
                            <Td className="suport-row" cols="90px auto 90px 160px 90px 160px">
                              <div className="suport-td caption">Fecha Inicio</div>
                              <div className="suport-td">
                                <DateTime
                                  className="form-control sp form-control-sm t-c"
                                  name="date_int"
                                  placeholder="Fecha de inicio"
                                  dateFormat="MMM d, yyyy hh:mm aa"
                                  value={this.state.data.date_int}
                                  _state={this.state.data._state}
                                  showTimeSelect={true}
                                  onChange={this.handleChangeDate}
                                />
                              </div>
                              <div className="suport-td caption">Terminación</div>
                              <div className="suport-td">
                                <DateTime
                                  className="form-control sp form-control-sm t-c"
                                  name="date_end"
                                  placeholder="Fecha de terminación"
                                  dateFormat="MMM d, yyyy hh:mm aa"
                                  value={this.state.data.date_end}
                                  _state={this.state.data._state}
                                  showTimeSelect={true}
                                  onChange={this.handleChangeDate}
                                />
                              </div>
                              <div className="suport-td caption">Tiempo</div>
                              <div className="suport-td">
                                <Time data={this.state.data} />
                              </div>
                            </Td>
                          </div>
                          <Td className="suport-col" cols="calc(50% - 4px) auto" colgap="4px">
                            <div className="suport-group">
                              <Td className="suport-head" cols="auto 85px 85px">
                                <div className="suport-td">Reportes por tipo</div>
                                <div className="suport-td">Cantidad</div>
                                <div className="suport-td">Operaciones</div>
                              </Td>
                              <div className="suport-list">{this.handlePrintIndex("KEEP_INFORM_INDEX01")}</div>
                              <Td className="suport-head" cols="auto 85px 85px">
                                <div className="suport-td">Reportes por entrada</div>
                                <div className="suport-td">Cantidad</div>
                                <div className="suport-td">Operaciones</div>
                              </Td>
                              <div className="suport-list">{this.handlePrintIndex("KEEP_INFORM_INDEX02")}</div>
                              <Td className="suport-head" cols="auto 85px">
                                <div className="suport-td">ODTs por tipo</div>
                                <div className="suport-td">Cantidad</div>
                              </Td>
                              <div className="suport-list">{this.handlePrintIndex("KEEP_INFORM_INDEX03")}</div>
                              <Td className="suport-head" cols="auto 85px">
                                <div className="suport-td">Operaciones por actividad</div>
                                <div className="suport-td">Cantidad</div>
                              </Td>
                              <div className="suport-list">{this.handlePrintIndex("KEEP_INFORM_INDEX05")}</div>
                            </div>
                            <div className="suport-group">
                              <Td className="suport-head" cols="auto 85px">
                                <div className="suport-td">Operaciones por cuadrilla</div>
                                <div className="suport-td">Cantidad</div>
                              </Td>
                              <div className="suport-list">{this.handlePrintIndex("KEEP_INFORM_INDEX07")}</div>
                              <Td className="suport-head" cols="auto 85px">
                                <div className="suport-td">Operaciones por responsable</div>
                                <div className="suport-td">Cantidad</div>
                              </Td>
                              <div className="suport-list">{this.handlePrintIndex("KEEP_INFORM_INDEX06")}</div>
                              <Td className="suport-head" cols="auto 85px">
                                <div className="suport-td">Tipos de equipos intervenidos</div>
                                <div className="suport-td">Cantidad</div>
                              </Td>
                              <div className="suport-list">{this.handlePrintIndex("KEEP_INFORM_INDEX08")}</div>
                            </div>
                          </Td>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-2`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-2-tab`}
                      >
                        <InformReports
                          project_id={this.state.data.project_id}
                          _id={this.state.data._id}
                          show={this.state.tabs[2] || false}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-3`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-3-tab`}
                      >
                        <InformOdts
                          project_id={this.state.data.project_id}
                          _id={this.state.data._id}
                          show={this.state.tabs[3] || false}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-4`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-4-tab`}
                      >
                        <InformVales
                          project_id={this.state.data.project_id}
                          _id={this.state.data._id}
                          show={this.state.tabs[4] || false}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-5`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-5-tab`}
                      >
                        <InformOperations
                          project_id={this.state.data.project_id}
                          _id={this.state.data._id}
                          isNew={false}
                          show={this.state.tabs[5] || false}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-6`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-6-tab`}
                      >
                        <InformAssets
                          project_id={this.state.data.project_id}
                          _id={this.state.data._id}
                          isNew={false}
                          show={this.state.tabs[6] || false}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-7`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-7-tab`}
                      >
                        <InformConsumptions
                          project_id={this.state.data.project_id}
                          _id={this.state.data._id}
                          show={this.state.tabs[7] || false}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-8`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-8-tab`}
                      >
                        <InformActivities
                          project_id={this.state.data.project_id}
                          _id={this.state.data._id}
                          show={this.state.tabs[8] || false}
                        />
                      </div>
                    </div>
                  </div>
                  <ModalFooterSuport
                    data={this.state.data}
                    change={this.state.change}
                    _state={this.state.data._state}
                    send={false}
                    isNew={this.state.isNew}
                    printParams={this.state.printParams}
                    handleCalc={this.handleClacIndex}
                    handleState={this.handleState}
                    handleNew={this.handleNew}
                    handleClose={this.handleClose}
                    handleOk={this.handleOk}
                  />
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    project: state.sistem.project,
    _view: state.sistem.folder._view || "",
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ModalInform);
