import React from "react";
import Td from "../../../components/td";
import ModalArea from "../modalArea";
import { Area } from "../listAreas";
import { Input } from "../../../components/inputs";

function ComponentAddress(props) {
  const id = props.id || "-1";
  const data = props.data || {};

  return (
    <React.Fragment>
      <Td className="suport-row mb-1" cols="90px auto">
        <div className="suport-td caption">
          <ModalArea
            className="link"
            project_id={data.project_id}
            _id={data.area_id}
            isFind={true}
            _state={data.equipment ? "0" : "1"}
            onSetData={props.onChangeArea}
          >
            Area
          </ModalArea>
        </div>
        <div className="suport-td component">
          <Area
            project_id={data.project_id}
            _state={data.equipment ? data._state : "1"}
            select={{
              _id: data.area_id,
              caption: data.area,
            }}
            onChange={props.onChangeArea}
          />
        </div>
      </Td>
      <Td className="suport-row mb-1" cols="90px auto">
        <div className="suport-td caption">Dirección</div>
        <div className="suport-td component">
          <Input
            type="text"
            className="form-control form-control-sm"
            autoComplete="nope"
            placeholder="Dirección"
            _state={data.equipment ? data._state : "1"}
            name="address"
            value={data.address}
            onChange={props.onChange}
          />
        </div>
      </Td>
      <Td className="suport-row" cols="90px auto">
        <div className="suport-td caption"></div>
        <div className="custom-control custom-switch">
          <input
            className="custom-control-input"
            type="checkbox"
            id={`${id}_CheckboxRural`}
            name="rural"
            checked={data.rural}
            disabled={data.equipment ? false : true}
            onChange={props.onChangeChk}
          />
          <label className="custom-control-label custom-label" htmlFor={`${id}_CheckboxRural`}>
            {data.rural ? "Es rural" : "Es urbano"}
          </label>
        </div>
      </Td>
    </React.Fragment>
  );
}

export default ComponentAddress;
