import React from "react";
import Td from "../../../components/td";
import { getSource, getValue } from "../../../components/utilities";
import ModalType from "../modalType";

function ComponentTypes(props) {
  const list = props.list || [];
  const _id = getValue(props.select, "_id", "");
  const _state = props._state || "0";
  const handleScroll = (e) => {
    const h = e.target.scrollTop + e.target.clientHeight + 1;
    if (e.target.id === props.id && h >= e.target.scrollHeight) {
      props.getData(true);
    }
  };
  const handleSelect = (e) => {
    if (typeof props.onSelect === "function") {
      props.onSelect(e);
    }
  };

  return (
    <React.Fragment>
      <div className="suport-group">
        <Td className="suport-head" cols="auto">
          <div className="suport-td m">Descripción</div>
        </Td>
        <div id={props.id} className="suport-scroll" onScroll={handleScroll} style={{ height: props.height || "280px" }}>
          {list.map((item, i) => {
            const item_id = getSource(item, ["_id"], "-1");
            const item_state = getSource(item, ["_state"], "0");
            const className = item_id === "-1"
              ? "d-none"
              : _state === 'ALL' ?
              `suport-item state-${item_state}`
              : item_state !== _state
              ? "d-none"
              : "suport-item";
            return (
              <React.Fragment key={i}>
                <Td
                  className={className}
                  selectedId={_id}
                  _id={item._id}
                  cols="auto"
                  onClick={() => handleSelect(item)}
                >
                  <ModalType
                    className="suport-td t-l link"
                    project_id={item.project_id}
                    _state={props._state}
                    data={item}
                    onSetData={props.onSetData}
                  >
                    <i className={item.project_id === item.manufacturer_id ? "fas fa-star sponsor" : "fas fa-star sponsor d-none"}></i>
                    {item.caption}
                  </ModalType>
                </Td>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
}

export default ComponentTypes;
