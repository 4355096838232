import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import Images from "../../components/images";
import ModalFooterSuport from "../../components/modalFooterSuport";
import ModalHeader from "../../components/modalHeader";
import { ALERT002 } from "../../components/msg";
import {
  ShowAlert,
  ShowInfo,
  Subscribe,
  UnSubscribe,
  formId,
  formatDate,
  formatDateTime,
  genId,
  getData,
  getDateTime,
  getSource,
  getValue,
  hideModal,
  msg,
  setFocus,
  showModal,
} from "../../components/utilities";
import { Api as Asset } from "../../services/asset";
import "../../styles/modal.scss";
import "../../styles/suport.scss";
import Operations from "../keep/components/operations";
import ComponentAddress from "./components/componentAddress";
import ComponentAsset from "./components/componentAsset";
import ComponentAtribs from "./components/componentAtribs";
import ComponentCheck from "./components/componentCheck";
import ComponentComponents from "./components/componentComponents";
import ComponentConnecteds from "./components/componentConnecteds";
import ComponentConnections from "./components/componentConnections";
import ComponentHistory from "./components/componentHistory";
import ComponentRCM from "./components/componentRCM";

class ModalAsset extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme(props.data);
    this.state = {
      form: "__ModalAsset",
      _id: "__ModalAsset",
      title: "Equipo",
      name: "asset",
      show: false,
      old: data,
      data: data,
      isNew: props.isNew || false,
      change: false,
      tabs: [],
      printParams: {
        project_id: "-1",
        _class: "ASSETS",
        _id: "-1",
        single: true,
      },
    };
  }

  handleScheme = (data) => {
    const now = new Date();
    const main_id = getValue(data, "main_id", "-1");
    return {
      project_id: this.props.project_id,
      _class: "ASSETS",
      _state: "0",
      _id: genId("-1"),
      description: "",
      date_of: now,
      class_id: getValue(data, "class_id", "-1"),
      type_id: getValue(data, "type_id", "-1"),
      reference_id: "-1",
      code: "",
      serie: "",
      rfidcode: "",
      main_id: main_id,
      address_id: "-1",
      equipment: this.props.equipment,
      install_and_running: false,
      class: getValue(data, "class", ""),
      type: getValue(data, "type", ""),
      reference: "",
      caption: "",
      country: "",
      city: "",
      area: "",
      address: "",
      address_short: "",
      address_small: "",
      address_long: "",
      rural: false,
      install: main_id === "-1" ? false : true,
      failure: false,
      running: getValue(data, "running", false),
      reported: false,
      preventive: false,
      low: false,
      date_install: null,
      date_failure: null,
      date_running: null,
      date_reported: null,
      date_low: null,
      date_factory: getDateTime(data, "date_factory", now),
      quantity: 1,
      unity: "und",
      cost: 0,
      owner_id: getValue(data, "owner_id", "-1"),
      atribs: {},
      components: [],
      connections: [],
      it: 0,
      tti: 0,
      rt: 0,
      ft: 0,
      tto: 0,
      tto_now: 0,
      ttr: 0,
      mtbf: 0,
      mttrt: 0,
      availability: 0,
      mtbf_direction: "",
      mttrt_direction: "",
      availability_direction: "",
      _data: {
        owner: getData(data, "owner", ""),
        username: "",
      },
      _v: 0,
    };
  };

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeData = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          [e.target.name]: e.target.value,
        },
      },
      change: true,
    });
  };

  handleChangeDate = (e) => {
    const value = formatDate(e.target.value, "yyyy-MM-dd HH:mm:ss");
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: value,
      },
      change: true,
    });
  };

  handleChangeQuantity = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.name]: e.value,
      },
      change: true,
    });
  };

  handleChangeCost = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.name]: e.value,
      },
      change: true,
    });
  };

  handleChangeImage = (e) => {
    const data = e.data.data;
    this.setState({
      data: {
        ...this.state.data,
        image: data.filepath,
      },
    });
  };

  handleChangeClase = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        class_id: getValue(e, "_id", "-1"),
        class: getValue(e, "caption", ""),
        equipment: getValue(e, "equipment", false),
        scheme: getData(e, "scheme_asset", []),
      },
      change: true,
    });
  };

  handleChangeType = (e) => {
    const old_type_id = this.state.data.type_id;
    const type_id = getValue(e, "_id", "-1");
    const type = getValue(e, "caption", "");
    const profile_id = getValue(e, "profile_id", "-1");
    if (old_type_id === type_id) {
      this.setState({
        data: {
          ...this.state.data,
          type_id: type_id,
          type: type,
          profile_id: profile_id,
        },
        change: true,
      });
    } else {
      const components = this.handleChangeComponents(e);
      const connections = this.handleChangeConnections(e);
      this.setState({
        data: {
          ...this.state.data,
          type_id: type_id,
          type: type,
          profile_id: profile_id,
          components: components,
          connections: connections,
        },
        change: true,
      });
    }
  };

  handleChangeReference = (e) => {
    console.log("handleChangeReference", {e});
    this.setState({
      data: {
        ...this.state.data,
        reference_id: getValue(e, "_id", "-1"),
        reference: getValue(e, "caption", ""),
      },
      change: true,
    });
  };

  handleChangeOwner = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        owner_id: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          owner: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeArea = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        area_id: getValue(e, "_id", "-1"),
        area: getValue(e, "caption", ""),
      },
      change: true,
    });
  };

  handleChangeChk = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: Boolean(e.target.checked),
      },
      change: true,
    });
  };

  handleChangeConnections = (e) => {
    let connections = [];
    e.connections.forEach((element) => {
      connections = this.handleConnections(element);
    });
    return connections;
  };

  handleChangeConnection = (e) => {
    const connections = this.handleConnections(e);
    const data = this.state.data;
    data.connections = connections;
    this.setState({ data: data, change: true });
  };

  handleChangeComponents = (e) => {
    let components = [];
    e.components.forEach((element) => {
      components = this.handleComponents(element);
    });
    return components;
  };

  handleChangeComponent = (e) => {
    const components = this.handleComponents(e);
    const data = this.state.data;
    data.components = components;
    this.setState({ data: data });
  };

  handleChangeAtrib = (e) => {
    if (e.action === "set") {
      const atrib = e.data;
      const data = this.state.data;
      let atribs = data.atribs;
      atribs[atrib.name] = atrib.value;
      data.atribs = atribs;
      this.setState({ data: data, change: true });
    }
  };

  handleComponents = (e) => {
    const data = this.state.data;
    const components = data.components;
    const index = components.findIndex((element) => element.class_id === e.class_id);
    if (index === -1) {
      const component = {
        alerts: false,
        atribs: {},
        class: e.class,
        class_id: e.class_id,
        code: "",
        cost: 0,
        date_update: new Date(),
        equipment: e.equipment,
        failure: false,
        install: false,
        installation: e.installation,
        main_id: data._id,
        maximum: e.quantity,
        preventive: false,
        quantity: 0,
        reference: e.reference,
        reference_code: e.reference_code,
        reference_id: e.reference_id,
        reported: false,
        rfidcode: "",
        running: false,
        serie: "",
        type: e.type,
        type_id: e.type_id,
        unity: e.unity,
        _id: "-1",
        _order: e._order,
      };
      components.push(component);
    } else {
      const component = components[index];
      component._id = e._id;
      component.code = e.code;
      component.quantity = e.quantity;
      component.unity = e.unity;
      component.class = e.class;
      component.type_id = e.type_id;
      component.type = e.type;
      component.install = e.install;
      component.failure = e.failure;
      component.running = e.running;
      component.reported = e.reported;
      component.preventive = e.preventive;
      components[index] = component;
    }
    return components;
  };

  handleConnections = (e) => {
    const data = this.state.data;
    const connections = data.connections;
    const index = connections.findIndex((element) => element.class_id === e.class_id);
    if (index === -1) {
      const connection = {
        alerts: false,
        asset_id: data._id,
        class: e.class,
        class_id: e.class_id,
        code: "",
        connection_id: "-1",
        date_update: new Date(),
        equipment: e.equipment,
        failure: false,
        install: false,
        maximum: e.quantity,
        preventive: false,
        quantity: 0,
        reported: false,
        rfidcode: "",
        running: false,
        serie: "",
        type: "",
        type_id: "-1",
        _id: "-1",
        _order: e._order,
      };
      connections.push(connection);
    } else {
      connections[index] = e;
    }
    return connections;
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false, tabs: [] });
  };

  handleShow = () => {
    const _state = this.props._state || "0";
    if (_state === "main_not_exist") {
      ShowAlert("Debe guardar primero el equipo que estás creando");
    } else {
      if (this.props.isFind) {
        const _id = getValue(this.props, "_id", "-1");
        if (_id !== "-1") {
          const form_id = formId(`${this.state.form}${_id}`);
          this.setState({ _id: form_id, show: true, change: false });
        }
      } else if (this.props.isNew) {
        const form_id = `${this.state.form}${Date.now()}`;
        this.setState({ _id: form_id, show: true, change: false });
      } else {
        const _id = getValue(this.props.data, "_id", "-1");
        if (_id !== "-1") {
          const form_id = formId(`${this.state.form}${_id}`);
          this.setState({ _id: form_id, show: true, change: false });
        }
      }
    }
  };

  handleClose = (e) => {
    if (this.state.change && e) {
      this.handleOk();
    } else {
      this.handleHide();
    }
  };

  handleOk = () => {
    if (this.state.change) {
      const params = this.state.data;
      const id = params._id;
      const now = new Date();
      params.date_of = formatDateTime(now, "yyyy-MM-dd HH:mm:ss");
      params.document_id = getValue(this.props, "document_id", "-1");
      params.document = getValue(this.props, "document", "");
      Asset.setAsset(id, params).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false, focusInt: false });
          this.handleExecute(data);
        } else if (result.msg === 452) {
          setFocus(`${this.state._id}_caption`);
        }
      });
    } else {
      this.handleHide();
    }
  };

  handleState = (e) => {
    if (this.state.change) {
      ShowAlert(ALERT002);
    } else {
      const _state = getValue(this.state.data, "_state");
      if (_state !== e) {
        const id = getValue(this.state.data, "_id");
        Asset.stateAsset(id, e).then((result) => {
          if (result.msg === "") {
            const data = result.data;
            this.handleSetData({ data, isNew: false, change: false, focusInt: false });
            this.handleExecute(data);
          }
        });
      }
    }
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      isNew: e.isNew,
      change: e.change,
      tabs: [],
      printParams: {
        project_id: e.data.project_id,
        _class: e.data.class_id,
        _id: e.data._id,
        single: true,
      },
    });
    showModal(this.state._id);
    setFocus(`${this.state._id}_caption`, e.focusInt);
  };

  handleUpdate = (e) => {
    if (!this.state.change) {
      this.handleSetData({ data: e, isNew: false, change: false, focusInt: false });
    }
  };

  handleData = () => {
    const _id = getSource(this.props.data, ["_id"], "-1");
    if (_id === "-1") {
      this.handleSetData({ data: this.props.data, isNew: false, change: false, focusInt: true });
    } else {
      this.handleFind(_id);
    }
  };

  handleNew = () => {
    const data = this.handleScheme(this.props.data);
    this.handleSetData({ data, isNew: true, change: true, focusInt: true });
  };

  handleFind = (id) => {
    if (this.state.change) {
      ShowInfo(msg("MSG-CHANGE"));
    } else if (id !== "-1") {
      Asset.asset(id, this.props.project_id).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false, focusInt: true });
        } else {
          this.setState({ show: false, change: false });
        }
      });
    }
  };

  handleUndo = (e) => {
    if (e.result) {
      this.handleFind(e.item.asset_id);
    }
  };

  handleTab = (e) => {
    const tabs = this.state.tabs;
    if (!tabs[e]) {
      tabs[e] = true;
      this.setState({ tabs: tabs });
    }
  };

  componentDidMount() {
    Subscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.data._id !== this.state.data._id) {
        UnSubscribe(`${prevState.data._class}/${prevState.data._id}`, (event) => this.eventSetData(event));
        Subscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
      }
      if (prevState.show !== this.state.show) {
        if (this.props.isNew) {
          this.handleNew();
        } else if (this.props.isFind) {
          this.handleFind(this.props._id);
        } else {
          this.handleData();
        }
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} disabled={!(this.props._state === "0")} onClick={this.handleShow}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} disabled={!(this.props._state === "0")} onClick={this.handleShow}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog __modalObject sm" role="document">
                <div className="modal-content">
                  <ModalHeader
                    title={this.state.data.caption === "" ? this.state.data.class : this.state.data.caption}
                    change={this.state.change}
                    handleClose={this.handleClose}
                  />
                  <div className="modal-body-tabs">
                    <nav>
                      <div className="nav nav-tabs" role="tablist">
                        <a
                          className="nav-item nav-link active"
                          id={`${this.state._id}-nav-1-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-1`}
                          role="tab"
                          aria-selected="true"
                        >
                          General
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-2-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-2`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(2)}
                        >
                          Detalle
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-3-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-3`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(3)}
                        >
                          Conectados
                        </a>
                        <a
                          className={this.state.data.equipment ? "nav-item nav-link" : "nav-item nav-link d-none"}
                          id={`${this.state._id}-nav-4-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-4`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(4)}
                        >
                          Operaciones
                        </a>
                      </div>
                    </nav>
                    <div className="tab-content modal">
                      <div
                        className="tab-pane fade show active"
                        id={`${this.state._id}-nav-1`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-1-tab`}
                      >
                        <div className="row">
                          <div className="col-6 pr-0">
                            <div className="suport-box box-1-1">
                              <ComponentAsset
                                project_id={this.state.data.project_id}
                                _state={this.state.data._state}
                                id={this.state._id}
                                data={this.state.data}
                                onChange={this.handleChange}
                                onChangeDate={this.handleChangeDate}
                                onChangeQuantity={this.handleChangeQuantity}
                                onChangeCost={this.handleChangeCost}
                                onChangeImage={this.handleChangeImage}
                                onChangeClase={this.handleChangeClase}
                                onChangeType={this.handleChangeType}
                                onChangeReference={this.handleChangeReference}
                                onChangeOwner={this.handleChangeOwner}
                              />
                              <ComponentAddress
                                project_id={this.state.data.project_id}
                                _state={this.state.data._state}
                                id={this.state._id}
                                data={this.state.data}
                                onChange={this.handleChange}
                                onChangeArea={this.handleChangeArea}
                                onChangeChk={this.handleChangeChk}
                              />
                              <ComponentCheck
                                project_id={this.state.data.project_id}
                                _state={this.state.data._state}
                                id={this.state._id}
                                data={this.state.data}
                                onChangeChk={this.handleChangeChk}
                              />
                              <hr className="mt-2 mb-2" />
                              <ComponentRCM
                                project_id={this.state.data.project_id}
                                _state={this.state.data._state}
                                id={this.state._id}
                                data={this.state.data}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <ComponentComponents
                              project_id={this.state.data.project_id}
                              profile_id={this.state.data.profile_id}
                              _state={this.state.data._state}
                              isNew={this.state.isNew}
                              data={this.state.data}
                              onChange={this.handleChangeComponent}
                              onChangeProfile={this.handleChangeClase}
                              height="438px"
                            />
                            <ComponentConnections
                              project_id={this.state.data.project_id}
                              profile_id={this.state.data.profile_id}
                              _state={this.state.data._state}
                              isNew={this.state.isNew}
                              data={this.state.data}
                              onChange={this.handleChangeConnection}
                              onChangeProfile={this.handleChangeClase}
                              height="124px"
                            />
                            <ComponentAtribs
                              project_id={this.state.data.project_id}
                              class_id={this.state.data.class_id}
                              _state={this.state.data._state}
                              scheme={this.state.data.scheme}
                              atribs={this.state.data.atribs}
                              onSetData={this.handleChangeAtrib}
                              onChangeClase={this.handleChangeClase}
                              height="302px"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-2`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-2-tab`}
                      >
                        <div className="row">
                          <div className="col-6">
                            <Images
                              project_id={this.state.data.project_id}
                              _state={this.state.data._state}
                              _class={this.state.data._class}
                              _id={this.state.data._id}
                              show={this.state.tabs[2] || false}
                            />
                          </div>
                          <div className="col-6">
                            <div className="row">
                              <div className="col-12">
                                <ComponentHistory
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  _class={this.state.data._class}
                                  _id={this.state.data._id}
                                  change={this.state.change}
                                  show={this.state.tabs[2] || false}
                                  onSetData={this.handleUndo}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-3`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-3-tab`}
                      >
                        <div className="row">
                          <div className="col-12">
                            <ComponentConnecteds
                              project_id={this.state.data.project_id}
                              _state={this.state.data._state}
                              _class={this.state.data._class}
                              _id={this.state.data._id}
                              show={this.state.tabs[3] || false}
                              height="448px"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-4`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-4-tab`}
                      >
                        <Operations
                          project_id={this.state.data.project_id}
                          _state={this.state.data._state}
                          _class={this.state.data._class}
                          _id={this.state.data._id}
                          show={this.state.tabs[4] || false}
                        />
                      </div>
                    </div>
                  </div>
                  <ModalFooterSuport
                    data={this.state.data}
                    change={this.state.change}
                    _state={this.state.data._state}
                    send={false}
                    isNew={this.state.isNew}
                    printParams={this.state.printParams}
                    handleState={this.handleState}
                    handleNew={this.handleNew}
                    handleClose={this.handleClose}
                    handleOk={this.handleOk}
                  />
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    user_id: state.sistem.profile._id || "-1",
    _view: state.sistem.folder._view || "",
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ModalAsset);
