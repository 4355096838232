import React from "react";
import ReactDOM from "react-dom";
import "../../styles/modal.scss";
import "../../styles/suport.scss";
import {
  showModal,
  hideModal,
  ShowInfo,
  msg,
  getValue,
  getData,
  getRowData,
  genId,
  getRow,
  setFocus,
  ShowAlert,
  formId,
  formatDate,
  Subscribe,
  UnSubscribe,
  getSource,
  compareObject,
} from "../../components/utilities";
import {
  Textarea,
  Select,
  Input,
  Typehead,
  City,
  SelectType,
  Phone,
  Type,
  Section,
  Time,
} from "../../components/inputs";
import { ApiTypehead } from "../../services/typehead";
import { Area } from "../assets/listAreas";
import { ListAssets } from "../assets/listAssets";
import { Odts } from "./checkListOdts";
import { ALERT002 } from "../../components/msg";
import ModalAsset from "../assets/modalAsset";
import ModalHeaderSuport from "../../components/modalHeaderSuport";
import ModalFooterSuport from "../../components/modalFooterSuport";
import Td from "../../components/td";
import DateTime from "../../components/dateTime";
import Operations from "./components/operations";
import Consumptions from "./components/consumptions";
import Activities from "./components/activities";
import { Api as Keep } from "../../services/keep";
import { connect } from "react-redux";

class ModalReport extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme(props.data);
    this.state = {
      form: "__ModalReport",
      _id: "__ModalReport",
      title: "Reporte",
      name: "report",
      show: false,
      old: data,
      data: data,
      isNew: props.isNew || false,
      change: false,
      tabs: [],
      printParams: {
        project_id: "-1",
        _class: "SUPORT-KEEP-REPORT",
        _id: "-1",
        single: true,
      },
      document_tp: [
        { _id: "-1", caption: "Documento" },
        { _id: "IDENTIFICATION_TP001", caption: "Cedula" },
        { _id: "IDENTIFICATION_TP002", caption: "Rut" },
        { _id: "IDENTIFICATION_TP003", caption: "Nit" },
        { _id: "IDENTIFICATION_TP004", caption: "Pasaporte" },
      ],
    };
  }

  handleScheme = (data) => {
    const now = new Date();
    return {
      date_of: formatDate(now, "yyyy-MM-dd HH:mm:ss"),
      project_id: this.props.project_id,
      _class: "SUPORT-KEEP-REPORT",
      _state: "0",
      _id: genId("-1"),
      code: "",
      caption: "Reporte",
      description: "",
      observations: "",
      type_tp: getValue(data, "type_tp", "KEEP_REPORT_TIPO02"),
      entry_tp: getValue(data, "entry_tp", "KEEP_REPORT_ENTRADA06"),
      priority_tp: getValue(data, "priority_tp", "SUPORT_PRIORITY02"),
      city_id: getValue(this.props.project, "city_id", "-1"),
      area_id: getValue(data, "area_id", "-1"),
      rural: false,
      date_int: formatDate(now, "yyyy-MM-dd HH:mm:ss"),
      date_end: null,
      assets: [],
      odts: [],
      chat_id: "-1",
      _data: {
        project: this.props.project,
        class_name: "",
        asunto: "",
        contact: "",
        document_tp: "-1",
        document: "",
        document_type: "",
        telephone: "",
        mobile: "",
        email: "",
        sitio: "",
        area: getData(data, "area", ""),
        city: getValue(this.props.project, "city", ""),
        type: getData(data, "type", "Queja o reclamo"),
        entry: getData(data, "entry", "Telefónico"),
        priority: getData(data, "priority", "Normal"),
        username: "",
      },
      next_id: "-1",
      prior_id: "-1",
      _v: 1,
    };
  };

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeData = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          [e.target.name]: e.target.value,
        },
      },
      change: true,
    });
  };

  handleChangeDate = (e) => {
    const value = formatDate(e.target.value, "yyyy-MM-dd HH:mm:ss");
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: value,
      },
      change: true,
    });
  };

  handleChangeContact = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          contact: getValue(e, "caption", ""),
          document_tp: getData(e, "document_tp", "-1"),
          document: getData(e, "document", ""),
          document_type: getData(e, "document_type", ""),
          telephone: getData(e, "telephone", ""),
          mobile: getData(e, "mobile", ""),
          email: getData(e, "email", ""),
        },
      },
      change: true,
    });
  };

  handleChangeDocumentTp = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          [e.target.name]: e.target.value,
          document_type: e.target.caption,
        },
      },
      change: true,
    });
  };

  handleChangeSitio = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        area_id: getData(e, "area_id", "-1"),
        city_id: getData(e, "city_id", "-1"),
        _data: {
          ...this.state.data._data,
          sitio: getValue(e, "caption", ""),
          area: getData(e, "area", ""),
          city: getData(e, "city", ""),
        },
      },
      change: true,
    });
  };

  handleChangeArea = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        area_id: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          area: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeCity = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        city_id: getValue(e, "city_id", "-1"),
        _data: {
          ...this.state.data._data,
          city: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeRural = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        rural: e.target.value === "true" ? true : false,
      },
      change: true,
    });
  };

  handleChangeType = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        type_tp: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          type: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeEntry = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        entry_tp: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          entry: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangePriority = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
        _data: {
          ...this.state.data._data,
          priority: e.target.caption,
        },
      },
      change: true,
    });
  };

  handleChangeAsset = (e) => {
    let assets = this.state.data.assets;
    let element = e.element;
    let index = e.i;
    let asset = assets[index];
    if (Object.keys(element).length === 0) {
      if (asset !== undefined) {
        asset.asset_id = "-1";
        asset._data.class_id = "-1";
        asset._data.class = "";
        asset._data.type_id = "-1";
        asset._data.type = "";
        asset._data.code = "";
        asset._data.sitio = "";
        assets[index] = asset;
      }
    } else {
      if (asset === undefined) {
        assets.push({
          date_of: this.state.data.date_of,
          _id: genId("-1"),
          report_id: this.state.data._id,
          asset_id: getValue(element, "_id", "-1"),
          failure: getValue(element, "failure", false),
          _data: {
            code: getValue(element, "code", ""),
            class_id: getValue(element, "class_id", "-1"),
            class: getValue(element, "class", ""),
            type_id: getValue(element, "type_id", "-1"),
            type: getValue(element, "type", ""),
            sitio: getValue(element, "address_short", ""),
          },
        });
      } else {
        asset = {
          date_of: this.state.data.date_of,
          _id: asset._id,
          report_id: this.state.data._id,
          asset_id: getValue(element, "_id", "-1"),
          failure: getValue(element, "failure", false),
          _data: {
            code: getValue(element, "code", ""),
            class_id: getValue(element, "class_id", "-1"),
            class: getValue(element, "class", ""),
            type_id: getValue(element, "type_id", "-1"),
            type: getValue(element, "type", ""),
            sitio: getValue(element, "address_short", ""),
          },
          user_id: asset.user_id,
        };
        assets[index] = asset;
      }
    }
    this.setState({
      data: {
        ...this.state.data,
        assets: assets,
      },
      change: true,
    });
  };

  handleChangeODTs = (e) => {
    e = e || [];
    let odts = this.state.data.odts || [];
    odts.forEach((element, i) => {
      const index = e.findIndex((item) => item.odt_id === element.odt_id);
      if (index === -1) {
        odts[i].odt_id = "-1";
        odts[i]._data.caption = "";
      }
    });
    e.forEach((element, i) => {
      const index = odts.findIndex((item) => item.odt_id === element.odt_id);
      if (index === -1) {
        odts.push({
          date_of: new Date(),
          _id: genId("-1"),
          odt_id: element.odt_id,
          report_id: this.state.data._id,
          _data: {
            date_of: this.state.data.date_of,
            odt: element.code,
            code: this.state.data.code,
            description: this.state.data.description,
          },
        });
      }
    });
    this.setState({
      data: {
        ...this.state.data,
        odts: odts,
      },
      change: true,
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    if (this.props.isFind) {
      const _id = getValue(this.props, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    } else if (this.props.isNew) {
      const form_id = `${this.state.form}${Date.now()}`;
      this.setState({ _id: form_id, show: true, change: false });
    } else {
      const _id = getValue(this.props.data, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    }
  };

  handleClose = (e) => {
    if (this.state.change && e) {
      this.handleOk();
    } else {
      this.handleHide();
    }
  };

  handleOk = () => {
    if (this.state.change) {
      const id = this.state.data._id;
      const params = this.state.data;
      Keep.setReport(id, params).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false });
          this.handleExecute(data);
        }
      });
    } else {
      this.handleHide();
    }
  };

  handleState = (e) => {
    if (this.state.change) {
      ShowAlert(ALERT002);
    } else {
      const _state = getValue(this.state.data, "_state");
      if (_state !== e) {
        const id = getValue(this.state.data, "_id");
        Keep.stateReport(id, e).then((result) => {
          if (result.msg === "") {
            const data = result.data;
            this.handleSetData({ data, change: false });
            this.handleExecute(data);
          }
        });
      }
    }
  };

  handlePrior = (e) => {
    const _id = getValue(this.state.data, "prior_id", "-1");
    if (_id === "-1") {
      ShowInfo(msg("MSG-FIRST-ROW"));
    } else {
      this.handleFind(_id);
    }
  };

  handleNext = (e) => {
    const _id = getValue(this.state.data, "next_id", "-1");
    if (_id === "-1") {
      ShowInfo(msg("MSG-END-ROW"));
    } else {
      this.handleFind(_id);
    }
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      isNew: e.isNew,
      change: e.change,
      tabs: [],
      printParams: {
        project_id: e.data.project_id,
        _class: e.data._class,
        _id: e.data._id,
        single: true,
      },
    });
    showModal(this.state._id);
    setFocus(`${this.state._id}_caption`, e.focusInt);
  };

  handleUpdate = (e) => {
    if (compareObject(this.state.data, e)) {
      this.handleSetData({
        data: e,
        isNew: false,
        change: false,
      });
    }
  };

  handleData = () => {
    const _id = getSource(this.props.data, ["_id"], "-1");
    if (_id === "-1") {
      this.handleSetData({ data: this.props.data, isNew: false, change: false, focusInt: true });
    } else {
      this.handleFind(_id);
    }
  };

  handleNew = () => {
    const data = this.handleScheme(this.props.data);
    this.handleSetData({ data, isNew: true, change: true, focusInt: true });
  };

  handleFind = (id) => {
    if (this.state.change) {
      ShowInfo(msg("MSG-CHANGE"));
    } else if (id !== "-1") {
      Keep.report(id, this.props.project_id).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false, focusInt: true });
        }
      });
    }
  };

  handlePrintAssets = (e) => {
    const items = this.state.data.assets;
    let result = [];

    for (let i = 0; i < e; i++) {
      const item = getRow(items, i);
      result.push(
        <Td key={i} className="suport-item" cols="25px 145px 75px auto 250px">
          <div className="suport-td t-c">{i + 1}</div>
          <div className="suport-td t-l">{getRowData(items, i, "class", "")}</div>
          <div className="suport-td t-c">
            <ModalAsset
              className="link"
              project_id={this.state.data.project_id}
              equipment={true}
              isFind={true}
              _id={getValue(item, "asset_id", "-1")}
            >
              {getRowData(items, i, "code", "")}
            </ModalAsset>
          </div>
          <div className="suport-td t-l">{getRowData(items, i, "type", "")}</div>
          <div className="suport-td bt">
            {getRowData(items, i, "sitio", "")}
            <div className="input-group-append">
              <ListAssets
                className="input-group-text sp"
                project_id={this.state.data.project_id}
                _state={this.state.data._state}
                class_id={getData(item, "class_id", "-1")}
                select={{
                  _id: getValue(item, "asset_id", "-1"),
                  class_id: getData(item, "class_id", "-1"),
                  code: getData(item, "code", "-1"),
                }}
                onSetData={(element) => this.handleChangeAsset({ element, i })}
              >
                <i className="fa fa-ellipsis-h"></i>
              </ListAssets>
            </div>
          </div>
        </Td>
      );
    }
    return result;
  };

  sourceContacts = (query) => {
    return ApiTypehead.keeps(this.state.data.project_id, "CLIENTS", query).then((result) => {
      return result;
    });
  };

  sourceReportSitios = (query) => {
    return ApiTypehead.keeps(this.state.data.project_id, "REPORT_SITIOS", query).then((result) => {
      return result;
    });
  };

  sourceAssets = (query) => {
    return ApiTypehead.assets(this.state.data.project_id, "51", query).then((result) => {
      return result;
    });
  };

  handleTab = (e) => {
    const tabs = this.state.tabs;
    if (!tabs[e]) {
      tabs[e] = true;
      this.setState({ tabs: tabs });
    }
  };

  componentDidMount() {
    Subscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.data._id !== this.state.data._id) {
        UnSubscribe(`${prevState.data._class}/${prevState.data._id}`, (event) => this.eventSetData(event));
        Subscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
      }
      if (prevState.show !== this.state.show) {
        if (this.props.isNew) {
          this.handleNew();
        } else if (this.props.isFind) {
          this.handleFind(this.props._id);
        } else {
          this.handleData();
        }
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog __modalObject sm" role="document">
                <div className="modal-content">
                  <ModalHeaderSuport
                    title={this.state.data.caption}
                    _state={this.state.data._state}
                    _id={this.state.data._id}
                    isNew={this.state.isNew}
                    alerts={this.state.data.alerts}
                    change={this.state.change}
                    handleClose={this.handleClose}
                    handlePrior={this.handlePrior}
                    handleNext={this.handleNext}
                  />
                  <div className="modal-body-tabs">
                    <nav>
                      <div className="nav nav-tabs" role="tablist">
                        <a
                          className="nav-item nav-link active"
                          id={`${this.state._id}-nav-1-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-1`}
                          role="tab"
                          aria-selected="true"
                        >
                          General
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-2-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-2`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(2)}
                        >
                          Operaciones
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-3-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-3`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(3)}
                        >
                          Consumos de material
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-4-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-4`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(4)}
                        >
                          Actividades
                        </a>
                      </div>
                    </nav>
                    <div className="tab-content modal">
                      <div
                        className="tab-pane fade show active"
                        id={`${this.state._id}-nav-1`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-1-tab`}
                      >
                        <div className="suport">
                          <div className="suport-group">
                            <Td className="suport-row" cols="90px auto 90px 160px">
                              <div className="suport-td caption">Asunto</div>
                              <div className="suport-td">
                                <Section
                                  id={`${this.state._id}_caption`}
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="asunto"
                                  value={this.state.data._data.asunto}
                                  onChange={this.handleChangeData}
                                />
                              </div>
                              <div className="suport-td caption">Fecha</div>
                              <div className="suport-td">
                                <DateTime
                                  className="form-control sp form-control-sm t-c"
                                  popperClassName="datepicker-right"
                                  name="date_of"
                                  _state={this.state.data._state}
                                  value={this.state.data.date_of}
                                  onChange={this.handleChangeDate}
                                  placeholder="Fecha de creación"
                                  dateFormat="MMM d, yyyy"
                                />
                              </div>
                            </Td>
                            <Td className="suport-row" cols="90px auto 90px 160px">
                              <div className="suport-td caption">Contacto</div>
                              <div className="suport-td dropdown">
                                <Typehead
                                  type="texty"
                                  className="form-control sp ctr-sm"
                                  placeholder="Nombre completo"
                                  name="contact"
                                  _id="index"
                                  caption="caption"
                                  _state={this.state.data._state}
                                  value={this.state.data._data.contact}
                                  onChange={this.handleChangeData}
                                  source={this.sourceContacts}
                                  onSelect={this.handleChangeContact}
                                />
                              </div>
                              <div className="suport-td">
                                <Select
                                  className="form-control sp select blod"
                                  _state={this.state.data._state}
                                  name="document_tp"
                                  value={this.state.data._data.document_tp}
                                  onChange={this.handleChangeDocumentTp}
                                  list={this.state.document_tp}
                                />
                              </div>
                              <div className="suport-td">
                                <Input
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="document"
                                  value={this.state.data._data.document}
                                  onChange={this.handleChangeData}
                                />
                              </div>
                            </Td>
                            <Td className="suport-row" cols="90px auto 90px 160px 90px 160px">
                              <div className="suport-td caption">Teléfono</div>
                              <div className="suport-td">
                                <Phone
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="telephone"
                                  value={this.state.data._data.telephone}
                                  onChange={this.handleChangeData}
                                />
                              </div>
                              <div className="suport-td caption">Celular</div>
                              <div className="suport-td">
                                <Phone
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="mobile"
                                  value={this.state.data._data.mobile}
                                  onChange={this.handleChangeData}
                                />
                              </div>
                              <div className="suport-td caption">Correo</div>
                              <div className="suport-td">
                                <Input
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="email"
                                  value={this.state.data._data.email}
                                  onChange={this.handleChangeData}
                                />
                              </div>
                            </Td>
                          </div>
                          <div className="suport-group">
                            <Td className="suport-row" cols="90px auto 90px 160px">
                              <div className="suport-td caption">Sitio</div>
                              <div className="suport-td dropdown">
                                <Typehead
                                  type="texty"
                                  className="form-control sp ctr-sm"
                                  placeholder="Sitio"
                                  name="sitio"
                                  _id="index"
                                  caption="caption"
                                  _state={this.state.data._state}
                                  value={this.state.data._data.sitio}
                                  onChange={this.handleChangeData}
                                  source={this.sourceReportSitios}
                                  onSelect={this.handleChangeSitio}
                                />
                              </div>
                              <div className="suport-td caption">Área</div>
                              <div className="suport-td">
                                <Area
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  select={{
                                    _id: this.state.data.area_id,
                                    caption: this.state.data._data.area,
                                  }}
                                  onChange={this.handleChangeArea}
                                />
                              </div>
                            </Td>
                            <Td className="suport-row" cols="90px auto 250px">
                              <div className="suport-td caption">Ciudad</div>
                              <div className="suport-td">
                                <City
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  _state={this.state.data._state}
                                  value={this.state.data._data.city}
                                  city_id={this.state.data.city_id}
                                  onChange={this.handleChangeCity}
                                />
                              </div>
                              <div className="suport-td t-c">
                                <div className="custom-control custom-radio custom-control-inline">
                                  <input
                                    type="radio"
                                    className="custom-control-input"
                                    id="report_urbano"
                                    name="rural"
                                    value={false}
                                    checked={!this.state.data.rural}
                                    onChange={this.handleChangeRural}
                                    disabled={this.state.data._state !== "0"}
                                  />
                                  <label className="custom-control-label" htmlFor="report_urbano">
                                    Urbano
                                  </label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                  <input
                                    type="radio"
                                    className="custom-control-input"
                                    id="report_rural"
                                    name="rural"
                                    value={true}
                                    checked={this.state.data.rural}
                                    onChange={this.handleChangeRural}
                                    disabled={this.state.data._state !== "0"}
                                  />
                                  <label className="custom-control-label" htmlFor="report_rural">
                                    Rural
                                  </label>
                                </div>
                              </div>
                            </Td>
                          </div>
                          <div className="suport-group">
                            <Td className="suport-row" cols="auto 250px">
                              <div className="suport-td">
                                <Textarea
                                  className="form-control sp notresize"
                                  _state={this.state.data._state}
                                  rows={3}
                                  name="observations"
                                  value={this.state.data.observations}
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div className="suport-td c">
                                <Td className="suport-row" cols="auto 160px">
                                  <div className="suport-td caption">Tipo</div>
                                  <div className="suport-td">
                                    <Type
                                      className="form-control sp ctr-sm t-m"
                                      classNameBtn="input-group-text sp"
                                      placeholder="Tipo"
                                      project_id={this.state.data.project_id}
                                      _class="KEEP_REPORT_TIPO"
                                      _state={this.state.data._state}
                                      title="Tipo de reporte"
                                      select={{
                                        _id: this.state.data.type_tp,
                                        caption: this.state.data._data.type,
                                      }}
                                      onChange={this.handleChangeType}
                                    />
                                  </div>
                                </Td>
                                <Td className="suport-row" cols="auto 160px">
                                  <div className="suport-td caption">Entrada</div>
                                  <div className="suport-td">
                                    <Type
                                      className="form-control sp ctr-sm t-m"
                                      classNameBtn="input-group-text sp"
                                      placeholder="Entreda"
                                      project_id={this.state.data.project_id}
                                      _class="KEEP_REPORT_ENTRADA"
                                      _state={this.state.data._state}
                                      title="Entrada"
                                      select={{
                                        _id: this.state.data.entry_tp,
                                        caption: this.state.data._data.entry,
                                      }}
                                      name="_class"
                                      onChange={this.handleChangeEntry}
                                    />
                                  </div>
                                </Td>
                                <Td className="suport-row" cols="auto 160px">
                                  <div className="suport-td caption">Prioridad</div>
                                  <div className="suport-td">
                                    <SelectType
                                      className="form-control sp"
                                      project_id={this.state.data.project_id}
                                      _class="SUPORT_PRIORITY"
                                      _state={this.state.data._state}
                                      name="priority_tp"
                                      value={this.state.data.priority_tp}
                                      onChange={this.handleChangePriority}
                                    />
                                  </div>
                                </Td>
                              </div>
                            </Td>
                            <Td className="suport-row" cols="90px auto">
                              <div className="suport-td caption">ODTs</div>
                              <div className="suport-td">
                                <Odts
                                  className="form-control sp ctr-sm t-m h26"
                                  classNameBtn="input-group-text sp"
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  odts={this.state.data.odts}
                                  onChange={this.handleChangeODTs}
                                />
                              </div>
                            </Td>
                          </div>
                          <div className="suport-group">
                            <Td className="suport-row" cols="90px auto 90px 160px 90px 160px">
                              <div className="suport-td caption">Fecha Inicio</div>
                              <div className="suport-td">
                                <DateTime
                                  className="form-control sp form-control-sm t-c"
                                  name="date_int"
                                  placeholder="Fecha de inicio"
                                  dateFormat="MMM d, yyyy hh:mm aa"
                                  value={this.state.data.date_int}
                                  _state={this.state.data._state}
                                  showTimeSelect={true}
                                  onChange={this.handleChangeDate}
                                />
                              </div>
                              <div className="suport-td caption">Terminación</div>
                              <div className="suport-td">
                                <DateTime
                                  className="form-control sp form-control-sm t-c"
                                  name="date_end"
                                  placeholder="Fecha de terminación"
                                  dateFormat="MMM d, yyyy hh:mm aa"
                                  value={this.state.data.date_end}
                                  _state={this.state.data._state}
                                  showTimeSelect={true}
                                  onChange={this.handleChangeDate}
                                />
                              </div>
                              <div className="suport-td caption">Tiempo</div>
                              <div className="suport-td">
                                <Time data={this.state.data} />
                              </div>
                            </Td>
                          </div>
                          <div className="suport-group">
                            <Td className="suport-head" cols="auto">
                              <div className="suport-td">Equipos para mantenimiento</div>
                            </Td>
                            <Td className="suport-head" cols="170px 75px auto 250px">
                              <div className="suport-td">Clase</div>
                              <div className="suport-td">Código</div>
                              <div className="suport-td">Tipo</div>
                              <div className="suport-td">Sitio</div>
                            </Td>
                            {this.handlePrintAssets(30)}
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-2`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-2-tab`}
                      >
                        <Operations
                          project_id={this.state.data.project_id}
                          _state={this.state.data._state}
                          _class={this.state.data._class}
                          _id={this.state.data._id}
                          master={{
                            report_id: this.state.data._id,
                            report: this.state.data.code,
                            odt_id: getSource(this.state.data, ["first_odt", "_id"], "-1"),
                            odt: getSource(this.state.data, ["first_odt", "code"], ""),
                            responsable_id: getSource(this.state.data, ["first_odt", "responsable_id"], "-1"),
                            responsable: getSource(this.state.data, ["first_odt", "_data", "responsable"], ""),
                            responsable_mobile: getSource(
                              this.state.data,
                              ["first_odt", "_data", "responsable_mobile"],
                              ""
                            ),
                            responsable_email: getSource(
                              this.state.data,
                              ["first_odt", "_data", "responsable_email"],
                              ""
                            ),
                          }}
                          show={this.state.tabs[2] || false}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-3`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-3-tab`}
                      >
                        <Consumptions
                          project_id={this.state.data.project_id}
                          _state={this.state.data._state}
                          _class={this.state.data._class}
                          _id={this.state.data._id}
                          show={this.state.tabs[3] || false}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-4`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-4-tab`}
                      >
                        <Activities
                          project_id={this.state.data.project_id}
                          _state={this.state.data._state}
                          _class={this.state.data._class}
                          _id={this.state.data._id}
                          show={this.state.tabs[4] || false}
                        />
                      </div>
                    </div>
                  </div>
                  <ModalFooterSuport
                    data={this.state.data}
                    change={this.state.change}
                    _state={this.state.data._state}
                    send={false}
                    isNew={this.state.isNew}
                    printParams={this.state.printParams}
                    handleState={this.handleState}
                    handleNew={this.handleNew}
                    handleClose={this.handleClose}
                    handleOk={this.handleOk}
                  />
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    project: state.sistem.project,
    _view: state.sistem.folder._view || "",
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ModalReport);
