import React from "react";
import { connect } from "react-redux";
import { getSource } from "../../../components/utilities";
import { Button, Section, Count } from "../../../components/inputs";
import { ApiUsa as Api } from "../../../services/usaOrder";

class ComponentOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 3,
      list: [],
      item: this.handleScheme(),
      change: false,
    };
  }

  handleScheme = () => {
    return {
      project_id: "USA",
      _id: "-1",
      caption: "",
      area: this.props.area,
      line: "",
      _order: "",
      code: "",
      _operation: 0,
      unit: 1,
      amount: 0,
      data: {},
      user_id: "C1",
    };
  };

  handleChangeItem = (e) => {
    this.setState({
      item: {
        ...this.state.item,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeItemValue = (e) => {
    this.setState({
      item: {
        ...this.state.item,
        [e.name]: Number(e.value),
      },
      change: true,
    });
  };

  handleSelItem = (e) => {
    this.setState({
      item: {
        ...this.state.item,
        line: e.line,
        _order: e._order,
        code: e.code,
      },
      change: true,
    });
  };

  handleData = () => {
    Api.listAreaLines(this.props.area).then((result) => {
      if (result.msg === "") {
        const data = result.data;
        this.setState({ list: data, change: false });
      }
    });
  };

  handleClear = () => {
    this.setState({ item: this.handleScheme(), change: false });
  };

  handleSet = () => {
    const item = this.state.item;
    item._operation = 1;
    item.caption = `Area: ${item.area}, line: ${item.line}, order: ${item._order} code: ${item.code}`;
    Api.setOrder("-1", item).then((result) => {
      if (result.msg === "") {
        this.handleData();
      }
    });
  };

  componentDidMount() {
    if (this.props.tab === this.state.tab) {
      this.handleData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tab !== this.props.tab && this.props.tab === this.state.tab) {
      this.handleData();
    }
  }

  componentWillUnmount() {}

  render() {
    return (
      <React.Fragment>
        <div className="tab-group p-2">
          {this.state.list.map((item, i) => {
            let line = getSource(item, ["line"], "");
            let _order = getSource(item, ["_order"], "");
            let details = getSource(item, ["details"], []);
            return (
              <div key={`area_line_${i}`} className="tab-group">
                <div className="row">
                  <h5 className="col-12 text-center p-1">{`Orders line (${line}) - ${_order}`}</h5>
                </div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Code</th>
                      <th scope="col">Mount</th>
                      <th scope="col">Put</th>
                      <th scope="col">Needed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {details.map((detail, j) => {
                      let code = getSource(detail, ["code"], "");
                      let total = getSource(detail, ["total"], 0);
                      let put = getSource(detail, ["put"], 0);
                      let needed = getSource(detail, ["needed"], 0);
                      return (
                        <tr
                          key={`area_line_detail_${j}`}
                          className={line === this.state.item.line && code === this.state.item.code ? "active" : ""}
                          onClick={() => this.handleSelItem({ line, _order, code })}
                        >
                          <th scope="row">{j + 1}</th>
                          <td className="text-center">{`${code}`}</td>
                          <td className="text-right">{`${total} box`}</td>
                          <td className="text-right">{`${put} box`}</td>
                          <td className="text-right">{`${needed} box`}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            );
          })}
          <div className="tab-group">
            <div className="row">
              <h5 className="col-12 text-center p-1">Options</h5>
            </div>
            <div className="form-group input-group-sm mb-2">
              <label>Line</label>
              <Section
                id={`component_order_line`}
                _state="0"
                type="text"
                className="form-control"
                placeholder="Nombre completo"
                name="line"
                value={this.state.item.line}
                onChange={this.handleChangeItem}
              />
            </div>
            <div className="form-group input-group-sm mb-2">
              <label>Order</label>
              <Section
                id={`component_order_order`}
                _state="0"
                type="text"
                className="form-control"
                placeholder="Nombre completo"
                name="_order"
                value={this.state.item._order}
                onChange={this.handleChangeItem}
              />
            </div>
            <div className="form-group input-group-sm mb-2">
              <label>Code</label>
              <Section
                id={`component_order_code`}
                _state="0"
                type="text"
                className="form-control"
                placeholder="Nombre completo"
                name="code"
                value={this.state.item.code}
                onChange={this.handleChangeItem}
              />
            </div>
            <div className="form-group input-group-sm mb-2">
              <label>Unit</label>
              <Count
                classNameInput={"form-control number t-c"}
                id={`component_order_unit`}
                _state="0"
                unity="box"
                name="unit"
                increase={1}
                minimum={1}
                precision={0}
                value={this.state.item.unit}
                handleValue={this.handleChangeItemValue}
              />
            </div>
            <div className="form-group input-group-sm mb-2">
              <label>Mount</label>
              <Count
                classNameInput={"form-control number t-c"}
                id={`component_order_amount`}
                _state="0"
                unity="box"
                name="amount"
                increase={1}
                minimum={1}
                precision={0}
                value={this.state.item.amount}
                handleValue={this.handleChangeItemValue}
              />
            </div>
            <div className="form-group input-group-sm mt-4 mb-4">
              <Button className="btn btn-primary mr-3" onClick={this.handleClear}>
                Clear
              </Button>
              <Button className="btn btn-primary" onClick={this.handleSet}>
                Set
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(ComponentOrder);
