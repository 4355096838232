import React from "react";
import Row from "../../../components/row";
import ModalSearch from "../../../components/modalSearch";
import ModalPrint from "../../../modals/modalPrint";
import { formatInteger, formatNumber, getValue } from "../../../components/utilities";
import ModalKardex from "../../../modals/warehouse/modalKardex";

function ComponentStock(props) {
  const _id = props._id;
  const data = props.data;
  const select = props.select;
  const printParams = props.printParams;
  const stock = data.stock;
  const cellar_id = getValue(stock, "cellar_id", "-1");

  return (
    <React.Fragment>
      <div className="view-list">
        <div className="view-list-menu">
          <div className="viewtop-search">
            <ModalSearch name="search" value={stock.search} onChange={props.changeStock} onSearch={props.search} />
            <div className="grid-2 grid-l menubar s">
              <ModalPrint className="btn btn-secondary btn-sm" params={printParams}>
                <i className="fa fa-print"></i>
              </ModalPrint>
            </div>
            <div className="btn-group btn-group-sm grid-3 grid-r s">
              <p className={stock.all > 0 ? "m-0" : "m-0 d-none"}>
                <b className="mr-1">{formatInteger(stock.end)}</b>
                de
                <b className="ml-1">{formatInteger(stock.all)}</b>
              </p>
            </div>
          </div>
        </div>
        <div className="view-list-detail">
          <div className="dtable">
            <div className="dtable-head">
              <Row className="dtable-row" cols={cellar_id !== "-1" ? "80px auto 58px 95px 119px" : "197px 80px auto 58px 119px"}>
                <div className={cellar_id !== "-1" ? "d-none" : "dtable-th"}>Bodega</div>
                <div className="dtable-th">Código</div>
                <div className="dtable-th">Descripción</div>
                <div className="dtable-th">Und</div>
                <div className={cellar_id !== "-1" ? "dtable-th" : "d-none"}>Reservas</div>
                <div className="dtable-th">Existencias</div>
              </Row>
            </div>
            <div className="dtable-details" id={`${_id}_scroll`} onScroll={props.scrolling}>
              {stock.list.map((item, i) => {
                return (
                  <ModalKardex key={i} isFind={true} _id={item._id} data={item}>
                    <Row
                      className={item.stock > 0 ? "dtable-row" : "d-none"}
                      cols={cellar_id !== "-1" ? "80px auto 58px 95px 115px" : "197px 80px auto 58px 115px"}
                      data={item}
                      _id={item.reference_id}
                      selectedId={select.reference_id}
                      selected={props.selected}
                    >
                      <div className={cellar_id !== "-1" ? "d-none" : "dtable-td"}>{item.cellar}</div>
                      <div className="dtable-td t-c">{item.code}</div>
                      <div className="dtable-td">{item.reference}</div>
                      <div className="dtable-td t-c">{item.unity}</div>
                      <div className={cellar_id !== "-1" ? "dtable-td t-r" : "d-none"}>{formatNumber(item.reservations)}</div>
                      <div className="dtable-td t-r">{formatNumber(item.stock)}</div>
                    </Row>
                  </ModalKardex>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ComponentStock;
