import React from "react";
import ReactDOM from "react-dom";
import "../../styles/view.scss";
import "../../styles/suport.scss";
import "../../styles/modal.scss";
import { Redirect } from "react-router-dom";
import {
  Loading,
  Subscribe,
  UnSubscribe,
  formatInteger,
  hideModal,
  showModal,
  updateList,
  deleteList,
  clone,
  formatNumber,
} from "../../components/utilities";
import ModalSearch from "../../components/modalSearch";
import ModalAsset from "../../modals/assets/modalAsset";
import ModalPrint from "../../modals/modalPrint";
import ModalFooter from "../../components/modalFooter";
import ModalHeaderList from "../../components/modalHeaderList";
import ModalList from "../../components/modalList";
import Row from "../../components/row";
import { Api as Filter } from "../../services/asset";
import { ToolTip } from "../../components/inputs";
import { connect } from "react-redux";

class ViewEquipment extends React.Component {
  constructor(props) {
    super(props);
    Loading();
    this.state = {
      _id: "__ViewEquipment",
      _class: "ASSETS",
      filter: "",
      class_id: "-1",
      area_id: "-1",
      equipment: true,
      project_id: this.props.project_id,
      user_id: this.props.user_id,
      params: this.props.location.state,
      rows: this.props.view_rows,
      data: {
        list: [],
        search: "",
        project_id: "",
        project: "",
        class_id: "-1",
        class: "",
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        all: 0,
        user_id: "-1",
      },
      printParams: {
        _class: "ASSETS",
        single: false,
      },
      select: { _id: "", captio: "" },
      change: false,
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  eventDelData = (e) => {
    setTimeout(() => {
      this.handleDelete(e);
    }, 500);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleSearch = () => {
    this.handleData(false);
  };

  handleData = (scroll) => {
    const filter = this.props.location.state.filter || {};
    if (filter.type === "equipment") {
      this.handleFilterEquipment(scroll);
    } else if (filter.type === "area") {
      this.handleFilterArea(scroll);
    } else {
      this.handleFilter(scroll);
    }
  };

  handleFilterEquipment = (scroll) => {
    const filter = this.props.location.state.filter || {};
    const project_id = filter.project_id;
    const user_id = this.state.user_id;
    const class_id = filter.class_id;
    const detail = filter.detail;
    const value = filter._value;
    const filters = this.state.filters;
    const search = this.state.data.search;
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Filter.filterEquipment(project_id, user_id, class_id, detail, value, filters, search, page, rows, this.state.data.list).then(
        (result) => {
          this.setState({
            filter: "equipment",
            class_id: class_id,
            area_id: "-1",
            data: result.data,
          });
        }
      );
    } else {
      Filter.filterEquipment(project_id, user_id, class_id, detail, value, filters, search, 1, rows, []).then((result) => {
        this.setState({
          filter: "equipment",
          class_id: class_id,
          area_id: "-1",
          data: result.data,
        });
      });
    }
  };

  handleFilterArea = (scroll) => {
    const filter = this.props.location.state.filter || {};
    const project_id = filter.project_id;
    const user_id = this.state.user_id;
    const area_id = filter.area_id;
    const class_id = filter.class_id;
    const filters = this.state.filters;
    const search = this.state.data.search;
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Filter.filterArea(project_id, user_id, area_id, class_id, filters, search, page, rows, this.state.data.list).then((result) => {
        this.setState({
          filter: "area",
          class_id: class_id,
          area_id: area_id,
          data: result.data,
        });
      });
    } else {
      Filter.filterArea(project_id, user_id, area_id, class_id, filters, search, 1, rows, []).then((result) => {
        this.setState({
          filter: "area",
          class_id: class_id,
          area_id: area_id,
          data: result.data,
        });
      });
    }
  };

  handleFilter = (scroll) => {
    const filter = this.props.location.state.filter || {};
    const project_id = filter.project_id;
    const user_id = this.state.user_id;
    const equipment = this.state.equipment;
    const filters = this.state.filters;
    const search = this.state.data.search;
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Filter.filter(project_id, user_id, equipment, filters, search, page, rows, this.state.data.list).then((result) => {
        this.setState({
          filter: "",
          class_id: "-1",
          area_id: "-1",
          data: result.data,
        });
      });
    } else {
      Filter.filter(project_id, user_id, equipment, filters, search, 1, rows, []).then((result) => {
        this.setState({
          filter: "",
          class_id: "-1",
          area_id: "-1",
          data: result.data,
        });
      });
    }
  };

  handleScroll = (e) => {
    const h = e.target.scrollTop + e.target.clientHeight + 1;
    if (e.target.id === `${this.state._id}_scroll` && h >= e.target.scrollHeight) {
      this.handleData(true);
    }
  };

  handleSelected = (e) => {
    this.setState({ select: e });
  };

  handleSetData = (e) => {};

  handleGoBack = () => {
    this.props.history.push(`${this.props._view}`, {
      origen: "Equipment",
    });
  };

  handleUpdate = (e) => {
    const filter = this.state.filter;
    const class_id = this.state.class_id;
    const area_id = this.state.area_id;
    const equipment = this.state.equipment;
    if (filter === "equipment" && class_id === e.class_id) {
      const result = updateList(this.state.data, e);
      this.setState({ data: result });
    } else if (filter === "area" && class_id === e.class_id && area_id === e.area_id) {
      const result = updateList(this.state.data, e);
      this.setState({ data: result });
    } else if (filter === "" && equipment === e.equipment) {
      const result = updateList(this.state.data, e);
      this.setState({ data: result });
    }
  };

  handleDelete = (e) => {
    const result = deleteList(this.state.data, e);
    this.setState({ data: result });
  };

  componentDidMount() {
    Subscribe(`${this.state._class}/${this.props.project_id}`, (event) => this.eventSetData(event));
    Subscribe(`delete/${this.state._class}/${this.props.project_id}`, (event) => this.eventDelData(event));
    this.handleData(false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.project_id !== this.props.project_id) {
      UnSubscribe(`${this.state._class}/${prevProps.project_id}`, (event) => this.eventSetData(event));
      UnSubscribe(`delete/${this.state._class}/${prevProps.project_id}`, (event) => this.eventDelData(event));
      Subscribe(`${this.state._class}/${this.props.project_id}`, (event) => this.eventSetData(event));
      Subscribe(`delete/${this.state._class}/${this.props.project_id}`, (event) => this.eventDelData(event));
      this.handleData(false);
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state._class}/${this.props.project_id}`, (event) => this.eventSetData(event));
    UnSubscribe(`delete/${this.state._class}/${this.props.project_id}`, (event) => this.eventDelData(event));
  }

  render() {
    if (this.state.project_id !== this.props.project_id) {
      return <Redirect to={this.props._view} push={true} />;
    }
    return (
      <React.Fragment>
        <div className={this.state._id}>
          <div className="viewcontainer">
            <div className="view-box">
              <div className="view-filters">
                <div className="view-detail">
                  <div className="view-detail-title">
                    <div className="btn btn-link btn-sm float-left" onClick={this.handleGoBack}>
                      <i className="fas fa-arrow-left"></i>
                    </div>
                    <div className="text">{this.props.location.state.filter.title || "Equipos"}</div>
                  </div>
                  <div className="view-detail-list">
                    <div className="view-list">
                      <div className="view-list-menu">
                        <div className="viewtop-search">
                          <ModalSearch
                            name="search"
                            value={this.state.data.search || ""}
                            onChange={this.handleChange}
                            onSearch={this.handleSearch}
                          />
                          <div className="grid-2 grid-l menubar s">
                            <ModalPrint className="btn btn-secondary btn-sm" params={this.state.printParams}>
                              <i className="fa fa-print"></i>
                            </ModalPrint>
                            <ModalAsset
                              className="btn btn-primary btn-sm"
                              project_id={this.props.project_id}
                              isNew={true}
                              equipment={true}
                              data={{
                                project_id: this.props.project_id,
                                class_id: this.state.class_id,
                                class: this.state.data.class,
                                main_id: "-1",
                                owner_id: this.state.data.project_id,
                                running: this.state.data.running,
                                _data: {
                                  owner: this.state.data.project,
                                },
                              }}
                              onSetData={this.handleSetData}
                            >
                              Nuevo
                            </ModalAsset>
                          </div>
                          <div className="btn-group btn-group-sm grid-3 grid-r s">
                            <p className={this.state.data.all > 0 ? "m-0" : "m-0 d-none"}>
                              <b className="mr-1">{formatInteger(this.state.data.end)}</b>
                              de
                              <b className="ml-1">{formatInteger(this.state.data.all)}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="view-list-detail">
                        <div className="dtable">
                          <div className="dtable-head">
                            <Row className="dtable-row" cols="75px 220px auto 165px 165px 40px 76px 80px">
                              <div className="dtable-th">Código</div>
                              <div className="dtable-th">
                                Tipo
                                <ModalFilter
                                  className="fas fa-filter"
                                  filter={this.props.location.state.filter || {}}
                                  field={{ field: "type_id", _id: "A.TYPE_ID", caption: "E.CAPTION" }}
                                />
                              </div>
                              <div className="dtable-th">
                                Referencia
                                <ModalFilter
                                  className="fas fa-filter"
                                  filter={this.props.location.state.filter || {}}
                                  field={{ field: "reference_id", _id: "A.REFERENCE_ID", caption: "D.CAPTION" }}
                                />
                              </div>
                              <div className="dtable-th">
                                Área
                                <ModalFilter
                                  className="fas fa-filter"
                                  filter={this.props.location.state.filter || {}}
                                  field={{ field: "area_id", _id: "C.AREA_ID", caption: "COALESCE(C._DATA->>'area', '')" }}
                                />
                              </div>
                              <div className="dtable-th">Sitio</div>
                              <ToolTip className="dtable-th" title="Total de fallas" placement="top">
                                TF
                              </ToolTip>
                              <ToolTip className="dtable-th" title="Disponibilidad" placement="top">
                                Dsp.
                              </ToolTip>
                              <div className="dtable-th">Estatus</div>
                            </Row>
                          </div>
                          <div className="dtable-details" id={`${this.state._id}_scroll`} onScroll={this.handleScroll}>
                            {this.state.data.list.map((item, i) => {
                              return (
                                <ModalAsset
                                  key={i}
                                  project_id={this.props.project_id}
                                  equipment={item.equipment}
                                  data={item}
                                  onSetData={this.handleSetData}
                                >
                                  <Row
                                    className="dtable-row"
                                    cols="75px 220px auto 165px 165px 40px 76px 76px"
                                    data={item}
                                    _id={item._id}
                                    selectedId={this.state.select._id}
                                    selected={this.handleSelected}
                                  >
                                    <div className="dtable-td t-c">{item.code}</div>
                                    <div className="dtable-td">{item.type}</div>
                                    <div className="dtable-td">{item.reference}</div>
                                    <div className="dtable-td">{item.area}</div>
                                    <div className="dtable-td">{item.address}</div>
                                    <div className="dtable-td t-c">{item.ft}</div>
                                    <div className="dtable-td t-c">
                                      {formatNumber(item.availability)}
                                      <small>
                                        <i
                                          className={
                                            item.availability_direction === "UP"
                                              ? "fas fa-level-up-alt ml-1 success"
                                              : item.availability_direction === "DOWN"
                                              ? "fas fa-level-down-alt ml-1 danger"
                                              : "d-none"
                                          }
                                        />
                                      </small>
                                    </div>
                                    <div className={item.failure ? "dtable-td status" : "d-none"}>
                                      <ToolTip className={item.failure ? "fas fa-exclamation-triangle" : "d-none"} title="En falla" />
                                    </div>
                                    <div className={!item.failure ? "dtable-td status" : "d-none"}>
                                      <ToolTip className={!item.install ? "fas fa-unlink" : "d-none"} title="Desinstalado" />
                                      <ToolTip
                                        className={item._id === "-1" ? "d-none" : item.running ? "fas fa-bolt" : "fas fa-pause"}
                                        title={item._id === "-1" ? "" : item.running ? "En funcionamiento" : "En pausa"}
                                      />
                                      <ToolTip className={item.reported ? "far fa-clipboard" : "d-none"} title="Reportado" />
                                      <ToolTip className={item.low ? "fas fa-ban" : "d-none"} title="Dado de baja" />
                                      <ToolTip className={item.preventive ? "fas fa-wrench" : "d-none"} title="Mantenimiento preventivo" />
                                      <ToolTip className={item.alerts ? "fas fa-exclamation-circle" : "d-none"} title="Alertas" />
                                    </div>
                                  </Row>
                                </ModalAsset>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    user_id: state.sistem.profile._id || "-1",
    _view: state.sistem.folder._view || "",
    view_rows: state.sistem.view_rows,
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ViewEquipment);

class ModalFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: "__ModalFilter",
      _id: "__ModalFilter",
      title: "Filtro",
      show: false,
      mains: [],
      main_id: "-1",
      data: {
        list: [],
        state: "0",
        search: "",
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      select: { _id: "-1", caption: "" },
      change: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
    this.handleHide();
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    const _id = `${this.state.form}${Date.now()}`;
    this.setState({ _id: _id, show: true, change: false });
  };

  handleClose = () => {
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.change) {
      const params = this.state.data;
      this.handleExecute(params);
    } else {
      this.handleHide();
    }
  };

  handleSetData = (data) => {
    this.setState({
      change: false,
    });
    showModal(this.state._id);
  };

  handleRemove = (e) => {
    if (e) {
      const data = {
        ...this.state.data,
        reference_id: "-1",
        quantity: 0,
        unitary: 0,
      };
      this.handleExecute(data);
    }
  };

  handleData = () => {
    let data = clone(this.props.data || {});
    this.handleSetData(data);
  };

  handleSearch = () => {
    this.handleData(false);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        this.handleData();
      }
    }
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog __modalSingleList" role="document">
                <div className="modal-content">
                  <ModalHeaderList
                    title={this.state.title}
                    handleClose={this.handleClose}
                    state={this.state.data.state}
                    rows={this.state.data.end}
                    all={this.state.data.all}
                  />
                  <div className="modal-body">
                    <div className="modalList single">
                      <div className="modalListFind">
                        <ModalSearch
                          name="search"
                          value={this.state.data.search}
                          onChange={this.handleChange}
                          onSearch={this.handleSearch}
                        />
                      </div>
                      <div className="modalListList">
                        <ModalList
                          id={`${this.state._id}_scroll`}
                          _state={this.state.data.state}
                          list={this.state.data.list}
                          select={this.state.select}
                          getData={this.handleData}
                          onSelect={this.handleSelect}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooter
                      title="¿Retirar actividad?"
                      description="¿Desea retirar la actividad, sobre el componente? este cambio no podrá revertirse."
                      onRemove={this.handleRemove}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}
