import { http } from "../components/http";
import { ShowAlert, getValue, setValue, join } from "../components/utilities";
import environment from "../env/env";
const apiUrl = environment.url;

export const Api = {
  warehouse: async function (id) {
    return await http("GET", `${apiUrl}/projects/warehouses/${id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  warehouseCellars: async function (id, state, search, page, rows, list) {
    return await http("GET", `${apiUrl}/warehouses/cellars/${id}?state=${state}&search=${search}&page=${page}&rows=${rows}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  warehouseKardex: async function (id, search, page, rows, list) {
    return await http("GET", `${apiUrl}/warehouses/kardex/${id}?search=${search}&page=${page}&rows=${rows}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  warehouseStock: async function (id, search, page, rows, list) {
    return await http("GET", `${apiUrl}/warehouses/stock/${id}?search=${search}&page=${page}&rows=${rows}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  cellar: async function (id, project_id, warehouse_id) {
    return await http("GET", `${apiUrl}/cellars/${id}?project_id=${project_id}&warehouse_id=${warehouse_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  cellarKardex: async function (id, search, page, rows, list) {
    return await http("GET", `${apiUrl}/cellars/kardex/${id}?search=${search}&page=${page}&rows=${rows}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "reference_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  cellarStock: async function (id, search, page, rows, list) {
    return await http("GET", `${apiUrl}/cellars/stock/${id}?search=${search}&page=${page}&rows=${rows}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "reference_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  cellarInventory: async function (id, cellar_id) {
    return await http("GET", `${apiUrl}/cellars/inventory/${cellar_id}?document_id=${id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  reference: async function (id) {
    return await http("GET", `${apiUrl}/references/${id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  purchase: async function (id, project_id) {
    return await http("GET", `${apiUrl}/purchases/${id}?project_id=${project_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  entry: async function (id, project_id, cellar_id, cellar, document_tp, document_id, document_code) {
    return await http(
      "GET",
      `${apiUrl}/entrys/${id}?project_id=${project_id}&cellar_id=${cellar_id}&cellar=${cellar}&document_tp=${document_tp}&document_id=${document_id}&document_code=${document_code}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  exite: async function (id, project_id, cellar_id, cellar, document_tp, document_id, document_code) {
    return await http(
      "GET",
      `${apiUrl}/exits/${id}?project_id=${project_id}&cellar_id=${cellar_id}&cellar=${cellar}&document_tp=${document_tp}&document_id=${document_id}&document_code=${document_code}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  refund: async function (id, project_id, cellar_id, cellar, document_tp, document_id, document_code) {
    return await http(
      "GET",
      `${apiUrl}/refunds/${id}?project_id=${project_id}&cellar_id=${cellar_id}&cellar=${cellar}&document_tp=${document_tp}&document_id=${document_id}&document_code=${document_code}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  inventory: async function (id, project_id, cellar_id, cellar) {
    return await http("GET", `${apiUrl}/inventorys/${id}?project_id=${project_id}&cellar_id=${cellar_id}&cellar=${cellar}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  kardex: async function (id) {
    return await http("GET", `${apiUrl}/kardex/${id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  kardexReference: async function (reference_id, cellar_id, main_id) {
    return await http("GET", `${apiUrl}/kardex/references/${reference_id}?cellar_id=${cellar_id}&main_id=${main_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setWarehouse: async function (id, params) {
    return await http("POST", `${apiUrl}/warehouses/${id}`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setCellar: async function (id, params) {
    return await http("POST", `${apiUrl}/cellars/${id}`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setReference: async function (id, params) {
    return await http("POST", `${apiUrl}/references/${id}`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setPurchase: async function (id, params) {
    return await http("POST", `${apiUrl}/purchases/${id}`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setEntry: async function (id, params) {
    return await http("POST", `${apiUrl}/entrys/${id}`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setExit: async function (id, params) {
    return await http("POST", `${apiUrl}/exits/${id}`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setRefund: async function (id, params) {
    return await http("POST", `${apiUrl}/refunds/${id}`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setInventory: async function (id, params) {
    return await http("POST", `${apiUrl}/inventorys/${id}`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setKardex: async function (id, params) {
    return await http("POST", `${apiUrl}/kardex/${id}`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateCellar: async function (id, state) {
    return await http("PATCH", `${apiUrl}/cellars/${id}`, { state })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateReference: async function (id, state) {
    return await http("PATCH", `${apiUrl}/references/${id}`, { state })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  statePurchase: async function (id, state) {
    return await http("PATCH", `${apiUrl}/purchases/${id}`, { state })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateEntry: async function (id, state) {
    return await http("PATCH", `${apiUrl}/entrys/${id}`, { state, rows: 30 })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateExit: async function (id, state) {
    return await http("PATCH", `${apiUrl}/exits/${id}`, { state, rows: 30 })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateRefund: async function (id, state) {
    return await http("PATCH", `${apiUrl}/refunds/${id}`, {
      state,
      rows: 30,
    })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateInventory: async function (id, state) {
    return await http("PATCH", `${apiUrl}/inventorys/${id}`, { state, rows: 30 })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  suports: async function (id, cellar_id, _class, state, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/warehouses/suports/${id}?cellar_id=${cellar_id}&_class=${_class}&state=${state}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  referenceStocks: async function (id, search, page, rows, list) {
    return await http("GET", `${apiUrl}/references/stocks/?reference_id=${id}&search=${search}&page=${page}&rows=${rows}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
};
