import React from "react";
import { Subscribe, UnSubscribe, notifyList, formatInteger, updateList, compare } from "../../../components/utilities";
import ModalSearchMenu from "../../../components/modalSearchMenu";
import ModalPrint from "../../../modals/modalPrint";
import Row from "../../../components/row";
import ModalReference from "../../../modals/warehouse/modalReference";
import { Api as Project } from "../../../services/project";
import { connect } from "react-redux";

class ComponentReferences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "__ComponentReferences",
      _view: {},
      rows: 50,
      title: "Referencias",
      name: "references",
      project_id: this.props.project_id,
      show: false,
      data: {
        list: [],
        state: this.props.state || "0",
        search: "",
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      select: { _id: "-1", caption: "" },
      printParams: {
        _class: "REFERENCE",
        _id: "-1",
        single: true,
      },
      change: false,
      update: {}
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  eventNotify = (e) => {
    setTimeout(() => {
      this.handleNotify(e);
    }, 500);
  };

  handleSetData = (e) => {
    this.handleUpdate(e);
    this.handleSelect(e);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeState = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        search: "",
        state: e,
      },
    });
  };

  handleData = (scroll) => {
    const project_id = this.props.project_id;
    const state = this.state.data.state;
    const search = this.state.data.search;
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Project.references(project_id, state, search, page, rows, this.state.data.list).then((result) => {
        const data = result.data;
        this.setState({
          data: data,
        });
      });
    } else {
      Project.references(project_id, state, search, 1, rows, []).then((result) => {
        const data = result.data;
        this.setState({
          data: data,
          change: false,
        });
      });
    }
  };

  handleSelect = (e) => {
    this.setState({ select: e, change: true });
  };

  handleSearch = () => {
    this.handleData(false);
  };

  handleScroll = (e) => {
    const h = e.target.scrollTop + e.target.clientHeight + 1;
    if (e.target.id === `${this.state._id}_scroll` && h >= e.target.scrollHeight) {
      this.handleData(true);
    }
  };

  handleUpdate = (e) => {
    if (compare(this.state.update, e)) {
      const data = updateList(this.state.data, e);
      this.setState({ data: data, change: true, update: e });
    }
  };

  handleNotify = (e) => {
    const data = notifyList(this.state.data, e);
    this.setState({ data: data, change: true });
  };

  handleSelected = (e) => {
    this.setState({ select: e });
  };

  componentDidMount() {
    Subscribe(`${this.state.name}/${this.props.project_id}`, (event) => this.eventSetData(event));
    Subscribe(`object/${this.state.project_id}`, (event) => this.eventNotify(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show) {
      if (prevProps.show !== this.props.show) {
        this.handleData(false);
      } else if (prevState.data.state !== this.state.data.state) {
        this.handleData(false);
      } else if (prevProps.project_id !== this.props.project_id) {
        UnSubscribe(`${this.state.name}/${prevProps.project_id}`, (event) => this.eventSetData(event));
        Subscribe(`${this.state.name}/${this.props.project_id}`, (event) => this.eventSetData(event));
        this.handleData(false);
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.name}/${this.props.project_id}`, (event) => this.eventSetData(event));
    UnSubscribe(`object/${this.state.project_id}`, (event) => this.eventNotify(event));
  }

  render() {
    return (
      <React.Fragment>
        <div className="view-list">
          <div className="view-list-menu">
            <div className="viewtop-search">
              <ModalSearchMenu
                name="search"
                value={this.state.data.search}
                state={this.state.data.state}
                onChange={this.handleChange}
                onSearch={this.handleSearch}
                onState={this.handleChangeState}
                states={[
                  { _state: "0", caption: "Activas" },
                  { _state: "1", caption: "Terminadas" },
                  { _state: "ALL", caption: "Todas" },
                ]}
              />
              <div className="grid-2 grid-l menubar s">
                <ModalPrint className="btn btn-secondary btn-sm" params={this.state.printParams}>
                  <i className="fa fa-print"></i>
                </ModalPrint>
                <ModalReference
                  className="btn btn-primary btn-sm mr-1"
                  project_id={this.props.project_id}
                  isNew={true}
                  onSetData={this.handleUpdate}
                >
                  Nueva
                </ModalReference>
              </div>
              <div className="btn-group btn-group-sm grid-3 grid-r s">
                <p className={this.state.data.all > 0 ? "m-0" : "m-0 d-none"}>
                  <b className="mr-1">{formatInteger(this.state.data.end)}</b>
                  de
                  <b className="ml-1">{formatInteger(this.state.data.all)}</b>
                </p>
              </div>
            </div>
          </div>
          <div className="view-list-detail">
            <div className="dtable">
              <div className="dtable-head">
                <Row className="dtable-row" cols="80px auto 165px 169px">
                  <div className="dtable-th">Código</div>
                  <div className="dtable-th">Nombre</div>
                  <div className="dtable-th">Método</div>
                  <div className="dtable-th">Fabricante</div>
                </Row>
              </div>
              <div className="dtable-details" id={`${this.state._id}_scroll`} onScroll={this.handleScroll}>
                {this.state.data.list.map((item, i) => {
                  return (
                    <ModalReference
                      key={i}
                      project_id={item.project_id}
                      isFind={item.isFind || false}
                      data={item}
                      _id={item._id}
                      onSetData={this.handleUpdate}
                    >
                      <Row
                        className={item._state === "-1" ? "dtable-row system" : item._state === "0" ? "dtable-row" : "dtable-row cancel"}
                        cols="80px auto 165px 165px"
                        data={item}
                        _id={item._id}
                        selectedId={this.state.select._id}
                        selected={this.handleSelect}
                      >
                        <div className="dtable-td t-c">{item.code}</div>
                        <div className="dtable-td">{item.caption}</div>
                        <div className="dtable-td">{item.method}</div>
                        <div className="dtable-td">{item.manufacturer}</div>
                      </Row>
                    </ModalReference>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    project: state.sistem.project,
    user_id: state.sistem.profile._id || "-1",
    user_name: state.sistem.profile.caption || "",
    _view: state.sistem.folder._view || "",
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ComponentReferences);
