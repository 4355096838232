import React from "react";
import ReactDOM from "react-dom";
import "../styles/modal.scss";
import { pdf, xls } from "../components/http";
import { showModal, hideModal, toolTip, getValue, getSource, formatDate } from "../components/utilities";
import { Type } from "../components/inputs";
import DateTime from "../components/dateTime";
import ModalHeader from "../components/modalHeader";
import { TpActivity } from "./keep/listTpActivities";
import { Api as System } from "../services/system";
import { connect } from "react-redux";
import environment from "../env/env";
const apiUrl = environment.url;

class ModalPrint extends React.Component {
  constructor(props) {
    super(props);
    const name = "ModalPrint";
    this.state = {
      _id: "__modalPrint",
      _state: "0",
      name: name,
      params: {},
      prints: [],
      filters: [],
      select: {
        _id: "-1",
        caption: "",
        description: "",
        filter_tp: "-1",
        single: false,
        _data: {
          filters: [],
          url_print: "",
          url_xls: "",
        },
      },
      show: false,
      change: false,
    };
    this.result = {};
  }

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
      change: true,
    });
  };

  handleChangeType = (item) => {
    this.setState({
      ...this.state,
      item: item,
      change: true,
    });
  };

  handleChangeTpActivity = (item) => {
    const filter = (select) => select.name === "actividad";
    const result = this.state.filters;
    const index = result.findIndex(filter);
    if (index !== -1) {
      result[index].value = {
        _id: getSource(item, ["_id"], ""),
        caption: getSource(item, ["caption"], ""),
      };
    }
    this.setState({
      ...this.state,
      filters: result,
    });
  };

  handleChangeDate = (item) => {
    const filter = (select) => select.name === item.target.name;
    const result = this.state.filters;
    const index = result.findIndex(filter);
    if (index !== -1) {
      result[index].value = item.target.value;
    }
    this.setState({
      ...this.state,
      filters: result,
    });
  };

  handleChangeDateTime = (item) => {
    const filter = (select) => select.name === item.target.name;
    const result = this.state.filters;
    const index = result.findIndex(filter);
    if (index !== -1) {
      result[index].value = item.target.value;
    }
    this.setState({
      ...this.state,
      filters: result,
    });
  };

  handleExeute = (e) => {
    if (typeof this.props.onChange === "function") {
      this.props.onChange(e);
    }
  };

  handleHide() {
    hideModal(this.state._id);
    this.setState({ show: false });
  }

  handleShow = (e) => {
    this.setState({ show: true, change: false });
  };

  handleClose = (e) => {
    this.handleHide();
  };

  handleFilter = async (url) => {
    const params = this.props.params;
    const filters = this.state.filters;
    let project_id = this.props.project_id;
    let _class = getValue(params, "_class", "-1");
    let _id = getValue(params, "_id", "-1");
    let user_id = this.props.user_id;
    url = url.replace("$project_id", project_id);
    url = url.replace("$_class", _class);
    url = url.replace("$_id", _id);
    url = url.replace("$user_id", user_id);
    for (let index = 0; index < filters.length; index++) {
      let item = filters[index];
      let name = getSource(item, ["name"], "");
      let value = getSource(item, ["value"], "");
      let type = getSource(item, ["type"], "");
      if (type === "date") {
        value = formatDate(value, "yyyy-MM-dd");
      } else if (type === "datetime") {
        value = formatDate(value, "yyyy-MM-dd HH:mm:SS");
      } else if (type === "type_actividad") {
        value = getSource(value, ["_id"], "-1");
      }
      url = url.replace(`$${name}`, value);
    }
    return url;
  };

  handleFilterPdf = async () => {
    const select = this.state.select;
    let url = getSource(select, ["_data", "url_print"], "");
    return this.handleFilter(url);
  };

  handleFilterXls = async () => {
    const select = this.state.select;
    let url = getSource(select, ["_data", "url_xls"], "");
    return this.handleFilter(url);
  };

  handlePrint = async (e) => {
    const url = await this.handleFilterPdf();
    pdf(`${apiUrl}/${url}`);
    this.handleExeute();
    this.handleHide();
  };

  handleExport = async (e) => {
    const url = await this.handleFilterXls();
    xls(`${apiUrl}/${url}`);
    this.handleExeute();
    this.handleHide();
  };

  handleSelect = (e) => {
    let filters = getSource(e, ["_data", "filters"], []);
    for (let index = 0; index < filters.length; index++) {
      const now = new Date();
      const item = filters[index];
      const type = getSource(item, ["type"], "");
      if (type === "date") {
        filters[index].value = now;
      } else if (type === "datetime") {
        filters[index].value = now;
      }
    }
    this.setState({ select: e, filters });
  };

  handleData = () => {
    const params = this.props.params;
    const project_id = this.props.project_id;
    const _class = getValue(params, "_class", "-1");
    const single = getValue(params, "single", false);
    System.prints(project_id, _class, single).then((result) => {
      const msg = getValue(result, "msg", "");
      let data = result.data;
      if (msg !== "") {
        data = [];
      }
      let select = {};
      if (data.length > 0) {
        select = data[0];
      }
      let filters = getSource(select, ["_data", "filters"], []);
      for (let index = 0; index < filters.length; index++) {
        const now = new Date();
        const item = filters[index];
        const type = getSource(item, ["type"], "");
        if (type === "date") {
          filters[index].value = now;
        } else if (type === "datetime") {
          filters[index].value = now;
        }
      }
      this.setState({ prints: data, select, filters });
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        showModal(this.state._id);
        this.handleData();
      }
    }
  }

  componentWillUnmount() {
    hideModal(this.state._id);
  }

  render() {
    toolTip();
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            data-toggle="tooltip"
            data-placement={this.props.placement || "bottom"}
            title={this.props.title}
            onClick={this.handleShow}
          >
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            data-toggle="tooltip"
            data-placement={this.props.placement || "bottom"}
            title={this.props.title}
            onClick={this.handleShow}
          >
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog __modalObject m" role="document">
                <div className="modal-content">
                  <ModalHeader title="Imprimir o exportar" handleClose={this.handleClose} />
                  <div className="modal-body">
                    <div className="modalPrint">
                      <div className="grid-1">
                        <div className="modalListBody modalPrintList">
                          {this.state.prints.map((item, i) => {
                            return (
                              <div
                                key={i}
                                className={
                                  this.state.select._id === item._id
                                    ? "modalLisRow b-none active"
                                    : "modalLisRow b-none"
                                }
                                onClick={() => this.handleSelect(item)}
                              >
                                <div className="grid-1 td bb-0">{item.caption}</div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="modalPrintParams">
                        <div className="modalPrintTitle">{this.state.select.description}</div>
                        <div className="modalPrintParam">
                          {getSource(this.state.select, ["_data", "filters"], []).map((item, i) => {
                            const type = getSource(item, ["type"], "");
                            const name = getSource(item, ["name"], "");
                            const value = getSource(item, ["value"], "");
                            if (type === "list") {
                              return (
                                <div key={i} className="form-group row mb-2">
                                  <label className="col-sm-4 col-form-label">{getSource(item, ["caption"], "")}</label>
                                  <div className="col-sm-8 input-group input-group-sm mb-1">
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="nope"
                                      placeholder="Recipient's username"
                                    />
                                    <div className="input-group-append">
                                      <span className="input-group-text">
                                        <i className="fa fa-ellipsis-h"></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            } else if (type === "list_type") {
                              return (
                                <div key={i} className="form-group row mb-2">
                                  <label className="col-sm-4 col-form-label">{getSource(item, ["caption"], "")}</label>
                                  <div className="col-sm-8 input-group mb-1">
                                    <Type
                                      className="form-control"
                                      classNameBtn="input-group-text sp"
                                      placeholder="Tipo"
                                      project_id={this.props.project_id}
                                      _class={value}
                                      _state="0"
                                      title="Tipo de operación"
                                      select={value}
                                      onChange={this.handleChangeType}
                                    />
                                  </div>
                                </div>
                              );
                            } else if (type === "type_actividad") {
                              return (
                                <div key={i} className="form-group row mb-2">
                                  <label className="col-sm-4 col-form-label">{getSource(item, ["caption"], "")}</label>
                                  <div className="col-sm-8 input-group mb-1">
                                    <TpActivity
                                      className="form-control"
                                      classNameBtn="input-group-text sp"
                                      placeholder="Actividad"
                                      project_id={this.props.project_id}
                                      _class="KEEP_OPERATIONS_TYPE"
                                      _state="0"
                                      select={value}
                                      onChange={this.handleChangeTpActivity}
                                    />
                                  </div>
                                </div>
                              );
                            } else if (type === "date") {
                              return (
                                <div key={i} className="form-group row mb-2">
                                  <label className="col-sm-4 col-form-label">{getSource(item, ["caption"], "")}</label>
                                  <div className="col-sm-8 input-group-sm mb-1">
                                    <DateTime
                                      type="text"
                                      className="form-control form-control-sm"
                                      placeholder="Recipient's username"
                                      timeFormat="hh:mm aa"
                                      timeIntervals={5}
                                      _state="0"
                                      timeCaption="Hora"
                                      dateFormat="MMMM d, yyyy h:mm aa"
                                      name={name}
                                      value={value}
                                      onChange={this.handleChangeDate}
                                    />
                                  </div>
                                </div>
                              );
                            } else if (type === "datetime") {
                              return (
                                <div key={i} className="form-group row mb-2">
                                  <label className="col-sm-4 col-form-label">{getSource(item, ["caption"], "")}</label>
                                  <div className="col-sm-8 input-group-sm mb-1">
                                    <DateTime
                                      type="text"
                                      className="form-control form-control-sm"
                                      placeholder="Recipient's username"
                                      showTimeSelect
                                      timeFormat="hh:mm aa"
                                      timeIntervals={5}
                                      _state="0"
                                      timeCaption="Hora"
                                      dateFormat="MMMM d, yyyy h:mm aa"
                                      name={name}
                                      value={value}
                                      onChange={this.handleChangeDateTime}
                                    />
                                  </div>
                                </div>
                              );
                            } else {
                              return <div key={i} className="form-group row mb-2"></div>;
                            }
                          })}
                        </div>
                        <div className={this.state.prints.length === 0 ? "modalPrintDescription" : "d-none"}>
                          ¡No hay impresiones asignadas!
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="float-left">
                      <button
                        type="button"
                        className={
                          getSource(this.state.select, ["_data", "url_xls"], "") === ""
                            ? "d-none"
                            : "btn btn-outline-success btn-sm"
                        }
                        onClick={this.handleExport}
                      >
                        Exportar
                      </button>
                    </div>
                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={this.handleClose}>
                      Cerrar
                    </button>
                    <button
                      type="button"
                      className={
                        getSource(this.state.select, ["_data", "url_print"], "") === ""
                          ? "d-none"
                          : "btn btn-primary btn-sm"
                      }
                      disabled={this.state._state !== "0"}
                      onClick={this.handlePrint}
                    >
                      Imprimir
                    </button>
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    _class: state.sistem.folder._class,
    _view: state.sistem.folder._view || "",
    user_id: state.sistem.profile._id || "-1",
  };
}

export default connect(mapStateToProps)(ModalPrint);
