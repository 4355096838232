import React from "react";
import ReactDOM from "react-dom";
import "../styles/modal.scss";
import { showModal, hideModal, setFocus, getDateFormat, getData } from "../components/utilities";
import ModalHeader from "../components/modalHeader";
import { Api as System } from "../services/system";
import { Button } from "../components/inputs";
import { connect } from "react-redux";
import Td from "../components/td";

class ModalAlerts extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme();
    this.state = {
      _id: "__ModalAlerts",
      title: "Alertas",
      show: false,
      old: data,
      data: data,
      change: false,
      printParams: {
        _class: data._class,
        single: true,
      },
    };
  }

  handleScheme = () => {
    return [];
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
    this.handleHide();
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    if (this.props.alerts) {
      this.setState({
        show: true,
        change: false,
      });
    }
  };

  handleClose = () => {
    if (this.state.change) {
      this.handleOk();
    } else {
      this.handleHide();
    }
  };

  handleOk = (e) => {
    this.handleHide();
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      change: e.change,
      printParams: {
        project_id: e.data.project_id,
        _class: e.data._class,
        single: true,
      },
    });
    showModal(this.state._id);
    setFocus(`${this.state._id}_caption`, e.focusInt);
  };

  handleUpdate = (e) => {
    if (!this.state.change) {
      this.handleSetData({ data: e, change: false, focusInt: false });
    }
  };

  handleData = (focusInt) => {
    System.alerts(this.props._id).then((result) => {
      this.handleSetData({ data: result.data, change: false, focusInt });
    });
  };

  handleScroll = (e) => {
    const h = e.target.scrollTop + e.target.clientHeight + 1;
    if (e.target.id === `${this.state._id}_scroll` && h >= e.target.scrollHeight) {
      this.handleData(false);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        this.handleData(true);
      }
    }
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} disabled={!this.props.alerts} onClick={this.handleShow}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} disabled={!this.props.alerts} onClick={this.handleShow}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <ModalHeader title={this.state.title} handleClose={this.handleClose} />
                  <div className="modal-body">
                    <div className="modal-box">
                      <div className="row">
                        <div className="col-12">
                          <div className="suport-group mb-3">
                            <Td className="suport-head" cols="115px auto">
                              <div className="suport-td">Fecha</div>
                              <div className="suport-td">Alerta</div>
                            </Td>
                            <div
                              className="suport-scroll small"
                              id={`${this.state._id}_scroll`}
                              onScroll={this.handleScroll}
                            >
                              {this.state.data.map((item, i) => {
                                return (
                                  <Td key={i} className="suport-item" cols="25px 90px auto">
                                    <div className="suport-td t-c">{i + 1}</div>
                                    <div className="suport-td t-c">
                                      {getDateFormat(item, "date_of", "MMM d, yyyy", "")}
                                    </div>
                                    <div className="suport-td">{getData(item, "txt", "")}</div>
                                  </Td>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Button className="btn btn-primary btn-sm" onClick={this.handleOk}>
                      Aceptar
                    </Button>
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    _view: state.sistem.folder._view || "",
    view_rows: state.sistem.view_rows,
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ModalAlerts);
