import React from "react";
import { menuToogle } from "../components/utilities";
import "../styles/sidebar.scss";
import MenuFolders from "./menuFolders";
import MenuProject from "./menuProject";
import MenuTools from "./menuTools";

function SideBar(props) {
  return (
    <React.Fragment>
      <div id="__sidebarMenu" className="sidebarMenu slide-left">
        <div className="sidebar bg-light">
          <div className="sidebar-top">
            <MenuProject />
          </div>
          <div className="sidebar-list">
            <MenuFolders />
          </div>
          <div className="sidebar-bottom p-0">
            <MenuTools />
          </div>
        </div>
      </div>
      <div id="__topMenu" className="topMenu">
        <nav id="__sidebarTopMenu" className="navbar navbar-dark bg-dark">
          <button className="navbar-toggler" type="button" onClick={menuToogle}>
            <span className="navbar-toggler-icon"></span>
          </button>
        </nav>
      </div>
    </React.Fragment>
  );
}

export default SideBar;
