import React from "react";
import "../styles/document.scss";
import { formatDate, genId, getSplit, Loading, ShowAlert } from "../components/utilities";
import { Api as Keep } from "../services/keep";

class FindReport extends React.Component {
  constructor(props) {
    super(props);
    Loading();
    this.title = "Integraciones";
    const project_id = getSplit(this.props.location, ['pathname'], '', '/', 'n');
    const data = this.handleScheme(project_id);
    this.state = {
      project_id: project_id,
      code: '',
      data: data,
    };
  }

  handleScheme = (project_id) => {
    const now = new Date();
    return {
      date_of: formatDate(now, 'yyyy-MM-dd HH:mm:ss'),
      project_id: project_id,
      _class: "SUPORT-KEEP-REPORT",
      _state: "0",
      _id: genId("-1"),
      code: "",
      caption: "Reporte",
      description: "",
      observations: "",
      type_tp: "KEEP_REPORT_TIPO02",
      entry_tp: "KEEP_REPORT_ENTRADA07",
      priority_tp: "SUPORT_PRIORITY02",
      city_id: '68081',
      area_id: '-1',
      rural: false,
      date_int: formatDate(now, 'yyyy-MM-dd HH:mm:ss'),
      date_end: null,
      assets: [],
      odts: [],
      chat_id: "-1",
      _data: {
        project: {},
        class_name: "",
        asunto: "",
        contact: "",
        document_tp: "-1",
        document: "",
        document_type: "",
        telephone: "",
        mobile: "",
        email: "",
        sitio: "",
        area: '',
        city: 'Barrancabermeja',
        type: '',
        entry: 'Web',
        priority: 'Normal',
        odts_text: '',
        username: "",
      },
      next_id: "-1",
      prior_id: "-1",
      _v: 1,
    };
  };

  handleChangeCode = (e) => {
    this.setState({
      code: e.target.value,
      change: true,
    });
  };

  handleOk = () => {
    const code = this.state.code;
    if (code === '') {
      ShowAlert('Código requerido!');
    } else {
      const project_id = this.state.project_id;
      Keep.findReport(code, project_id).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.setState({
            data: data
          })
        }
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="flex-shrink-0">
          <div className="signinColumns">
            <div className="row">
              <div className="col-sm-3 col-md-3"></div>
              <div className="col-sm-6 col-md-6 mb-4">
                <div className="form-group mb-2">
                  <label htmlFor="input1" className="mb-1">Número de reporte</label>
                  <input
                    type="text"
                    className="form-control"
                    id="input1"
                    aria-describedby="help1"
                    placeholder="Número"
                    name="code"
                    value={this.state.code}
                    onChange={this.handleChangeCode}
                  />
                  <small id="help1" className="form-text text-muted">Número de reporte a buscar.</small>
                </div>
                <button type="submit" className="btn btn-primary mb-4" onClick={this.handleOk}>Consultar</button>
                <div className="form-group mb-2">
                  <label htmlFor="input2" className="mb-1">Código</label>
                  <label
                    className="form-control"
                    id="input2"
                  >{this.state.data.code}</label>
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="input3" className='mb-1'>Tipo</label>
                  <label
                    className="form-control"
                    id="input3"
                  >{this.state.data._data.type}</label>
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="input3" className='mb-1'>Fecha atención</label>
                  <label
                    className="form-control"
                    id="input3"
                  >{this.state.data.date_end === null ? '' :formatDate(this.state.data.date_end, 'MMM d, yyyy hh:mm a')}</label>
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="input3" className='mb-1'>Ordenes de trabajo</label>
                  <label
                    className="form-control"
                    id="input3"
                  >{this.state.data._data.odts_text}</label>
                </div>
              </div>
              <div className="col-sm-3 col-md-3"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FindReport;
