import React from "react";
import ReactDOM from "react-dom";
import "../../styles/modal.scss";
import "../../styles/suport.scss";
import {
  showModal,
  hideModal,
  ShowAlert,
  formatDateTime,
  getValue,
  genId,
  getDateTime,
  getData,
  setFocus,
} from "../../components/utilities";
import { AssetClass } from "./listClass";
import { AssetType } from "./listAssetTypes";
import { Count, Input } from "../../components/inputs";
import { AssetReference } from "./listAssetReferences";
import ModalHeader from "../../components/modalHeader";
import ModalClass from "./modalClass";
import ModalType from "./modalType";
import ModalFooter from "../../components/modalFooter";
import ModalReference from "../warehouse/modalReference";
import { Api as Asset } from "../../services/asset";
import { connect } from "react-redux";

class ModalAssetNew extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme();
    this.state = {
      form: "__ModalNewAsset",
      _id: "__ModalNewAsset",
      title: "Nuevo equipo",
      show: false,
      old: data,
      data: data,
      change: false,
    };
  }

  handleScheme = (data) => {
    const now = new Date();
    const main_id = getValue(data, "main_id", "-1");
    return {
      project_id: this.props.project_id,
      _class: "ASSETS",
      _state: "0",
      _id: genId("-1"),
      description: "",
      date_of: now,
      equipment: this.props.equipment,
      class_id: getValue(data, "class_id", "-1"),
      type_id: getValue(data, "type_id", "-1"),
      reference_id: getValue(data, "reference_id", "-1"),
      code: "",
      serie: "",
      rfidcode: "",
      main_id: main_id,
      country_id: "-1",
      city_id: "-1",
      area_id: "-1",
      address: "",
      rural: false,
      install: main_id === "-1" ? false : true,
      failure: false,
      running: getValue(data, "running", false),
      reported: false,
      alerts: [],
      class: getValue(data, "class", ""),
      type: getValue(data, "type", ""),
      reference: getValue(data, "reference", ""),
      caption: "",
      country: "",
      city: "",
      area: "",
      address_short: "",
      address_small: "",
      address_long: "",
      preventive: false,
      low: false,
      date_install: null,
      date_failure: null,
      date_running: null,
      date_reported: null,
      date_low: null,
      date_factory: getDateTime(data, "date_factory", now),
      quantity: 1,
      unity: getValue(data, "unity", "und"),
      cost: 0,
      owner_id: getValue(data, "owner_id", "-1"),
      atribs: {},
      components: [],
      connections: [],
      it: 0,
      tti: 0,
      rt: 0,
      ft: 0,
      tto: 0,
      tto_now: 0,
      ttr: 0,
      mtbf: 0,
      mttrt: 0,
      availability: 0,
      mtbf_direction: "",
      mttrt_direction: "",
      availability_direction: "",
      _data: {
        owner: getData(data, "owner", ""),
        username: "",
      },
      _v: 0,
      create: getValue(data, "create", false),
    };
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeClase = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        class_id: getValue(e, "_id", "-1"),
        class: getValue(e, "caption", ""),
        scheme: getData(e, "scheme_asset", []),
      },
      change: true,
    });
  };

  handleChangeType = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        type_id: getValue(e, "_id", "-1"),
        type: getValue(e, "caption", ""),
        components: getValue(e, "components", []),
        connections: getValue(e, "connections", []),
      },
      change: true,
    });
  };

  handleChangeReference = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        reference_id: getValue(e, "_id", "-1"),
        reference: getValue(e, "caption", ""),
      },
      change: true,
    });
  };

  handleChangeQuantity = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.name]: e.value,
      },
      change: true,
    });
  };

  handleExecute = (e) => {
    this.handleHide();
    if (typeof this.props.onSetData === "function") {
      this.props.onSetData(e);
    } else if (typeof this.props.onChange === "function") {
      this.props.onChange(e);
    }
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    const form_id = `${this.state.form}${Date.now()}`;
    this.setState({ _id: form_id, show: true, change: false });
  };

  handleClose = (e) => {
    if (this.state.change) {
      this.setState({ data: this.state.old, change: false });
    }
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.change) {
      const params = this.state.data;
      const id = params._id;
      const create = getValue(params, "create", false);
      if (create) {
        const now = new Date();
        params.date_of = formatDateTime(now, "yyyy-MM-dd HH:mm:ss");
        params.document_id = getValue(this.props, "document_id", "-1");
        params.document = getValue(this.props, "document", "");
        Asset.setAsset(id, params).then((result) => {
          const data = result.data;
          if (result.msg === "") {
            this.handleExecute(data);
          } else {
            const msg = getValue(data, "msg", "");
            setFocus(`${this.state._id}_caption`);
            ShowAlert(`${msg}`);
          }
        });
      } else {
        Asset.valid(params.project_id, params.main_id, params.class_id, params.type_id, params.code, id).then(
          (result) => {
            if (result.msg === "") {
              const data = result.data;
              const valid = getValue(data, "valid", false);
              if (valid) {
                this.handleExecute(params);
              } else {
                const msg = getValue(data, "msg", "");
                setFocus(`${this.state._id}_caption`);
                ShowAlert(`${msg}`);
              }
            } else {
              setFocus(`${this.state._id}_caption`);
            }
          }
        );
      }
    } else {
      this.handleHide();
    }
  };

  handleData = () => {
    let data = this.handleScheme(this.props.data);
    this.setState({ old: data, data: data, change: false });
    showModal(this.state._id);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        this.handleData();
      }
    }
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} disabled={!(this.props._state === "0")} onClick={this.handleShow}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} disabled={!(this.props._state === "0")} onClick={this.handleShow}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog xs" role="dialog">
                <div className="modal-content">
                  <ModalHeader title={this.state.title} handleClose={this.handleClose} />
                  <div className="modal-body">
                    <div className="modal-box">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group input-group-sm">
                            <label>
                              <ModalClass
                                className="link"
                                project_id={this.state.data.project_id}
                                _state={this.state.data._state}
                                _id={this.state.data.class_id}
                                isFind={true}
                                onSetData={this.handleChangeClase}
                              >
                                Clase
                              </ModalClass>
                            </label>
                            <AssetClass
                              project_id={this.state.data.project_id}
                              _state={this.state.data._state}
                              equipment={this.state.data.equipment}
                              select={{
                                _id: this.state.data.class_id,
                                caption: this.state.data.class,
                              }}
                              onChange={this.handleChangeClase}
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>
                              <ModalType
                                className="link"
                                project_id={this.state.data.project_id}
                                _state={this.state.data._state}
                                _id={this.state.data.type_id}
                                isFind={true}
                                onSetData={this.handleChangeType}
                              >
                                Tipo
                              </ModalType>
                            </label>
                            <AssetType
                              project_id={this.state.data.project_id}
                              class_id={this.state.data.class_id}
                              _state={this.state.data._state}
                              select={{
                                _id: this.state.data.type_id,
                                caption: this.state.data.type,
                              }}
                              onChange={this.handleChangeType}
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>
                              <ModalReference
                                className="link"
                                project_id={this.state.data.project_id}
                                _state={this.state.data._state}
                                _id={this.state.data.reference_id}
                                isFind={true}
                                onSetData={this.handleChangeReference}
                              >
                                Referencia
                              </ModalReference>
                            </label>
                            <AssetReference
                              type="text"
                              className="form-control"
                              placeholder="Referencia"
                              project_id={this.state.data.project_id}
                              class_id={this.state.data.class_id}
                              _state={this.state.data._state}
                              select={{
                                _id: this.state.data.reference_id,
                                caption: this.state.data.reference,
                              }}
                              onSetData={this.handleChangeReference}
                            />
                          </div>
                          <div className="form-row input-group-sm mb-0">
                            <div className="form-group col-md-5 input-group-sm mb-0">
                              <label>Código</label>
                              <Input
                                className="form-control"
                                project_id={this.state.data.project_id}
                                _state={this.state.data._state}
                                name="code"
                                value={this.state.data.code}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="form-group col-md-7 input-group-sm mb-0">
                              <label>Cantidad</label>
                              <Count
                                className="input-group input-group-sm"
                                classNameUnity="input-group-text unity-sm"
                                _state={this.state.data._state}
                                name="quantity"
                                unity={this.state.data.unity}
                                value={this.state.data.quantity}
                                increase={1}
                                precision={2}
                                handleValue={this.handleChangeQuantity}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooter
                      title="Eliminar equipo?"
                      description="¿Desea eliminar el equipo? este cambio no podrá revertirse."
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    user_id: state.sistem.profile._id || "-1",
    _view: state.sistem.folder._view || "",
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ModalAssetNew);
