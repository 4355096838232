import React, { useState } from "react";
import { getValue } from "./utilities";
import Td from "./td";

function ModalList(props) {
  const _state = props._state || "0";
  const [selectedId, setSelect] = useState(props.selectedId || "-1");
  const caption = props.caption || "caption";
  const field = props.field || "_id";
  const list = props.list || [];
  const checkList = props.checkList || false;
  const filterId = props.filterId || "_id";
  const select = props.select || [];
  const handleScrolling = (e) => {
    const h = e.target.scrollTop + e.target.clientHeight;
    if (e.target.id === props.id && h === e.target.scrollHeight) {
      props.getData(true);
    }
  };
  const handleClick = (e) => {
    setSelect(e);
    if (typeof props.onClick === "function") {
      props.onClick(e);
    }
  };
  const handleCheck = (e) => {
    if (typeof props.onSelect === "function") {
      props.onSelect(e);
    }
  };
  const handleSelect = (e) => {
    const selectedId = getValue(e, field, "-1");
    setSelect(selectedId);
    if (typeof props.onSelect === "function") {
      props.onSelect(e);
    }
  };

  if (checkList) {
    return (
      <React.Fragment>
        <div className="suport-group">
          <div className="suport-scroll" onScroll={handleScrolling} id={props.id}>
            {list.map((item, i) => {
              const item_project_id = getValue(item, "project_id", "");
              const item_state = getValue(item, "_state", "0");
              const item_field = getValue(item, field, "-1");
              const item_delete = getValue(item, "delete", false);
              let className =
                item_field === ""
                  ? "d-none"
                  : item_field === "-1"
                  ? "d-none"
                  : item_delete === true
                  ? "d-none"
                  : _state === '1' && item_state !== '1'
                  ? "d-none"
                  : _state === '2' && item_state !== '2'
                  ? "d-none"
                  : "suport-item";
              if (item_project_id === "-1") {
                className = `${className} system`
              } else if (item_state === '-1') {
                className = `${className} system`
              } else if (item_state === '2') {
                className = `${className} cancel`
              }
              return (
                <React.Fragment key={i}>
                  <Td
                    className={`${className}`}
                    selectedId={selectedId}
                    _id={item_field}
                    cols="25px auto"
                    onClick={handleClick}
                  >
                    <div className="suport-td form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="chk"
                        checked={select.findIndex((sel) => sel[filterId] === item._id) > -1}
                        onChange={() => handleCheck(item)}
                      />
                    </div>
                    <div className="suport-td">
                      <props.Modal className="link" project_id={props.project_id} _state={props._state} data={item} onSetData={props.onSetData}>
                        {item[caption]}
                      </props.Modal>
                    </div>
                  </Td>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className="modalListBody" onScroll={handleScrolling} id={props.id}>
          {list.map((item, i) => {
            const item_project_id = getValue(item, "project_id", "");
            const item_state = getValue(item, "_state", "0");
            const item_field = getValue(item, field, "-1");
            const item_delete = getValue(item, "delete", false);
            let className =
                item_field === ""
                  ? "d-none"
                  : item_field === "-1"
                  ? "d-none"
                  : item_delete === true
                  ? "d-none"
                  : _state === '1' && item_state !== '1'
                  ? "d-none"
                  : _state === '2' && item_state !== '2'
                  ? "d-none"
                  : "modalLisRow";
              if (item_project_id === "-1") {
                className = `${className} system`
              } else if (item_state === '-1') {
                className = `${className} system`
              } else if (item_state === '2') {
                className = `${className} cancel`
              }
            return (
              <React.Fragment key={i}>
                <div
                  className={item_field === selectedId ? `${className} active`: `${className}`}
                  onClick={() => handleSelect(item)}
                >
                  <div className={getValue(item, "project_id", "") === "-1" ? "grid-1 td system" : "grid-1 td"}>{item[caption]}</div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </React.Fragment>
    );
  }
}

export default ModalList;
