import React from "react";
import ReactDOM from "react-dom";
import "../../styles/modal.scss";
import { showModal, hideModal, Subscribe, UnSubscribe, compare, clone, getValue, updateList } from "../../components/utilities";
import ModalHeaderList from "../../components/modalHeaderList";
import ModalSearchMenu from "../../components/modalSearchMenu";
import ModalFooterList from "../../components/modalFooterList";
import ModalOdt from "./modalOdt";
import ModalList from "../../components/modalList";
import { Api as Keep } from "../../services/keep";

class CheckListOdts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "__CheckListOdts",
      title: "ODTs",
      name: "SUPORT-KEEP-ODT",
      show: false,
      rows: 30,
      data: {
        list: [],
        search: "",
        state: getValue(props, "state", "0"),
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      select: [],
      change: false,
      update: {}
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleSetData = (e) => {
    this.handleUpdate(e);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeState = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        search: "",
        state: e,
      },
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
    this.handleHide();
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    const _state = this.props._state || "0";
    if (_state === "0") {
      this.setState({
        data: {
          ...this.state.data,
          search: "",
        },
        show: true,
      });
    }
  };

  handleClose = () => {
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.change) {
      this.handleExecute(this.state.select);
    } else {
      this.handleHide();
    }
  };

  handleData = (scroll) => {
    const project_id = this.props.project_id;
    const state = this.state.data.state;
    const search = this.state.data.search;
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Keep.listODTs(project_id, state, search, page, rows, this.state.data.list).then((result) => {
        const data = result.data;
        this.setState({
          data: {
            ...this.state.data,
            list: data.list,
            state: data.state,
            search: data.search,
            page: data.page,
            rows: data.rows,
            int: data.int,
            end: data.end,
            count: data.count,
            all: data.all,
          },
        });
      });
    } else {
      Keep.listODTs(project_id, state, search, 1, rows, []).then((result) => {
        const data = result.data;
        this.setState({
          data: {
            ...this.state.data,
            list: data.list,
            state: data.state,
            search: data.search,
            page: data.page,
            rows: data.rows,
            int: data.int,
            end: data.end,
            count: data.count,
            all: data.all,
          },
          change: false,
        });
      });
    }
  };

  handleInit = () => {
    const select = clone(this.props.select);
    this.setState({ select: select, change: false });
    this.handleData(false);
  };

  handleSearch = () => {
    this.handleData(false);
  };

  handleSelect = (e) => {
    let select = this.state.select;
    const index = select.findIndex((select) => select.odt_id === e._id);
    if (index === -1) {
      select.push({ odt_id: e._id, code: e.code, caption: e.caption });
    } else {
      select.splice(index, 1);
    }
    this.setState({ select: select, change: true });
  };

  handleUpdate = (e) => {
    if (compare(this.state.update, e)) {
      const data = updateList(this.state.data, e);
      this.setState({ data: data, change: true, update: e });
    }
  };

  componentDidMount() {
    Subscribe(`${this.state.name}/${this.props.project_id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        this.handleInit();
        showModal(this.state._id);
      } else if (prevState.data.state !== this.state.data.state) {
        this.handleData(false);
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.name}/${this.props.project_id}`, (event) => this.eventSetData(event));
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog __modalSingleList" role="document">
                <div className="modal-content">
                  <ModalHeaderList
                    title={this.state.title}
                    handleClose={this.handleClose}
                    state={this.state.data.state}
                    rows={this.state.data.end}
                    all={this.state.data.all}
                  />
                  <div className="modal-body">
                    <div className="modalList single">
                      <div className="modalListFind">
                        <ModalSearchMenu
                          name="search"
                          value={this.state.data.search}
                          state={this.state.data.state}
                          onChange={this.handleChange}
                          onSearch={this.handleSearch}
                          onState={this.handleChangeState}
                          states={[
                            { _state: "0", caption: "Activas" },
                            { _state: "1", caption: "Terminadas" },
                          ]}
                        />
                      </div>
                      <div className="modalListList">
                        <ModalList
                          id={`${this.state._id}_scroll`}
                          project_id={this.props.project_id}
                          _state={this.state.data.state}
                          list={this.state.data.list}
                          select={this.state.select}
                          getData={this.handleData}
                          onSelect={this.handleSelect}
                          onSetData={this.handleSetData}
                          filterId="odt_id"
                          checkList={true}
                          Modal={ModalOdt}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooterList
                      project_id={this.props.project_id}
                      _state={this.state.data._state}
                      onSetData={this.handleSetData}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                      Modal={ModalOdt}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function Odts(props) {
  const list = props.odts || [];
  return (
    <React.Fragment>
      <div className="input-group input-group-sm">
        <div className={props.className || "form-control sp ctr-sm t-m"}>
          {list
            .filter((item) => item.odt_id !== "-1")
            .map((item, i) => {
              return (
                <ModalOdt className="span link" key={i} project_id={props.project_id} isFind={true} _id={item.odt_id}>
                  {i > 0 ? `, ${item.odt}` : item.odt}
                </ModalOdt>
              );
            })}
        </div>
        <div className="input-group-append">
          <CheckListOdts
            className={props.classNameBtn}
            project_id={props.project_id}
            _state={props._state}
            select={list}
            onSetData={props.onChange}
            disabled={props._state !== "0"}
          >
            <i className="fa fa-ellipsis-h"></i>
          </CheckListOdts>
        </div>
      </div>
    </React.Fragment>
  );
}

export { CheckListOdts, Odts };
