import React from "react";
import "../../styles/view.scss";
import "../../styles/suport.scss";
import { Redirect } from "react-router-dom";
import { Loading } from "../../components/utilities";
import TabAreaEquipments from "../../widget/assets/tabAreaEquipments";
import TabClassEquipments from "../../widget/assets/tabClassEquipments";
import TabDocuments from "../../widget/keep/tabDocuments";
import { connect } from "react-redux";

class ViewKeep extends React.Component {
  constructor(props) {
    super(props);
    Loading();
    this.state = {
      _id: "__ViewKeep",
      printParams: {},
      change: false,
    };
  }

  render() {
    if (this.props._view !== this.props.location.pathname) {
      return <Redirect to={this.props._view} push={true} />;
    }
    return (
      <React.Fragment>
        <div className={this.state._id}>
          <div className="viewcontainer">
            <nav className="navbar view-navbar">
              <div className="navbar-brand">Tablero - {this.props.folder.caption}</div>
            </nav>
            <div className="view-scrolling">
              <div className="row mb-3">
                <div className="col-lg-8">
                  <TabClassEquipments history={this.props.history} _view={this.state._view} />
                  <TabAreaEquipments history={this.props.history} _view={this.state._view} />
                </div>
                <div className="col-lg-4">
                  <TabDocuments />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    folder: state.sistem.folder,
    _view: state.sistem.folder._view || "",
    view_rows: state.sistem.view_rows,
  };
}

export default connect(mapStateToProps)(ViewKeep);
