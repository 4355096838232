import React from "react";
import { Actions as Sistem } from "../services/actions/sistem";

function ItemProject(props) {
  return (
    <React.Fragment>
      <div
        className={props.project._id === props.select_id ? "dropdownProject active" : "dropdownProject"}
        onClick={() => Sistem.selProject(props.project)}
      >
        <div className="dropdownProjectLogo">
          <img className="avatar" src={props.project.avatar || "/logo.svg"} alt="" />
        </div>
        <div className="dropdownProjectInfo">
          <div className="name">{props.project.caption || "Nombre usuario"}</div>
          <div className="profile">{props.project.profile || "Perfil"}</div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ItemProject;
