import React from "react";
import ReactDOM from "react-dom";
import "../../styles/modal.scss";
import { showModal, hideModal, ShowInfo, msg, genId, getValue, formId, getSource } from "../../components/utilities";
import { Name, SelectType, Textarea } from "../../components/inputs";
import ModalHeader from "../../components/modalHeader";
import ModalFooter from "../../components/modalFooter";
import ComponentClassReferences from "./components/componentClassReferences";
import ComponentClassTypes from "./components/componentClassTypes";
import ComponentScheme from "./components/componentScheme";
import { Api as Asset } from "../../services/asset";
import { connect } from "react-redux";

class ModalClass extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme();
    this.state = {
      form: "__ModalClass",
      _id: "__ModalClass",
      title: "Clase",
      rows: 15,
      show: false,
      old: data,
      data: data,
      change: false,
      tabs: [],
      printParams: {
        project_id: "-1",
        _class: "CLASS",
        _id: "-1",
        single: true,
      },
    };
  }

  handleScheme = (e) => {
    return {
      project_id: this.props.project_id,
      _class: "JS_CLASS",
      _state: "0",
      _id: genId("-1"),
      name: "",
      caption: "",
      description: "",
      unity: "",
      equipment: false,
      install_and_running: false,
      control_tp: "CONTROL_TP00",
      _data: {
        scheme_type: [],
        scheme_asset: [],
      },
      username: "",
    };
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeChk = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: Boolean(e.target.checked),
      },
      change: true,
    });
  };

  handleChangeScheme = (e) => {
    let data = this.state.data;
    let _data = data._data;
    let scheme = _data.scheme_type || [];
    if (!e.type) {
      scheme = _data.scheme_asset || [];
    }
    const index = scheme.findIndex((atrib) => atrib["name"] === e.data["name"]);
    if (index === -1) {
      if (e.action === "set") {
        scheme.push(e.data);
      }
    } else {
      if (e.action === "set") {
        scheme[index] = e.data;
      } else {
        scheme.splice(index, 1);
      }
    }
    if (e.type) {
      _data.scheme_type = scheme;
    } else {
      _data.scheme_asset = scheme;
    }
    data._data = _data;
    this.setState({ data: data, change: true });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
  };

  handleHide = () => {
    const _state = this.props._state || "0";
    if (typeof this.props.onHide === "function" && _state === "0") {
      this.props.onHide(this.state.data);
    }
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    if (this.props.isFind) {
      const _id = getValue(this.props, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    } else if (this.props.isNew) {
      const form_id = `${this.state.form}${Date.now()}`;
      this.setState({ _id: form_id, show: true, change: false });
    } else {
      const _id = getValue(this.props.data, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    }
  };

  handleClose = (e) => {
    if (this.state.change && e) {
      this.handleOk();
    } else {
      this.handleHide();
    }
  };

  handleOk = () => {
    if (this.state.change) {
      const id = this.state.data._id;
      const params = this.state.data;
      Asset.setClase(id, params).then((result) => {
        if (result.msg === "") {
          let data = result.data;
          this.handleSetData({ data, isNew: false, change: false, focusInt: false });
          this.handleExecute(result.data);
        }
      });
    } else {
      this.handleHide();
    }
  };

  handleFinish = (e) => {
    if (e) {
      const id = this.state.data._id;
      Asset.stateClase(id, "1").then((result) => {
        if (result.msg === "") {
          this.handleExecute(result.data);
          this.handleHide();
        }
      });
    }
  };

  handleRestore = (e) => {
    if (e) {
      const id = this.state.data._id;
      Asset.stateClase(id, "0").then((result) => {
        if (result.msg === "") {
          this.handleExecute(result.data);
        }
      });
    }
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      isNew: e.isNew,
      change: e.change,
      tabs: [],
      printParams: {
        project_id: e.data.project_id,
        _class: e.data._class,
        _id: e.data._id,
        single: true,
      },
    });
    showModal(this.state._id);
  };

  handleData = () => {
    const _id = getSource(this.props.data, ["_id"], "-1");
    if (_id === "-1") {
      this.handleSetData({ data: this.props.data, isNew: false, change: false });
    } else {
      this.handleFind(_id);
    }
  };

  handleNew = () => {
    const data = this.handleScheme();
    this.handleSetData({ data, isNew: true, change: true });
  };

  handleFind = (id) => {
    if (this.state.change) {
      ShowInfo(msg("MSG-CHANGE"));
    } else if (id !== "-1") {
      Asset.class(id, this.props.project_id).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false });
        }
      });
    }
  };

  handleTab = (e) => {
    const tabs = this.state.tabs;
    if (!tabs[e]) {
      tabs[e] = true;
      this.setState({ tabs: tabs });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        if (this.props.isNew) {
          this.handleNew();
        } else if (this.props.isFind) {
          this.handleFind(this.props._id);
        } else {
          this.handleData();
        }
      }
    }
  }

  componentWillUnmount() {
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog s __modalObject" role="dialog">
                <div className="modal-content">
                  <ModalHeader title={this.state.title} handleClose={this.handleClose} />
                  <div className="modal-body-tabs" style={{ height: "421px" }}>
                    <nav>
                      <div className="nav nav-tabs" role="tablist">
                        <a
                          className="nav-item nav-link active"
                          id={`${this.state._id}-nav-1-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-1`}
                          role="tab"
                          aria-selected="true"
                        >
                          General
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-2-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-2`}
                          role="tab"
                          aria-selected="false"
                        >
                          Atributos
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-3-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-3`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(3)}
                        >
                          Tipos
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-3-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-3`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(4)}
                        >
                          Referencias
                        </a>
                      </div>
                    </nav>
                    <div className="tab-content modal not-scroll">
                      <div
                        className="tab-pane fade show active"
                        id={`${this.state._id}-nav-1`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-1-tab`}
                      >
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group input-group-sm">
                              <label>Nombre</label>
                              <Name
                                id={`${this.state._id}_caption`}
                                type="text"
                                className="form-control name"
                                placeholder="Nombre"
                                _state={this.state.data._state}
                                name="caption"
                                value={this.state.data.caption}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="form-group input-group-sm">
                              <label>Descripción</label>
                              <Textarea
                                className="form-control notresize"
                                placeholder="description"
                                rows={4}
                                _state={this.state.data._state}
                                name="description"
                                value={this.state.data.description}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="form-row input-group-sm">
                              <div className="form-group col-md-4 input-group-sm">
                                <label>Unidades</label>
                                <SelectType
                                  className="form-control"
                                  project_id={this.props.project_id}
                                  _class="UNITY"
                                  _state={this.state.data._state}
                                  name="unity"
                                  value={this.state.data.unity}
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div className="form-group col-md-8 input-group-sm">
                                <label>Control</label>
                                <SelectType
                                  className="form-control"
                                  project_id={this.props.project_id}
                                  _class="CONTROL_TP"
                                  _state={this.state.data._state}
                                  name="control_tp"
                                  value={this.state.data.control_tp}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <hr className="mb-2 mt-2" />
                            <div className="form-row input-group-sm">
                              <div className="custom-control custom-switch">
                                <input
                                  className="custom-control-input"
                                  type="checkbox"
                                  id={`${this.state._id}_Checkbox1`}
                                  disabled={this.props.isNew ? false : true}
                                  name="equipment"
                                  checked={this.state.data.equipment}
                                  onChange={this.handleChangeChk}
                                />
                                <label className="custom-control-label pt-px-2 ml-2 pl-2" htmlFor={`${this.state._id}_Checkbox1`}>
                                  {this.state.data.equipment ? "Es un componente" : "Es un equipo"}
                                </label>
                              </div>
                            </div>
                            <div className="form-row input-group-sm">
                              <div className="custom-control custom-switch">
                                <input
                                  className="custom-control-input"
                                  type="checkbox"
                                  id={`${this.state._id}_Checkbox1`}
                                  disabled={this.props.isNew ? false : true}
                                  name="install_and_running"
                                  checked={this.state.data.install_and_running}
                                  onChange={this.handleChangeChk}
                                />
                                <label className="custom-control-label pt-px-2 ml-2 pl-2" htmlFor={`${this.state._id}_Checkbox1`}>
                                  {this.state.data.install_and_running
                                    ? "Funcionamiento no depende de la instalación"
                                    : "Poner en funcionamiento al instalar"}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-2`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-2-tab`}
                      >
                        <ComponentScheme
                          project_id={this.props.project_id}
                          _state="0"
                          data={this.state.data._data}
                          onSetData={this.handleChangeScheme}
                          height="286px"
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-3`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-3-tab`}
                      >
                        <ComponentClassTypes
                          project_id={this.props.project_id}
                          _state="0"
                          class_id={this.state.data._id}
                          only={false}
                          height="286px"
                          show={this.state.tabs[3] || false}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-4`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-4-tab`}
                      >
                        <ComponentClassReferences
                          project_id={this.props.project_id}
                          _state="0"
                          class_id={this.state.data._id}
                          only={false}
                          height="286px"
                          show={this.state.tabs[4] || false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooter
                      single="Clase"
                      plural="la clase"
                      project_id={this.state.data.project_id}
                      _state="0"
                      change={this.state.change}
                      onFinish={this.handleFinish}
                      onRestore={this.handleRestore}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    project: state.sistem.project,
    user_id: state.sistem.profile._id || "-1",
    user_name: state.sistem.profile.caption || "",
    _view: state.sistem.folder._view || "",
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ModalClass);
