import React from "react";
import Td from "../../../components/td";
import { getSource, getValue } from "../../../components/utilities";
import ModalReference from "../modalReference";

function ComponentReferences(props) {
  const list = props.list || [];
  const _id = getValue(props.select, "_id", "");
  const _state = props._state || "0";
  const chk = props.chk || false;
  const handleScroll = (e) => {
    const h = e.target.scrollTop + e.target.clientHeight + 1;
    if (e.target.id === props.id && h >= e.target.scrollHeight) {
      props.getData(true);
    }
  };
  const handleSelect = (e) => {
    if (typeof props.onSelect === "function") {
      props.onSelect(e);
    }
  };
  const handleCheck = (e) => {
    e.item.chk = !e.item.chk;
    if (typeof props.onCkeck === "function") {
      props.onCkeck(e);
    }
  };

  if (chk) {
    return (
      <React.Fragment>
        <div className="suport-group">
          <Td className="suport-head" cols="105px auto">
            <div className="suport-td m">Código</div>
            <div className="suport-td m">Descripción</div>
          </Td>
          <div id={props.id} className="suport-scroll" onScroll={handleScroll} style={{ height: props.height || "280px" }}>
            {list.map((item, i) => {
              const item_id = getSource(item, ["_id"], "-1");
              const item_state = getSource(item, ["_state"], "0");
              const className = item_id === "-1"
                ? "d-none"
                : _state === '2' && item_state !== _state
                ? "d-none"
                : _state === '1' && item_state !== _state
                ? "d_none"
                : "suport-item";
              return (
                <React.Fragment key={i}>
                  <Td
                    className={className}
                    selectedId={_id}
                    _id={item._id}
                    cols="25px 80px auto"
                    onClick={() => handleSelect(item)}
                  >
                    <div className="suport-td form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="chk"
                        checked={item.chk}
                        onChange={() => handleCheck({ item, i })}
                      />
                    </div>
                    <div className="suport-td t-c">{item.code}</div>
                    <ModalReference
                      className="suport-td t-l link"
                      project_id={item.project_id}
                      _state={props._state}
                      data={item}
                      onSetData={props.onSetData}
                    >
                      <i className={item.project_id === item.manufacturer_id ? "fas fa-star sponsor" : "fas fa-star sponsor d-none"}></i>
                      {item.caption}
                    </ModalReference>
                  </Td>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className="suport-group">
          <Td className="suport-head" cols="80px auto">
            <div className="suport-td m">Código</div>
            <div className="suport-td m">Descripción</div>
          </Td>
          <div id={props.id} className="suport-scroll" onScroll={handleScroll} style={{ height: props.height || "280px" }}>
            {list.map((item, i) => {
              const item_id = getSource(item, ["_id"], "-1");
              const item_state = getSource(item, ["_state"], "0");
              const className = item_id === "-1"
                ? "d-none"
                : _state === '2' && item_state !== _state
                ? "d-none"
                : _state === '1' && item_state !== _state
                ? "d_none"
                : "suport-item";
              return (
                <React.Fragment key={i}>
                  <Td
                    className={className}
                    selectedId={_id}
                    _id={item._id}
                    cols="80px auto"
                    onClick={() => handleSelect(item)}
                  >
                    <div className="suport-td t-c">{item.code}</div>
                    <div className="suport-td t-l">
                      <i className={item.project_id === item.manufacturer_id ? "fas fa-star sponsor" : "fas fa-star sponsor d-none"}></i>
                      {item.caption}
                    </div>
                  </Td>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ComponentReferences;
