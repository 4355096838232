import React from "react";
import DateTime from "../../../components/dateTime";
import { Contact, Count, Image, Input } from "../../../components/inputs";
import Td from "../../../components/td";
import ModalContact from "../../modalContact";
import ModalReference from "../../warehouse/modalReference";
import { AssetReference } from "../listAssetReferences";
import { AssetType } from "../listAssetTypes";
import { AssetClass } from "../listClass";
import ModalClass from "../modalClass";
import ModalType from "../modalType";

function ComponentAsset(props) {
  const id = props.id || {};
  const data = props.data || {};

  return (
    <React.Fragment>
      <Td className="suport-row" cols="auto 140px">
        <div className="suport-td">
          <Td className="suport-row mb-1" cols="90px auto">
            <div className="suport-td caption">Código</div>
            <div className="suport-td component">
              <Input
                id={`${id}_caption`}
                type="text"
                className="form-control form-control-sm t-c"
                autoComplete="nope"
                placeholder="Código de identificación"
                _state={data._state}
                name="code"
                value={data.code}
                onChange={props.onChange}
              />
            </div>
          </Td>
          <Td className="suport-row mb-1" cols="90px auto">
            <div className="suport-td caption">Serie</div>
            <div className="suport-td component">
              <Input
                type="text"
                className="form-control form-control-sm t-c"
                autoComplete="nope"
                placeholder="Número de serie"
                _state={data._state}
                name="serie"
                value={data.serie}
                onChange={props.onChange}
              />
            </div>
          </Td>
          <Td className="suport-row mb-1" cols="90px auto">
            <div className="suport-td caption">Cantidad</div>
            <div className="suport-td component">
              <Count
                className="input-group input-group-sm"
                _state={data._state}
                name="quantity"
                unity={data.unity}
                value={data.quantity}
                increase={1}
                vertical={true}
                handleValue={props.onChangeQuantity}
              />
            </div>
          </Td>
          <Td className="suport-row mb-1" cols="90px auto">
            <div className="suport-td caption">Costo</div>
            <div className="suport-td component">
              <Count
                className="input-group input-group-sm"
                _state={data._state}
                name="cost"
                unity="$"
                value={data.cost}
                increase={1000}
                precision={0}
                vertical={true}
                handleValue={props.onChangeCost}
              />
            </div>
          </Td>
          <Td className="suport-row mb-1" cols="90px auto">
            <div className="suport-td caption">Fabricación</div>
            <div className="suport-td component">
              <DateTime
                className="form-control form-control-sm t-c"
                name="date_factory"
                placeholder="Fecha de fabricación"
                dateFormat="MMMM d, yyyy"
                _state={data._state}
                value={data.date_factory}
                onChange={props.onChangeDate}
              />
            </div>
          </Td>
        </div>
        <div className="suport-td">
          <div className="image">
            <Image
              className="img-thumbnail square-image"
              project_id={data.project_id}
              _class="IMAGE"
              _state={data._state}
              object_id={data._id}
              path={data.image}
              onSetData={props.onChangeImage}
            />
          </div>
        </div>
      </Td>
      <Td className="suport-row mb-1" cols="90px auto">
        <div className="suport-td text">Ultima Intervención</div>
        <div className="suport-td component">
          <DateTime
            className="form-control form-control-sm t-c"
            name="date_of"
            placeholder="Ultima Intervención"
            dateFormat="MMMM d, yyyy hh:mm aa"
            showTimeSelect={true}
            _state="1"
            value={data.date_of}
            onChange={props.onChangeDate}
          />
        </div>
      </Td>
      <Td className="suport-row mb-1" cols="90px auto">
        <div className="suport-td caption">Descripción</div>
        <div className="suport-td component">
          <Input
            type="text"
            className="form-control form-control-sm"
            autoComplete="nope"
            placeholder="Descripción"
            _state={data._state}
            name="description"
            value={data.description}
            onChange={props.onChange}
          />
        </div>
      </Td>
      <Td className="suport-row mb-1" cols="90px auto">
        <div className="suport-td caption">
          <ModalClass
            className="link"
            project_id={data.project_id}
            _state={data._state}
            _id={data.class_id}
            isFind={true}
            onSetData={props.onChangeClase}
          >
            Clase
          </ModalClass>
        </div>
        <div className="suport-td component">
          <AssetClass
            project_id={data.project_id}
            _state={data._state}
            equipment={data.equipment}
            select={{
              _id: data.class_id,
              caption: data.class,
            }}
            onChange={props.onChangeClase}
          />
        </div>
      </Td>
      <Td className="suport-row mb-1" cols="90px auto">
        <div className="suport-td caption">
          <ModalType
            className="link"
            project_id={data.project_id}
            _state={data._state}
            _id={data.type_id}
            isFind={true}
            onSetData={props.onChangeType}
          >
            Tipo
          </ModalType>
        </div>
        <div className="suport-td component">
          <AssetType
            project_id={data.project_id}
            class_id={data.class_id}
            _state={data._state}
            select={{
              _id: data.type_id,
              caption: data.type,
            }}
            onChange={props.onChangeType}
          />
        </div>
      </Td>
      <Td className="suport-row mb-1" cols="90px auto">
        <div className="suport-td caption">
          <ModalReference
            className="link"
            project_id={data.project_id}
            _id={data.reference_id}
            isFind={true}
            onSetData={props.onChangeReference}
          >
            Referencia
          </ModalReference>
        </div>
        <div className="suport-td component">
          <AssetReference
            type="text"
            className="form-control"
            placeholder="Referencia"
            project_id={data.project_id}
            class_id={data.class_id}
            _state={data._state}
            select={{
              _id: data.reference_id,
              caption: data.reference,
            }}
            onSetData={props.onChangeReference}
          />
        </div>
      </Td>
      <Td className="suport-row mb-1" cols="90px auto">
        <div className="suport-td caption">
          <ModalContact className="link" project_id={data.project_id} _id={data.owner_id} isFind={true} onSetData={props.onChangeOwner}>
            Propietario
          </ModalContact>
        </div>
        <div className="suport-td component">
          <Contact
            project_id={data.project_id}
            _state={data._state}
            select={{
              _id: data.owner_id,
              caption: data._data.owner,
            }}
            onChange={props.onChangeOwner}
          />
        </div>
      </Td>
    </React.Fragment>
  );
}

export default ComponentAsset;
