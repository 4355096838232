import React from "react";
import ReactDOM from "react-dom";
import "../../styles/modal.scss";
import "../../styles/suport.scss";
import {
  showModal,
  hideModal,
  ShowInfo,
  msg,
  setFocus,
  formatNumber,
  formatMoney,
  getValue,
  formatDate,
  formId,
  getSource,
} from "../../components/utilities";
import ModalHeaderSuport from "../../components/modalHeaderSuport";
import ModalFooterSuport from "../../components/modalFooterSuport";
import ModalReference from "./modalReference";
import Td from "../../components/td";
import { Section } from "../../components/inputs";
import ModalEnter from "./modalEnter";
import ModalExit from "./modalExit";
import ModalRefund from "./modalRefund";
import ModalInventory from "./modalInventory";
import { Api as Warehouse } from "../../services/warehouse";

class ModalKardex extends React.Component {
  constructor(props) {
    super(props);
    const data = props.data;
    this.state = {
      form: "__ModalKardex",
      _id: "__ModalKardex",
      title: "Kardex",
      name: "kardex",
      show: false,
      old: data,
      data: data,
      isNew: false,
      change: false,
      rows: 35,
      printParams: {
        project_id: "-1",
        _class: "SUPORT-WAREHOUSE-KARDEX",
        _id: "-1",
        single: true,
      },
    };
  }

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeReference = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        reference_id: getValue(e, "_id", "-1"),
        code: getValue(e, "code", ""),
        reference: getValue(e, "caption", ""),
        caption: getValue(e, "caption", ""),
        barcode: getValue(e, "barcode", ""),
        rfidcode: getValue(e, "rfidcode", ""),
        unity: getValue(e, "unity", ""),
        method_tp: getValue(e, "method_tp", "KARDEX_METHOD_TP00"),
        method: getValue(e, "method", ""),
      },
      change: true,
    });
  };

  handleChangeMethod = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        method_tp: getValue(e, "_id", "-1"),
        method: getValue(e, "caption", ""),
      },
      change: true,
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    if (this.props.isFind) {
      let _id = getValue(this.props, "_id", 0);
      if (_id !== 0) {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    } else {
      const _id = getValue(this.props.data, "_id", 0);
      if (_id !== 0) {
        const form_id = `${this.state.form}${Date.now()}`;
        this.setState({ _id: form_id, show: true, change: false });
      }
    }
  };

  handleClose = (e) => {
    if (this.state.change && e) {
      this.handleOk();
    } else {
      this.handleHide();
    }
  };

  handleOk = () => {
    if (this.state.change) {
      const id = this.state.data._id;
      const params = this.state.data;
      Warehouse.setKardex(id, params).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false });
          this.handleExecute(data);
        }
      });
    } else {
      this.handleHide();
    }
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      isNew: e.isNew,
      change: e.change,
      tabs: [],
      printParams: {
        project_id: e.data.project_id,
        _class: e.data._class,
        _id: e.data._id,
        single: true,
      },
    });
    showModal(this.state._id);
    setFocus(`${this.state._id}_caption`, e.focusInt);
  };

  handleData = () => {
    const _id = getSource(this.props.data, ["_id"], "-1");
    if (_id === "-1") {
      this.handleSetData({ data: this.props.data, isNew: false, change: false, focusInt: true });
    } else {
      this.handleFind(_id);
    }
  };

  handleNew = () => {
    const data = this.handleScheme(this.props.data);
    this.handleSetData({ data, isNew: true, change: true, focusInt: true });
  };

  handleFind = (id) => {
    if (this.state.change) {
      ShowInfo(msg("MSG-CHANGE"));
    } else if (id !== 0) {
      Warehouse.kardex(id).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false, focusInt: true });
        }
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        if (this.props.isNew) {
          this.handleNew();
        } else if (this.props.isFind) {
          this.handleFind(this.props._id);
        } else {
          this.handleData();
        }
      }
    }
  }

  componentWillUnmount() {
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog __modalObject sm" role="document">
                <div className="modal-content">
                  <ModalHeaderSuport
                    title={this.state.title}
                    _state={this.state.data._state}
                    _id={this.state.data._id}
                    isNew={false}
                    authorized={this.state.data.authorized}
                    change={this.state.change}
                    handleClose={this.handleClose}
                  />
                  <div className="modal-body-tabs">
                    <nav>
                      <div className="nav nav-tabs" role="tablist">
                        <a
                          className="nav-item nav-link active"
                          id={`${this.state._id}-nav-1-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-1`}
                          role="tab"
                          aria-selected="true"
                        >
                          General
                        </a>
                      </div>
                    </nav>
                    <div className="tab-content modal">
                      <div
                        className="tab-pane fade show active"
                        id={`${this.state._id}-nav-1`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-1-tab`}
                      >
                        <div className="suport">
                          <div className="suport-group">
                            <Td className="suport-row" cols="90px auto 95px 180px">
                              <div className="suport-td caption">Almacén</div>
                              <div className="suport-td value t-l">{this.state.data.warehouse}</div>
                              <div className="suport-td caption">Método</div>
                              <div className="suport-td value t-c">{this.state.data.method}</div>
                            </Td>
                            <Td className="suport-row" cols="90px auto 95px 180px">
                              <div className="suport-td caption">Bodega</div>
                              <div className="suport-td value t-l">{this.state.data.cellar}</div>
                              <div className="suport-td caption">Existencias</div>
                              <div className="suport-td value t-r">{formatNumber(this.state.data.stock)}</div>
                            </Td>
                            <Td className="suport-row" cols="90px auto 95px 180px">
                              <div className="suport-td caption">
                                <ModalReference
                                  className="link"
                                  isFind={true}
                                  project_id={this.state.data.project_id}
                                  _id={this.state.data.reference_id}
                                  onSetData={this.handleChangeReference}
                                >
                                  Referencia
                                </ModalReference>
                              </div>
                              <div className="suport-td value t-l">{this.state.data.caption}</div>
                              <div className="suport-td caption">Costo</div>
                              <div className="suport-td value t-r">{formatMoney(this.state.data.cost)}</div>
                            </Td>
                            <Td className="suport-row" cols="90px auto">
                              <div className="suport-td caption">Localización</div>
                              <div className="suport-td">
                                <Section
                                  id={`${this.state._id}_caption`}
                                  type="text"
                                  className="form-control sp ctr-sm"
                                  placeholder="Localización"
                                  _state="0"
                                  name="location"
                                  value={this.state.data.location}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </Td>
                          </div>
                          <ComponentLevels _id={this.state._id} data={this.state.data} />
                          <div className="suport-space"></div>
                          <ComponentMovements _id={this.state._id} data={this.state.data} />
                          <div className="suport-space"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ModalFooterSuport
                    data={this.state.data}
                    change={this.state.change}
                    _state={this.state.data._state}
                    send={false}
                    isNew={this.state.isNew}
                    printParams={this.state.printParams}
                    handleState={this.handleState}
                    handleNew={this.handleNew}
                    handleClose={this.handleClose}
                    handleOk={this.handleOk}
                  />
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

export default ModalKardex;

function ComponentLevels(props) {
  const _id = props._id || "-1";
  const data = props.data || {};
  const levels = data.levels || [];

  return (
    <React.Fragment>
      <div className="suport-group">
        <Td className="suport-head" cols="auto">
          <div className="suport-td">Existencias por nivel</div>
        </Td>
        <Td className="suport-head" cols="90px auto 40px 100px 100px 124px">
          <div className="suport-td">Fecha</div>
          <div className="suport-td">Nivel</div>
          <div className="suport-td">Und</div>
          <div className="suport-td">Existencias</div>
          <div className="suport-td">Unitario</div>
          <div className="suport-td">Costo</div>
        </Td>
        <div className="suport-scroll" style={{ height: "150px" }} id={`${_id}_scroll`}>
          {levels.map((item, i) => {
            return (
              <Td key={i} className="suport-item" cols="90px auto 40px 100px 100px 120px">
                <div className="suport-td t-c">{formatDate(item.date_update, "MMM d, yyyy")}</div>
                <div className="suport-td">{item.level}</div>
                <div className="suport-td t-c">{item.unity}</div>
                <div className="suport-td t-r">{formatNumber(item.stock)}</div>
                <div className="suport-td t-r">{formatMoney(item.unitary)}</div>
                <div className="suport-td t-r">{formatMoney(item.cost)}</div>
              </Td>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
}

function ComponentMovements(props) {
  const _id = props._id || "-1";
  const data = props.data || {};
  const movements = data.movements || {};
  const list = movements.list || [];

  return (
    <React.Fragment>
      <div className="suport-group">
        <Td className="suport-head" cols="90px auto 35px 525px">
          <div className="suport-td m">Fecha</div>
          <div className="suport-td m">Soporte</div>
          <div className="suport-td m">Und</div>
          <div className="suport-td c">
            <Td className="suport-head" cols="210px auto">
              <div className="suport-td">Movimientos</div>
              <div className="suport-td">Saldo</div>
            </Td>
            <Td className="suport-head" cols="90px 120px 95px 120px 100px">
              <div className="suport-td">Cantidad</div>
              <div className="suport-td">Costo</div>
              <div className="suport-td">Cantidad</div>
              <div className="suport-td">Costo</div>
              <div className="suport-td">Unitario</div>
            </Td>
          </div>
        </Td>
        <div className="suport-scroll" id={`${_id}_scroll`}>
          {list.map((item, i) => {
            if (item._class === "SUPORT-WAREHOUSE-ENTRY") {
              return (
                <ModalEnter key={i} isFind={true} project_id={item.project_id} _id={item.document_id}>
                  <Td className="suport-item" cols="90px auto 35px 90px 120px 95px 120px 96px">
                    <div className="suport-td t-c">{formatDate(item.date_of, "MMM d, yyyy")}</div>
                    <div className="suport-td">{item.main}</div>
                    <div className="suport-td t-c">{item.unity}</div>
                    <div className="suport-td t-r">
                      {item._operation === -1 ? `${formatNumber(item.end_quantity * -1)}` : formatNumber(item.int_quantity)}
                    </div>
                    <div className="suport-td t-r">
                      {item._operation === -1 ? `${formatMoney(item.end_cost * -1)}` : formatMoney(item.int_cost)}
                    </div>
                    <div className="suport-td t-r">{formatNumber(item.balance_quantity)}</div>
                    <div className="suport-td t-r">{formatMoney(item.balance_cost)}</div>
                    <div className="suport-td t-r">{formatMoney(item.unitary)}</div>
                  </Td>
                </ModalEnter>
              );
            } else if (item._class === "SUPORT-WAREHOUSE-EXIT") {
              return (
                <ModalExit key={i} isFind={true} project_id={item.project_id} _id={item.document_id}>
                  <Td className="suport-item" cols="90px auto 35px 90px 120px 95px 120px 96px">
                    <div className="suport-td t-c">{formatDate(item.date_of, "MMM d, yyyy")}</div>
                    <div className="suport-td">{item.main}</div>
                    <div className="suport-td t-c">{item.unity}</div>
                    <div className="suport-td t-r">
                      {item._operation === -1 ? `${formatNumber(item.end_quantity * -1)}` : formatNumber(item.int_quantity)}
                    </div>
                    <div className="suport-td t-r">
                      {item._operation === -1 ? `${formatMoney(item.end_cost * -1)}` : formatMoney(item.int_cost)}
                    </div>
                    <div className="suport-td t-r">{formatNumber(item.balance_quantity)}</div>
                    <div className="suport-td t-r">{formatMoney(item.balance_cost)}</div>
                    <div className="suport-td t-r">{formatMoney(item.unitary)}</div>
                  </Td>
                </ModalExit>
              );
            } else if (item._class === "SUPORT-WAREHOUSE-REFUND") {
              return (
                <ModalRefund key={i} isFind={true} project_id={item.project_id} _id={item.document_id}>
                  <Td className="suport-item" cols="90px auto 35px 90px 120px 95px 120px 96px">
                    <div className="suport-td t-c">{formatDate(item.date_of, "MMM d, yyyy")}</div>
                    <div className="suport-td">{item.main}</div>
                    <div className="suport-td t-c">{item.unity}</div>
                    <div className="suport-td t-r">
                      {item._operation === -1 ? `${formatNumber(item.end_quantity * -1)}` : formatNumber(item.int_quantity)}
                    </div>
                    <div className="suport-td t-r">
                      {item._operation === -1 ? `${formatMoney(item.end_cost * -1)}` : formatMoney(item.int_cost)}
                    </div>
                    <div className="suport-td t-r">{formatNumber(item.balance_quantity)}</div>
                    <div className="suport-td t-r">{formatMoney(item.balance_cost)}</div>
                    <div className="suport-td t-r">{formatMoney(item.unitary)}</div>
                  </Td>
                </ModalRefund>
              );
            } else if (item._class === "SUPORT-WAREHOUSE-INITIAL") {
              return (
                <ModalInventory key={i} isFind={true} project_id={item.project_id} _id={item.document_id}>
                  <Td className="suport-item" cols="90px auto 35px 90px 120px 95px 120px 96px">
                    <div className="suport-td t-c">{formatDate(item.date_of, "MMM d, yyyy")}</div>
                    <div className="suport-td">{item.main}</div>
                    <div className="suport-td t-c">{item.unity}</div>
                    <div className="suport-td t-r">
                      {item._operation === -1 ? `${formatNumber(item.end_quantity * -1)}` : formatNumber(item.int_quantity)}
                    </div>
                    <div className="suport-td t-r">
                      {item._operation === -1 ? `${formatMoney(item.end_cost * -1)}` : formatMoney(item.int_cost)}
                    </div>
                    <div className="suport-td t-r">{formatNumber(item.balance_quantity)}</div>
                    <div className="suport-td t-r">{formatMoney(item.balance_cost)}</div>
                    <div className="suport-td t-r">{formatMoney(item.unitary)}</div>
                  </Td>
                </ModalInventory>
              );
            } else {
              return (
                <Td className="suport-item" cols="90px auto 35px 90px 120px 95px 120px 96px">
                  <div className="suport-td t-c">{formatDate(item.date_of, "MMM d, yyyy")}</div>
                  <div className="suport-td">{item.main}</div>
                  <div className="suport-td t-c">{item.unity}</div>
                  <div className="suport-td t-r">
                    {item._operation === -1 ? `${formatNumber(item.end_quantity * -1)}` : formatNumber(item.int_quantity)}
                  </div>
                  <div className="suport-td t-r">
                    {item._operation === -1 ? `${formatMoney(item.end_cost * -1)}` : formatMoney(item.int_cost)}
                  </div>
                  <div className="suport-td t-r">{formatNumber(item.balance_quantity)}</div>
                  <div className="suport-td t-r">{formatMoney(item.balance_cost)}</div>
                  <div className="suport-td t-r">{formatMoney(item.unitary)}</div>
                </Td>
              );
            }
          })}
        </div>
      </div>
    </React.Fragment>
  );
}
