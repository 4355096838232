import React from "react";
import "../styles/document.scss";
import TopBar from "../components/topbar";
import { Loading } from "../components/utilities";
import * as Scroll from "react-scroll";
const Element = Scroll.Element;

class Politics extends React.Component {
  constructor(props) {
    super(props);
    Loading();
    this.title = "Política";
  }
  render() {
    return (
      <React.Fragment>
        <TopBar></TopBar>
        <div className="blog">
          <div className="container-fluid">
            <header className="blog-header py-3">
              <div className="row flex-nowrap justify-content-between align-items-center">
                <div className="col-12 text-center">
                  <label className="blog-header-logo text-dark">{this.title}</label>
                </div>
              </div>
            </header>
          </div>
          <main role="main" className="container">
            <div className="row">
              <div className="col-md-8 blog-main">
                <div className="blog-post text-justify">
                  <Element id="start">
                    <h2 className="blog-post-title">Protocolo de Tratamiento de Datos</h2>
                    <p className="blog-post-meta">Ultima actualización: Diciembre 14, 2020</p>
                  </Element>
                  <Element id="paragraph01">
                    <h2>Introducción</h2>
                    <p>
                      La seguridad de la información y en particular de los datos personales recaudados es un compromiso
                      y una obligación legal de <b>DPLOY S.A.S.,</b> sociedad comercial con domicilio en la ciudad de
                      San Gil e identificada con Nit No. 900.960.413-1, en adelante DPLOY.
                    </p>
                    <p>
                      Dadas sus obligaciones legales y con el propósito de asumir los compromisos que implican el
                      ejercicio del comercio, DPLOY ha procedido a expedir el presente Manual de Protección de Datos
                      Personales que incluye su política de privacidad y manejo de la información. Este documento se
                      fundamenta en lo establecido en el artículo 15 de la Constitución Política de Colombia, la ley
                      1266 de 2008, la ley 1581 de 2012, el decreto 1074 de 2015 y la jurisprudencia que las interpreta.
                    </p>
                    <p>
                      Una vez que usted haya brindado su autorización de tratamiento de datos personales a DPLOY, el
                      presente documento, los derechos que reconoce y las obligaciones que prevé le resultarán
                      aplicables en todo aquello que el mismo no resulte contrario a lo previsto en la legislación
                      vigente en favor del titular del dato.
                    </p>
                  </Element>
                  <Element id="paragraph02">
                    <h2>Definiciones</h2>
                    <p>
                      Las definiciones señaladas a continuación son términos y/o expresiones a las cuales estará
                      expuesto el titular de los datos personales al leer las Políticas de Protección de datos de DPLOY.
                      Los conceptos expresados a continuación son extraídos en su mayoría del artículo tercero (3) de la
                      ley 1581 de 2012, de las normas que la reglamentan y de la jurisprudencia que las interpreta. Los
                      conceptos acá expresados en la medida que no resulten claros serán interpretados en beneficio de
                      los titulares de datos personales que resulten tratados por DPLOY.
                    </p>
                    <ol type="a">
                      <li>
                        <b>Autorización:</b> Consentimiento previo, expreso e informado del expresado por el titular,
                        que ha permitido dar inicio al tratamiento de sus datos personales.
                      </li>
                      <br></br>
                      <li>
                        <b>Aviso de privacidad:</b>Aviso de privacidad: Comunicación verbal o escrita generada por el
                        responsable, dirigida al Titular para el Tratamiento de sus datos personales, mediante la cual
                        se le informa acerca de la existencia de las políticas de Tratamiento de información que le
                        serán aplicables, la forma de acceder a las mismas y las finalidades del Tratamiento que se
                        pretende dar a los datos personales.
                      </li>
                      <br></br>
                      <li>
                        <b>Base de datos:</b> Es el conjunto organizado de datos personales que es objeto de
                        tratamiento.
                      </li>
                      <br></br>
                      <li>
                        <b>Dato personal:</b> Cualquier información vinculada o que pueda asociarse a una o varias
                        personas naturales determinadas o determinables.
                      </li>
                      <br></br>
                      <li>
                        <b>Dato Sensible:</b> Aquella información que afecta la intimidad del Titular y cuyo uso
                        indebido puede generar discriminación por parte de otras personas, ejemplo de ello son aquellos
                        que revelan la convicción religiosa o filosófica, política, entre otros.
                      </li>
                      <br></br>
                      <li>
                        <b>Dato semiprivado.</b> Es semiprivado el dato que no tiene naturaleza íntima, reservada, ni
                        pública y cuyo conocimiento o divulgación puede interesar no sólo a su titular sino a cierto
                        sector o grupo de personas o a la sociedad en general, como el dato financiero y crediticio de
                        actividad comercial o de servicios a que se refiere el Título IV de la ley 1266 de 2008.
                      </li>
                      <br></br>
                      <li>
                        <b>Dato privado:</b> Es el dato que por su naturaleza íntima o reservada solo es relevante para
                        el titular.
                      </li>
                      <br></br>
                      <li>
                        <b>Dato Público:</b> Todo dato personal que no es privado o sensible, ejemplo de ello son los
                        relativos al estado civil de las personas, a su profesión u oficio, este tipo de datos en razón
                        a su naturaleza pueden estar contenidos en registros públicos, gacetas, boletines oficiales,
                        entre otros.
                      </li>
                      <br></br>
                      <li>
                        <b>Consulta:</b> Es toda solicitud realizada por el titular del dato o por personas autorizadas
                        por este o por mandato de la ley, con el fin de acceder a la información almacenada en cualquier
                        base de datos de DPLOY.
                      </li>
                      <br></br>
                      <li>
                        <b>Encargado del tratamiento:</b> Persona natural o jurídica, pública o privada, que por sí
                        misma o en asocio con otros, realice el Tratamiento de datos personales por cuenta del
                        responsable del Tratamiento.
                      </li>
                      <br></br>
                      <li>
                        <b>Funcionario responsable de la gestión de peticiones:</b> Es aquella persona y/o personas a
                        las que DPLOY ha encomendado la coordinación y control de las quejas, consultas o reclamos
                        realizados por los titulares de los datos personales almacenados.
                      </li>
                      <br></br>
                      <li>
                        <b>Usuarios:</b> Son las personas naturales vinculadas por contrato de trabajo a DPLOY y que
                        utilizan cualquiera de los sistemas de información de la compañía accediendo a los datos en
                        ejercicio de sus funciones.
                      </li>
                      <br></br>
                      <li>
                        <b>Reclamo:</b> Es aquella solicitud elevada por el titular del dato personal o la persona
                        autorizada por este o por la ley, para realizar la consulta, actualización, rectificación y
                        supresión de sus datos personales o para revocar la autorización para el tratamiento de datos,
                        según lo establecido por la ley.
                      </li>
                      <br></br>
                      <li>
                        <b>Responsable del tratamiento:</b> DPLOY es la responsable por el tratamiento de los datos
                        personales almacenados y recaudados por aquella en sus bases de datos.
                      </li>
                      <br></br>
                      <li>
                        <b>Titular del dato personal:</b> Persona Natural cuyos datos personales sean objeto de
                        tratamiento.
                      </li>
                      <br></br>
                      <li>
                        <b>Transferencia de datos personales:</b> La transferencia de datos tiene lugar cuando el
                        responsable y/o Encargado del Tratamiento de datos personales, ubicado en Colombia, envía la
                        información o los datos personales a un receptor, que dada la adquisición actuará como
                        Responsable del Tratamiento y se encuentra dentro o fuera del país.
                      </li>
                      <br></br>
                      <li>
                        <b>Transmisión de datos personales:</b> Tratamiento de datos personales que implica la
                        comunicación de estos dentro o fuera del territorio de la República de Colombia cuando tenga por
                        objeto la realización de un Tratamiento por parte del Encargado que actúa por cuenta del
                        responsable.
                      </li>
                      <br></br>
                      <li>
                        <b>Tratamiento de datos personales:</b> Cualquier operación o conjunto de operaciones realizadas
                        respecto de los datos personales, tales como la recolección, almacenamiento, uso, transferencia,
                        circulación o supresión.
                      </li>
                    </ol>
                  </Element>
                  <Element id="paragraph03">
                    <h2>Principios de la protección de datos personales de DPLOY</h2>
                    <p>
                      Los principios que se establecen a continuación serán los pilares de toda política y actividad al
                      interior de DPLOY que tienda al manejo, recolección, tratamiento, uso, transferencia,
                      almacenamiento e intercambio de datos personales.
                    </p>
                    <ol type="a">
                      <li>
                        <b>Principio de finalidad:</b> DPLOY al momento de recaudar datos personales informará al
                        titular de la necesidad de obtención de uno o varios datos personales y le señalará el
                        tratamiento que dará a los mismos. El cambio de finalidad de los datos recaudados solo será
                        llevado a cabo mediando autorización del titular de estos.{" "}
                      </li>
                      <br></br>
                      <li>
                        <b>Principio de libertad:</b> El titular de los datos personales a ser tratados por DPLOY es
                        libre de otorgar la autorización o no para el tratamiento de estos, a menos que la
                        jurisprudencia o la ley establezcan la obligatoriedad de entrega y/o tratamiento de los datos
                        para la generación de negocios jurídicos u otros. Todo tratamiento de un dato personal estará
                        precedido de la autorización que incorpore el consentimiento libre y expreso de su titular.{" "}
                      </li>
                      <br></br>
                      <li>
                        <b>Principio de veracidad o calidad:</b> DPLOY realizará el tratamiento de datos veraces,
                        completos, comprensibles, actualizados y comprobables
                      </li>
                      <br></br>
                      <li>
                        <b>Principio de transparencia:</b> DPLOY garantizará a los titulares de los datos personales
                        tratados por aquella, el derecho de obtener información sobre los datos que reposen en sus bases
                        de datos respecto aquellos o sus personas a cargo.{" "}
                      </li>
                      <br></br>
                      <li>
                        <b>Principio de seguridad:</b> DPLOY adoptará medidas suficientes para que los datos personales
                        que trate cuenten con estándares suficientes de seguridad de la información que garanticen la
                        protección conforme a la legislación vigente. Lo anterior, implica la toma de medidas técnicas
                        administrativas y jurídicas que otorguen seguridad a sus registros, con el objeto de evitar
                        adulteraciones, perdidas, consultas y/o usos no autorizados o fraudulentos.
                      </li>
                      <br></br>
                      <li>
                        <b>Principio de confidencialidad:</b> Las personas que intervienen en el tratamiento de datos
                        personales al interior de DPLOY o por delegación de aquella, están obligadas a garantizar la
                        reserva de la información, inclusive una vez finalice la relación contractual con aquella.
                      </li>
                      <br></br>
                      <li>
                        <b>Principio de Legalidad:</b> El Tratamiento de los datos personales es una actividad reglada
                        que debe sujetarse a lo establecido en la constitución, la ley 1581 de 2012 y demás normas que
                        la reglamentan o adicionen.
                      </li>
                      <br></br>
                      <li>
                        <b>Principio de Circulación Restringida:</b> El tratamiento de los datos personales se sujeta a
                        los límites que se derivan de su naturaleza y solamente podrá ser realizado por las personas
                        autorizadas por el titular o por quienes ha previsto la ley. Los datos no estarán disponibles en
                        redes o cualquier otro medio de divulgación salvo que su acceso sea controlable técnicamente.
                      </li>
                    </ol>
                  </Element>
                  <Element id="paragraph04">
                    <h2>Objetivo</h2>
                    <p>
                      Las presentes políticas y procedimientos adoptados aplicarán para el manejo de la información
                      personal que sea recolectada, utilizada y/o se encuentre en las bases de datos de DPLOY, quien
                      actuará según el caso, en calidad responsable y/o encargado del tratamiento de datos personales.
                    </p>
                  </Element>
                  <Element id="paragraph05">
                    <h2>Alcance</h2>
                    <p>
                      Esta política aplica para toda la información personal registrada en las bases de datos de DPLOY,
                      quien actúa en calidad de responsable o encargado del tratamiento de los datos personales, según
                      sea el caso. Los términos y condiciones aquí contemplados aplican para cualquier registro de datos
                      personales captados por DPLOY de parte de un titular o su representante en forma presencial, a
                      distancia, por telepresencia, de forma virtual o cualquier otra permitida por la ley.
                    </p>
                  </Element>
                  <Element id="paragraph06">
                    <h2>Obligacion legal del cumplimiento</h2>
                    <p>
                      Esta política es de obligatorio y estricto cumplimiento para DPLOY S.A.S. y se fundamenta en la
                      Constitución Política, la ley 1581 de 2012, la ley 1266 de 2008, los decretos reglamentarios de
                      estas, las disposiciones establecidas por la Superintendencia de Industria y Comercio y demás
                      normas aplicables.
                    </p>
                  </Element>
                  <Element id="paragraph07">
                    <h2>Responsable del tratamiento</h2>
                    <p>
                      DPLOY S.A.S., sociedad comercial con domicilio en la ciudad de San Gil e identificada con Nit No.
                      900.960.413-1.
                    </p>
                    <p>
                      Datos de contacto:<br></br>
                      Dirección de Notificaciones Judiciales Calle 3 #12B - 41 APTO 402 San Gil<br></br>
                      Teléfono: +57 3016219802 <br></br>
                      Correo electrónico: <a href="mailto:contacto@dploy.site">contacto@dploy.site</a>
                      <br></br>
                      Página web:{" "}
                      <a href="https://dploy.site/" rel="noopener noreferrer">
                        https://dploy.site/
                      </a>
                    </p>
                  </Element>
                  <Element id="paragraph08">
                    <h2>Finalidades y tratamiento de los datos personales</h2>
                    <p>
                      Con el fin de suministrar un buen servicio, promocionar la actividad de DPLOY y hacer más
                      efectivas, ágiles y seguras las operaciones realizadas por los titulares, se recolectarán los
                      datos personales dentro de los preceptos legales, con las siguientes finalidades:
                    </p>
                    <ol type="a">
                      <li>
                        Realizar actividades de posicionamiento de marca como campañas de publicidad personalizada en
                        redes sociales, a través del uso de mensajes de texto, correos electrónicos y otros
                        relacionados.
                      </li>
                      <br></br>
                      <li>
                        Realizar demostraciones comerciales de los productos y/o servicios ofrecidos por DPLOY S.A.S..
                      </li>
                      <br></br>
                      <li>
                        Remitir propuestas comerciales para la prestación de los servicios ofrecidos por DPLOY S.A.S..
                      </li>
                      <br></br>
                      <li>
                        Llevar a cabo la prestación de los servicios y la venta de los productos que corresponden a la
                        actividad económica de DPLOY S.A.S..
                      </li>
                      <br></br>
                      <li>
                        Elaborar estudios de mercado sobre los intereses, comportamiento, demografía, tendencias y otros
                        relacionados de clientes y/o posibles clientes de DPLOY S.A.S..
                      </li>
                      <br></br>
                      <li>
                        Realizar encuestas de satisfacción al cliente y/o usuario de los productos y servicios
                        comercializados por DPLOY S.A.S..{" "}
                      </li>
                      <br></br>
                      <li>
                        Contactar al Titular a través de medios físicos o electrónicos como SMS, chat, correos
                        electrónicos, WhatsApp, Instagram, Facebook Messenger u otros sistemas de información en línea
                        y/o telemáticos para el envió de noticias relacionadas con el lanzamiento de productos,
                        servicios, soporte de aplicaciones o productos y otros relacionados.
                      </li>
                      <br></br>
                      <li>
                        Contactar al Titular a través de correo electrónico, chat, SMS o por medios físicos o virtuales
                        para el envió de extractos, estados de cuenta o facturas en relación con las obligaciones
                        derivadas del contrato celebrado entre las partes
                      </li>
                      <br></br>
                      <li>
                        Proceder a realizar actividades de elaboración de contratos, facturación y/o cobro por la
                        prestación de sus servicios y/o la venta de productos, cuando aplique.
                      </li>
                      <br></br>
                      <li>
                        Completar la información y en general, adelantar las actividades necesarias para gestionar las
                        solicitudes, quejas y reclamos presentadas por titulares.{" "}
                      </li>
                      <br></br>
                      <li>Mejorar las iniciativas comerciales y promocionales de DPLOY S.A.S..</li>
                      <br></br>
                      <li>
                        Dar cumplimiento a las obligaciones contraídas por DPLOY S.A.S. con el Titular de la
                        información, con relación al pago de salarios, prestaciones sociales y demás retribuciones
                        consagradas en el contrato de trabajo o según lo disponga la ley.
                      </li>
                      <br></br>
                      <li>
                        Dar respuesta de las entidades gubernamentales o judiciales por solicitud expresa de esta,
                        cuando manifiesten la existencia de una orden judicial o administrativa debidamente
                        fundamentada.
                      </li>
                      <br></br>
                      <li>
                        Almacenar y administrar los datos suministrados en relación con los contratos de servicios
                        celebrados entre las partes.
                      </li>
                      <br></br>
                      <li>
                        Realizar el tratamiento de la voz y otros datos biométricos de los usuarios de las aplicaciones
                        y herramientas de hardware y software desarrollados por la compañía con el fin de prestar los
                        servicios contratados.
                      </li>
                      <br></br>
                      <li>
                        Realizar el tratamiento de la voz y otros datos biométricos de los usuarios de las aplicaciones
                        y herramientas de hardware y software desarrollados por la compañía con el fin de mejorar los
                        aspectos funcionales de estos.
                      </li>
                      <br></br>
                      <li>
                        Realizar el tratamiento de la voz y otros datos biométricos de los usuarios de las aplicaciones
                        y herramientas de hardware y software desarrollados por la compañía con el fin de prestar
                        soporte, mantenimiento y acompañamiento en los servicios contratados.
                      </li>
                      <br></br>
                      <li>
                        Realizar el tratamiento de la voz y otros datos biométricos de los usuarios de las aplicaciones
                        y herramientas de hardware y software desarrollados por la compañía con el fin de que los mismos
                        sean captados e interpretados para efectos de dejar records de uso de cada una de estas
                        herramientas vinculándolos a códigos QR.
                      </li>
                      <br></br>
                      <li>
                        Realizar el tratamiento de la voz y otros datos biométricos de los usuarios de las aplicaciones
                        y herramientas de hardware y software desarrollados por la compañía con el fin de someterlos a
                        procesos de cambio de formato para su interpretación por máquinas y otros usuarios, como por
                        ejemplo de voz/video a texto a través de sistemas de aprendizaje automático, traducción del
                        lenguaje o relacionados.
                      </li>
                      <br></br>
                      <li>
                        Realizar el tratamiento de los datos personales de los usuarios de las aplicaciones y
                        herramientas de hardware y software desarrollados por la compañía con el fin de almacenarlos y/o
                        encapsularlos a través de códigos QR u otros que permitan diferenciar un usuario de otro y
                        brindar accesos conforme a los roles técnicos y administrativos definidos.
                      </li>
                      <br></br>
                      <li>
                        Gestionar administrativa y comercialmente las herramientas de software y hardware diseñadas por
                        DPLOY S.A.S.
                      </li>
                      <br></br>
                      <li>
                        Procesar la información ingresada en las plataformas tecnológicas diseñadas por DPLOY y que sea
                        ingresada por nuestros clientes y/o usuarios. En estos casos el procesamiento de la información
                        se realizará en las condiciones de prestación de cada servicio y con base en las limitaciones
                        establecidas en la ley, las autorizaciones de tratamiento, contratos y otros relacionados.
                      </li>
                      <br></br>
                      <li>
                        Para los demás casos en que lo autorice expresamente la ley o los titulares de los datos
                        personales recaudados.
                      </li>
                    </ol>
                  </Element>
                  <Element id="paragraph09">
                    <h2>Tratamiento de datos sensibles</h2>
                    <p>
                      DPLOY podrá tratar los datos personales de carácter sensible del titular en aquellos casos que:
                    </p>
                    <ol type="a">
                      <li>
                        El titular haya dado su autorización expresa, con excepción de los casos en que la ley permita
                        el tratamiento sin autorización previa.
                      </li>
                      <br></br>
                      <li>
                        El tratamiento resulte vital para salvaguardar los intereses del titular y aquél se encuentre
                        física o jurídicamente incapacitado. En este caso el representante legal del titular debe
                        otorgar su autorización.
                      </li>
                      <br></br>
                      <li>
                        El Tratamiento se refiera a datos que sean necesarios para el reconocimiento, ejercicio o
                        defensa de un derecho en un proceso judicial.
                      </li>
                      <br></br>
                      <li>
                        El Tratamiento tenga una finalidad histórica, estadística o científica. En este evento deberán
                        adoptarse las medidas conducentes a la supresión de la identidad de los Titulares.
                      </li>
                    </ol>
                  </Element>
                  <Element id="paragraph10">
                    <h2>Personsa sobre las cuales se realiza el tratamiento de datos personales</h2>
                    <p>
                      DPLOY podrá llegar a realizar el tratamiento de datos personales que se relacionen con personas
                      naturales como clientes, potenciales clientes, usuarios de sus plataformas de hardware y software,
                      colaboradores, trabajadores, familiares de trabajadores, contratistas, destinatarios de
                      promociones, proveedores y cualquier otro que llegare a ser requerido y respecto del cual haya
                      obtenido una autorización de tratamiento de datos cuando así lo exija la ley.
                    </p>
                    <p>
                      Atendiendo a que DPLOY S.A.S. es una compañía de tecnología que desarrolla distintos tipos de
                      plataformas de software y hardware que realizan el tratamiento de datos personales de distinto
                      tipo, como por ejemplo biométricos como la voz e imagen de sus usuarios, la presente política de
                      tratamiento de datos personales aplica para el tratamiento que se realice por parte de esta
                      compañía de los datos obtenidos a través de cada una de las aplicaciones y dispositivos que la
                      misma ponga en el comercio.
                    </p>
                  </Element>
                  <Element id="paragraph11">
                    <h2>Datos de menores de edad</h2>
                    <p>
                      Los productos y servicios ofrecidos por DPLOY están dirigidos a personas que de conformidad con la
                      legislación vigente se consideran mayores de edad, razón por la cual en principio no se tratarán
                      datos personales de menores de edad como niños, niñas o adolescentes.
                    </p>
                    <p>
                      No obstante, en caso de que se presentara la necesidad o se realizara el tratamiento de menores de
                      edad, DPLOY tratará los datos personales de un niño, niña o adolescente, respondiendo al interés
                      superior de aquellos y buscando asegurar el respeto de sus derechos fundamentales. El tratamiento
                      de los datos personales de los menores se realizará cuando se cuente con la autorización de su
                      representante legal o del menor si la ley lo permitiera y siempre que dicho dato pueda ser tratado
                      de conformidad con la legislación vigente.
                    </p>
                  </Element>
                  <Element id="paragraph12">
                    <h2>Derechos de los titulares</h2>
                    <p>
                      Los titulares de los datos personales que resulten tratados por DPLOY tienen los siguientes
                      derechos:
                    </p>
                    <ol type="a">
                      <li>
                        Conocer, actualizar y rectificar los datos personales respecto de los cuales ha otorgado
                        autorización de tratamiento a DPLOY y que resulten parciales, inexactos, incompletos,
                        fraccionados, que induzcan a error, o aquellos cuyo tratamiento esté prohibido o no haya sido
                        autorizado. No obstante, DPLOY podrá hacer uso de las medidas necesarias para establecer
                        previamente la identificación del solicitante, con el objeto de evitar que personas no
                        autorizadas accedan a la información.{" "}
                      </li>
                      <br></br>
                      <li>
                        Autorizar el tratamiento de sus datos personales de forma previa a la realización de este y
                        conforme las finalidades que así considere de forma libre y espontánea. No obstante, puede ser
                        que ciertas funcionalidades de una o varias aplicaciones o dispositivos comercializados por
                        DPLOY no funcionen correctamente si no se brinda la autorización para ciertos tipos de
                        finalidades según cada caso particular.
                      </li>
                      <br></br>
                      <li>
                        Solicitar prueba de la autorización otorgada a DPLOY para el tratamiento de datos personales.
                        DPLOY no estará obligada a solicitar autorización para el tratamiento de datos personales cuando
                        exista alguna disposición legal que señale que dicha autorización no es necesaria.
                      </li>
                      <br></br>
                      <li>
                        Ser informado frente al uso que se ha dado a sus datos personales, cuando este lo solicite
                        previamente.
                      </li>
                      <br></br>
                      <li>
                        Consultar sus datos personales y hacer reclamos, con el ánimo de protegerlos, mediante el
                        procedimiento señalado por la ley y lo establecido en el presente manual
                      </li>
                      <br></br>
                      <li>
                        Revocar la autorización y/o solicitar la supresión de uno o varios datos personales tratados por
                        DPLOY, siempre y cuando no exista un deber legal de almacenar dicha información para la misma y
                        que le impida eliminarlos.
                      </li>
                      <br></br>
                      <li>
                        Acceder gratuitamente a los datos personales proporcionados que hayan sido objeto de
                        tratamiento.
                      </li>
                      <br></br>
                      <li>
                        Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a la Ley 1581
                        del 2012 y al Decreto 1074 de 2015.
                      </li>
                      <br></br>
                      <li>
                        Abstenerse de responder las preguntas sobre datos sensibles. Tendrán carácter facultativo las
                        respuestas que versen sobre datos sensibles o sobre datos de las niñas, niños y adolescentes.
                      </li>
                    </ol>
                  </Element>
                  <Element id="paragraph13">
                    <h2>Autorización para el tratamieno de datos personales</h2>
                    <p>
                      Salvo excepción prevista en la ley que la habilite para lo contrario, DPLOY solicitará
                      autorización previa, expresa e informada del titular para proceder al tratamiento de sus datos
                      personales. La autorización se solicitará por un medio que permita el almacenamiento para la
                      posterior consulta y verificación. La solicitud de autorización incluirá la mención clara de la
                      finalidad o finalidades del tratamiento.
                    </p>
                    <ol type="a">
                      <li>Medios para obtener la autorización.</li>
                    </ol>
                    <p>
                      <b>DPLOY</b> solicitará la autorización para el tratamiento de datos personales mediante un
                      documento físico, correo electrónico, mensaje de datos, internet, sitio web o cualquier otro que
                      permita la obtención del consentimiento informado del titular y la posterior consulta de la
                      autorización obtenida.
                    </p>
                    <ol type="a" start="2">
                      <li>Medios para obtener la autorización.</li>
                    </ol>
                    <p>
                      <b>DPLOY</b> utilizará los mecanismos necesarios para demostrar cómo y cuándo obtuvo autorización
                      por parte del titular para proceder con el tratamiento de sus datos personales.
                    </p>
                    <ol type="a" start="3">
                      <li>Casos en que no se requerirá autorización.</li>
                    </ol>
                    <p>
                      De conformidad con lo previsto en el artículo 10 de la ley 1581 de 2012, DPLOY no requerirá
                      autorización para el tratamiento de datos personales cuando deba tratar datos de naturaleza
                      pública, datos relacionados con el registro civil de las personas, a excepción de los datos
                      biométricos, datos requeridos por una entidad pública o administrativa en ejercicio de funciones
                      legales y/o judiciales, proceda a realizar el tratamiento de información autorizado por la ley
                      para fines estadísticos y/o científicos o enfrente casos de urgencia médica o sanitaria.
                    </p>
                    <ol type="a" start="4">
                      <li>Revocatoria de la autorización.</li>
                    </ol>
                    <p>
                      El titular de un dato personal podrá revocar su autorización para el tratamiento de este en
                      cualquier momento, siempre que no exista una disposición de carácter normativo o jurisprudencial
                      que obligue y/o le permita a DPLOY continuar el tratamiento o mantener almacenado el dato. La
                      revocatoria de la autorización podrá ser parcial o total, respecto de las finalidades de
                      tratamiento para las cuales se había obtenido el dato.
                    </p>
                    <p>
                      Si usted desea revocar la autorización de tratamiento de datos otorgada a DPLOY, por favor remita
                      un correo electrónico a <a href="mailto:contacto@dploy.site">contacto@dploy.site</a> con dicha
                      solicitud y siguiendo el procedimiento previsto en el acápite de{" "}
                      <b>
                        “PROCEDIMIENTO PARA LA REALIZACIÓN DE CONSULTAS Y RECLAMOS EN DESARROLLO DEL DERECHO DE HABEAS
                        DATA”
                      </b>{" "}
                      de este documento.
                    </p>
                  </Element>
                  <Element id="paragraph14">
                    <h2>Responsables del tratamiento de datos personales</h2>
                    <p>
                      DPLOY es la responsable del tratamiento de los datos personales recaudados en virtud de la
                      presente política de privacidad y de conformidad con el artículo 17 de la ley 1581 de 2012, le
                      corresponden los siguientes deberes:
                    </p>
                    <ol type="a">
                      <li>
                        Garantizar al titular de un dato personal, en todo tiempo, el pleno y efectivo ejercicio del
                        derecho de hábeas data.
                      </li>
                      <br></br>
                      <li>
                        Solicitar y conservar, en las condiciones previstas en la ley, copia de la respectiva
                        autorización otorgada por el titular del dato.
                      </li>
                      <br></br>
                      <li>
                        Informar debidamente al titular sobre la finalidad de la recolección y los derechos que le
                        asisten por virtud de la autorización otorgada.
                      </li>
                      <br></br>
                      <li>
                        Conservar la información recaudada bajo las condiciones de seguridad necesarias para impedir su
                        adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.
                      </li>
                      <br></br>
                      <li>
                        Garantizar que la información que se suministre al encargado del tratamiento sea veraz,
                        completa, exacta, actualizada, comprobable y comprensible.
                      </li>
                      <br></br>
                      <li>
                        Actualizar la información, comunicando de forma oportuna al encargado del tratamiento, todas las
                        novedades respecto de los datos que previamente le haya suministrado y adoptar las demás medidas
                        necesarias para que la información suministrada a este se mantenga actualizada.
                      </li>
                      <br></br>
                      <li>
                        Rectificar la información cuando sea incorrecta y comunicar lo pertinente al encargado del
                        tratamiento.
                      </li>
                      <br></br>
                      <li>
                        Suministrar al encargado del tratamiento, según el caso, únicamente datos cuyo tratamiento esté
                        previamente autorizado de conformidad con lo previsto en la ley.
                      </li>
                      <br></br>
                      <li>
                        Exigir al encargado del tratamiento en todo momento, el respeto a las condiciones de seguridad y
                        privacidad de la información del titular.
                      </li>
                      <br></br>
                      <li>Tramitar las consultas y reclamos formulados en los términos señalados en la ley.</li>
                      <br></br>
                      <li>
                        Adoptar un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento
                        de la ley de protección de datos personales y en especial, para la atención de consultas y
                        reclamos.
                      </li>
                      <br></br>
                      <li>
                        Informar al encargado del tratamiento cuando determinada información se encuentra en discusión
                        por parte del titular, una vez se haya presentado la reclamación y no haya finalizado el trámite
                        respectivo.
                      </li>
                      <br></br>
                      <li>
                        Informar a solicitud del titular sobre el uso dado a sus datos, cuando lo solicite previamente.
                      </li>
                      <br></br>
                      <li>
                        Informar a la autoridad de protección de datos cuando se presenten violaciones a los códigos de
                        seguridad y existan riesgos en la administración de la información de los titulares.
                      </li>
                      <br></br>
                      <li>
                        Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y
                        Comercio.
                      </li>
                    </ol>
                  </Element>
                  <Element id="paragraph15">
                    <h2>
                      Constitución de encargados de tratamiento de la información y actuación como encargado del
                      tratamiento de datos por terceros
                    </h2>
                    <p>
                      Cuando cuente con la correspondiente autorización del titular del dato o se encuentre autorizada
                      por la jurisprudencia o la ley, DPLOY S.A.S. podrá recurrir a terceros (personas naturales o
                      jurídicas) para que en asocio o por mandato de aquella realicen el tratamiento de los datos
                      personales recolectados. De igual manera, en el ejercicio de su actividad comercial DPLOY podrá
                      ser constituido como encargado del tratamiento de datos personales y de ser ese el caso deberá
                      cumplir con la legislación vigente como se señala a continuación.
                    </p>
                    <p>
                      Aquellos que resulten encargados por <b>DPLOY</b> del tratamiento de datos personales, de
                      conformidad con el artículo 18 de la ley 1581 de 2012, tendrán los siguientes deberes:
                    </p>
                    <ol type="a">
                      <li>
                        Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data.
                      </li>
                      <br></br>
                      <li>
                        Conservar la información bajo las condiciones de seguridad necesarias para impedir su
                        adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.
                      </li>
                      <br></br>
                      <li>
                        Realizar oportunamente la actualización, rectificación o supresión de los datos tratados cuando
                        le resulte exigible.
                      </li>
                      <br></br>
                      <li>
                        Actualizar la información reportada por DPLOY dentro de los cinco (5) días hábiles contados a
                        partir de su recibo.
                      </li>
                      <br></br>
                      <li>
                        Tramitar las consultas y los reclamos formulados por los titulares en los términos señalados en
                        la ley.
                      </li>
                      <br></br>
                      <li>
                        Adoptar un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento
                        de la ley de protección de datos personales y, en especial, para la atención de consultas y
                        reclamos por parte de los titulares.
                      </li>
                      <br></br>
                      <li>
                        Registrar en la base de datos la leyenda “reclamo en trámite” cuando así le resulte exigible.
                      </li>
                      <br></br>
                      <li>
                        Insertar en la base de datos la leyenda “información en discusión judicial” una vez notificado
                        por parte de la autoridad competente sobre procesos judiciales relacionados con la calidad del
                        dato personal.
                      </li>
                      <br></br>
                      <li>
                        Abstenerse de circular información que esté siendo controvertida por el titular y cuyo bloqueo
                        haya sido ordenado por la Superintendencia de Industria y Comercio.
                      </li>
                      <br></br>
                      <li>
                        Permitir el acceso a la información únicamente a las personas que pueden tener acceso a ella.
                      </li>
                      <br></br>
                      <li>
                        Informar a la Superintendencia de Industria y Comercio y a DPLOY cuando se presenten violaciones
                        a los códigos de seguridad y existan riesgos en la administración de la información de los
                        titulares.
                      </li>
                      <br></br>
                      <li>
                        Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y
                        Comercio.
                      </li>
                    </ol>
                    <p>
                      Cuando DPLOY S.A.S. cumpla el rol de encargado del tratamiento de datos personales deberá cumplir
                      con las obligaciones que anteceden frente a los responsables y titulares de los datos personales.
                    </p>
                    <p>
                      Mientras no exista un encargado del tratamiento de los datos personales, DPLOY cumplirá de forma
                      adicional con los deberes de este, cuando así le corresponda.
                    </p>
                  </Element>
                  <Element id="paragraph16">
                    <h2>Confidencialidad y seguridad de la información</h2>
                    <p>
                      Los datos personales de los titulares serán tratados por DPLOY, según lo establecido en la
                      presente política de protección de datos personales, la jurisprudencia y la ley. A los datos
                      recaudados solo podrá acceder el personal de DPLOY que resulte necesario para que esta última
                      realice su actividad económica y los trate de acuerdo con la finalidad señalada en la autorización
                      obtenida. Así mismo, DPLOY garantizará acceso a los datos a los encargados del tratamiento que
                      seleccione conforme a lo determinado en la presente política y a otros responsables cuando se
                      realice la transferencia de datos personales por contar con autorización expresa de los usuarios
                      para proceder en este sentido. Cuando así se lo exija o permita la normatividad vigente, DPLOY
                      garantizará el acceso a los datos personales recaudados a terceros, entre los cuales pueden estar
                      entidades públicas, funcionarios judiciales o entidades de derecho privado.
                    </p>
                    <p>
                      Cuando DPLOY actúe como encargado del tratamiento de datos personales captados por un responsable
                      garantizará a este último y el personal que aquél determine el acceso a los datos que se encuentre
                      tratando como encargado. El acceso concedido por el encargado al responsable se regulará conforme
                      con el contrato suscrito entre dichas partes.
                    </p>
                    <p>
                      DPLOY implementará las medidas de seguridad de la información que resulten necesarias para
                      garantizar la confidencialidad y seguridad del tratamiento de los datos personales recaudados.
                    </p>
                  </Element>
                  <Element id="paragraph17">
                    <h2>Atención de peticiones, consultas y reclamos</h2>
                    <p>
                      El área de gerencia de DPLOY será la encargada de atender y dar trámite a las peticiones,
                      consultas y reclamos realizadas por los titulares para hacer efectivos sus derechos. Para el
                      desempeño del presente encargo, el área encargada contará con el apoyo económico, técnico,
                      jurídico y administrativo de la organización.
                    </p>
                    <p>
                      Toda petición, queja, consulta o reclamo debe ser remitida a DPLOY S.A.S. a través del correo
                      electrónico <a href="mailto:contacto@dploy.site">contacto@dploy.site</a> o mediante comunicación
                      física radicada en la Calle 3 #12B - 41 APTO 402 del municipio de San Gil.
                    </p>
                  </Element>
                  <Element id="paragraph18">
                    <h2>Requisito de procedibilidad</h2>
                    <p>
                      Se informa a los titulares o sus causahabientes, que de conformidad con el artículo 16 de la ley
                      1581 de 2012, solo se podrán interponer quejas ante la Superintendencia de Industria y Comercio,
                      en relación con el tratamiento de datos personales, una vez se haya agotado el trámite de consulta
                      y/o reclamo ante DPLOY.
                    </p>
                  </Element>
                  <Element id="paragraph19">
                    <h2>
                      Procedimiento para la realización de consultar y reclamos en desarrollo del derecho de habeas data
                    </h2>
                    <p>
                      DPLOY tendrá la obligación de garantizar al titular de los datos personales recaudados los
                      derechos de consulta, actualización, rectificación, supresión y demás que le otorgue la ley
                      respecto de la información que le ha entregado al responsable del tratamiento.
                    </p>
                    <p>Toda petición, queja, consulta o reclamo será resuelta por el área de gerencia de DPLOY.</p>
                    <p>
                      La realización de consultas o reclamos sobre el tratamiento de datos personales implican el
                      siguiente procedimiento:
                    </p>
                    <p>
                      El titular del dato o su representante legal, remitirá una comunicación escrita por correo
                      electrónico o comunicación física a una de las direcciones que serán señaladas posteriormente, en
                      donde indique:
                    </p>
                    <ol>
                      <li>Nombre completo y apellidos.</li>
                      <br></br>
                      <li>
                        Datos de contacto que deben incluir su dirección física y/o de correo electrónico, así como
                        también un número telefónico de contacto.
                      </li>
                      <br></br>
                      <li>La definición del medio por el cual desea recibir la respuesta a su solicitud.</li>
                      <br></br>
                      <li>
                        La calidad en que actúa (titular, representante, causahabiente), demostrando la misma. Esto con
                        el propósito de evitar suplantaciones personales.
                      </li>
                      <br></br>
                      <li>
                        La descripción clara y precisa de la consulta o reclamo que pretende que le sea resuelta, junto
                        a la descripción de los hechos que fundamentan su petición.
                      </li>
                      <br></br>
                      <li>
                        La descripción clara y precisa del derecho que desea ejercer (conocer, actualizar, rectificar,
                        solicitar prueba de la autorización otorgada, revocarla, suprimir, acceder a la información).
                      </li>
                      <br></br>
                      <li>
                        Los documentos que pretenda hacer valer como pruebas, los cuales deben adjuntarse a la
                        comunicación.
                      </li>
                      <br></br>
                      <li>Los demás que considere necesarios para demostrar su interés y/o necesidad de consulta.</li>
                      <br></br>
                      <li>
                        La imposición de su firma cuando resulte aplicable y el número de su identificación personal.
                      </li>
                    </ol>
                    <p>
                      Si lo que se ha recibido es una <b>CONSULTA,</b> DPLOY procederá dentro de los diez (10) días
                      hábiles contados desde la fecha de recepción de la solicitud a dar respuesta a la misma. En caso
                      de que dentro del término establecido no se pueda dar respuesta, DPLOY informará de esta situación
                      al titular y le indicará la fecha en que resolverá su solicitud. La nueva fecha no podrá ser
                      posterior a los cinco (5) días hábiles siguientes al vencimiento del termino inicial. Toda
                      respuesta a una consulta será realizada por el medio en que la misma fue recibida o a través del
                      cual señale el solicitante en la comunicación recibida o de manera posterior.
                    </p>
                    <p>
                      En caso de que lo recibido sea un <b>RECLAMO</b> y:
                    </p>
                    <ol>
                      <li>
                        El mismo resulte incompleto, se procederá a requerir dentro de los cinco (5) días hábiles
                        siguientes al remitente para que complete su solicitud. Si transcurren dos (2) meses desde que
                        se ha requerido al remitente para que complete la solicitud, sin que el mismo proceda de
                        conformidad, se entenderá desistido el reclamo.
                      </li>
                      <br></br>
                      <li>
                        Quien lo reciba no sea competente para resolverlo, aquél procederá a remitirlo a quien
                        verdaderamente corresponda dentro de los dos (2) días hábiles siguientes a la fecha de recibido
                        e informará de tal situación al interesado.
                      </li>
                      <br></br>
                      <li>
                        El mismo resulta completo, se procederá de la siguiente manera:
                        <br></br>
                        <br></br>
                        <ol type="a">
                          <li>
                            Se incluirá en la base de datos una leyenda que indique “reclamo en trámite” y el motivo de
                            este. La inscripción se realizará dentro de los dos (2) días siguientes a aquel en que se
                            recibió el reclamo. La leyenda se mantendrá mientras que el reclamo sea decidido.
                          </li>
                          <br></br>
                          <li>
                            Se dará solución al reclamo dentro de los quince (15) días hábiles siguientes a la fecha de
                            recibido. En caso de que dentro del término establecido no se pueda dar respuesta, DPLOY
                            informará de esta situación al titular y le indicará la fecha en que resolverá su solicitud;
                            la nueva fecha no podrá ser posterior a los ocho (8) días hábiles siguientes al vencimiento
                            del termino inicial. Toda respuesta a un reclamo será realizada por el medio en que el mismo
                            fue recibido o a través del cual señale el solicitante de manera posterior.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </Element>
                  <Element id="paragraph20">
                    <h2>Aviso de privacidad</h2>
                    <p>
                      DPLOY dará aviso a toda persona respecto de la cual pueda llegar a tratar sus datos personales,
                      sobre la existencia de esta política de privacidad, los derechos que le corresponden como titular
                      del derecho de habeas data y de las diversas finalidades para las cuales realiza el recaudo de
                      datos personales. El aviso será emitido a través de documentos físicos, electrónicos y de
                      cualquier otro tipo que permitan cumplir con le legislación vigente.
                    </p>
                    <p>El aviso de privacidad contendrá la siguiente información:</p>
                    <ol type="a">
                      <li>El nombre, razón o denominación social de DPLOY, su domicilio y datos de contacto.</li>
                      <br></br>
                      <li>
                        El tipo de tratamiento al que serán sometidos los datos personales y la finalidad de su recaudo.
                      </li>
                      <br></br>
                      <li>Los derechos que tiene respecto del tratamiento al cual son sometidos sus datos.</li>
                      <br></br>
                      <li>
                        Los mecanismos dispuestos por DPLOY para que el titular pueda conocer y/o consultar la política
                        de tratamiento de información y los cambios producidos en esta.
                      </li>
                    </ol>
                  </Element>
                  <Element id="paragraph21">
                    <h2>Actualización de la política de protección de datos</h2>
                    <p>
                      DPLOY se reserva el derecho a modificar total o parcialmente el presente documento y en caso de
                      que así suceda, se obliga a dar aviso a los titulares de los datos recaudados. En caso de que la
                      presente política resulte modificada, se remitirá a cada titular una notificación electrónica o
                      material, en la cual se detallen los cambios realizados y el impacto que los mismos tienen
                      respecto del tratamiento de sus datos personales. Si el titular no acepta los cambios y
                      modificaciones, podrá dar por terminada su vinculación jurídica con DPLOY de manera unilateral y
                      revocar la autorización de tratamiento, cuando así se lo permita la ley. En todo aquello que la
                      presente política o sus modificaciones o adiciones llegaren a resultar contrarias a la legislación
                      vigente, en detrimento de los titulares de datos personales, se preferirá la aplicación directa de
                      la ley.
                    </p>
                  </Element>
                  <Element id="paragraph22">
                    <h2>Vigencia</h2>
                    <p>
                      La presente política de protección de datos personales entrará en vigor a partir del día catorce
                      (14) de diciembre de 2020. Los datos personales que resulten recolectados por DPLOY permanecerán
                      en sus bases de datos durante el tiempo que resulte necesario para el cumplimiento de las
                      finalidades establecidas en la solicitud de autorización de tratamiento y esta política. Una vez
                      se cumplan esas finalidades y siempre que no exista un deber legal o contractual de conservar su
                      información, sus datos serán eliminados de nuestras bases de datos. Como mínimo, los datos
                      personales proporcionados se conservarán mientras se mantenga la relación contractual con el
                      Titular de la información. DPLOY conforme con las finalidades declaradas podrá conservar los datos
                      personales recaudados mientras no se solicite su supresión por el interesado y siempre que no
                      exista un deber legal de conservarlos.
                    </p>
                  </Element>
                  <Element id="paragraph23">
                    <h2 className="text-center">Clausulas y autorizaciones</h2>
                    <li type="a">
                      Autorización para el uso de HOME VOICE (cuando el tratamiento se realice mediando tratamiento de
                      datos por DPLOY S.A.S.)
                    </li>
                    <br></br>
                    <p>
                      Al momento de dar clic en el botón ACEPTAR, AUTORIZO a DPLOY S.A.S., sociedad comercial con
                      domicilio en la ciudad de San Gil e identificada con Nit No. 900.960.413-1 para que realice el
                      tratamiento de mi VOZ vinculada a un perfil de usuario de la aplicación HOME VOICE, con el fin de
                      que la misma sea captada e intepretada por dicho software para efectos de dejar un record del
                      manejo de inventarios y pedidos que realizo y en general para desarrollar procesos de logística en
                      el manejo de inventarios y envíos apoyado por un asistente de voz. En relación con lo anterior, me
                      permito señalar que he sido informado de que tengo la facultad de solicitar la consulta,
                      actualización, rectificación y supresión de mis datos personales al correo electrónico
                      contacto@dploy.site cuando la ley me lo permita y que sé que puedo consultar en la página web
                      https://dploy.site/ las Políticas de Tratamiento de la Información de DPLOY S.A.S. para conocer
                      más acerca de mis derechos y obligaciones en relación con la presente autorización.
                    </p>
                    <li type="a">
                      Autorización para el uso de HOME VOICE (cuando el tratamiento se realice mediando tratamiento de
                      datos por DPLOY S.A.S.)
                    </li>
                    <br></br>
                    <p>
                      Al momento de dar clic en el botón ACEPTAR, AUTORIZO a COMERCIALIZADORA SANTANDER S.A.S., sociedad
                      comercial con domicilio en la ciudad de Bogotá e identificada con Nit No. 816.004.998-3 para que
                      realice el tratamiento de mi VOZ vinculada a un perfil de usuario de la aplicación HOMEVOICE, con
                      el fin de que la misma sea captada e intepretada por dicho software para efectos de dejar un
                      record del manejo de inventarios y pedidos que realizo y en general para desarrollar procesos de
                      logística en el manejo de inventarios y envíos apoyado por un asistente de voz. En relación con lo
                      anterior, me permito señalar que he sido informado de que tengo la facultad de solicitar la
                      consulta, actualización, rectificación y supresión de mis datos personales al correo electrónico
                      servicio.cliente@comersantander.com cuando la ley me lo permita y, que sé que puedo consultar en
                      la página web www.homeelementsweb.com las Políticas de Tratamiento de la Información de
                      COMERCIALIZADORA SANTANDER S.A.S. para conocer más acerca de mis derechos y obligaciones en
                      relación con la presente autorización.
                    </p>
                    <li type="a">Autorización capacitaciones</li>
                    <br></br>
                    <p>
                      Al momento de incorporar mi nombre y mis datos en el listado de asistencia, AUTORIZO libre y
                      voluntariamente a DPLOY S.A.S., sociedad comercial con domicilio en la ciudad de San Gil e
                      identificada con Nit No. 900.960.413-1 para que recolecte, circule, almacene y en general realice
                      actos de tratamiento sobre los datos personales que entrego, con la finalidad de que pueda
                      ofrecerme sus productos o servicios por medio de correos electrónicos, mensajes de texto,
                      aplicaciones de mensajería móvil como WhatsApp, publicidad en línea y otros relacionados. En
                      relación con lo anterior, me permito señalar que he sido informado de que tengo la facultad de
                      solicitar la consulta, actualización, rectificación y supresión de mis datos personales al correo
                      electrónico contacto@dploy.site, y que sé que puedo consultar en la página web https://dploy.site/
                      las Políticas de Tratamiento de la Información de DPLOY S.A.S. para conocer más acerca de mis
                      derechos y obligaciones en relación con la presente autorización
                    </p>
                    <li type="a">Autorización Web:</li>
                    <br></br>
                    <p>
                      Al momento de dar clic en el botón aceptar, AUTORIZO a DPLOY S.A.S., sociedad comercial con
                      domicilio en la ciudad de San Gil e identificada con Nit No. 900.960.413-1 para que recolecte,
                      circule, almacene y en general realice actos de tratamiento sobre los datos personales que entrego
                      a través del siguiente formulario, con la finalidad de que pueda ofrecerme sus productos o
                      servicios por medio de correos electrónicos, mensajes de texto, aplicaciones de mensajería móvil
                      como WhatsApp, publicidad en línea y otros relacionados. En relación con lo anterior, me permito
                      señalar que he sido informado de que tengo la facultad de solicitar la consulta, actualización,
                      rectificación y supresión de mis datos personales al correo electrónico contacto@dploy.site, y que
                      sé que puedo consultar en la página web https://dploy.site/ las Políticas de Tratamiento de la
                      Información de DPLOY S.A.S. para conocer más acerca de mis derechos y obligaciones en relación con
                      la presente autorización.
                    </p>
                  </Element>
                </div>
              </div>
              <aside className="col-md-4 blog-sidebar">
                <div className="p-4 mb-3 bg-light rounded">
                  <h4 className="font-italic">Acerca de la Política</h4>
                  <p className="mb-0 text-justify">
                    Bienvenido a Dploy! Le invitamos a acceder a nuestros sitios web y a utilizar nuestros servicios,
                    pero tenga en cuenta que su uso está sujeto a su aceptación de la{" "}
                    <strong>Politica de privacidad</strong>. Este documento describe en detalle sus derechos y nuestros
                    derechos en relación al tratamiento de datos personales.
                  </p>
                </div>
              </aside>
            </div>
          </main>
          <footer className="blog-footer">
            <small>© 2016-2020 Josphine Labs.</small>
          </footer>
        </div>
      </React.Fragment>
    );
  }
}

export default Politics;
