import { http } from "../components/http";
import { ShowAlert } from "../components/utilities";
import environment from "../env/env";
const apiUrl = environment.url;

export const ApiUsa = {
  floor: async function (id, project_id) {
    return await http("GET", `${apiUrl}/usa/floor/${id}?project_id=${project_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setFloor: async function (id, params, background) {
    return await http("POST", `${apiUrl}/usa/floor/${id}`, params, background)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateFloor: async function (id, state) {
    return await http("PATCH", `${apiUrl}/usa/floor/${id}`, { state })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setOrder: async function (id, params, background) {
    return await http("POST", `${apiUrl}/usa/order/${id}`, params, background)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateOrder: async function (area, line, _order, state) {
    return await http("PATCH", `${apiUrl}/usa/order/${area}`, { line, _order, state })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  deleteOrder: async function (area, line, _order, code) {
    return await http("DELETE", `${apiUrl}/usa/order/${area}`, { line, _order, code })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setLine: async function (id, params, background) {
    return await http("POST", `${apiUrl}/usa/order/line/${id}`, params, background)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  listFloorAreaSummer: async function (area) {
    return await http("GET", `${apiUrl}/usa/floor/area/summary/?area=${area}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  listAreaLines: async function (area) {
    return await http("GET", `${apiUrl}/usa/floor/area/lines/?area=${area}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  orderSummary: async function (area, line, _order) {
    return await http("GET", `${apiUrl}/usa/order/summary/?area=${area}&line=${line}&_order=${_order}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
};
