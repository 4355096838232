import React from "react";
import ModalOptions from "../modals/modalOptions";
import ModalPrint from "../modals/modalPrint"
import { Button } from "./inputs";

function ModalFooter(props) {
  const single = props.single || "";
  const plural = props.plural || "";
  const change = props.change || false;
  const _state = props._state || "0";

  return (
    <React.Fragment>
      <div className="float-left btn-toolbar">
        <ModalOptions
          className={
            props.onRemove === undefined
              ? "d-none"
              : _state !== '0'
              ? "d-none"
              : change
              ? "d-none"
              : props.isNew
              ? "d-none"
              : "btn btn-primary btn-sm mr-2"
          }
          params={{
            title: `Borrar ${single}?`,
            description: `¿Desea borrar ${plural}?`,
          }}
          change={true}
          onClose={props.onRemove}
        >
          Borrar
        </ModalOptions>
        <ModalOptions
          className={
            props.onFinish === undefined
              ? "d-none"
              : _state !== '0'
              ? "d-none"
              : change
              ? "d-none"
              : props.isNew
              ? "d-none"
              : props.project_id === "-1"
              ? "d-none"
              : props._state === "0"
              ? "btn btn-outline-secondary btn-sm mr-2"
              : "d-none"
          }
          params={{
            title: `¿Terminar ${single}?`,
            description: `¿Desea dejar de usar ${plural}?`,
          }}
          change={true}
          onClose={props.onFinish}
        >
          Terminar
        </ModalOptions>
        <ModalOptions
          className={
            props.onRestore === undefined
              ? "d-none"
              : change
              ? "d-none"
              : props.isNew
              ? "d-none"
              : props.project_id === "-1"
              ? "d-none"
              : props._state === "1"
              ? "btn btn-outline-secondary btn-sm mr-2"
              : "d-none"
          }
          params={{
            title: `¿Restaurar ${single}?`,
            description: `¿Desea restaurar ${plural}?`,
          }}
          change={true}
          onClose={props.onRestore}
        >
          Restaurar
        </ModalOptions>
        <ModalPrint
          className={
            props.printParams === undefined
              ? "d-none"
              : change
              ? "d-none"
              : props.isNew
              ? "d-none"
              : props.project_id === "-1"
              ? "d-none"
              : props._state === "0"
              ? "btn btn-outline-secondary btn-sm mr-2"
              : "d-none"
          }
          params={props.printParams}
        >
          Imprimir
        </ModalPrint>
      </div>
      <ModalOptions
        className={
          props.onClose === undefined
            ? "d-none"
            : "btn btn-outline-secondary btn-sm"
        }
        change={props.change || false}
        onClose={props.onClose}
      >
        Cerrar
      </ModalOptions>
      <Button className={
          _state !== '0'
          ? "d-none"
          : "btn btn-primary btn-sm"
        }
        onClick={props.onOk}>
        Aceptar
      </Button>
    </React.Fragment>
  );
}

export default ModalFooter;
