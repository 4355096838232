import React from "react";
import ReactDOM from "react-dom";
import "../../styles/modal.scss";
import { showModal, hideModal, ShowInfo, msg, genId, getValue, formatDHM, formId, getSource } from "../../components/utilities";
import { Textarea, Count, Section } from "../../components/inputs";
import ModalHeader from "../../components/modalHeader";
import ModalFooter from "../../components/modalFooter";
import ModalClass from "./modalClass";
import { AssetClass } from "./listClass";
import { AssetReference } from "./listAssetReferences";
import ComponentProfileComponents from "./components/componentProfileComponents";
import ComponentProfileConnections from "./components/componentProfileConnections";
import { Api as Asset } from "../../services/asset";

class ModalProfile extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme();
    this.state = {
      form: "__ModalProfile",
      _id: "__ModalProfile",
      title: "Perfil de mantenimiento",
      project_id: props.project_id,
      rows: 15,
      show: false,
      old: data,
      data: data,
      references: {
        end: 0,
        all: 0,
      },
      printParams: {},
      change: false,
    };
  }

  handleScheme = (e) => {
    return {
      project_id: this.props.project_id,
      _class: "JS_PROFILE",
      _state: "0",
      _id: genId("-1"),
      caption: "",
      description: "",
      module_id: "MODULE-APP",
      class_id: getValue(this.props.data, "class_id", "-1"),
      class: getValue(this.props.data, "class", ""),
      reference_id: "-1",
      reference: "",
      sponsor_id: "-1",
      useful_life: 0,
      first_keep: 0,
      unity: "und",
      components: [],
      _data: {},
      _v: 1,
    };
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeClase = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        class_id: getValue(e, "_id", "-1"),
        class: getValue(e, "caption", ""),
      },
      change: true,
    });
  };

  handleChangeReference = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        reference_id: getValue(e, "_id", "-1"),
        reference: getValue(e, "caption", ""),
      },
      change: true,
    });
  };

  handleChangeValue = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.name]: Number(e.value),
      },
      change: true,
    });
  };

  handleChangeComponents = (e) => {
    const action = e.action;
    const item = e.data;
    const components = this.state.data.components;
    const index = components.findIndex((element) => element.class_id === item.class_id);
    if (action === "set" && index > -1) {
      components[index] = item;
    } else if (action === "set" && index === -1) {
      components.push(item);
    } else if (action === "del" && index > -1) {
      item.profile_id = "-1";
      components[index] = item;
    }
    this.setState({
      data: {
        ...this.state.data,
        components: components,
      },
      change: true,
    });
  };

  handleChangeConnections = (e) => {
    const action = e.action;
    const item = e.data;
    const connections = this.state.data.connections;
    const index = connections.findIndex((element) => element.class_id === item.class_id);
    if (action === "set" && index > -1) {
      connections[index] = item;
    } else if (action === "set" && index === -1) {
      connections.push(item);
    } else if (action === "del" && index > -1) {
      item.profile_id = "-1";
      connections[index] = item;
    }
    this.setState({
      data: {
        ...this.state.data,
        connections: connections,
      },
      change: true,
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
  };

  handleHide = () => {
    const _state = this.props._state || "0";
    if (typeof this.props.onHide === "function" && _state === "0") {
      this.props.onHide(this.state.data);
    }
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    if (this.props.isFind) {
      const _id = getValue(this.props, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    } else if (this.props.isNew) {
      const form_id = `${this.state.form}${Date.now()}`;
      this.setState({ _id: form_id, show: true, change: false });
    } else {
      const _id = getValue(this.props.data, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    }
  };

  handleClose = (e) => {
    if (this.state.change && e) {
      this.handleOk();
    } else {
      this.handleHide();
    }
  };

  handleOk = () => {
    if (this.state.change) {
      const id = this.state.data._id;
      const params = this.state.data;
      Asset.setProfile(id, params).then((result) => {
        if (result.msg === "") {
          let data = result.data;
          this.handleSetData({ data, isNew: false, change: false, focusInt: false });
          this.handleExecute(result.data);
        }
      });
    } else {
      this.handleHide();
    }
  };

  handleFinish = (e) => {
    if (e) {
      const id = this.state.data._id;
      Asset.stateProfile(id, "1").then((result) => {
        if (result.msg === "") {
          this.handleExecute(result.data);
          this.handleHide();
        }
      });
    }
  };

  handleRestore = (e) => {
    if (e) {
      const id = this.state.data._id;
      Asset.stateProfile(id, "0").then((result) => {
        if (result.msg === "") {
          this.handleExecute(result.data);
        }
      });
    }
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      isNew: e.isNew,
      change: e.change,
      _state: this.props.project_id === '37860631' ? "0" : e.data.project_id === this.props.project_id ? "0" : "1",
      printParams: {
        project_id: e.data.project_id,
        _class: e.data._class,
        single: true,
      },
    });
    showModal(this.state._id);
  };

  handleData = () => {
    const _id = getSource(this.props.data, ["_id"], "-1");
    if (_id === "-1") {
      this.handleSetData({ data: this.props.data, isNew: false, change: false });
    } else {
      this.handleFind(_id);
    }
  };

  handleNew = () => {
    const data = this.handleScheme();
    this.handleSetData({ data, isNew: true, change: true });
  };

  handleFind = (id) => {
    if (this.state.change) {
      ShowInfo(msg("MSG-CHANGE"));
    } else if (id !== "-1") {
      Asset.profile(id, this.props.project_id).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false });
        }
      });
    }
  };

  handleAccountant = (e) => {
    this.setState({ references: e });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        if (this.props.isNew) {
          this.handleNew();
        } else if (this.props.isFind) {
          this.handleFind(this.props._id);
        } else {
          this.handleData();
        }
      }
    }
  }

  componentWillUnmount() {
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog s __modalObject" role="dialog">
                <div className="modal-content">
                  <ModalHeader title={this.state.title} handleClose={this.handleClose} />
                  <div className="modal-body-tabs" style={{ height: "480px" }}>
                    <nav>
                      <div className="nav nav-tabs" role="tablist">
                        <a
                          className="nav-item nav-link active"
                          id={`${this.state._id}-nav-1-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-1`}
                          role="tab"
                          aria-selected="true"
                        >
                          General
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-2-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-2`}
                          role="tab"
                          aria-selected="false"
                        >
                          Componentes
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-3-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-3`}
                          role="tab"
                          aria-selected="false"
                        >
                          Conexiones
                        </a>
                      </div>
                    </nav>
                    <div className="tab-content modal not-scroll">
                      <div
                        className="tab-pane fade show active"
                        id={`${this.state._id}-nav-1`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-1-tab`}
                      >
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group input-group-sm">
                              <label>
                                <ModalClass
                                  className="link"
                                  project_id={this.props.project_id}
                                  _state={this.state.data._state}
                                  _id={this.state.data.class_id}
                                  isFind={true}
                                >
                                  Clase
                                </ModalClass>
                              </label>
                              <AssetClass
                                project_id={this.props.project_id}
                                _state={this.state.data._state}
                                equipment={true}
                                select={{
                                  _id: this.state.data.class_id,
                                  caption: this.state.data.class,
                                }}
                                onChange={this.handleChangeClase}
                              />
                            </div>
                            <div className="form-group input-group-sm">
                              <label>Nombre</label>
                              <Section
                                id={`${this.state._id}_caption`}
                                type="text"
                                className="form-control name"
                                placeholder="Nombre"
                                _state={this.state.data._state}
                                name="caption"
                                value={this.state.data.caption}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="form-group input-group-sm">
                              <label>Descripción</label>
                              <Textarea
                                className="form-control notresize"
                                placeholder="description"
                                rows={4}
                                _state={this.state.data._state}
                                name="description"
                                value={this.state.data.description}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="form-group input-group-sm">
                              <label>Referencia</label>
                              <AssetReference
                                type="text"
                                className="form-control"
                                placeholder="Referencia"
                                project_id={this.props.project_id}
                                class_id={this.state.data.class_id}
                                _state={this.state.data._state}
                                select={{
                                  _id: this.state.data.reference_id,
                                  caption: this.state.data.reference,
                                }}
                                onSetData={this.handleChangeReference}
                              />
                            </div>
                            <div className="form-row input-group-sm">
                              <div className="form-group col-md-6 input-group-sm">
                                <label>Vida util</label>
                                <Count
                                  id={`${this.state._id}_caption`}
                                  className="input-group input-group-sm"
                                  _state={this.state.data._state}
                                  name="useful_life"
                                  unity="Horas"
                                  value={this.state.data.useful_life}
                                  increase={24}
                                  handleValue={this.handleChangeValue}
                                />
                                <label className="float-right">{formatDHM(this.state.data.useful_life * 60)}</label>
                              </div>
                              <div className="form-group col-md-6 input-group-sm">
                                <label>Primer mantenimiento</label>
                                <Count
                                  id={`${this.state._id}_caption`}
                                  className="input-group input-group-sm"
                                  _state={this.state.data._state}
                                  name="first_keep"
                                  unity="Horas"
                                  value={this.state.data.first_keep}
                                  increase={24}
                                  handleValue={this.handleChangeValue}
                                />
                                <label className="float-right">{formatDHM(this.state.data.first_keep * 60)}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-2`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-2-tab`}
                      >
                        <ComponentProfileComponents
                          project_id={this.props.project_id}
                          profile_id={this.state.data._id}
                          _state={this.state.data._state}
                          data={this.state.data}
                          onChange={this.handleChangeComponents}
                          height="344px"
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-3`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-3-tab`}
                      >
                        <ComponentProfileConnections
                          project_id={this.props.project_id}
                          profile_id={this.state.data._id}
                          _state={this.state.data._state}
                          data={this.state.data}
                          onChange={this.handleChangeConnections}
                          height="320px"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooter
                      single="Perfil"
                      plural="el perfil"
                      project_id={this.state.data.project_id}
                      _state={this.state.data._state}
                      change={this.state.change}
                      onFinish={this.handleFinish}
                      onRestore={this.handleRestore}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

export default ModalProfile;
