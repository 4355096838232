import React from "react";
import ReactDOM from "react-dom";
import "../../styles/modal.scss";
import {
  showModal,
  hideModal,
  Subscribe,
  UnSubscribe,
  getValue,
  updateList,
  compare,
} from "../../components/utilities";
import ModalHeaderList from "../../components/modalHeaderList";
import ModalSearch from "../../components/modalSearch";
import ModalList from "../../components/modalList";
import ModalFooterList from "../../components/modalFooterList";
import ModalSelect from "../../components/modalSelect";
import ModalAsset from "./modalAsset";
import ModalAssetNew from "./modalAssetNew";
import { SelectAssetClass } from "./listClass";
import { Api as Assets } from "../../services/asset";

class ListAssets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "__listAssets",
      title: "Equipos",
      name: "assets",
      show: false,
      rows: 30,
      project_id: props.project_id,
      class_id: "-1",
      class: "",
      unity: "",
      data: {
        list: [],
        state: getValue(props, "state", "0"),
        search: getValue(props, "search", ""),
        page: 1,
        rows: 15,
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      select: {
        class_id: "-1",
        class: "",
        _id: "-1",
        code: "",
        caption: "",
      },
      change: false,
      update: {},
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleSetData = (e) => {
    this.handleUpdate(e);
    this.handleSelect(e);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeMain = (e) => {
    this.setState({
      class_id: e.target.value,
      class: e.target.caption,
      unity: e.target.unity,
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
    this.handleHide();
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({
      class_id: "-1",
      class: "",
      data: {
        ...this.state.data,
        search: "",
      },
      show: true,
    });
  };

  handleClose = () => {
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.change) {
      this.handleExecute(this.state.select);
    } else {
      this.handleHide();
    }
  };

  handleRemove = () => {
    this.handleSelect({});
    this.handleExecute({});
    this.handleHide();
  };

  handleData = (scroll) => {
    const project_id = this.props.project_id;
    const class_id = this.state.class_id;
    const search = this.state.data.search;
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Assets.equipments(project_id, class_id, search, page, rows, this.state.data.list).then((result) => {
        const data = result.data;
        this.setState({
          data: {
            ...this.state.data,
            list: data.list,
            search: data.search,
            page: data.page,
            rows: data.rows,
            int: data.int,
            end: data.end,
            count: data.count,
            all: data.all,
          },
        });
      });
    } else {
      Assets.equipments(project_id, class_id, search, 1, rows, [this.state.select]).then((result) => {
        const data = result.data;
        this.setState({
          data: {
            ...this.state.data,
            list: data.list,
            search: data.search,
            page: data.page,
            rows: data.rows,
            int: data.int,
            end: data.end,
            count: data.count,
            all: data.all,
          },
          change: false,
        });
      });
    }
  };

  handleInit = () => {
    const _id = getValue(this.props.select, "_id", "-1");
    let project_id = this.props.project_id;
    let class_id = this.props.class_id;
    let _class = this.props.class;
    let unity = this.props.unity;
    let select = this.props.select;
    if (_id !== "-1") {
      Assets.asset(_id, project_id).then((result) => {
        select = getValue(result, "data", {
          class_id: "-1",
          class: "",
          _id: "-1",
          code: "",
          caption: "",
        });
        this.setState({
          class_id: class_id,
          class: _class,
          unity: unity,
          select: select,
          change: false,
        });
      });
    } else {
      this.setState({
        class_id: class_id,
        class: _class,
        unity: unity,
        select: select,
        change: false,
      });
    }
  };

  handleSearch = () => {
    this.handleData(false);
  };

  handleSelect = (e) => {
    this.setState({ select: e, change: true });
  };

  handleUpdate = (e) => {
    if (compare(this.state.update, e)) {
      const data = updateList(this.state.data, e);
      this.setState({ data: data, change: true, update: e });
    }
  };

  componentDidMount() {
    Subscribe(`${this.state.class_id}/${this.state.project_id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.project_id !== this.state.project_id || prevState.class_id !== this.state.class_id) {
        UnSubscribe(`${this.state.class_id}/${this.state.project_id}`, (event) => this.eventSetData(event));
        Subscribe(`${this.state.class_id}/${this.state.project_id}`, (event) => this.eventSetData(event));
      }
      if (prevState.show !== this.state.show) {
        this.handleInit();
        showModal(this.state._id);
      } else if (prevState.class_id !== this.state.class_id) {
        this.handleData(false);
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.class_id}/${this.state.project_id}`, (event) => this.eventSetData(event));
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog xs __modalSingleList" role="document">
                <div className="modal-content">
                  <ModalHeaderList
                    title={this.state.title}
                    handleClose={this.handleClose}
                    state={this.state.data.state}
                    rows={this.state.data.end}
                    all={this.state.data.all}
                  />
                  <div className="modal-body">
                    <div className="modalList master select">
                      <div className="modalListMaster input-group-sm">
                        <SelectAssetClass
                          className="custom-select"
                          _state={this.props._state}
                          project_id={this.props.project_id}
                          equipment="TRUE"
                          name="class_id"
                          value={this.state.class_id}
                          onChange={this.handleChangeMain}
                        />
                      </div>
                      <div className="modalListFind">
                        <ModalSearch
                          name="search"
                          value={this.state.data.search}
                          onChange={this.handleChange}
                          onSearch={this.handleSearch}
                        />
                      </div>
                      <div className="modalListList">
                        <ModalList
                          id={`${this.state._id}_scroll`}
                          _state={this.state.data.state}
                          caption={this.props.caption}
                          list={this.state.data.list}
                          select={this.state.select}
                          getData={this.handleData}
                          onSelect={this.handleSelect}
                        />
                      </div>
                      <div className="modalListSelect">
                        <ModalSelect
                          project_id={this.props.project_id}
                          _state="0"
                          caption={this.props.caption}
                          select={this.state.select}
                          onSetData={this.handleSetData}
                          Modal={ModalAsset}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooterList
                      project_id={this.props.project_id}
                      _state={this.state.data._state}
                      data={{
                        project_id: this.props.project_id,
                        class_id: this.state.class_id,
                        class: this.state.class,
                        unity: this.state.unity,
                        create: this.props.create,
                      }}
                      Modal={ModalAssetNew}
                      onSetData={this.handleSetData}
                      onRemove={this.handleRemove}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function Asset(props) {
  const caption = props.caption || "code";
  const select = getValue(props, "select", {
    class_id: props.class_id || "-1",
    class: props.class || "",
    _id: "-1",
    code: "",
    caption: "",
    unity: "und",
  });

  return (
    <React.Fragment>
      <div className="input-group input-group-sm">
        <input className={props.className || "form-control"} readOnly value={select[caption] || ""} />
        <div className="input-group-append">
          <ListAssets
            className={props.classNameBtn}
            _state={props._state}
            caption={caption}
            project_id={props.project_id}
            class_id={select.class_id}
            class={select.class}
            unity={select.unity}
            select={select}
            create={props.create || false}
            onSetData={props.onChange}
          >
            <i className="fa fa-ellipsis-h"></i>
          </ListAssets>
        </div>
      </div>
    </React.Fragment>
  );
}

export { ListAssets, Asset };
