import React from "react";
import { formatInteger, formatDHM, formatNumber } from "../../../components/utilities";

function ComponentRCM(props) {
  return (
    <React.Fragment>
      <div className="suport-group border-0">
        <h6 className="text">Indicadores de confiabilidad y mantenimiento preventivo</h6>
        <div className="row">
          <div className="col-lg-6">
            <div className="ibox">
              <div className="ibox-title">TF</div>
              <div className="ibox-content">
                <h6 className="no-margins">{formatInteger(props.data.ft)}</h6>
                <small>Total fallas</small>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ibox">
              <div className="ibox-title">TTO</div>
              <div className="ibox-content">
                <h6 className="no-margins">{formatDHM(props.data.tto_now / 60)}</h6>
                <small>Tiempo total de operación</small>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ibox">
              <div className="ibox-title">TTR</div>
              <div className="ibox-content">
                <h6 className="no-margins">{formatDHM(props.data.ttr / 60)}</h6>
                <small>Tiempo total de restauración</small>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ibox">
              <div className="ibox-title">MTBF - CONFIABILIDAD</div>
              <div className="ibox-content">
                <h6 className="no-margins">
                  {formatDHM(props.data.mtbf / 60)}
                  <small>
                    <i
                      className={
                        props.data.mtbf_direction === "UP"
                          ? "fas fa-level-up-alt ml-1 success"
                          : props.data.mtbf_direction === "DOWN"
                          ? "fas fa-level-down-alt ml-1 danger"
                          : "d-none"
                      }
                    />
                  </small>
                </h6>
                <small>Tiempo medio entre fallas</small>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ibox">
              <div className="ibox-title">
                MTTRt - <small>MANTENIBILIDAD</small>
              </div>
              <div className="ibox-content">
                <h6 className="no-margins">
                  {formatDHM(props.data.mttrt / 60)}
                  <small>
                    <i
                      className={
                        props.data.mttrt_direction === "UP"
                          ? "fas fa-level-up-alt ml-1 danger"
                          : props.data.mttrt_direction === "DOWN"
                          ? "fas fa-level-down-alt ml-1 success"
                          : "d-none"
                      }
                    />
                  </small>
                </h6>
                <small>Tiempo medio para restauración</small>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ibox">
              <div className="ibox-title">DISPONIBILIDAD</div>
              <div className="ibox-content">
                <h6 className="no-margins">
                  {formatNumber(props.data.availability)}
                  <small>
                    <i
                      className={
                        props.data.availability_direction === "UP"
                          ? "fas fa-level-up-alt ml-1 success"
                          : props.data.availability_direction === "DOWN"
                          ? "fas fa-level-down-alt ml-1 danger"
                          : "d-none"
                      }
                    />
                  </small>
                </h6>
                <small>Indice de disponibilidad</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ComponentRCM;
