import React from "react";
import "../../styles/view.scss";
import "../../styles/suport.scss";
import { formatInteger, Subscribe, UnSubscribe } from "../../components/utilities";
import Td from "../../components/td";
import { Api } from "../../services/asset";
import { connect } from "react-redux";

class TabAreaEquipments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "__TabAreaEquipments",
      _class: "ASSETS",
      rows: this.props.view_rows,
      class_id: props.class_id || "ALL",
      data: {
        list: [],
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        all: 0,
      },
      change: false,
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  eventDelData = (e) => {
    setTimeout(() => {
      this.handleDelete(e);
    }, 500);
  };

  handleData = (scroll) => {
    const project_id = this.props.project_id;
    const class_id = this.state.class_id;
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Api.meassureAreas(project_id, class_id, page, rows, this.state.data.list).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.setState({ data: data });
        }
      });
    } else {
      Api.meassureAreas(project_id, class_id, 1, rows).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.setState({ data: data });
        }
      });
    }
  };

  handleUpdate = (e) => {
    if (e.equipment === true) {
      this.handleData(false);
    }
  };

  handleDelete = (e) => {
    this.handleData(false);
  };

  handleScroll = (e) => {
    const h = e.target.scrollTop + e.target.clientHeight + 1;
    if (e.target.id === `${this.state._id}_Scroll` && h >= e.target.scrollHeight) {
      this.handleData(true);
    }
  };

  handleGoDetail = (e) => {
    const title = `${e.area.caption === "" ? "Área no asignada**" : e.area.caption} - ${e.clase.caption}`;
    this.props.history.push("./equipment", {
      origen: this.props._view,
      filter: {
        project_id: this.props.project_id,
        type: "area",
        title: title,
        area_id: e.area._id,
        area: e.area.caption,
        class_id: e.clase._id,
        clase: e.clase.caption,
        quantity: e.clase.quantity,
      },
    });
  };

  componentDidMount() {
    Subscribe(`delete/${this.props._class}/${this.props.project_id}`, (event) => this.eventDelData(event));
    Subscribe(`${this.props._class}/${this.props.project_id}`, (event) => this.eventSetData(event));
    this.handleData(false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps._view === this.props._view && this.props.project_id !== "-1") {
      if (prevProps.project_id !== this.props.project_id || prevProps._class !== this.props._class) {
        UnSubscribe(`delete/${prevProps._class}/${prevProps.project_id}`, (event) => this.eventDelData(event));
        UnSubscribe(`${prevProps._class}/${prevProps.project_id}`, (event) => this.eventSetData(event));
        Subscribe(`delete/${this.props._class}/${this.props.project_id}`, (event) => this.eventDelData(event));
        Subscribe(`${this.props._class}/${this.props.project_id}`, (event) => this.eventSetData(event));
        this.handleData(false);
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`delete/${this.props._class}/${this.props.project_id}`, (event) => this.eventDelData(event));
    UnSubscribe(`${this.props._class}/${this.props.project_id}`, (event) => this.eventSetData(event));
  }

  render() {
    return (
      <React.Fragment>
        <div className="ibox mb-0">
          <div className="ibox-title">Equipos instalados por área - Total de áreas ({this.state.data.all})</div>
          <div className="ibox-content table">
            <Td className="suport-head" cols="200px auto 81px 80px">
              <div className="suport-td">Area</div>
              <div className="suport-td">Clases</div>
              <div className="suport-td">Cantidad</div>
              <div className="suport-td">Total</div>
            </Td>
            <div className="suport-scroll auto" id={`${this.state._id}_Scroll`} onScroll={this.handleScroll}>
              {this.state.data.list.map((item, i) => {
                return (
                  <Td key={i} className="suport-row" cols="200px auto 76px">
                    <div className="suport-td caption middle b-g">
                      <div className="text-middle">{item.caption === "" ? "Área no asignada**" : item.caption}</div>
                    </div>
                    <div className="suport-td c">
                      {item.equipments.map((subitem, j) => {
                        return (
                          <Td key={j} className="suport-row" cols="auto 80px">
                            <div
                              className="suport-td caption link"
                              onClick={() =>
                                this.handleGoDetail({
                                  area: item,
                                  clase: subitem,
                                })
                              }
                            >
                              {subitem.caption}
                            </div>
                            <div className="suport-td value">{formatInteger(subitem.quantity)}</div>
                          </Td>
                        );
                      })}
                    </div>
                    <div className="suport-td value middle b-g">
                      <div className="text-middle-right">{formatInteger(item.quantity)}</div>
                    </div>
                  </Td>
                );
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    _view: state.sistem.folder._view || "",
    view_rows: state.sistem.view_rows,
  };
}

export default connect(mapStateToProps)(TabAreaEquipments);
