import React from "react";
import Td from "../../../components/td";
import ModalSearchMenu from "../../../components/modalSearchMenu";
import { formatInteger, getData, getDateFormat, getValue, Subscribe, UnSubscribe, updateList } from "../../../components/utilities";
import { Api as Keep } from "../../../services/keep";
import ModalAsset from "../../assets/modalAsset";

class InformAssets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "__Assets",
      title: "Equipos",
      name: "ASSETS",
      show: false,
      rows: 30,
      data: {
        list: [],
        state: "ALL",
        search: "",
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      select: { _id: "-1", caption: "" },
      change: false,
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeState = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        search: "",
        state: e,
      },
    });
  };

  handleUpdate = (e) => {
    const data = updateList(this.state.data, e);
    this.setState({ data: data });
  };

  handleSearch = () => {
    this.handleData(false);
  };

  handleData = (scroll) => {
    const _id = this.props._id;
    const state = this.state.data.state;
    const search = this.state.data.search;
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Keep.informAssets(_id, state, search, page, rows, this.state.data.list).then((result) => {
        const data = result.data;
        this.setState({
          data: data,
        });
      });
    } else {
      Keep.informAssets(_id, state, search, 1, rows, []).then((result) => {
        const data = result.data;
        this.setState({
          data: data,
        });
      });
    }
  };

  handleScroll = (e) => {
    const h = e.target.scrollTop + e.target.clientHeight + 1;
    if (e.target.id === `${this.state._id}_scroll` && h >= e.target.scrollHeight) {
      this.handleData(true);
    }
  };

  componentDidMount() {
    Subscribe(`${this.state.name}/${this.props._id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show) {
      if (prevProps.show !== this.props.show) {
        this.handleData(false);
      } else if (prevState.data.state !== this.state.data.state) {
        this.handleData(false);
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.name}/${this.props._id}`, (event) => this.eventSetData(event));
  }

  render() {
    return (
      <React.Fragment>
        <Td className="suport-tool" cols="34% auto">
          <ModalSearchMenu
            name="search"
            value={this.state.data.search}
            state={this.state.data.state}
            onChange={this.handleChange}
            onSearch={this.handleSearch}
            onState={this.handleState}
            states={[
              { _state: "ALL", caption: "Todos" },
              { _state: "0", caption: "Activos" },
              { _state: "1", caption: "Terminados" },
            ]}
          />
          <div className={this.state.data.all > 0 ? "scroll-count" : "d-none"}>
            <p>
              <b className="scroll-count-end">{formatInteger(this.state.data.end)}</b>
              de
              <b className="scroll-count-all">{formatInteger(this.state.data.all)}</b>
            </p>
          </div>
        </Td>
        <div className="suport mb-0">
          <div className="suport-group mb-0">
            <Td className="suport-head" cols="115px 80px 140px 160px auto 94px">
              <div className="suport-td">Fecha</div>
              <div className="suport-td">Equipo</div>
              <div className="suport-td">Clase</div>
              <div className="suport-td">Tipo</div>
              <div className="suport-td">Sitio</div>
              <div className="suport-td">Operación</div>
            </Td>
            <div className="suport-scroll small" id={`${this.state._id}_scroll`} onScroll={this.handleScroll}>
              {this.state.data.list.map((item, i) => {
                return (
                  <ModalAsset key={i} project_id={item.project_id} isFind={true} _id={item.asset_id}>
                    <Td className="suport-item" cols="25px 90px 80px 140px 160px auto 90px">
                      <div className="suport-td t-c">{i + 1}</div>
                      <div className="suport-td t-c">{getDateFormat(item, "date_int", "MMM d, yyyy", "")}</div>
                      <div className="suport-td t-c">{getValue(item, "asset_code", "")}</div>
                      <div className="suport-td">{getData(item, "asset_class", "")}</div>
                      <div className="suport-td">{getData(item, "asset_type", "")}</div>
                      <div className="suport-td">{getData(item, "address", "")}</div>
                      <div className="suport-td t-c">{getValue(item, "code", "")}</div>
                    </Td>
                  </ModalAsset>
                );
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default InformAssets;
