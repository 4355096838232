import React from "react";
import "../../../styles/modal.scss";
import { Subscribe, UnSubscribe, getDifferenceInDays, formatDate, updateList, ShowAlert } from "../../../components/utilities";
import ModalOperation from "../../keep/modalOperation";
import ModalOptions from "../../modalOptions";
import { Api as Asset } from "../../../services/asset";

class ComponentHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "__ComponentHistory",
      title: "Historico",
      name: "SUPORT-KEEP-OPERATION",
      show: false,
      rows: 30,
      data: {
        list: [],
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      select: { index: 0, caption: "" },
      change: false,
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(false);
    }, 500);
  };

  handleData = (scroll) => {
    const _id = this.props._id;
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Asset.getHisotry(_id, page, rows, this.state.data.list).then((result) => {
        const data = result.data;
        this.setState({
          show: this.props.show,
          data: data,
        });
      });
    } else {
      Asset.getHisotry(_id, 1, rows, []).then((result) => {
        const data = result.data;
        this.setState({
          show: this.props.show,
          data: data,
          change: false,
        });
      });
    }
  };

  handleUndo = (e) => {
    if (e.result) {
      if (this.props.change) {
        ShowAlert("Hay cambios pendientes por guardar");
      } else {
        Asset.undoHisotry(e.item._id, e.item.asset_id).then((result) => {
          const data = result.data;
          this.setState({
            data: data,
            change: false,
          });
          if (typeof this.props.onSetData === "function") {
            this.props.onSetData(e);
          }
        });
      }
    }
  };

  handleSelect = (e) => {
    this.setState({ select: e, change: true });
  };

  handleUpdate = (e) => {
    if (this.state.show) {
      const data = updateList(this.state.data, e);
      this.setState({ data: data, change: true });
    }
  };

  componentDidMount() {
    Subscribe(`${this.props._class}/${this.props._id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show) {
      if (prevProps._class !== this.props._class || prevProps._id !== this.props._id) {
        UnSubscribe(`${prevProps._class}/${prevProps._id}`, (event) => this.eventSetData(event));
        Subscribe(`${this.props._class}/${this.props._id}`, (event) => this.eventSetData(event));
      }
      if (prevProps.show !== this.props.show) {
        this.handleData(false);
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.props._class}/${this.props._id}`, (event) => this.eventSetData(event));
    this.setState({ show: false });
  }

  render() {
    const list = this.state.data.list || [];
    return (
      <React.Fragment>
        <React.Fragment>
          <h6 className="text">Historicos</h6>
          <div className="modal_content suport-scroll small" style={{ height: this.props.height || "455px" }}>
            <div className="vertical-timeline vertical-container dark-timeline">
              {list.map((item, i) => {
                return (
                  <div key={i} className="vertical-timeline-block">
                    <div className={item.document_id === "-1" ? "vertical-timeline-icon bg-success" : "vertical-timeline-icon bg-primary"}>
                      <i className={item.document_id === "-1" ? "fa fa-briefcase" : "fas fa-paste"}></i>
                    </div>
                    <div className="vertical-timeline-content">
                      <ModalOperation
                        className={item.document_id === "-1" ? "d-none" : "link"}
                        project_id={item.project_id}
                        isFind={true}
                        _id={item.document_id}
                      >
                        {item.caption}
                      </ModalOperation>
                      <h6 className={item.document_id === "-1" ? "" : "d-none"}>{item.caption}</h6>
                      <p>{item.changes}.</p>
                      <span className="vertical-date">
                        <p>Hace {getDifferenceInDays(item, "date_of", true)}</p>
                        <small>{formatDate(item.date_of, "MMM d, yyyy hh:mm aa")}</small>
                      </span>
                      <ModalOptions
                        className={i === 0 ? "btn btn-sm btn-primary" : "d-none"}
                        params={{
                          title: `Reversar`,
                          description: `¿Desea revertir los cambios?`,
                        }}
                        change={true}
                        onClose={(result) => this.handleUndo({ result, item })}
                      >
                        Reversar
                      </ModalOptions>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default ComponentHistory;
