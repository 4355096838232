import React from "react";
import ReactDOM from "react-dom";
import "../../styles/modal.scss";
import { showModal, hideModal, Subscribe, UnSubscribe, updateList, compare, getValue, emptyValue, getStorage } from "../../components/utilities";
import ModalHeaderList from "../../components/modalHeaderList";
import ModalSearchMenu from "../../components/modalSearchMenu";
import ModalStock from "../../components/modalStock";
import ModalFooterList from "../../components/modalFooterList";
import ModalSelect from "../../components/modalSelect";
import ModalReference from "../warehouse/modalReference";
import ComponentReferences from "../warehouse/components/componentReferences";
import { Api as Keep } from "../../services/keep";
import { Api as Assets } from "../../services/asset";
import { Api as Project } from "../../services/project";

class ListActivityStock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "__ListActivityStock",
      title: "Referencias",
      name: "keepReferences",
      show: false,
      rows: 30,
      reference: false,
      consumption: false,
      warehouse: false,
      controlConsumptions: false,
      class_id: "-1",
      class_name: "",
      data: {
        list: [],
        state: "0",
        search: "",
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      select: { _id: "-1", caption: "" },
      change: false,
      update: {},
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleSetData = (e) => {
    this.handleUpdate(e);
    this.handleSelect(e);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeClass = (e) => {
    this.setState({
      class_id: e,
    });
  };

  handleModuleWarehouse = () => {
    const modules = getStorage(["sistem", "project", "modules"], {});
    const index = modules.findIndex((element) => element._id === "MODULE-WAREHOUSE" && element.chk === true);
    return index > -1;
  };

  handleControlConsumptions = async () => {
    return await Project.getVar(this.props.project_id, "controlConsumptions", true).then((result) => {
      return result;
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      e.consumption = !this.state.reference;
      this.props.onSetData(e);
    }
    this.handleHide();
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    this.handleControlConsumptions().then((controlConsumptions) => {
      const select = emptyValue(this.props, "select", { _id: "-1", caption: "" });
      const warehouse = this.handleModuleWarehouse();
      const consumption = [1, 3, 4, 7].indexOf(this.props.activity_base) > -1;
      const class_id = this.props.class_id;
      const class_name = this.props.class_name;
      controlConsumptions = controlConsumptions.toLowerCase() === "true";
      this.setState({
        consumption: consumption,
        warehouse: warehouse,
        controlConsumptions: controlConsumptions,
        class_id: class_id,
        class_name: class_name,
        select: select,
        data: {
          ...this.state.data,
          search: "",
        },
        show: true,
      });
    });
  };

  handleClose = () => {
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.change) {
      this.handleExecute(this.state.select);
    } else {
      this.handleHide();
    }
  };

  handleRemove = () => {
    this.handleSelect({});
    this.handleExecute({});
    this.handleHide();
  };

  handleData = (scroll) => {
    const vale_id = this.props.vale_id;
    const class_id = this.state.class_id;
    const search = this.state.data.search;
    const rows = this.state.rows;
    const consumption = this.state.consumption;
    const warehouse = this.state.warehouse;
    const controlConsumptions = this.state.controlConsumptions;
    if ((warehouse && consumption) || (controlConsumptions && consumption)) {
      /** Consumos de material */
      if (scroll) {
        const page = this.state.data.page + 1;
        Keep.stocks(vale_id, class_id, search, page, rows, this.state.data.list).then((result) => {
          const data = result.data;
          this.setState({
            data: data,
            reference: false,
          });
        });
      } else {
        Keep.stocks(vale_id, class_id, search, 1, rows, []).then((result) => {
          const data = result.data;
          this.setState({
            data: data,
            reference: false,
            change: false,
          });
        });
      }
    } else {
      const project_id = this.props.project_id;
      const only = this.props.only || true;
      if (scroll) {
        const page = this.state.data.page + 1;
        Assets.references(project_id, class_id, only, search, page, rows, this.state.data.list).then((result) => {
          const data = result.data;
          this.setState({
            data: data,
            reference: true,
          });
        });
      } else {
        Assets.references(project_id, class_id, only, search, 1, rows, [this.state.select]).then((result) => {
          const data = result.data;
          this.setState({
            data: data,
            reference: true,
            change: false,
          });
        });
      }
    }
  };

  handleSearch = () => {
    this.handleData(false);
  };

  handleSelect = (e) => {
    this.setState({ select: e, change: true });
  };

  handleUpdate = (e) => {
    if (compare(this.state.update, e)) {
      const data = updateList(this.state.data, e);
      this.setState({ data: data, change: true, update: e });
    }
  };

  componentDidMount() {
    Subscribe(`${this.state.name}/${this.props.cellar_id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        this.handleData(false);
        showModal(this.state._id);
      } else if (prevState.data.class_id !== this.state.class_id) {
        this.handleData(false);
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.name}/${this.props.cellar_id}`, (event) => this.eventSetData(event));
    hideModal(this.state._id);
  }

  render() {
    const consumption = this.state.consumption;
    const warehouse = this.state.warehouse;
    const controlConsumptions = this.state.controlConsumptions;
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else if ((warehouse && consumption) || (controlConsumptions && consumption)) {
      /** Consumos de material */
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog s" role="document">
                <div className="modal-content">
                  <ModalHeaderList
                    title={this.state.class_name || this.state.title}
                    handleClose={this.handleClose}
                    state={this.state.data.state}
                    rows={this.state.data.end}
                    all={this.state.data.all}
                  />
                  <div className="modal-body">
                    <div className="modalList select">
                      <div className="modalListFind">
                        <ModalSearchMenu
                          name="search"
                          value={this.state.data.search}
                          state={this.state.class_id}
                          onChange={this.handleChange}
                          onSearch={this.handleSearch}
                          onState={this.handleChangeClass}
                          states={[
                            { _state: this.props.class_id, caption: this.props.class_name },
                            { _state: "ALL", caption: "Todos" },
                          ]}
                        />
                      </div>
                      <div className="modalListList">
                        <ModalStock
                          id={`${this.state._id}_scroll`}
                          _state={this.state.data.state}
                          list={this.state.data.list}
                          select={this.state.select}
                          caption="caption"
                          stockField="stock"
                          getData={this.handleData}
                          onSelect={this.handleSelect}
                        />
                      </div>
                      <div className="modalListSelect">
                        <ModalSelect
                          project_id={this.props.project_id}
                          select={this.state.select}
                          onSetData={this.handleSetData}
                          Modal={ModalReference}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooterList
                      _state={this.state.data._state}
                      onRemove={this.handleRemove}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    } else {
      /** Referencias por clase */
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog s" role="document">
                <div className="modal-content">
                  <ModalHeaderList
                    title={this.state.class_name || this.state.title}
                    handleClose={this.handleClose}
                    state={this.state.data.state}
                    rows={this.state.data.end}
                    all={this.state.data.all}
                  />
                  <div className="modal-body">
                    <div className="modalList select">
                      <div className="modalListFind">
                        <ModalSearchMenu
                          name="search"
                          value={this.state.data.search}
                          state={this.state.class_id}
                          onChange={this.handleChange}
                          onSearch={this.handleSearch}
                          onState={this.handleChangeClass}
                          states={[
                            { _state: this.props.class_id, caption: this.props.class_name },
                            { _state: "ALL", caption: "Todos" },
                          ]}
                        />
                      </div>
                      <div className="modalListList">
                        <ComponentReferences
                          id={`${this.state._id}_scroll`}
                          _state={this.state.data.state}
                          list={this.state.data.list}
                          select={this.state.select}
                          getData={this.handleData}
                          onSelect={this.handleSelect}
                        />
                      </div>
                      <div className="modalListSelect">
                        <ModalSelect
                          project_id={this.props.project_id}
                          select={this.state.select}
                          onSetData={this.handleChangeSelect}
                          Modal={ModalReference}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooterList
                      project_id={this.props.project_id}
                      _state={this.state.data._state}
                      Modal={ModalReference}
                      onSetData={this.handleSetData}
                      onRemove={this.handleRemove}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function ActivityStock(props) {
  const select = getValue(props, "select", { _id: "-1", caption: "" });
  const activity_base = props.activity_base || 0;

  return (
    <React.Fragment>
      <div className="input-group input-group-sm">
        <input className={props.className || "form-control"} readOnly value={select.caption || ""} />
        <div className="input-group-append">
          <ListActivityStock
            className={props.classNameBtn || "input-group-text"}
            project_id={props.project_id}
            odt_id={props.odt_id}
            vale_id={props.vale_id}
            class_id={props.class_id}
            class_name={props.class_name}
            activity_base={activity_base}
            _state={props._state}
            select={select}
            onSetData={props.onChange}
            disabled={props._state !== "0"}
          >
            <i className="fa fa-ellipsis-h"></i>
          </ListActivityStock>
        </div>
      </div>
    </React.Fragment>
  );
}

export { ListActivityStock, ActivityStock };
