import React from "react";
import ModalSearchMenu from "../../../components/modalSearchMenu";
import Td from "../../../components/td";
import { formatInteger, getData, getDateFormat, getSource, getValue } from "../../../components/utilities";
import ModalAsset from "../../assets/modalAsset";
import ModalOdt from "../modalOdt";
import ModalOperation from "../modalOperation";
import ModalReport from "../modalReport";
import ModalTpActivity from "../modalTpActivity";

function ToolSearch(props) {
  return (
    <React.Fragment>
      <Td className="suport-tool" cols="34% auto 160px">
        <ModalSearchMenu
          name="search"
          value={props.search}
          state={props.state}
          onChange={props.onChange}
          onSearch={props.onSearch}
          onState={props.onState}
          states={[
            { _state: "ALL", caption: "Todas" },
            { _state: "0", caption: "Activas" },
            { _state: "1", caption: "Terminadas" },
          ]}
        />
        <div className="details-menu">
          <props.Modal
            className={props.isNew ? "btn btn-primary btn-sm" : "d-none"}
            project_id={props.project_id}
            master={props.master}
            isNew={props.isNew}
            onSetData={props.onSetData}
          >
            Nueva
          </props.Modal>
        </div>
        <div className={props.all > 0 ? "scroll-count" : "d-none"}>
          <p>
            <b className="scroll-count-end">{formatInteger(props.end)}</b>
            de
            <b className="scroll-count-all">{formatInteger(props.all)}</b>
          </p>
        </div>
      </Td>
    </React.Fragment>
  );
}

function ComponentOperations(props) {
  const data = props.data || {};
  const list = data.list || [];
  const search = data.search || "";
  const state = data.state || "";
  const all = data.all || 0;
  const end = data.end || 0;
  const _id = getValue(props.select, "_id", "-1");
  const isNew = props.isNew === undefined ? true : props.isNew;
  const handleScroll = (e) => {
    const h = e.target.scrollTop + e.target.clientHeight + 1;
    const limit = e.target.scrollHeight;
    console.log({ h, limit, target: e.target, props });
    // if (e.target.id === props.id && h >= limit) {
    if (h >= limit) {
      props.getData(true);
    }
  };

  if (props._class === "ASSETS") {
    return (
      <React.Fragment>
        <ToolSearch
          project_id={props.project_id}
          _class={props._class}
          master={props.master}
          isNew={isNew}
          search={search}
          state={state}
          all={all}
          end={end}
          Modal={props.Modal}
          onChange={props.onChange}
          onSearch={props.onSearch}
          onState={props.onState}
          onSetData={props.onSetData}
        />
        <div className="suport mb-0">
          <div className="suport-group mb-0">
            <Td className="suport-head" cols="90px 75px 75px 75px 170px auto">
              <div className="suport-td">Fecha</div>
              <div className="suport-td">Operación</div>
              <div className="suport-td">Reporte</div>
              <div className="suport-td">ODT</div>
              <div className="suport-td">Actividad</div>
              <div className="suport-td">Sitio</div>
            </Td>
            <div className="suport-scroll small" id={props.id} onScroll={handleScroll}>
              {list.map((item, i) => {
                return (
                  <Td
                    key={i}
                    className={
                      item.delete
                        ? "d-none"
                        : item._id === "-1"
                        ? "d-none"
                        : item._id === _id
                        ? "suport-item active"
                        : item._state === "0"
                        ? "suport-item _state0"
                        : item._state === "1"
                        ? "suport-item _state1"
                        : item._state === "2"
                        ? "suport-item _state2"
                        : "suport-item"
                    }
                    cols="90px 75px 75px 75px 170px auto"
                  >
                    <div className="suport-td t-c">{getDateFormat(item, "date_int", "MMM d, yyyy", "")}</div>
                    <div className="suport-td t-c">
                      <ModalOperation className="link" project_id={props.project_id} data={item}>
                        {getValue(item, "code", "")}
                      </ModalOperation>
                    </div>
                    <div className="suport-td t-c">
                      <ModalReport className="link" project_id={props.project_id} isFind={true} _id={item.report_id}>
                        {getData(item, "report", "")}
                      </ModalReport>
                    </div>
                    <div className="suport-td t-c">
                      <ModalOdt className="link" project_id={props.project_id} isFind={true} _id={item.odt_id}>
                        {getData(item, "odt", "")}
                      </ModalOdt>
                    </div>
                    <div className="suport-td">
                      <ModalTpActivity
                        className="link"
                        isFind={true}
                        project_id={props.project_id}
                        _class="KEEP_OPERATIONS_TYPE"
                        _id={item.activity_tp}
                      >
                        {getSource(item, ["_data", "activity"], "")}
                      </ModalTpActivity>
                    </div>
                    <div className="suport-td">{getData(item, "address", "")}</div>
                  </Td>
                );
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else if (props._class === "SUPORT-KEEP-REPORT") {
    return (
      <React.Fragment>
        <ToolSearch
          project_id={props.project_id}
          _class={props._class}
          master={props.master}
          isNew={isNew}
          search={search}
          state={state}
          all={all}
          end={end}
          Modal={props.Modal}
          onChange={props.onChange}
          onSearch={props.onSearch}
          onState={props.onState}
          onSetData={props.onSetData}
        />
        <div className="suport mb-0">
          <div className="suport-group mb-0">
            <Td className="suport-head" cols="90px 75px 75px 75px 120px 160px auto">
              <div className="suport-td">Fecha</div>
              <div className="suport-td">Operación</div>
              <div className="suport-td">ODT</div>
              <div className="suport-td">Equipo</div>
              <div className="suport-td">Clase</div>
              <div className="suport-td">Tipo</div>
              <div className="suport-td">Sitio</div>
            </Td>
            <div className="suport-scroll small" id={props.id} onScroll={handleScroll}>
              {list.map((item, i) => {
                return (
                  <Td
                    key={i}
                    className={
                      item.delete
                        ? "d-none"
                        : item._id === "-1"
                        ? "suport-item d-none"
                        : item._id === _id
                        ? "suport-item active"
                        : item._state === "0"
                        ? "suport-item _state0"
                        : item._state === "2"
                        ? "suport-item _state2"
                        : "suport-item"
                    }
                    cols="90px 75px 75px 75px 120px 160px auto"
                  >
                    <div className="suport-td t-c">{getDateFormat(item, "date_int", "MMM d, yyyy", "")}</div>
                    <div className="suport-td t-c">
                      <ModalOperation className="link" project_id={props.project_id} data={item}>
                        {getValue(item, "code", "")}
                      </ModalOperation>
                    </div>
                    <div className="suport-td t-c">
                      <ModalOdt className="link" project_id={props.project_id} isFind={true} _id={item.odt_id}>
                        {getData(item, "odt", "")}
                      </ModalOdt>
                    </div>
                    <div className="suport-td t-c">
                      <ModalAsset
                        className="link"
                        project_id={props.project_id}
                        equipment={true}
                        isFind={true}
                        _id={item.asset_id}
                      >
                        {getValue(item, "asset_code", "")}
                      </ModalAsset>
                    </div>
                    <div className="suport-td">{getData(item, "asset_class", "")}</div>
                    <div className="suport-td">{getData(item, "asset_type", "")}</div>
                    <div className="suport-td">{getData(item, "address", "")}</div>
                  </Td>
                );
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else if (props._class === "SUPORT-KEEP-ODT") {
    return (
      <React.Fragment>
        <ToolSearch
          project_id={props.project_id}
          _class={props._class}
          master={props.master}
          isNew={isNew}
          search={search}
          state={state}
          all={all}
          end={end}
          Modal={props.Modal}
          onChange={props.onChange}
          onSearch={props.onSearch}
          onState={props.onState}
          onSetData={props.onSetData}
        />
        <div className="suport mb-0">
          <div className="suport-group mb-0">
            <Td className="suport-head" cols="90px 75px 75px 75px 120px 160px auto">
              <div className="suport-td">Fecha</div>
              <div className="suport-td">Operación</div>
              <div className="suport-td">Reporte</div>
              <div className="suport-td">Equipo</div>
              <div className="suport-td">Clase</div>
              <div className="suport-td">Tipo</div>
              <div className="suport-td">Sitio</div>
            </Td>
            <div className="suport-scroll small" id={props.id} onScroll={handleScroll}>
              {list.map((item, i) => {
                return (
                  <Td
                    key={i}
                    className={
                      item.delete
                        ? "d-none"
                        : item._id === "-1"
                        ? "suport-item d-none"
                        : item._id === _id
                        ? "suport-item active"
                        : item._state === "0"
                        ? "suport-item _state0"
                        : item._state === "2"
                        ? "suport-item _state2"
                        : "suport-item"
                    }
                    cols="90px 75px 75px 75px 120px 160px auto"
                  >
                    <div className="suport-td t-c">{getDateFormat(item, "date_int", "MMM d, yyyy", "")}</div>
                    <div className="suport-td t-c">
                      <ModalOperation className="link" project_id={props.project_id} data={item}>
                        {getValue(item, "code", "")}
                      </ModalOperation>
                    </div>
                    <div className="suport-td t-c">
                      <ModalReport className="link" project_id={props.project_id} isFind={true} _id={item.odt_id}>
                        {getData(item, "report", "")}
                      </ModalReport>
                    </div>
                    <div className="suport-td t-c">
                      <ModalAsset
                        className="link"
                        project_id={props.project_id}
                        equipment={true}
                        isFind={true}
                        _id={item.asset_id}
                      >
                        {getValue(item, "asset_code", "")}
                      </ModalAsset>
                    </div>
                    <div className="suport-td">{getData(item, "asset_class", "")}</div>
                    <div className="suport-td">{getData(item, "asset_type", "")}</div>
                    <div className="suport-td">{getData(item, "address", "")}</div>
                  </Td>
                );
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else if (props._class === "SUPORT-KEEP-VALE") {
    return (
      <React.Fragment>
        <ToolSearch
          project_id={props.project_id}
          _class={props._class}
          master={props.master}
          isNew={isNew}
          search={search}
          state={state}
          all={all}
          end={end}
          Modal={props.Modal}
          onChange={props.onChange}
          onSearch={props.onSearch}
          onState={props.onState}
          onSetData={props.onSetData}
        />
        <div className="suport mb-0">
          <div className="suport-group mb-0">
            <Td className="suport-head" cols="90px 75px 75px 75px 75px 120px auto">
              <div className="suport-td">Fecha</div>
              <div className="suport-td">Operación</div>
              <div className="suport-td">Reporte</div>
              <div className="suport-td">ODT</div>
              <div className="suport-td">Equipo</div>
              <div className="suport-td">Clase</div>
              <div className="suport-td">Sitio</div>
            </Td>
            <div className="suport-scroll small" id={props.id} onScroll={handleScroll}>
              {list.map((item, i) => {
                return (
                  <Td
                    key={i}
                    className={
                      item.delete
                        ? "d-none"
                        : item._id === "-1"
                        ? "suport-item d-none"
                        : item._id === _id
                        ? "suport-item active"
                        : item._state === "0"
                        ? "suport-item _state0"
                        : item._state === "2"
                        ? "suport-item _state2"
                        : "suport-item"
                    }
                    cols="90px 75px 75px 75px 75px 120px auto"
                  >
                    <div className="suport-td t-c">{getDateFormat(item, "date_int", "MMM d, yyyy", "")}</div>
                    <div className="suport-td t-c">
                      <ModalOperation className="link" project_id={props.project_id} data={item}>
                        {getValue(item, "code", "")}
                      </ModalOperation>
                    </div>
                    <div className="suport-td t-c">
                      <ModalReport className="link" project_id={props.project_id} isFind={true} _id={item.odt_id}>
                        {getData(item, "report", "")}
                      </ModalReport>
                    </div>
                    <div className="suport-td t-c">
                      <ModalOdt className="link" project_id={props.project_id} isFind={true} _id={item.odt_id}>
                        {getData(item, "odt", "")}
                      </ModalOdt>
                    </div>
                    <div className="suport-td t-c">
                      <ModalAsset
                        className="link"
                        project_id={props.project_id}
                        equipment={true}
                        isFind={true}
                        _id={item.asset_id}
                      >
                        {getValue(item, "asset_code", "")}
                      </ModalAsset>
                    </div>
                    <div className="suport-td">{getData(item, "asset_class", "")}</div>
                    <div className="suport-td">{getData(item, "address", "")}</div>
                  </Td>
                );
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <ToolSearch
          project_id={props.project_id}
          _class={props._class}
          master={props.master}
          isNew={isNew}
          search={search}
          state={state}
          all={all}
          end={end}
          Modal={props.Modal}
          onChange={props.onChange}
          onSearch={props.onSearch}
          onState={props.onState}
          onSetData={props.onSetData}
        />
        <div className="suport mb-0">
          <div className="suport-group mb-0">
            <Td className="suport-head" cols="90px 75px 75px 75px 75px 120px 160px auto">
              <div className="suport-td">Fecha</div>
              <div className="suport-td">Operación</div>
              <div className="suport-td">Reporte</div>
              <div className="suport-td">ODT</div>
              <div className="suport-td">Equipo</div>
              <div className="suport-td">Clase</div>
              <div className="suport-td">Tipo</div>
              <div className="suport-td">Sitio</div>
            </Td>
            <div className="suport-scroll small" id={props.id} onScroll={handleScroll}>
              {list.map((item, i) => {
                return (
                  <Td
                    key={i}
                    className={
                      item.delete
                        ? "d-none"
                        : item._id === "-1"
                        ? "suport-item d-none"
                        : item._id === _id
                        ? "suport-item active"
                        : item._state === "0"
                        ? "suport-item _state0"
                        : item._state === "2"
                        ? "suport-item _state2"
                        : "suport-item"
                    }
                    cols="90px 75px 75px 75px 75px 120px 160px auto"
                  >
                    <div className="suport-td t-c">{getDateFormat(item, "date_int", "MMM d, yyyy", "")}</div>
                    <div className="suport-td t-c">
                      <ModalOperation className="link" project_id={props.project_id} data={item}>
                        {getValue(item, "code", "")}
                      </ModalOperation>
                    </div>
                    <div className="suport-td t-c">
                      <ModalReport className="link" project_id={props.project_id} isFind={true} _id={item.odt_id}>
                        {getData(item, "report", "")}
                      </ModalReport>
                    </div>
                    <div className="suport-td t-c">
                      <ModalOdt className="link" project_id={props.project_id} isFind={true} _id={item.odt_id}>
                        {getData(item, "odt", "")}
                      </ModalOdt>
                    </div>
                    <div className="suport-td t-c">
                      <ModalAsset
                        className="link"
                        project_id={props.project_id}
                        equipment={true}
                        isFind={true}
                        _id={item.asset_id}
                      >
                        {getValue(item, "asset_code", "")}
                      </ModalAsset>
                    </div>
                    <div className="suport-td">{getData(item, "asset_class", "")}</div>
                    <div className="suport-td">{getData(item, "asset_type", "")}</div>
                    <div className="suport-td">{getData(item, "address", "")}</div>
                  </Td>
                );
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ComponentOperations;
