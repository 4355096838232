import Mapboxgl from "mapbox-gl/dist/mapbox-gl.js";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button, City } from "../../components/inputs";
import { App, Loading, chart, formatMoney } from "../../components/utilities";
import { Api as Project } from "../../services/project";
import "../../styles/view.scss";
Mapboxgl.accessToken = App.mapbox;

class ViewMaps extends React.Component {
  constructor(props) {
    super(props);
    Loading();
    this.state = {
      _id: "__ViewMaps",
      _view: {},
      initpoint: {},
      themes: { search: "", page: 1, end: 0, all: 0, list: [] },
      theme: {},
      printParams: {},
      change: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      themes: {
        ...this.state.themes,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleSetData = () => {
    if (this.state.change) {
      Project.setCellar(this.state.data._id, this.state.data).then((result) => {
        this.setState({ data: result.data, change: false });
      });
    }
  };

  handleMap = (e) => {
    e = e || {};
    if (!this.state.map) {
      const map = new Mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: e.center === undefined ? [-73.1231582, 6.553601] : e.center,
        zoom: 15,
      });
      this.setState({ map: map });
    }
  };

  handleThemes = (e) => {
    /*
    const id = e._id;
    const project_id = e.project_id;
    const warehouse_id = e.warehouse_id;
    Project.cellar(id, project_id, warehouse_id).then(result => {
      this.setState({
        data: result.data,
        old: result.data,
        stock: result.data.stock
      });
      setFocus(`${this.state._id}_caption`);
    });*/
  };

  handleSearch = () => {};

  handleSelected = (e) => {
    this.setState({ theme: e });
  };

  componentDidMount() {
    this.handleMap();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps._view === this.props._view && this.props.project_id !== "-1") {
      if (prevProps.project_id !== this.props.project_id) {
        this.handleMap();
        // } else if (prevState.data.state !== this.state.data.state) {
        // this.handleMap();
      } else {
        this.handleMap();
      }
    }
  }

  componentWillUnmount() {}

  render() {
    if (this.props._view !== this.props.location.pathname) {
      return <Redirect to={this.props._view} push={true} />;
    }
    return (
      <React.Fragment>
        <div className={this.state._id}>
          <div className="viewcontainer">
            <div className="view-box">
              <div className="view-object">
                <div className="view-map" id="map"></div>
                <div className="view-master">
                  <nav>
                    <div className="nav nav-tabs" role="tablist">
                      <a
                        className="nav-item nav-link active"
                        id={`${this.state._id}-nav-1-tab`}
                        data-toggle="tab"
                        href={`#${this.state._id}-nav-1`}
                        role="tab"
                        aria-selected="true"
                      >
                        Temas
                      </a>
                      <a
                        className="nav-item nav-link"
                        id={`${this.state._id}-nav-2-tab`}
                        data-toggle="tab"
                        href={`#${this.state._id}-nav-2`}
                        role="tab"
                        aria-selected="false"
                      >
                        Herramientas
                      </a>
                    </div>
                  </nav>
                  <div className="tab-content view">
                    <div
                      className="tab-pane fade show active"
                      id={`${this.state._id}-nav-1`}
                      role="tabpanel"
                      aria-labelledby={`${this.state._id}-nav-1-tab`}
                    >
                      <div className="tab-list">
                        {this.state.themes.list.map((item, i) => {
                          return (
                            <React.Fragment key={i}>
                              <div className="tab-item pointer">
                                <div className="tab-item-logo">
                                  <div className="avatar">
                                    <div className="chart">
                                      <div>{chart(item.caption, 0)}</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="tab-item-detail">
                                  <div className="name">{item.caption}</div>
                                  <div className="label">{item.description}</div>
                                  <div className="label">
                                    Costo:
                                    <strong className="ml-1">{formatMoney(item.cost)}</strong>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade show"
                      id={`${this.state._id}-nav-2`}
                      role="tabpanel"
                      aria-labelledby={`${this.state._id}-nav-2-tab`}
                    >
                      <div className="tab-group">
                        <div className="row">
                          <h5 className="col-12 text-center p-1">Herramientas</h5>
                        </div>
                        <div className="form-group input-group-sm mb-2">
                          <label>Ciudad</label>
                          <City
                            type="text"
                            className="form-control"
                            classNameBtn="input-group-text"
                            placeholder="Ciudad"
                          />
                        </div>
                        <div className="form-group input-group-sm float-right">
                          <Button className="btn btn-primary btn-sm" onClick={this.handleSetData}>
                            Aceptar
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    folder: state.sistem.folder,
    _view: state.sistem.folder._view || "",
    view_rows: state.sistem.view_rows,
  };
}

export default connect(mapStateToProps)(ViewMaps);
