import React from "react";
import ReactDOM from "react-dom";
import "../../../styles/modal.scss";
import { showModal, hideModal, getValue } from "../../../components/utilities";
import { Section, Textarea, SelectType, Count } from "../../../components/inputs";
import DateTime from "../../../components/dateTime";
import ModalHeader from "../../../components/modalHeader";
import ModalFooter from "../../../components/modalFooter";
import ModalClass from "../modalClass";
import Td from "../../../components/td";
import { connect, useSelector } from "react-redux";

class ModalAtrib extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme();
    this.state = {
      _id: "__ModalAtrib",
      title: "Atributo",
      show: false,
      old: data,
      data: data,
      printParams: {},
      change: false,
    };
  }

  handleScheme = (e) => {
    return {
      name: "",
      caption: "",
      description: "",
      type: "text",
      values: "",
      unity: "",
      increase: 1,
      precision: 0,
      _default: "",
      value: "",
      list: [],
      dateTime: new Date(),
    };
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeDateTime = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        value: e.value,
        [e.name]: e.value,
      },
      change: true,
    });
  };

  handleChangeValue = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.name]: e.value,
      },
      change: true,
    });
  };

  handelValueDate = (e) => {
    if (e === "") {
      return new Date();
    } else {
      return new Date(e);
    }
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData({ data: e.data, action: e.action });
    }
    this.handleHide();
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    if (this.props.isFind) {
      const _id = getValue(this.props, "_id", "-1");
      if (_id !== "-1") {
        this.setState({ show: true, change: false });
      }
    } else if (this.props.isNew) {
      this.setState({ show: true, change: false });
    } else {
      const _state = this.props._state || "0";
      if (_state === "0") {
        this.setState({ show: true, change: false });
      }
    }
  };

  handleClose = () => {
    if (this.state.change) {
      this.setState({ data: this.state.old, change: false });
    }
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.change) {
      this.handleExecute({ data: this.state.data, action: "set" });
    } else {
      this.handleHide();
    }
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      change: e.change,
      printParams: {
        project_id: e.data.project_id,
        _class: e.data._class,
        single: true,
      },
    });
    showModal(this.state._id);
  };

  handleData = () => {
    let data = this.props.data;
    let values = data.values;
    let list = [""];
    let dateTime = new Date(data.value);
    try {
      values = values.split(",");
      values.forEach((element) => {
        element = element.trim();
        list.push(element);
      });
    } catch {
      list = [];
    }
    if (isNaN(dateTime.getTime())) {
      dateTime = new Date();
    }
    data.list = list;
    data.dateTime = dateTime;
    this.handleSetData({ data, change: false });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        this.handleData();
      }
    }
  }

  componentWillUnmount() {
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog xs" role="document">
                <div className="modal-content">
                  <ModalHeader title={this.state.title} handleClose={this.handleClose} />
                  <div className="modal-body">
                    <div className="modal-box">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group input-group-sm">
                            <label>{this.state.data.caption}</label>
                            <Section
                              type="text"
                              id={`${this.state._id}_caption`}
                              className={this.state.data.type === "text" ? "form-control name" : "d-none"}
                              placeholder="Valor"
                              name="value"
                              _state={this.props._state}
                              value={this.state.data.value}
                              onChange={this.handleChange}
                            />
                            <select
                              className={this.state.data.type === "list" ? "form-control sp select blod" : "d-none"}
                              name="value"
                              value={this.state.data.value}
                              disabled={this.props._state !== "0"}
                              onChange={this.handleChange}
                            >
                              {this.state.data.list.map((item, i) => {
                                return (
                                  <option key={i} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                            </select>
                            <Textarea
                              className={this.state.data.type === "description" ? "form-control notresize" : "d-none"}
                              placeholder="Descripción"
                              _state={this.props._state}
                              rows={4}
                              name="values"
                              value={this.state.data.values}
                              onChange={this.handleChange}
                            />
                            <SelectType
                              className={this.state.data.type === "type" ? "form-control" : "d-none"}
                              project_id={this.props.project_id}
                              _class={this.state.data.values}
                              _state={this.props._state}
                              name="value"
                              value={this.state.data.value}
                              onChange={this.handleChange}
                            />
                            <Count
                              className={
                                this.state.data.type === "number" || this.state.data.type === "power"
                                  ? "input-group input-group-sm"
                                  : "d-none"
                              }
                              _state={this.props._state}
                              name="value"
                              unity={this.state.data.unity}
                              value={this.state.data.value}
                              increase={this.state.data.increase}
                              precision={this.state.data.precision}
                              handleValue={this.handleChangeValue}
                            />
                            <DateTime
                              className={
                                this.state.data.type === "date" || this.state.data.type === "datetime" ? "form-control t-c" : "d-none"
                              }
                              placeholder="Fecha de inicio"
                              dateFormat="MMM d, yyyy hh:mm aa"
                              showTimeSelect={this.state.data.type === "datetime" ? true : false}
                              _state={this.props._state}
                              name="dateTime"
                              value={this.state.data.dateTime}
                              onChange={this.handleChangeDateTime}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooter
                      single="atributo"
                      plural="el atributo"
                      _state={this.props._state}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    _view: state.sistem.folder._view || "",
    online: state.sistem.online,
  };
}

connect(mapStateToProps)(ModalAtrib);

function ComponentAtribs(props) {
  const project_id = useSelector((state) => {
    return state.sistem.project._id || "-1";
  });
  const scheme = props.scheme || [];
  const atribs = props.atribs || {};

  return (
    <React.Fragment>
      <div className="suport-group mb-4">
        <Td className="suport-head" cols="auto">
          <div className="suport-th">
            Atributos
            <ModalClass
              className="link float-right pr-1"
              project_id={project_id}
              _state={props._state || "0"}
              _id={props.class_id || "-1"}
              isFind={true}
              onHide={props.onChangeClase}
            >
              Clase
            </ModalClass>
          </div>
        </Td>
        <Td className="suport-head" cols="auto 145px">
          <div className="suport-th">Atributo</div>
          <div className="suport-th">Valor</div>
        </Td>
        <div className="suport-scroll small" style={{ height: props.height || "413px" }}>
          {scheme.map((atrib, i) => {
            atrib.value = atribs[atrib.name] || atrib._default;
            return (
              <Td key={i} className="suport-item" cols="auto 141px">
                <div className="suport-td">
                  <ModalAtrib
                    className="link"
                    project_id={project_id}
                    _state={props._state || "0"}
                    data={atrib}
                    onSetData={props.onSetData}>
                    {atrib.caption}
                  </ModalAtrib>
                </div>
                <div className="suport-td t-r">{atrib.value}</div>
              </Td>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
}

export default ComponentAtribs;
