import { http } from "../components/http";
import { ShowAlert, getValue, setValue, join } from "../components/utilities";
import environment from "../env/env";
const apiUrl = environment.url;

export const Api = {
  talkings: async function (id, search, page, rows, list) {
    return await http("GET", `${apiUrl}/talkings/${id}?search=${search}&page=${page}&rows=${rows}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
};
