import React from "react";
import ReactDOM from "react-dom";
import "../../styles/modal.scss";
import {
  showModal,
  hideModal,
  ShowInfo,
  msg,
  setFocus,
  genId,
  getValue,
  formId,
  getSource,
} from "../../components/utilities";
import ModalHeader from "../../components/modalHeader";
import { Input, Image, Contact, Textarea, SelectType, Count, Section, Type } from "../../components/inputs";
import ModalFooter from "../../components/modalFooter";
import ComponentReferenceClass from "./components/componentReferenceClass";
import ComponentReferenceStocks from "./components/componentReferenceStocks";
import { Api as WareHouse } from "../../services/warehouse";
import { connect } from "react-redux";

class ModalReference extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme();
    this.state = {
      form: "__modalReference",
      _id: "__modalReference",
      title: "Referencia",
      show: false,
      old: data,
      data: data,
      change: false,
      isNew: false,
      tabs: [],
      printParams: {
        project_id: "-1",
        _class: "REFERENCE",
        _id: "-1",
        single: true,
      },
    };
  }

  handleScheme = (e) => {
    return {
      project_id: this.props.project_id,
      _class: "REFERENCE",
      _state: "0",
      _id: genId("-1"),
      caption: "",
      description: "",
      type_id: "REFERENCE_TYPE00",
      type: "Material",
      code: "",
      barcode: "",
      rfidcode: "",
      manufacturer_id: "-1",
      manufacturer: "",
      unity: "und",
      image: "",
      method_tp: "KARDEX_METHOD_TP00",
      method: "",
      tax: 0,
      _data: {
        type: "",
        manufacturer: "",
        caption_short: "",
        username: "",
      },
      user_id: "-1",
    };
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeData = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          [e.target.name]: e.target.value,
        },
      },
      change: true,
    });
  };

  handleChangeManufacturer = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        manufacturer_id: e._id,
        manufacturer: e.caption,
        _data: {
          ...this.state.data._data,
          manufacturer_id: e._id,
          manufacturer: e.caption,
        },
      },
      change: true,
    });
  };

  handleChangeTax = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        tax: e.value,
      },
      change: true,
    });
  };

  handleChangeMethod = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        method_tp: getValue(e, "_id", "-1"),
        method: getValue(e, "caption", ""),
      },
      change: true,
    });
  };

  handleChangeImage = (e) => {
    const data = e.data.data;
    this.setState({
      data: {
        ...this.state.data,
        image: data.filepath,
      },
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    if (this.props.isFind) {
      const _id = getValue(this.props, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    } else if (this.props.isNew) {
      const form_id = `${this.state.form}${Date.now()}`;
      this.setState({ _id: form_id, show: true, change: false });
    } else {
      const _id = getValue(this.props.data, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    }
  };

  handleClose = () => {
    if (this.state.change) {
      this.setState({ data: this.state.old, change: false });
    }
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.change) {
      const id = this.state.data._id;
      const params = this.state.data;
      WareHouse.setReference(id, params).then((result) => {
        if (result.msg === "") {
          let data = result.data;
          this.handleSetData({ data, isNew: false, change: false, focusInt: false });
          this.handleExecute(result.data);
        }
      });
    } else {
      this.handleHide();
    }
  };

  handleFinish = (e) => {
    if (e) {
      const id = this.state.data._id;
      WareHouse.stateReference(id, "1").then((result) => {
        if (result.msg === "") {
          this.handleExecute(result.data);
          this.handleHide();
        }
      });
    }
  };

  handleRestore = (e) => {
    if (e) {
      const id = this.state.data._id;
      WareHouse.stateReference(id, "0").then((result) => {
        if (result.msg === "") {
          this.handleExecute(result.data);
        }
      });
    }
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      isNew: e.isNew,
      change: e.change,
      tabs: [],
      printParams: {
        project_id: e.data.project_id,
        _class: e.data._class,
        _id: e.data._id,
        single: true,
      },
    });
    showModal(this.state._id);
    setFocus(`${this.state._id}_caption`, e.focusInt);
  };

  handleData = () => {
    const _id = getSource(this.props.data, ["_id"], "-1");
    if (_id === "-1") {
      this.handleSetData({ data: this.props.data, isNew: false, change: false, focusInt: true });
    } else {
      this.handleFind(_id);
    }
  };

  handleNew = () => {
    const data = this.handleScheme();
    this.handleSetData({ data, isNew: true, change: true, focusInt: true });
  };

  handleFind = (id) => {
    if (this.state.change) {
      ShowInfo(msg("MSG-CHANGE"));
    } else if (id !== "-1") {
      WareHouse.reference(id).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false, focusInt: true });
        }
      });
    }
  };

  handleTab = (e) => {
    const tabs = this.state.tabs;
    if (!tabs[e]) {
      tabs[e] = true;
      this.setState({ tabs: tabs });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        if (this.props.isNew) {
          this.handleNew();
        } else if (this.props.isFind) {
          this.handleFind(this.props._id);
        } else {
          this.handleData();
        }
      }
    }
  }

  componentWillUnmount() {
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog sl __modalObject" role="dialog">
                <div className="modal-content">
                  <ModalHeader title={this.state.title} handleClose={this.handleClose} />
                  <div className="modal-body-tabs">
                    <nav>
                      <div className="nav nav-tabs" role="tablist">
                        <a
                          className="nav-item nav-link active"
                          id={`${this.state._id}-nav-1-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-1`}
                          role="tab"
                          aria-selected="true"
                        >
                          General
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-2-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-2`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(2)}
                        >
                          Clasificación
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-3-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-3`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(3)}
                        >
                          Stock
                        </a>
                      </div>
                    </nav>
                    <div className="tab-content modal not-scroll">
                      <div
                        className="tab-pane fade show active"
                        id={`${this.state._id}-nav-1`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-1-tab`}
                      >
                        <div className="row">
                          <div className="col-4">
                            <div className="text-center">
                              <Image
                                className="img-thumbnail square-image"
                                project_id={this.state.data.project_id}
                                object_id={this.state.data._id}
                                _state={this.state.data._state}
                                main_id="IMAGE"
                                group_tp="-1"
                                caption="Imagen"
                                description=""
                                path={this.state.data.image}
                                onChange={this.handleChangeImage}
                              />
                            </div>
                          </div>
                          <div className="col-8">
                            <div className="form-row input-group-sm mb-0">
                              <div className="form-group col-md-5 input-group-sm mb-0">
                                <label>Código</label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  placeholder="Código"
                                  _state={this.state.data._state}
                                  name="code"
                                  value={this.state.data.code}
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div className="form-group col-md-7 input-group-sm mb-0">
                                <label>Código de barras</label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  placeholder="Código de barras"
                                  _state={this.state.data._state}
                                  name="barcode"
                                  value={this.state.data.barcode}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="form-group input-group-sm">
                              <label>Nombre</label>
                              <Textarea
                                id={`${this.state._id}_caption`}
                                className="form-control notresize"
                                placeholder="Nombre"
                                rows={3}
                                _state={this.state.data._state}
                                name="caption"
                                value={this.state.data.caption}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="form-group input-group-sm">
                              <label>Nombre corto</label>
                              <Section
                                className="form-control name"
                                placeholder="Nombre corto"
                                _state={this.state.data._state}
                                name="caption_short"
                                value={this.state.data._data.caption_short}
                                onChange={this.handleChangeData}
                              />
                            </div>
                            <div className="form-group input-group-sm">
                              <label>Descripción</label>
                              <Textarea
                                className="form-control notresize"
                                placeholder="Descripción"
                                rows={4}
                                _state={this.state.data._state}
                                name="description"
                                value={this.state.data.description}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="form-group input-group-sm">
                              <label>Tipo</label>
                              <SelectType
                                className="form-control"
                                project_id={this.state.data.project_id}
                                _class="REFERENCE_TYPE"
                                _state={this.state.data._state}
                                name="type_id"
                                value={this.state.data.type_id}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="form-group input-group-sm">
                              <label>Método</label>
                              <Type
                                className="form-control sp ctr-sm t-m"
                                classNameBtn="input-group-text sp"
                                placeholder="Tipo"
                                project_id={this.state.data.project_id}
                                _class="KARDEX_METHOD_TP"
                                _state="0"
                                title="Método de control"
                                select={{
                                  _id: this.state.data.method_tp,
                                  caption: this.state.data.method,
                                }}
                                onChange={this.handleChangeMethod}
                              />
                            </div>
                            <div className="form-row input-group-sm mb-0">
                              <div className="form-group col-md-5 input-group-sm mb-0">
                                <label>Unidades</label>
                                <SelectType
                                  className="form-control"
                                  project_id={this.state.data.project_id}
                                  _class="UNITY"
                                  _state={this.state.data._state}
                                  name="unity"
                                  value={this.state.data.unity}
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div className="form-group col-md-7 input-group-sm mb-0">
                                <label>IVA</label>
                                <Count
                                  className="input-group input-group-sm"
                                  classNameUnity="input-group-text unity-sm"
                                  _state={this.state.data._state}
                                  name="tax"
                                  unity="%"
                                  value={this.state.data.tax}
                                  increase={0.1}
                                  precision={2}
                                  handleValue={this.handleChangeTax}
                                />
                              </div>
                            </div>
                            <hr className="mb-1 mt-1" />
                            <div className="form-group input-group-sm">
                              <label>Fabricante</label>
                              <Contact
                                className="form-control"
                                classNameBtn="input-group-text"
                                project_id={this.state.data.project_id}
                                _state={this.state.data._state}
                                select={{
                                  _id: this.state.data.manufacturer_id,
                                  caption: this.state.data._data.manufacturer,
                                }}
                                onChange={this.handleChangeManufacturer}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-2`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-2-tab`}
                      >
                        <ComponentReferenceClass
                          project_id={this.state.data.project_id}
                          _state={this.state.data._state}
                          _class={this.state.data._class}
                          _id={this.state.data._id}
                          only={false}
                          height="528px"
                          show={this.state.tabs[2] || false}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-3`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-3-tab`}
                      >
                        <ComponentReferenceStocks
                          project_id={this.state.data.project_id}
                          _state={this.state.data._state}
                          _class={this.state.data._class}
                          _id={this.state.data._id}
                          only={false}
                          height="528px"
                          show={this.state.tabs[3] || false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooter
                      single="Referencia"
                      plural="la referencia"
                      project_id={this.state.data.project_id}
                      _state={this.state.data._state}
                      isNew={this.state.isNew}
                      onFinish={this.handleFinish}
                      onRestore={this.handleRestore}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    project: state.sistem.project,
    user_id: state.sistem.profile._id || "-1",
    user_name: state.sistem.profile.caption || "",
    _view: state.sistem.folder._view || "",
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ModalReference);
