import React from "react";
import ReactDOM from "react-dom";
import "../../styles/modal.scss";
import { showModal, hideModal, ShowInfo, msg, genId, getValue, formId, getSource } from "../../components/utilities";
import { Name } from "../../components/inputs";
import ModalHeader from "../../components/modalHeader";
import ModalFooter from "../../components/modalFooter";
import { Api as Asset } from "../../services/asset";
import { connect } from "react-redux";

class ModalZona extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme();
    this.state = {
      form: "__ModalZona",
      _id: "__ModalZona",
      title: "Zona",
      show: false,
      old: data,
      data: data,
      printParams: {
        project_id: "-1",
        _class: "ZONA",
        _id: "-1",
        single: true,
      },
      change: false,
      isNew: false,
    };
  }

  handleScheme = (e) => {
    return {
      project_id: this.props.project_id,
      _class: "ZONA_TP00",
      _state: "0",
      _id: genId("-1"),
      name: "",
      caption: "",
      main_id: "-1",
      rural: false,
      _data: {},
      username: "",
    };
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    if (this.props.isFind) {
      const _id = getValue(this.props, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    } else if (this.props.isNew) {
      const form_id = `${this.state.form}${Date.now()}`;
      this.setState({ _id: form_id, show: true, change: false });
    } else {
      const _id = getValue(this.props.data, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    }
  };

  handleClose = () => {
    if (this.state.change) {
      this.setState({ data: this.state.old, change: false });
    }
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.change) {
      const id = this.state.data._id;
      const params = this.state.data;
      Asset.setArea(id, params).then((result) => {
        if (result.msg === "") {
          let data = result.data;
          this.handleSetData({ data, isNew: false, change: false, focusInt: false });
          this.handleExecute(result.data);
        }
      });
    } else {
      this.handleHide();
    }
  };

  handleFinish = (e) => {
    if (e) {
      const id = this.state.data._id;
      Asset.stateArea(id, "1").then((result) => {
        if (result.msg === "") {
          this.handleExecute(result.data);
          this.handleHide();
        }
      });
    }
  };

  handleRestore = (e) => {
    if (e) {
      const id = this.state.data._id;
      Asset.stateArea(id, "0").then((result) => {
        if (result.msg === "") {
          this.handleExecute(result.data);
        }
      });
    }
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      isNew: e.isNew,
      change: e.change,
      printParams: {
        project_id: e.data.project_id,
        _class: e.data._class,
        _id: e.data._id,
        single: true,
      },
    });
    showModal(this.state._id);
  };

  handleData = () => {
    const _id = getSource(this.props.data, ["_id"], "-1");
    if (_id === "-1") {
      this.handleSetData({ data: this.props.data, isNew: false, change: false });
    } else {
      this.handleFind(_id);
    }
  };

  handleNew = () => {
    const data = this.handleScheme();
    this.handleSetData({ data, isNew: true, change: true });
  };

  handleFind = (id) => {
    if (this.state.change) {
      ShowInfo(msg("MSG-CHANGE"));
    } else if (id !== "-1") {
      Asset.area(id, this.props.project_id).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false });
        }
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        if (this.props.isNew) {
          this.handleNew();
        } else if (this.props.isFind) {
          this.handleFind(this.props._id);
        } else {
          this.handleData();
        }
      }
    }
  }

  componentWillUnmount() {
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog xs" role="dialog">
                <div className="modal-content">
                  <ModalHeader title={this.state.title} handleClose={this.handleClose} />
                  <div className="modal-body">
                    <div className="modal-box">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group input-group-sm">
                            <label>Nombre</label>
                            <Name
                              id={`${this.state._id}_caption`}
                              type="text"
                              className="form-control name"
                              placeholder="Nombre"
                              _state={this.state.data._state}
                              name="name"
                              value={this.state.data.name}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>Descripción</label>
                            <input className="form-control textarea" readOnly={true} value={this.state.data.caption} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooter
                      single="zona"
                      plural="la zona"
                      _state={this.state.data._state}
                      isNew={this.state.isNew}
                      onFinish={this.handleFinish}
                      onRestore={this.handleRestore}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    _view: state.sistem.folder._view || "",
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ModalZona);
