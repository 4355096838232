import store from "../store";
import {
  LOADING,
  ALERT,
  ONLINE,
  SIGNIN,
  SIGNOUT,
  SET_FOLDERS,
  SET_FOLDER,
  SET_DISPLAY,
  SET_VAR,
  SET_PAY,
  SET_VIEW_ROWS,
} from "../actionTypes";
import { getRow, getValue, dropDown, getSource, getSourceRow } from "../../components/utilities";
import { Api as Profile } from "../profile";
import { Api as Project } from "../project";
import { Api as Authentication } from "../authentication";

const _loading = (payload) => ({
  type: LOADING,
  payload,
});

const _alert = (payload) => ({
  type: ALERT,
  payload,
});

const _online = (payload) => ({
  type: ONLINE,
  payload,
});

const _signin = (payload) => ({
  type: SIGNIN,
  payload,
});

const _signout = (payload) => ({
  type: SIGNOUT,
  payload,
});

const _setFolders = (payload) => ({
  type: SET_FOLDERS,
  payload,
});

const _setFolder = (payload) => ({
  type: SET_FOLDER,
  payload,
});

const _setDisplay = (payload) => ({
  type: SET_DISPLAY,
  payload,
});

const _setVar = (payload) => ({
  type: SET_VAR,
  payload,
});

const _setPay = (payload) => ({
  type: SET_PAY,
  payload,
});

const _setViewRows = (payload) => ({
  type: SET_VIEW_ROWS,
  payload,
});

function hideAlert() {
  setTimeout(() => {
    const msg = { show: false, message: "", type: "" };
    store.dispatch(_alert(msg));
  }, 2000);
}

function _profile() {
  const state = store.getState();
  return getSource(state.sistem, ["profile"], {});
}

function _project() {
  const state = store.getState();
  return getSource(state.sistem, ["project"], {});
}

function _vars() {
  const state = store.getState();
  return getSource(state.sistem, ["vars"], []);
}

function _pay() {
  const state = store.getState();
  return getSource(state.sistem, ["pay"], 0);
}

function _project_id() {
  return getSource(_project(), ["_id"], "-1");
}

function getVar(_id, _var, _default) {
  const state = store.getState();
  const vars = state.sistem.vars || [];
  const index = vars.findIndex((element) => element._id === _id && element.var === _var);
  if (index === -1) {
    return _default;
  } else {
    return vars[index].value;
  }
}

function setVar(_id, _var, value) {
  const state = store.getState();
  const vars = state.sistem.vars || [];
  const index = vars.findIndex((element) => element._id === _id && element.var === _var);
  if (index === -1) {
    vars.push({
      _id,
      var: _var,
      value,
    });
  } else {
    vars[index].value = value;
  }
  store.dispatch(_setVar(vars));
  return value;
}

async function getProfile() {
  await Profile.profile().then((result) => {
    const error = getSource(result, ["error"], 0);
    if (error === 503) {
      //store.dispatch(_signout({}));
    } else {
      const profile = getSource(result, ["data"], {});
      const projects = getSource(profile, ["projects"], []);
      let project_id = _project_id();
      const filter = (select) => select._id === project_id;
      let index = projects.findIndex(filter);
      if (index === -1) {
        index = 0;
      }
      const project = getRow(projects, index, {});
      project_id = getSource(project, ["_id"], "-1");
      const payload = { profile, project };
      getFolders(project_id, payload);
      getPay(project_id, {});
    }
  });
}

async function getFolders(project_id, payload) {
  const folders = await Profile.folders(project_id).then((result) => {
    const msg = getSource(result, ["msg"], "");
    if (msg === "") {
      return getSource(result, ["data"], []);
    } else {
      return [];
    }
  });
  let folder = getVar(project_id, "folder", {});
  const main_id = getSource(folder, ["main_id"], "-1");
  const folder_id = getSource(folder, ["_id"], "-1");
  let index = 0;
  if (main_id === "-1") {
    const filter = (select) => select._id === folder_id;
    index = folders.findIndex(filter);
    if (index === -1) {
      index = 0;
    }
    folder = getRow(folders, index, {});
  } else {
    let subfolders = folders;
    let filter = (select) => select._id === main_id;
    index = folders.findIndex(filter);
    if (index === -1) {
      index = 0;
    } else {
      subfolders = getSourceRow(folders, index, ["subfolders"], []);
      filter = (select) => select._id === folder_id;
      index = subfolders.findIndex(filter);
      if (index === -1) {
        index = 0;
      }
    }
    folder = getRow(subfolders, index, {});
  }
  payload = Object.assign(payload, { folders, folder });
  store.dispatch(_setFolders(payload));
}

async function getPay(project_id, payload) {
  const pay = await Project.getVar(project_id, "PAY", 0).then((result) => {
    return result;
  });
  payload = Object.assign(payload, { pay });
  store.dispatch(_setPay(payload));
}

async function selProject(project) {
  let project_id = getValue(project, "_id", "-1");
  getFolders(project_id, { project });
  getPay(project_id, {});
}

async function selFolder(item) {
  const project_id = _project_id();
  const folder = item.folder;
  setVar(project_id, "folder", folder);
  store.dispatch(_setFolder(folder));
  getPay(project_id, {});
}

export const Actions = {
  loading: async function (tag, count) {
    store.dispatch(_loading({ show: true, tag, count }));
  },
  outLoading: async function () {
    store.dispatch(_loading({ show: false, tag: "", count: 0 }));
  },
  showAlert: async function (message) {
    const msg = { show: true, message, type: "" };
    store.dispatch(_alert(msg));
    hideAlert();
  },
  showDanger: async function (message) {
    const msg = { show: true, message, type: "danger" };
    store.dispatch(_alert(msg));
    hideAlert();
  },
  showWarning: async function (message) {
    const msg = { show: true, message, type: "warning" };
    store.dispatch(_alert(msg));
    hideAlert();
  },
  showInfo: async function (message) {
    const msg = { show: true, message, type: "info" };
    store.dispatch(_alert(msg));
    hideAlert();
  },
  online: async function (connect) {
    store.dispatch(_online(connect));
  },
  signin: async function (username, password) {
    Authentication.signIn(username, password)
      .then((result) => {
        if (result.msg === "") {
          const token = result.data;
          store.dispatch(_signin(token));
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  signout: async function () {
    store.dispatch(_signout({}));
  },
  match360: async function (token) {
    store.dispatch(_signin(token));
  },
  forgot: async function (username, password, confirmation, code) {
    Authentication.forgot(username, password, confirmation, code)
      .then((result) => {
        if (result.msg === "") {
          const token = result.data;
          store.dispatch(_signin(token));
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  signup: async function (username, password, confirmation, caption, project, module_id, city_id, code) {
    Authentication.signup(username, password, confirmation, caption, project, module_id, city_id, code)
      .then((result) => {
        if (result.msg === "") {
          const token = result.data;
          store.dispatch(_signin(token));
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  selProject: async function (item) {
    selProject(item);
  },
  selFolder: async function (item) {
    selFolder(item);
  },
  setFolders: async function (folders) {
    store.dispatch(_setFolders(folders));
  },
  getFolderDisplay: function (project_id, folder_id) {
    const state = store.getState();
    const display = state.sistem.display || [];
    const index = display.findIndex((element) => element.project_id === project_id && element.folder_id === folder_id);
    if (index === -1) {
      return false;
    } else {
      return display[index].display;
    }
  },
  setFolderDisplay: async function (project_id, folder_id, dropdowId) {
    const state = store.getState();
    const display = state.sistem.display || [];
    const index = display.findIndex((element) => element.project_id === project_id && element.folder_id === folder_id);
    let displayed = true;
    if (index === -1) {
      display.push({
        project_id,
        folder_id,
        display: displayed,
      });
    } else {
      displayed = !display[index].display;
      display[index].display = displayed;
    }
    store.dispatch(_setDisplay(display));
    dropDown(dropdowId, displayed);
  },
  getFolders: function (project_id) {
    getFolders(project_id, {});
  },
  getProfile: function () {
    getProfile();
  },
  getVar: function (_id, _var, _default) {
    return getVar(_id, _var, _default);
  },
  getPay: function (project_id) {
    getPay(project_id, {});
  },
  setVar: function (_id, _var, value) {
    return setVar(_id, _var, value);
  },
  setViewRows: function (rows) {
    return store.dispatch(_setViewRows(rows));
  },
  toogleTalking: async function () {
    console.log({ action: "toogleTalking" });
  },
  profile: async function () {
    return _profile();
  },
  project: async function () {
    return _project();
  },
  vars: async function () {
    return _vars();
  },
  pay: async function () {
    return _pay();
  },
  project_id: async function () {
    return _project_id();
  },
};
