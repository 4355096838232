import React from "react";
import ModalContact from "../modals/modalContact";
import { getSource } from "./utilities";

function TabContact(props) {
  return (
    <React.Fragment>
      <div className={props.state === "All" ? "col-sm-3" : props.state === props.item._state ? "col-sm-3" : "d-none"}>
        <div className="contact-box center-version">
          <div className="contact-detail">
            <img alt="avatar" className="rounded-circle img-thumbnail" src={props.item.avatar === "" ? "/avatar.svg" : props.item.avatar} />
            <h6 className="contact-box-name">{props.item.caption}</h6>
            <div className="contact-box-profile">{props.item.profile}</div>
            <address>
              <div className="detail">{props.item.address === "" ? "Sin dirección conocida" : props.item.address}</div>
              <div className="detail-strong">{props.item.email}</div>
              <div className="detail">{props.item.city}</div>
              <div className="detail">{props.item.phone}</div>
              <div className="detail">Cel: {props.item.cellphone}</div>
            </address>
            <div className="state" style={props.item._state === "1" ? { display: "block" } : { display: "none" }}>
              <i className="fas fa-user-times"></i>
            </div>
          </div>
          <div className="contact-box-footer">
            <div className="btn-group">
              <ModalContact className="btn btn-primary btn-sm" data={props.item} onSetData={props.onSetData}>
                Editar
              </ModalContact>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function TabContacts(props) {
  const list = getSource(props.data, ["list"], []);
  const state = getSource(props.data, ["state"], "0");

  return (
    <React.Fragment>
      {list.map((item, i) => {
        return <TabContact key={i} item={item} state={state} onSetData={props.onSetData}></TabContact>;
      })}
    </React.Fragment>
  );
}

export default TabContacts;
