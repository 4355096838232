import { http } from "../components/http";
import { ShowAlert, getValue, join, setValue } from "../components/utilities";
import environment from "../env/env";
const apiUrl = environment.url;

export const Api = {
  asset: async function (id, project_id) {
    return await http("GET", `${apiUrl}/assets/${id}?project_id=${project_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  class: async function (id, project_id) {
    return await http("GET", `${apiUrl}/assets/clases/${id}?project_id=${project_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  type: async function (id, project_id) {
    return await http("GET", `${apiUrl}/assets/types/${id}?project_id=${project_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  profile: async function (id, project_id) {
    return await http("GET", `${apiUrl}/assets/profiles/${id}?project_id=${project_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  area: async function (id, project_id) {
    return await http("GET", `${apiUrl}/assets/areas/${id}?project_id=${project_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setClase: async function (id, params) {
    return await http("POST", `${apiUrl}/assets/clases/${id}`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setType: async function (id, params) {
    return await http("POST", `${apiUrl}/assets/types/${id}`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setProfile: async function (id, params) {
    return await http("POST", `${apiUrl}/assets/profiles/${id}`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setAsset: async function (id, params) {
    return await http("POST", `${apiUrl}/assets/${id}`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setComponent: async function (id, params) {
    return await http("POST", `${apiUrl}/assets/component/${id}`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  runningAsset: async function (id, running, document_id, document) {
    return await http("POST", `${apiUrl}/assets/running/${id}`, { running, document_id, document })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  failureAsset: async function (id, failure, document_id, document) {
    return await http("POST", `${apiUrl}/assets/failure/${id}`, { failure, document_id, document })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  installAsset: async function (id, main_id, installation, document_id, document) {
    return await http("POST", `${apiUrl}/assets/install/${id}`, { main_id, installation, document_id, document })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  setArea: async function (id, params) {
    return await http("POST", `${apiUrl}/assets/areas/${id}`, params)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateClase: async function (id, state) {
    return await http("PATCH", `${apiUrl}/assets/clases/${id}`, { state })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateType: async function (id, state) {
    return await http("PATCH", `${apiUrl}/assets/types/${id}`, { state })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateProfile: async function (id, state) {
    return await http("PATCH", `${apiUrl}/assets/profiles/${id}`, { state })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateAsset: async function (id, state) {
    return await http("PATCH", `${apiUrl}/assets/${id}`, { state })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  stateArea: async function (id, state) {
    return await http("PATCH", `${apiUrl}/assets/areas/${id}`, { state })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  chkReference: async function (id, reference_id, chk, user_id) {
    return await http("POST", `${apiUrl}/assets/clases/chkReference/${id}`, {
      reference_id,
      chk,
      user_id,
    })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  clases: async function (id, equipment, search, page, rows, list) {
    return await http("GET", `${apiUrl}/assets/clases/?project_id=${id}&equipment=${equipment}&search=${search}&page=${page}&rows=${rows}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  references: async function (project_id, class_id, only, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/assets/clases/references/${project_id}?class_id=${class_id}&only=${only}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  referenceClass: async function (project_id, reference_id, only, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/assets/reference/clases/${project_id}?reference_id=${reference_id}&only=${only}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  tipos: async function (project_id, class_id, state, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/assets/clases/types/${project_id}?class_id=${class_id}&state=${state}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  profiles: async function (project_id, class_id, state, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/assets/profiles/?project_id=${project_id}&class_id=${class_id}&state=${state}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  assets: async function (project_id, class_id, search, page, rows, list) {
    return await http("GET", `${apiUrl}/assets/${project_id}?class_id=${class_id}&search=${search}&page=${page}&rows=${rows}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  equipments: async function (project_id, class_id, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/equipments/?project_id=${project_id}&class_id=${class_id}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  areas: async function (project_id, state, search, page, rows, list) {
    return await http("GET", `${apiUrl}/assets/areas/?project_id=${project_id}&state=${state}&search=${search}&page=${page}&rows=${rows}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  zonas: async function (project_id, state, search, page, rows, list) {
    return await http("GET", `${apiUrl}/assets/zonas/?project_id=${project_id}&state=${state}&search=${search}&page=${page}&rows=${rows}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  getComponents: async function (project_id, type_id, reference_id, main_id, _id) {
    return await http(
      "GET",
      `${apiUrl}/assets/components/${_id}?project_id=${project_id}&type_id=${type_id}&reference_id=${reference_id}&main_id=${main_id}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  getConnections: async function (project_id, type_id, reference_id, _id) {
    return await http("GET", `${apiUrl}/assets/connections/${_id}?project_id=${project_id}&type_id=${type_id}&reference_id=${reference_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  getConnecteds: async function (_id, search, page, rows, list) {
    return await http("GET", `${apiUrl}/assets/connecteds/${_id}?search=${search}&page=${page}&rows=${rows}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  getHisotry: async function (id, page, rows, list) {
    return await http("GET", `${apiUrl}/assets/history/${id}?page=${page}&rows=${rows}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "index");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  undoHisotry: async function (id, asset_id) {
    return await http("POST", `${apiUrl}/assets/undo/${id}`, { asset_id })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  valid: async function (project_id, main_id, class_id, type_id, code, id) {
    return await http(
      "GET",
      `${apiUrl}/assets/valid/${id}?project_id=${project_id}&main_id=${main_id}&class_id=${class_id}&type_id=${type_id}&code=${code}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  getTypeComponents: async function (project_id, id) {
    return await http("GET", `${apiUrl}/assets/types/components/${id}?project_id=${project_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  meassure: async function (id, class_id, page, rows, list) {
    return await http("GET", `${apiUrl}/assets/meassure/?project_id=${id}&class_id=${class_id}&page=${page}&rows=${rows}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "index");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  meassureAreas: async function (id, class_id, page, rows, list) {
    return await http("GET", `${apiUrl}/assets/meassure/areas/?project_id=${id}&class_id=${class_id}&page=${page}&rows=${rows}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  meassureEquipments: async function (id, equipment, page, rows, list) {
    return await http("GET", `${apiUrl}/assets/meassure/equipments/?project_id=${id}&equipment=${equipment}&page=${page}&rows=${rows}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  meassureClass: async function (id, equipment, class_id) {
    return await http("GET", `${apiUrl}/assets/meassure/class/?project_id=${id}&equipment=${equipment}&class_id=${class_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  filter: async function (project_id, user_id, equipment, class_id, filters, search, page, rows, list) {
    return await http("POST", `${apiUrl}/assets/filter/${project_id}`, { user_id, equipment, class_id, filters, search, page, rows })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  filterEquipment: async function (project_id, user_id, class_id, detail, value, filters, search, page, rows, list) {
    return await http("POST", `${apiUrl}/assets/filterEquipment/${project_id}`, {
      user_id,
      class_id,
      detail,
      value,
      filters,
      search,
      page,
      rows,
    })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  filterEquipmentValues: async function (project_id, class_id, detail, value, field, page, rows, list) {
    return await http("POST", `${apiUrl}/assets/filterEquipment/values/${project_id}`, {
      project_id,
      class_id,
      detail,
      value,
      field,
      page,
      rows,
    })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  filterArea: async function (project_id, user_id, area_id, class_id, filters, search, page, rows, list) {
    return await http("POST", `${apiUrl}/assets/filterArea/${project_id}`, { user_id, area_id, class_id, filters, search, page, rows })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  summaryInstall: async function (id, class_id, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/assets/summary/install/?project_id=${id}&class_id=${class_id}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  summaryConnection: async function (id, class_id, search, page, rows, list) {
    return await http(
      "GET",
      `${apiUrl}/assets/summary/connection/?project_id=${id}&class_id=${class_id}&search=${search}&page=${page}&rows=${rows}`
    )
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
};
