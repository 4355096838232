import { http } from "../components/http";
import { ShowAlert } from "../components/utilities";
import environment from "../env/env";
const apiUrl = environment.url;

export const Api = {
  prints: async function (project_id, _class, single) {
    return await http("GET", `${apiUrl}/prints/${project_id}?_class=${_class}&single=${single}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  attachments: async function (project_id, id, object_id, group_tp, _class, main_id, caption, description, user_id, filepath) {
    return await http("POST", `${apiUrl}/attachments/${project_id}`, {
      id,
      object_id,
      group_tp,
      _class,
      main_id,
      caption,
      description,
      user_id,
      filepath,
    })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  attachmentsBase64: async function (
    project_id,
    id,
    object_id,
    group_tp,
    _class,
    main_id,
    caption,
    description,
    name,
    ext,
    size,
    user_id,
    base64
  ) {
    return await http("POST", `${apiUrl}/attachments/base64/${project_id}`, {
      id,
      object_id,
      group_tp,
      _class,
      main_id,
      caption,
      description,
      name,
      ext,
      size,
      user_id,
      base64,
    })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  attachmentsDel: async function (object_id, main_id) {
    return await http("DELETE", `${apiUrl}/attachments/${object_id}`, { main_id })
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  files: async function (id, _class, state, search, page, rows) {
    return await http("GET", `${apiUrl}/attachments/${id}?_class=${_class}&state=${state}&search=${search}&page=${page}&rows${rows}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  alerts: async function (_id) {
    return await http("GET", `${apiUrl}/alerts/${_id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
};
