import React from "react";
import "../../styles/view.scss";
import "../../styles/suport.scss";
import { formatInteger, Subscribe, UnSubscribe } from "../../components/utilities";
import Td from "../../components/td";
import { Api } from "../../services/asset";
import { connect } from "react-redux";

class TabClassEquipments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "__TabClassEquipments",
      _class: "ASSETS",
      rows: this.props.view_rows,
      equipment: props.equipment || true,
      data: {
        list: [],
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        all: 0,
      },
      change: false,
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  eventDelData = (e) => {
    setTimeout(() => {
      this.handleDelete(e);
    }, 500);
  };

  handleData = (scroll) => {
    const project_id = this.props.project_id;
    const equipment = this.state.equipment;
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Api.meassureEquipments(project_id, equipment, page, rows, this.state.data.list).then((result) => {
        if (result.msg === "") {
          this.setState({
            data: result.data,
          });
        }
      });
    } else {
      Api.meassureEquipments(project_id, equipment, 1, rows).then((result) => {
        if (result.msg === "") {
          this.setState({
            data: result.data,
          });
        }
      });
    }
  };

  handleUpdate = (e) => {
    const data = this.state.data;
    if (data.equipment === e.equipment) {
      const list = data.list;
      const index = list.findIndex((element) => element._id === e.class_id);
      if (index > -1) {
        this.handleData(false);
      }
    }
  };

  handleDelete = (e) => {
    this.handleData(false);
  };

  handleScroll = (e) => {
    const h = e.target.scrollTop + e.target.clientHeight + 1;
    if (e.target.id === `${this.state._id}_Scroll` && h >= e.target.scrollHeight) {
      this.handleData(true);
    }
  };

  handleGoDetail = (e) => {
    const title =
      e.status === "ALL"
        ? `Total - ${e.caption}`
        : e.status === "INSTALL" && e._value
        ? `Instalados - ${e.caption}`
        : e.status === "INSTALL" && !e._value
        ? `Desinstalados - ${e.caption}`
        : e.status === "RUNNING"
        ? `En servicio - ${e.caption}`
        : e.status === "FAILURE"
        ? `En falla - ${e.caption}`
        : e.status === "REPORTED"
        ? `Reportados - ${e.caption}`
        : e.status === "LOW"
        ? `Dados de baja - ${e.caption}`
        : `No clasificados - ${e.caption}`;
    this.props.history.push("./equipment", {
      origen: this.props._view,
      filter: {
        project_id: this.props.project_id,
        type: "equipment",
        title: title,
        class_id: e._id,
        detail: e.status,
        _value: e._value,
        quantity: e.quantity,
      },
    });
  };

  componentDidMount() {
    Subscribe(`delete/${this.props._class}/${this.props.project_id}`, (event) => this.eventDelData(event));
    Subscribe(`${this.props._class}/${this.props.project_id}`, (event) => this.eventSetData(event));
    this.handleData(false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps._view === this.props._view && this.props.project_id !== "-1") {
      if (prevProps.project_id !== this.props.project_id || prevProps._class !== this.props._class) {
        UnSubscribe(`delete/${prevProps._class}/${prevProps.project_id}`, (event) => this.eventDelData(event));
        UnSubscribe(`${prevProps._class}/${prevProps.project_id}`, (event) => this.eventSetData(event));
        Subscribe(`delete/${this.props._class}/${this.props.project_id}`, (event) => this.eventDelData(event));
        Subscribe(`${this.props._class}/${this.props.project_id}`, (event) => this.eventSetData(event));
        this.handleData(false);
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`delete/${this.props._class}/${this.props.project_id}`, (event) => this.eventDelData(event));
    UnSubscribe(`${this.props._class}/${this.props.project_id}`, (event) => this.eventSetData(event));
  }

  render() {
    return (
      <React.Fragment>
        <div className="ibox mb-0">
          <div className="ibox-title">Equipos por clase - Total de clases ({this.state.data.all})</div>
          <div className="ibox-content table">
            <Td className="suport-head" cols="200px auto 81px 80px">
              <div className="suport-td">Clase</div>
              <div className="suport-td">Estatus</div>
              <div className="suport-td">Cantidad</div>
              <div className="suport-td">Total</div>
            </Td>
            <div className="suport-scroll auto" id={`${this.state._id}_Scroll`} onScroll={this.handleScroll}>
              {this.state.data.list.map((item, i) => {
                return (
                  <Td key={i} className="suport-row" cols="200px auto 76px">
                    <div className="suport-td caption middle b-g">
                      <div className="text-middle link" onClick={() => this.handleGoDetail(item)}>
                        {item.caption === "" ? "Clase no asignada**" : item.caption}
                      </div>
                    </div>
                    <div className="suport-td c">
                      {item.equipments.map((subitem, j) => {
                        return (
                          <Td key={j} className="suport-row" cols="auto 80px">
                            <div
                              className={
                                subitem.status === "PREVENTIVE"
                                  ? "suport-td caption link success"
                                  : subitem.status === "FAILURE"
                                  ? "suport-td caption link danger"
                                  : subitem._value
                                  ? "suport-td caption link"
                                  : "suport-td caption link warning"
                              }
                              onClick={() => this.handleGoDetail(subitem)}
                            >
                              {subitem.status === "INSTALL" && subitem._value
                                ? "Instalados"
                                : subitem.status === "INSTALL" && !subitem._value
                                ? "Desinstalados"
                                : subitem.status === "RUNNING"
                                ? "En servicio"
                                : subitem.status === "FAILURE"
                                ? "En falla"
                                : subitem.status === "REPORTED"
                                ? "Reportadas"
                                : subitem.status === "PREVENTIVE"
                                ? "Mantenimiento preventivo programado"
                                : subitem.status === "LOW"
                                ? "De baja"
                                : "No definidas"}
                            </div>
                            <div className="suport-td value">{formatInteger(subitem.quantity)}</div>
                          </Td>
                        );
                      })}
                    </div>
                    <div className="suport-td value middle b-g">
                      <div className="text-middle-right">{formatInteger(item.quantity)}</div>
                    </div>
                  </Td>
                );
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    _view: state.sistem.folder._view || "",
    view_rows: state.sistem.view_rows,
  };
}

export default connect(mapStateToProps)(TabClassEquipments);
