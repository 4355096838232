import { http } from "../components/http";
import { ShowAlert } from "../components/utilities";
import environment from "../env/env";
const apiUrl = environment.url;

export const ApiTypehead = {
  users: async function (search) {
    return await http("GET", `${apiUrl}/users/typehead/${search}`, {}, true)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  keeps: async function (project_id, collection, search) {
    return await http("GET", `${apiUrl}/keep/typehead/${project_id}?collection=${collection}&search=${search}`, {}, true)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  assets: async function (project_id, class_id, search) {
    return await http("GET", `${apiUrl}/assets/typehead/${project_id}?class_id=${class_id}&search=${search}`, {}, true)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
};
