import React from "react";
import { connect } from "react-redux";
import { getSource } from "../../../components/utilities";
import { Button, Section, Count } from "../../../components/inputs";
import { ApiUsa as Api } from "../../../services/usaOrder";

class ComponentFloor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 2,
      list: [],
      item: this.handleScheme(),
      change: false,
    };
  }

  handleScheme = () => {
    return {
      project_id: "USA",
      _id: "-1",
      caption: "",
      area: this.props.area,
      code: "",
      _operation: 0,
      unit: 1,
      amount: 0,
      data: {},
      user_id: "C1",
    };
  };

  handleChangeItem = (e) => {
    this.setState({
      item: {
        ...this.state.item,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeItemValue = (e) => {
    this.setState({
      item: {
        ...this.state.item,
        [e.name]: Number(e.value),
      },
      change: true,
    });
  };

  handleSelItem = (e) => {
    this.handleChangeItem(e);
  };

  handleData = () => {
    Api.listFloorAreaSummer(this.props.area).then((result) => {
      if (result.msg === "") {
        const data = result.data;
        this.setState({ list: data, change: false });
      }
    });
  };

  handleClear = () => {
    this.setState({ item: this.handleScheme(), change: false });
  };

  handleRemove = () => {
    const item = this.state.item;
    item._operation = -1;
    item.caption = `Area: ${item.area}, code: ${item.code}`;
    Api.setFloor("-1", item).then((result) => {
      if (result.msg === "") {
        this.handleData();
      }
    });
  };

  handleAdd = () => {
    const item = this.state.item;
    item._operation = 1;
    item.caption = `Area: ${item.area}, code: ${item.code}`;
    Api.setFloor("-1", item).then((result) => {
      if (result.msg === "") {
        this.handleData();
      }
    });
  };

  componentDidMount() {
    if (this.props.tab === this.state.tab) {
      this.handleData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tab !== this.props.tab && this.props.tab === this.state.tab) {
      this.handleData();
    }
  }

  componentWillUnmount() {}

  render() {
    return (
      <React.Fragment>
        <div className="tab-group p-2">
          <div className="tab-group">
            <div className="row">
              <h5 className="col-12 text-center p-1">{`Floor stock (${this.props.area})`}</h5>
            </div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Code</th>
                  <th scope="col">Mount</th>
                  <th scope="col">Needed</th>
                </tr>
              </thead>
              <tbody>
                {this.state.list.map((item, i) => {
                  let code = getSource(item, ["code"], "");
                  let total = getSource(item, ["total"], 0);
                  let balance = getSource(item, ["balance"], 0);
                  let name = "code";
                  let value = code;
                  return (
                    <tr
                      key={`floor_detail_${i}`}
                      className={code === this.state.item.code ? "active" : ""}
                      onClick={() => this.handleSelItem({ target: { name, value } })}
                    >
                      <th scope="row">{i + 1}</th>
                      <td className="text-center">{`${code}`}</td>
                      <td className="text-right">{`${total} box`}</td>
                      <td className="text-right">{`${balance} box`}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="tab-group">
            <div className="row">
              <h5 className="col-12 text-center p-1">Options</h5>
            </div>
            <div className="form-group input-group-sm mb-2">
              <label>Code</label>
              <Section
                id={`component_floor_code`}
                _state="0"
                type="text"
                className="form-control"
                placeholder="Nombre completo"
                name="code"
                value={this.state.item.code}
                onChange={this.handleChangeItem}
              />
            </div>
            <div className="form-group input-group-sm mb-2">
              <label>Unit</label>
              <Count
                classNameInput={"form-control number t-c"}
                id={`component_floor_unit`}
                _state="0"
                name="unit"
                unity="box"
                increase={1}
                minimum={1}
                precision={0}
                value={this.state.item.unit}
                handleValue={this.handleChangeItemValue}
              />
            </div>
            <div className="form-group input-group-sm mb-2">
              <label>Amount</label>
              <Count
                classNameInput={"form-control number t-c"}
                id={`component_floor_mount`}
                _state="0"
                name="amount"
                unity="box"
                increase={1}
                minimum={1}
                precision={0}
                value={this.state.item.amount}
                handleValue={this.handleChangeItemValue}
              />
            </div>
            <div className="form-group input-group-sm mt-4 mb-4">
              <Button className="btn btn-primary mr-3" onClick={this.handleClear}>
                Clear
              </Button>
              <Button className="btn btn-primary mr-3" onClick={this.handleRemove}>
                Remove
              </Button>
              <Button className="btn btn-primary" onClick={this.handleAdd}>
                Add
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(ComponentFloor);
