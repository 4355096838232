import React from "react";
import { Subscribe, UnSubscribe, getValue, updateList } from "../../../components/utilities";
import { Api as Keep } from "../../../services/keep";
import ModalOperation from "../modalOperation";
import ComponentOperations from "./componentOperations";

class Operations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "__Operations",
      title: "Operaciones",
      name: "SUPORT-KEEP-OPERATION",
      show: false,
      rows: 30,
      data: {
        list: [],
        state: "ALL",
        search: "",
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      select: { _id: "-1", caption: "" },
      change: false,
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeState = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        search: "",
        state: e,
      },
    });
  };

  handleData = (scroll) => {
    const _class = this.props._class;
    const _id = this.props._id;
    const state = this.state.data.state;
    const search = this.state.data.search;
    const rows = this.state.rows;
    console.log("scroll:", scroll);
    if (scroll) {
      const page = this.state.data.page + 1;
      Keep.listOperations(_class, _id, state, search, page, rows, this.state.data.list).then((result) => {
        const data = result.data;
        this.setState({
          show: this.props.show,
          data: data,
        });
      });
    } else {
      Keep.listOperations(_class, _id, state, search, 1, rows, []).then((result) => {
        const data = result.data;
        this.setState({
          show: this.props.show,
          data: data,
          change: false,
        });
      });
    }
  };

  handleSearch = () => {
    this.handleData(false);
  };

  handleSelect = (e) => {
    this.setState({ select: e, change: true });
  };

  handleUpdate = (e) => {
    if (this.state.show) {
      let _id = getValue(e, "project_id", "-1");
      if (this.props._class === "SUPORT-KEEP-REPORT") {
        _id = getValue(e, "report_id", "-1");
      } else if (this.props._class === "SUPORT-KEEP-ODT") {
        _id = getValue(e, "odt_id", "-1");
      } else if (this.props._class === "SUPORT-KEEP-DRAFT") {
        _id = getValue(e, "draft_id", "-1");
      } else if (this.props._class === "SUPORT-KEEP-VALE") {
        _id = getValue(e, "vale_id", "-1");
      } else if (this.props._class === "SUPORT-KEEP-RESPONSABLE") {
        _id = getValue(e, "responsable_id", "-1");
      } else if (this.props._class === "ASSETS") {
        _id = getValue(e, "asset_id", "-1");
      }
      if (_id === this.props._id) {
        const data = updateList(this.state.data, e);
        this.setState({ data: data, change: true });
      }
    }
  };

  componentDidMount() {
    Subscribe(`${this.state.name}/${this.props.project_id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show) {
      if (prevProps.show !== this.props.show) {
        this.handleData(false);
      } else if (prevState.data.state !== this.state.data.state) {
        this.handleData(false);
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.name}/${this.props.project_id}`, (event) => this.eventSetData(event));
  }

  render() {
    return (
      <React.Fragment>
        <ComponentOperations
          project_id={this.props.project_id}
          _class={this.props._class}
          master={this.props.master}
          data={this.state.data}
          select={this.state.select}
          isNew={this.props.isNew}
          getData={this.handleData}
          onSelect={this.handleSelect}
          onChange={this.handleChange}
          onSearch={this.handleSearch}
          onState={this.handleChangeState}
          Modal={ModalOperation}
        />
      </React.Fragment>
    );
  }
}

export default Operations;
