import React from "react";
import "../../../styles/modal.scss";
import { Subscribe, UnSubscribe, compare, updateList, getValue, getNumber } from "../../../components/utilities";
import ModalSearch from "../../../components/modalSearch";
import ModalKardex from "../modalKardex";
import { Api as Warehouse } from "../../../services/warehouse";
import Td from "../../../components/td";
import { connect } from "react-redux";

class ComponentReferenceStocks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "__ComponentReferenceStocks",
      name: "KARDEX",
      show: false,
      rows: 30,
      data: {
        list: [],
        search: "",
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      select: { _id: "-1", caption: "" },
      change: false,
      update: {}
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleSetData = (e) => {
    this.handleUpdate(e);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleData = (scroll) => {
    const reference_id = this.props._id;
    const search = this.state.data.search;
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Warehouse.referenceStocks(reference_id, search, page, rows, this.state.data.list).then((result) => {
        this.setState({
          data: result.data,
        });
      });
    } else {
      Warehouse.referenceStocks(reference_id, search, 1, rows, []).then((result) => {
        this.setState({
          data: result.data,
          change: false,
        });
      });
    }
  };

  handleSearch = () => {
    this.handleData(false);
  };

  handleUpdate = (e) => {
    if (compare(this.state.update, e)) {
      const data = updateList(this.state.data, e);
      this.setState({ data: data, change: true, update: e });
    }
  };

  handleScroll = (e) => {
    const h = e.target.scrollTop + e.target.clientHeight + 1;
    if (e.target.id === `${this.state._id}_scroll` && h >= e.target.scrollHeight) {
      this.handleData(true);
    }
  };

  componentDidMount() {
    Subscribe(`${this.state.name}/${this.props.project_id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show) {
      if (prevProps.show !== this.props.show) {
        this.handleData(false);
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.name}/${this.props.project_id}`, (event) => this.eventSetData(event));
  }

  render() {
    return (
      <React.Fragment>
        <div className="modalList single pt-0 pb-0">
          <div className="modalListFind">
            <ModalSearch
              name="search"
              value={this.state.data.search}
              state={this.props._state}
              onChange={this.handleChange}
              onSearch={this.handleSearch}
            />
          </div>
          <div className="modalListList">
            <div className="suport-group">
              <Td className="suport-head" cols="auto 94px">
                <div className="suport-td m">Bodega</div>
                <div className="suport-td m">Stock</div>
              </Td>
              <div
                className="suport-scroll small"
                id={`${this.state._id}_scroll`}
                onScroll={this.handleScroll}
                style={{ height: this.props.height || "280px" }}
              >
                {this.state.data.list.map((item, i) => {
                  return (
                    <ModalKardex key={i} isFind={true} _id={item._id} data={item}>
                      <Td className="suport-item" _id={item._id} cols="auto 90px">
                        <div className="suport-td">{getValue(item, "cellar", "")}</div>
                        <div className="suport-td t-r">{getNumber(item, "stock", "")}</div>
                      </Td>
                    </ModalKardex>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    user_id: state.sistem.profile._id || "-1",
    _view: state.sistem.folder._view || "",
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ComponentReferenceStocks);
