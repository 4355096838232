import React from "react";
import ReactDOM from "react-dom";
import "../../styles/modal.scss";
import "../../styles/suport.scss";
import {
  showModal,
  hideModal,
  ShowInfo,
  msg,
  setFocus,
  getValue,
  getData,
  genId,
  clone,
  div,
  getRow,
  getRowValue,
  getRowNumber,
  getRowMoney,
  formatMoney,
  ShowAlert,
  getStorage,
  formId,
  formatDate,
  getSource,
  UnSubscribe,
  Subscribe,
  compareObject,
} from "../../components/utilities";
import { ALERT001, ALERT002 } from "../../components/msg";
import { Textarea, Select, User, Count, Input } from "../../components/inputs";
import { Stock } from "./listStock";
import { Cellar } from "./listCellars";
import { Suport } from "../listSuports";
import ModalHeaderSuport from "../../components/modalHeaderSuport";
import ModalFooterSuport from "../../components/modalFooterSuport";
import ModalHeader from "../../components/modalHeader";
import ModalFooter from "../../components/modalFooter";
import Td from "../../components/td";
import DateTime from "../../components/dateTime";
import ComponentMovements from "./components/componentMovements";
import { Api as Warehouse } from "../../services/warehouse";
import { Actions as Slope } from "../../services/actions/stock";
import { connect } from "react-redux";

class ModalExit extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme(props.data);
    this.state = {
      form: "__ModalExit",
      _id: "__ModalExit",
      title: "Salida",
      name: "exit",
      show: false,
      old: data,
      data: data,
      isNew: props.isNew || false,
      change: false,
      rows: 35,
      printParams: {
        project_id: "-1",
        _class: "SUPORT-WAREHOUSE-EXIT",
        _id: "-1",
        single: true,
      },
      document_tp: [
        { _id: "-1", caption: "" },
        { _id: "SUPORT-KEEP-VALE", caption: "Vale" },
        { _id: "SUPORT-WAREHOUSE-REQUISITION", caption: "Requisición" },
        { _id: "SUPORT-WAREHOUSE-REMISSION", caption: "Remisión" },
      ],
    };
  }

  handleScheme = (data) => {
    const now = new Date();
    return {
      date_of: formatDate(now, "yyyy-MM-dd HH:mm:ss"),
      project_id: this.props.project_id,
      _class: "SUPORT-WAREHOUSE-EXIT",
      _state: "0",
      _id: genId("-1"),
      code: "",
      caption: "Salida",
      description: "",
      observations: "",
      warehouse_id: "-1",
      cellar_id: getValue(data, "cellar_id", "-1"),
      _operation: -1,
      document_tp: getValue(data, "document_tp", "-1"),
      document_id: getValue(data, "document_id", "-1"),
      cost: 0,
      details: [],
      movements: [],
      _data: {
        project: this.props.project,
        cellar: getValue(data, "cellar", ""),
        document_code: getValue(data, "document_code", ""),
        document_type: getValue(data, "document_type", ""),
        destino: "",
        solicito_id: getValue(data, "solicito_id", "-1"),
        solicito: getValue(data, "solicito", ""),
        recibio_id: "-1",
        recibio: "",
        entrego_id: this.props.user_id,
        entrego: this.props.user_name,
        aprobo_id: "-1",
        aprobo: "",
        username: "",
      },
      next_id: "-1",
      prior_id: "-1",
    };
  };

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeData = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          [e.target.name]: e.target.value,
        },
      },
      change: true,
    });
  };

  handleChangeDate = (e) => {
    const value = formatDate(e.target.value, "yyyy-MM-dd HH:mm:ss");
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: value,
      },
      change: true,
    });
  };

  handleChangeTpDocument = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
        _data: {
          ...this.state.data._data,
          document_type: e.target.caption,
        },
      },
      change: true,
    });
  };

  handleChangeCellar = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        cellar_id: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          cellar: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeDocument = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        document_id: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          document_code: getValue(e, "code", ""),
          document_type: getData(e, "class_name", ""),
        },
      },
      change: true,
    });
  };

  handleChangeSolicito = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          solicito_id: getValue(e, "_id", "-1"),
          solicito: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeRecibio = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          recibio_id: getValue(e, "_id", "-1"),
          recibio: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeEntrego = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          entrego_id: getValue(e, "_id", "-1"),
          entrego: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeAprobo = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          aprobo_id: getValue(e, "_id", "-1"),
          aprobo: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeDetail = (e) => {
    const details = this.state.data.details;
    details[e.index] = e.item;
    this.setState({
      data: {
        ...this.state.data,
        details: details,
      },
      change: true,
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    const _state = this.props._state || "0";
    if (_state === "main_not_exist") {
      ShowAlert(ALERT001);
    } else {
      if (this.props.isFind) {
        const _id = getValue(this.props, "_id", "-1");
        if (_id !== "-1") {
          const form_id = formId(`${this.state.form}${_id}`);
          this.setState({ _id: form_id, show: true, change: false });
        }
      } else if (this.props.isNew) {
        const form_id = `${this.state.form}${Date.now()}`;
        this.setState({ _id: form_id, show: true, change: false });
      } else {
        const _id = getValue(this.props.data, "_id", "-1");
        if (_id !== "-1") {
          const form_id = formId(`${this.state.form}${_id}`);
          this.setState({ _id: form_id, show: true, change: false });
        }
      }
    }
  };

  handleClose = (e) => {
    if (this.state.change && e) {
      this.handleOk();
    } else {
      this.handleHide();
    }
  };

  handleOk = () => {
    if (this.state.change) {
      const id = this.state.data._id;
      const params = this.state.data;
      Warehouse.setExit(id, params).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false });
          this.handleExecute(data);
        }
      });
    } else {
      this.handleHide();
    }
  };

  handleState = (e) => {
    if (this.state.change) {
      ShowAlert(ALERT002);
    } else {
      const _state = getValue(this.state.data, "_state");
      if (_state !== e) {
        const id = getValue(this.state.data, "_id");
        Warehouse.stateExit(id, e).then((result) => {
          if (result.msg === "") {
            const data = result.data;
            this.handleSetData({ data, isNew: false, change: false });
            this.handleExecute(data);
          }
        });
      }
    }
  };

  handlePrior = (e) => {
    const _id = getValue(this.state.data, "prior_id", "-1");
    if (_id === "-1") {
      ShowInfo(msg("MSG-FIRST-ROW"));
    } else {
      this.handleFind(_id);
    }
  };

  handleNext = (e) => {
    const _id = getValue(this.state.data, "next_id", "-1");
    if (_id === "-1") {
      ShowInfo(msg("MSG-END-ROW"));
    } else {
      this.handleFind(_id);
    }
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      isNew: e.isNew,
      change: e.change,
      tabs: [],
      printParams: {
        project_id: e.data.project_id,
        _class: e.data._class,
        _id: e.data._id,
        single: true,
      },
    });
    Slope.setSlope([]);
    showModal(this.state._id);
    setFocus(`${this.state._id}_caption`, e.focusInt);
  };

  handleUpdate = (e) => {
    if (compareObject(this.state.data, e)) {
      this.handleSetData({
        data: e,
        isNew: false,
        change: false,
      });
    }
  };

  handleData = () => {
    const _id = getSource(this.props.data, ["_id"], "-1");
    if (_id === "-1") {
      this.handleSetData({ data: this.props.data, isNew: false, change: false, focusInt: true });
    } else {
      this.handleFind(_id);
    }
  };

  handleNew = () => {
    const data = this.handleScheme(this.props.data);
    this.handleSetData({ data, isNew: true, change: true, focusInt: true });
  };

  handleFind = (id) => {
    if (this.state.change) {
      ShowInfo(msg("MSG-CHANGE"));
    } else if (id !== "-1") {
      Warehouse.exite(id, this.props.project_id).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false, focusInt: true });
        }
      });
    }
  };

  componentDidMount() {
    Subscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.data._id !== this.state.data._id) {
        UnSubscribe(`${prevState.data._class}/${prevState.data._id}`, (event) => this.eventSetData(event));
        Subscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
      }
      if (prevState.show !== this.state.show) {
        if (this.props.isNew) {
          this.handleNew();
        } else if (this.props.isFind) {
          this.handleFind(this.props._id);
        } else {
          this.handleData();
        }
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog __modalObject sm" role="document">
                <div className="modal-content">
                  <ModalHeaderSuport
                    title={this.state.data.caption}
                    _state={this.state.data._state}
                    _id={this.state.data._id}
                    isNew={this.state.isNew}
                    authorized={this.state.data.authorized}
                    alerts={this.state.data.alerts}
                    change={this.state.change}
                    handleClose={this.handleClose}
                    handlePrior={this.handlePrior}
                    handleNext={this.handleNext}
                  />
                  <div className="modal-body-tabs">
                    <nav>
                      <div className="nav nav-tabs" role="tablist">
                        <a
                          className="nav-item nav-link active"
                          id={`${this.state._id}-nav-1-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-1`}
                          role="tab"
                          aria-selected="true"
                        >
                          General
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-2-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-2`}
                          role="tab"
                          aria-selected="false"
                        >
                          Movimientos
                        </a>
                      </div>
                    </nav>
                    <div className="tab-content modal">
                      <div
                        className="tab-pane fade show active"
                        id={`${this.state._id}-nav-1`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-1-tab`}
                      >
                        <div className="suport">
                          <div className="suport-group">
                            <Td className="suport-row" cols="90px auto 95px 230px">
                              <div className="suport-td caption">Bodega</div>
                              <div className="suport-td">
                                <Cellar
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  select={{
                                    _id: this.state.data.cellar_id,
                                    caption: this.state.data._data.cellar,
                                  }}
                                  onChange={this.handleChangeCellar}
                                />
                              </div>
                              <div className="suport-td caption">Fecha Salida</div>
                              <div className="suport-td">
                                <DateTime
                                  className="form-control sp form-control-sm t-c"
                                  popperClassName="datepicker-right"
                                  name="date_of"
                                  value={this.state.data.date_of}
                                  _state={this.state.data._state}
                                  onChange={this.handleChangeDate}
                                  placeholder="Fecha de despacho"
                                  dateFormat="MMMM d, yyyy"
                                />
                              </div>
                            </Td>
                            <Td className="suport-row" cols="90px auto 95px 230px">
                              <div className="suport-td caption">Destino</div>
                              <div className="suport-td">
                                <Input
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="destino"
                                  value={this.state.data._data.destino}
                                  onChange={this.handleChangeData}
                                />
                              </div>
                              <div className="suport-td">
                                <Select
                                  className="form-control sp select blod"
                                  _state={this.state.data._state}
                                  name="document_tp"
                                  value={this.state.data.document_tp}
                                  onChange={this.handleChangeTpDocument}
                                  list={this.state.document_tp}
                                />
                              </div>
                              <div className="suport-td">
                                <Suport
                                  className="form-control sp ctr-sm t-m t-c"
                                  classNameBtn="input-group-text sp"
                                  project_id={this.state.data.project_id}
                                  _class={this.state.data.document_tp}
                                  _state={this.state.data._state}
                                  select={{
                                    _id: this.state.data.document_id,
                                    caption: this.state.data._data.document_code,
                                  }}
                                  onChange={this.handleChangeDocument}
                                />
                              </div>
                            </Td>
                          </div>
                          <div className="suport-group">
                            <Td className="suport-row" cols="90px auto 95px 230px">
                              <div className="suport-td caption">Solicitó</div>
                              <div className="suport-td">
                                <User
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  select={{
                                    _id: this.state.data._data.solicito_id,
                                    caption: this.state.data._data.solicito,
                                  }}
                                  onChange={this.handleChangeSolicito}
                                />
                              </div>
                              <div className="suport-td caption">Recibió</div>
                              <div className="suport-td">
                                <User
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  select={{
                                    _id: this.state.data._data.recibio_id,
                                    caption: this.state.data._data.recibio,
                                  }}
                                  onChange={this.handleChangeRecibio}
                                />
                              </div>
                            </Td>
                            <Td className="suport-row" cols="90px auto 95px 230px">
                              <div className="suport-td caption">Entregó</div>
                              <div className="suport-td">
                                <User
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  select={{
                                    _id: this.state.data._data.entrego_id,
                                    caption: this.state.data._data.entrego,
                                  }}
                                  onChange={this.handleChangeEntrego}
                                />
                              </div>
                              <div className="suport-td caption">Aprobó</div>
                              <div className="suport-td">
                                <User
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  select={{
                                    _id: this.state.data._data.aprobo_id,
                                    caption: this.state.data._data.aprobo,
                                  }}
                                  onChange={this.handleChangeAprobo}
                                />
                              </div>
                            </Td>
                          </div>
                          <ComponentDetails
                            data={this.state.data}
                            rows={this.state.rows}
                            changeDetail={this.handleChangeDetail}
                          />
                          <div className="suport-group">
                            <Td className="suport-row" cols="auto">
                              <div className="suport-td d">
                                <Textarea
                                  className="form-control sp notresize"
                                  _state={this.state.data._state}
                                  rows={4}
                                  name="observations"
                                  value={this.state.data.observations}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </Td>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-2`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-2-tab`}
                      >
                        <ComponentMovements
                          _state={this.state.data._state}
                          rows={this.state.rows}
                          _operation={this.state.data._operation}
                          movements={this.state.data.movements}
                        />
                      </div>
                    </div>
                  </div>
                  <ModalFooterSuport
                    data={this.state.data}
                    change={this.state.change}
                    _state={this.state.data._state}
                    send={false}
                    isNew={this.state.isNew}
                    printParams={this.state.printParams}
                    handleState={this.handleState}
                    handleNew={this.handleNew}
                    handleClose={this.handleClose}
                    handleOk={this.handleOk}
                  />
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    project: state.sistem.project,
    user_id: state.sistem.profile._id || "-1",
    user_name: state.sistem.profile.caption || "",
    _view: state.sistem.folder._view || "",
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ModalExit);

class ModalDetail extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme();
    this.state = {
      _id: "__modalExitDetail",
      title: "Detalle",
      show: false,
      old: data,
      data: data,
      change: false,
    };
  }

  handleScheme = () => {
    return {
      document_id: this.props.document_id,
      _state: "0",
      _id: genId("-1"),
      reference_id: "-1",
      code: "",
      description: "",
      unity: "und",
      quantity: 0,
      unitary: 0,
      cost: 0,
      _data: {
        code: "",
        description: "",
        stock: 0,
        username: "",
      },
    };
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeReference = (e) => {
    const data = this.state.data;
    this.setState({
      data: {
        ...this.state.data,
        reference_id: getValue(e, "reference_id", "-1"),
        code: getValue(e, "code", ""),
        description: getValue(e, "reference", ""),
        unity: getValue(e, "unity", ""),
        unitary: getValue(e, "unitary", 0),
        cost: data.quantity * getValue(e, "unitary", 0),
        _data: {
          ...this.state.data._data,
          code: getValue(e, "code", ""),
          description: getValue(e, "reference", ""),
          stock: getValue(e, "total", 0),
        },
      },
      change: true,
    });
  };

  handleChangeValue = (e) => {
    const value = e.value;
    const data = this.state.data;
    if (e.name === "quantity") {
      data.quantity = value;
      data.cost = data.quantity * data.unitary;
    } else if (e.name === "unitary") {
      data.unitary = value;
      data.cost = data.quantity * data.unitary;
    } else if (e.name === "cost") {
      data.cost = value;
      data.unitary = div(data.cost, data.unitary);
    }
    this.setState({
      data: {
        ...this.state.data,
        quantity: data.quantity,
        unitary: data.unitary,
        cost: data.cost,
      },
      change: true,
    });
  };

  handleChangeSlopeOld = () => {
    let item = this.state.old;
    let stock = getStorage(["stock"], {});
    let slope = getValue(stock, "slope", []);
    let index = slope.findIndex(
      (element) => element.cellar_id === item.cellar_id && element.reference_id === item.reference_id
    );
    if (index > -1) {
      let quantity = getValue(item, "quantity", 0);
      slope[index].quantity = slope[index].quantity - quantity;
    }
    Slope.setSlope(slope);
  };

  handleChangeSlopeNew = (item) => {
    let stock = getStorage(["stock"], {});
    let slope = getValue(stock, "slope", []);
    let index = slope.findIndex(
      (element) => element.cellar_id === item.cellar_id && element.reference_id === item.reference_id
    );
    if (index === -1) {
      slope.push({
        cellar_id: this.props.cellar_id,
        reference_id: item.reference_id,
        quantity: item.quantity,
      });
    } else {
      slope[index].quantity = slope[index].quantity - item.quantity;
    }
    Slope.setSlope(slope);
  };

  handleExecute = (e) => {
    if (typeof this.props.onSetData === "function") {
      this.handleChangeSlopeOld();
      this.handleChangeSlopeNew(e);
      this.props.onSetData({ index: this.props.index, item: e });
    }
    this.handleHide();
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({
      show: true,
      change: false,
    });
  };

  handleClose = () => {
    if (this.state.change) {
      this.setState({ data: this.state.old, change: false });
    }
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.change) {
      this.handleExecute(this.state.data);
    } else {
      this.handleHide();
    }
  };

  handleRemove = (e) => {
    if (e) {
      const data = {
        ...this.state.data,
        reference_id: "-1",
        code: "",
        description: "",
        unity: "",
        quantity: 0,
        unitary: 0,
        cost: 0,
        _data: {
          ...this.state.data._data,
          code: "",
          description: "",
          stock: 0,
        },
      };
      this.handleExecute(data);
    }
  };

  handleData = () => {
    let data = clone(this.props.data);
    let _id = getValue(data, "_id", "-1");
    if (_id === "-1") {
      data = this.handleScheme();
    }
    let old = clone(data);
    this.setState({ old: old, data: data, change: false });
    showModal(this.state._id);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        this.handleData();
      }
    }
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog xs" role="dialog">
                <div className="modal-content">
                  <ModalHeader
                    title={`${this.state.title}${this.state.data.code === "" ? "" : " - "}${this.state.data.code}`}
                    handleClose={this.handleClose}
                  />
                  <div className="modal-body">
                    <div className="modal-box">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group input-group-sm">
                            <label>Referencia</label>
                            <Stock
                              className="form-control textarea"
                              placeholder="Referencia"
                              cellar_id={this.props.cellar_id}
                              main_id={this.props.main_id}
                              _state={this.props._state}
                              select={{
                                _id: 0,
                                reference_id: this.state.data.reference_id,
                                code: this.state.data.code,
                                caption: this.state.data.description,
                              }}
                              onChange={this.handleChangeReference}
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>Cantidad</label>
                            <Count
                              id={`${this.state._id}_caption`}
                              className="input-group input-group-sm"
                              _state={this.props._state}
                              name="quantity"
                              unity={this.state.data.unity}
                              value={this.state.data.quantity}
                              maximum={this.state.data._data.stock}
                              increase={1}
                              handleValue={this.handleChangeValue}
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>Existencias</label>
                            <Count
                              className="input-group input-group-sm"
                              _state={this.props._state}
                              write={false}
                              name="stock"
                              unity={this.state.data.unity}
                              value={this.state.data._data.stock || 0}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooter
                      title="¿Retirar detalle?"
                      description="¿Desea retirar el detalle de la compra? este cambio no podrá revertirse."
                      _state={this.props._state}
                      onRemove={this.handleRemove}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function ComponentDetails(props) {
  const data = props.data || {};
  const details = data.details || [];
  const rows = props.rows || 35;
  const handlePrintDetail = (e) => {
    const items = details || [];
    let result = [];

    for (let i = 0; i < e; i++) {
      const item = getRow(items, i);
      result.push(
        <Td
          key={i}
          className={data._state === "0" ? "suport-item" : "suport-item disabled"}
          cols="25px 75px 75px 35px auto 130px"
        >
          <div className="suport-td t-c">{i + 1}</div>
          <div className="suport-td t-c">{getRowValue(items, i, "code", "")}</div>
          <div className="suport-td t-r">{getRowNumber(items, i, "quantity", "")}</div>
          <div className="suport-td t-c">{getRowValue(items, i, "unity", "")}</div>
          <div className="suport-td">{getRowValue(items, i, "description", "")}</div>
          <div className="suport-td t-r bt">
            {getRowMoney(items, i, "cost", "")}
            <ModalDetail
              className="input-group-text sp"
              project_id={data.project_id}
              warehouse_id={data.warehouse_id}
              cellar_id={data.cellar_id}
              document_id={data._id}
              _state={data._state}
              index={i}
              data={item}
              onSetData={props.changeDetail}
            >
              <i className="fa fa-ellipsis-h"></i>
            </ModalDetail>
          </div>
        </Td>
      );
    }
    return result;
  };

  return (
    <React.Fragment>
      <div className="suport-group">
        <Td className="suport-head" cols="100px 75px 35px auto 130px">
          <div className="suport-td">Referencia</div>
          <div className="suport-td">Cantidad</div>
          <div className="suport-td">Und</div>
          <div className="suport-td">Descripción</div>
          <div className="suport-td">Total</div>
        </Td>
        {handlePrintDetail(rows)}
        <Td className="suport-bottom" cols="auto 130px">
          <div className="suport-td t-r">Total</div>
          <div className="suport-td t-r">{formatMoney(data.cost)}</div>
        </Td>
      </div>
    </React.Fragment>
  );
}
