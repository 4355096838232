import Modal from "../modals/modal";
import ModalPurchase from "../modals/warehouse/modalPurchase";
import ModalEnter from "../modals/warehouse/modalEnter";
import ModalExit from "../modals/warehouse/modalExit";
import ModalRefund from "../modals/warehouse/modalRefund";
import ModalInventory from "../modals/warehouse/modalInventory";
import ModalReport from "../modals/keep/modalReport";
import ModalOdt from "../modals/keep/modalOdt";
import ModalVale from "../modals/keep/modalVale";
import ModalOperation from "../modals/keep/modalOperation";
import ModalInform from "../modals/keep/modalInform";

const getModal = function (_class) {
  if (_class === "") {
    return Modal;
  } else if (_class === "SUPORT-WAREHOUSE-ORDER") {
    return ModalPurchase;
  } else if (_class === "SUPORT-WAREHOUSE-ENTRY") {
    return ModalEnter;
  } else if (_class === "SUPORT-WAREHOUSE-EXIT") {
    return ModalExit;
  } else if (_class === "SUPORT-WAREHOUSE-REFUND") {
    return ModalRefund;
  } else if (_class === "SUPORT-WAREHOUSE-INITIAL") {
    return ModalInventory;
  } else if (_class === "SUPORT-KEEP-REPORT") {
    return ModalReport;
  } else if (_class === "SUPORT-KEEP-ODT") {
    return ModalOdt;
  } else if (_class === "SUPORT-KEEP-VALE") {
    return ModalVale;
  } else if (_class === "SUPORT-KEEP-OPERATION") {
    return ModalOperation;
  } else if (_class === "SUPORT-KEEP-INFORM") {
    return ModalInform;
  } else {
    return Modal;
  }
};

export default getModal;
