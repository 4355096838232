import React from "react";
import "../styles/document.scss";
import { formatDate, genId, getSource, getSplit, Loading, ShowAlert } from "../components/utilities";
import { Api as Keep } from "../services/keep";

class UserReport extends React.Component {
  constructor(props) {
    super(props);
    Loading();
    this.title = "Integraciones";
    const project_id = getSplit(this.props.location, ['pathname'], '', '/', 'n');
    const data = this.handleScheme(project_id);
    this.state = {
      project_id: project_id,
      data: data,
      types: [
        { _id: "KEEP_REPORT_TIPO01", caption: "Petición" },
        { _id: "KEEP_REPORT_TIPO02", caption: "Queja o reclamo" }
      ],
      showAlert: false,
      message: ''
    };
  }

  handleScheme = (project_id) => {
    const now = new Date();
    return {
      date_of: formatDate(now, 'yyyy-MM-dd HH:mm:ss'),
      project_id: project_id,
      _class: "SUPORT-KEEP-REPORT",
      _state: "0",
      _id: genId("-1"),
      code: "",
      caption: "Reporte",
      description: "",
      observations: "",
      type_tp: "KEEP_REPORT_TIPO02",
      entry_tp: "KEEP_REPORT_ENTRADA07",
      priority_tp: "SUPORT_PRIORITY02",
      city_id: '68081',
      area_id: '-1',
      rural: false,
      date_int: formatDate(now, 'yyyy-MM-dd HH:mm:ss'),
      date_end: null,
      assets: [],
      odts: [],
      chat_id: "-1",
      _data: {
        project: {},
        class_name: "",
        asunto: "",
        contact: "",
        document_tp: "-1",
        document: "",
        document_type: "",
        telephone: "",
        mobile: "",
        email: "",
        sitio: "",
        area: '',
        city: 'Barrancabermeja',
        type: 'Queja o reclamo',
        entry: 'Web',
        priority: 'Normal',
        username: "",
      },
      next_id: "-1",
      prior_id: "-1",
      _v: 1,
    };
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeData = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          [e.target.name]: e.target.value,
        },
      },
      change: true,
    });
  };

  handleChangeType = (e) => {
    const item = this.state.types.find((item) => item._id === e.target.value);
    const result = {
      name: e.target.name,
      value: e.target.value,
      caption: getSource(item, ["caption"], ""),
    };
    this.setState({
      data: {
        ...this.state.data,
        type_tp: getSource(result, ["value"], "-1"),
        _data: {
          ...this.state.data._data,
          type: getSource(result, ["caption"], ""),
        },
      },
      change: true,
    });
  };

  handleShowCode = (message) => {
    if (message !== '') {
      this.setState({
        showAlert: true,
        message: message
      })
      setTimeout(() => {
        const data = this.handleScheme(this.state.project_id);
        this.setState({
          data: data,
          showAlert: false,
          message: ''
        })
      }, 7000);
    }
  }

  handleSetData = (data) => {
    this.setState({
      data: data,
      changue: false
    })
  }

  handleOk = () => {
    if (this.state.change) {
      const params = this.state.data;
      const id = params._id;
      const name = getSource(params, ['_data', 'contact'], '');
      const mobile = getSource(params, ['_data', 'mobile'], '');
      const observations = getSource(params, ['observations'], '');
      if (name === '') {
        ShowAlert('¡Nombre requerido!');
      } else if (mobile === '') {
        ShowAlert('Teléfono celular requerido!');
      } else if (observations === '') {
        ShowAlert('Descripción requerida!');
      } else {
        Keep.setUserReport(id, params).then((result) => {
          if (result.msg === "") {
            const data = result.data;
            const caption = getSource(data, ['caption'], '');
            this.handleShowCode(caption);
          }
        });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="flex-shrink-0">
          <div className="signinColumns">
            <div className="row mb-4 mt-4">
              <div className="col-sm-3 col-md-3"></div>
              <div className="col-sm-6 col-md-6 mb-4">
                <div className="form-group mb-2">
                  <label htmlFor="input1" className="mb-1">Cliente</label>
                  <input
                    type="text"
                    className="form-control"
                    id="input1"
                    placeholder="Nombre completo"
                    name="contact"
                    value={this.state.data._data.contact}
                    onChange={this.handleChangeData}
                  />
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="input2" className="mb-1">Correo electronico</label>
                  <input
                    type="text"
                    className="form-control"
                    id="input2"
                    placeholder="Correo electronico"
                    name="email"
                    value={this.state.data._data.email}
                    onChange={this.handleChangeData}
                  />
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="input3" className='mb-1'>Tipo</label>
                  <select
                    className="form-control"
                    id="input3"
                    name="type_tp"
                    value={this.state.type_tp}
                    onChange={this.handleChangeType}
                  >
                    {this.state.types.map((item, i) => {
                      return (
                        <option key={i} value={item._id}>
                          {item.caption}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="input4" className='mb-1'>Teléfono celular</label>
                  <input
                    type="text"
                    className="form-control"
                    id="input4"
                    placeholder="Teléfono celular"
                    name="mobile"
                    value={this.state.data._data.mobile}
                    onChange={this.handleChangeData}
                  />
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="input5" className='mb-1'>Descripción</label>
                  <textarea
                    className="form-control notresize"
                    id="input5"
                    rows="5"
                    aria-describedby="help1"
                    name="observations"
                    value={this.state.data.observations}
                    onChange={this.handleChange}
                  />
                  <small id="help1" className="form-text text-muted">Describa la falla.</small>
                </div>
                <button type="submit" className="btn btn-primary" onClick={this.handleOk} >Enviar</button>
                <div className={!this.state.showAlert ? "d-none" : "alert alert-primary mt-4"} role="alert">
                  {this.state.message}
                </div>
              </div>
              <div className="col-sm-3 col-md-3"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserReport;
