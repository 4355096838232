import React from "react";
import { connect } from "react-redux";
import { getRowValue, getSource } from "../../../components/utilities";
import { Button, Section, Count } from "../../../components/inputs";
import { ApiUsa as Api } from "../../../services/usaOrder";

class ComponentLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 4,
      master: [],
      list: [],
      item: this.handleScheme(),
      line: "",
      _order: "",
      change: false,
    };
  }

  handleScheme = () => {
    return {
      project_id: "USA",
      _id: "-1",
      caption: "",
      area: this.props.area,
      line: "",
      _order: "",
      code: "",
      _operation: 0,
      unit: 1,
      amount: 0,
      data: {},
      user_id: "C1",
    };
  };

  handleChangeItem = (e) => {
    this.setState({
      item: {
        ...this.state.item,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeItemValue = (e) => {
    this.setState({
      item: {
        ...this.state.item,
        [e.name]: Number(e.value),
      },
      change: true,
    });
  };

  handleSelItem = (e) => {
    this.handleChangeItem(e);
  };

  handleMaster = () => {
    Api.listAreaLines(this.props.area).then((result) => {
      if (result.msg === "") {
        const data = result.data;
        const line = getRowValue(data, 0, "line", "");
        const _order = getRowValue(data, 0, "_order", "");
        this.handleDetails(data, line, _order);
      }
    });
  };

  handleDetails = (master, line, _order) => {
    Api.orderSummary(this.props.area, line, _order).then((result) => {
      if (result.msg === "") {
        const data = result.data;
        this.setState({ master, list: data, line, _order, change: false });
      }
    });
  };

  handleSetLine = (line, _order) => {
    this.handleDetails(this.state.master, line, _order);
  };

  handleData = () => {
    this.handleMaster();
  };

  handleClear = () => {
    this.setState({ item: this.handleScheme(), change: false });
  };

  handlePut = () => {
    const item = this.state.item;
    item._operation = 1;
    item.line = this.state.line;
    item._order = this.state._order;
    item.caption = `Area: ${item.area}, line: ${item.line}, order: ${item._order}, code: ${item.code}`;
    Api.setLine("-1", item).then((result) => {
      if (result.msg === "") {
        this.handleSetLine(item.line, item._order);
      }
    });
  };

  handleRemove = () => {
    const item = this.state.item;
    item._operation = -1;
    item.line = this.state.line;
    item._order = this.state._order;
    item.caption = `Area: ${item.area}, line: ${item.line}, order: ${item._order} code: ${item.code}`;
    Api.setLine("-1", item).then((result) => {
      if (result.msg === "") {
        this.handleSetLine(item.line, item._order);
      }
    });
  };

  handleCkeckOut = () => {
    let area = this.props.area;
    let line = this.state.line;
    let _order = this.state._order;
    Api.stateOrder(area, line, _order, "1").then((result) => {
      if (result.msg === "") {
        this.handleMaster();
      }
    });
  };

  handleDelete = () => {
    let area = this.props.area;
    let line = this.state.line;
    let _order = this.state._order;
    let code = this.state.item.code;
    Api.deleteOrder(area, line, _order, code).then((result) => {
      if (result.msg === "") {
        this.handleSetLine(line, _order);
      }
    });
  };

  handleSet = () => {
    const item = this.state.item;
    item._operation = 1;
    item.line = this.state.line;
    item.caption = `Area: ${item.area}, , line: ${item.line}, code: ${item.code}`;
    Api.setOrder("-1", item).then((result) => {
      if (result.msg === "") {
        this.handleSetLine(item.line);
      }
    });
  };

  componentDidMount() {
    if (this.props.tab === this.state.tab) {
      this.handleMaster();
      this.handleData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tab !== this.props.tab && this.props.tab === this.state.tab) {
      this.handleData();
    }
  }

  componentWillUnmount() {}

  render() {
    return (
      <React.Fragment>
        <div className="tab-group p-2">
          <div className="tab-group">
            <ul className="list-group mt-2">
              {this.state.master.map((item, i) => {
                let area = getSource(item, ["area"], "");
                let line = getSource(item, ["line"], "");
                let _order = getSource(item, ["_order"], "");
                return (
                  <li
                    key={`area_order_${i}`}
                    className={
                      (this.state.line === line) & (this.state._order === _order)
                        ? "list-group-item active"
                        : "list-group-item"
                    }
                    aria-current="true"
                    onClick={() => this.handleSetLine(line, _order)}
                  >
                    {`Area: ${area}, line: ${line}, order: ${_order}`}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="tab-group">
            <div className="row">
              <h5 className="col-12 text-center p-1">{`Orders line (${this.state.line}), order: ${this.state._order}`}</h5>
            </div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Code</th>
                  <th scope="col">Mount</th>
                  <th scope="col">Put</th>
                  <th scope="col">Needed</th>
                </tr>
              </thead>
              <tbody>
                {this.state.list.map((detail, j) => {
                  let code = getSource(detail, ["code"], "");
                  let total = getSource(detail, ["total"], 0);
                  let put = getSource(detail, ["put"], 0);
                  let needed = getSource(detail, ["needed"], 0);
                  let name = "code";
                  let value = code;
                  return (
                    <tr
                      key={`area_order_detail_${j}`}
                      className={code === this.state.item.code ? "active" : ""}
                      onClick={() => this.handleSelItem({ target: { name, value } })}
                    >
                      <th scope="row">{j + 1}</th>
                      <td className="text-center">{`${code}`}</td>
                      <td className="text-right">{`${total} box`}</td>
                      <td className="text-right">{`${put} box`}</td>
                      <td className="text-right">{`${needed} box`}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="tab-group">
            <div className="row">
              <h5 className="col-12 text-center p-1">Options</h5>
            </div>
            <div className="form-group input-group-sm mb-2">
              <label>Code</label>
              <Section
                id={`component_line_code`}
                _state="0"
                type="text"
                className="form-control"
                placeholder="Nombre completo"
                name="code"
                value={this.state.item.code}
                onChange={this.handleChangeItem}
              />
            </div>
            <div className="form-group input-group-sm mb-2">
              <label>Unit</label>
              <Count
                classNameInput={"form-control number t-c"}
                id={`component_line_unitn`}
                _state="0"
                unity="box"
                increase={1}
                minimum={1}
                precision={0}
                name="unit"
                value={this.state.item.unit}
                handleValue={this.handleChangeItemValue}
              />
            </div>
            <div className="form-group input-group-sm mb-2">
              <label>Mount</label>
              <Count
                classNameInput={"form-control number t-c"}
                id={`component_line_amount`}
                _state="0"
                unity="box"
                increase={1}
                minimum={1}
                precision={0}
                name="amount"
                value={this.state.item.amount}
                handleValue={this.handleChangeItemValue}
              />
            </div>
            <div className="form-group input-group-sm mt-4 mb-4">
              <Button className="btn btn-primary mr-3" onClick={this.handleClear}>
                Clear
              </Button>
              <Button className="btn btn-primary mr-3" onClick={this.handleSet}>
                Set
              </Button>
              <Button className="btn btn-primary mr-3" onClick={this.handlePut}>
                Put
              </Button>
              <Button className="btn btn-primary" onClick={this.handleRemove}>
                Remove
              </Button>
            </div>
            <div className="form-group input-group-sm mt-4 mb-4">
              <Button className="btn btn-primary mr-3" onClick={this.handleCkeckOut}>
                Check out
              </Button>
              <Button className="btn btn-primary mr-3" onClick={this.handleDelete}>
                Delete
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(ComponentLine);
