import { http } from "../components/http";
import { ShowAlert, getValue, join, setValue } from "../components/utilities";
import environment from "../env/env";
const apiUrl = environment.url;

export const Api = {
  logs: async function (search, page, rows, list) {
    return await http("GET", `${apiUrl}/logs/?search=${search}&page=${page}&rows=30`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        } else {
          let data = getValue(result, "data", {});
          let add = getValue(data, "list", []);
          list = join(list, add, "_id");
          data = setValue(data, "list", list);
          result = setValue(result, "data", data);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  delete: async function (id) {
    return await http("DELETE", `${apiUrl}/logs/${id}`)
      .then((result) => {
        if (result.msg !== "") {
          ShowAlert(result.message);
        }
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
};
