import React from "react";
import {
  Subscribe,
  UnSubscribe,
  notifyList,
  formatInteger,
  updateList,
  formatMoney,
  formatDate,
  compare,
} from "../../../components/utilities";
import ModalSearch from "../../../components/modalSearch";
import ModalPrint from "../../../modals/modalPrint";
import Row from "../../../components/row";
import ModalEnter from "../../../modals/warehouse/modalEnter";
import ModalExit from "../../../modals/warehouse/modalExit";
import ModalRefund from "../../../modals/warehouse/modalRefund";
import ModalInventory from "../../../modals/warehouse/modalInventory";
import { Api as Warehouse } from "../../../services/warehouse";
import { connect } from "react-redux";

class ComponentSuports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "__ComponentSuports",
      _view: {},
      rows: 50,
      title: "Soportes",
      name: "SUPORT-WAREHOUSE",
      project_id: this.props.project_id,
      channel: props.cellar_id === "ALL" ? props.warehouse_id : props.cellar_id,
      show: false,
      data: {
        list: [],
        state: "ALL",
        search: "",
        page: 1,
        rows: 50,
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      select: { _id: "-1", caption: "" },
      printParams: {
        _class: "SUPORT-WAREHOUSE",
        _id: "-1",
        single: true,
      },
      change: false,
      update: {}
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  eventNotify = (e) => {
    setTimeout(() => {
      this.handleNotify(e);
    }, 500);
  };

  handleSetData = (e) => {
    this.handleUpdate(e);
    this.handleSelect(e);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleData = (scroll) => {
    const warehouse_id = this.props.warehouse_id;
    const cellar_id = this.props.cellar_id;
    const _class = this.props._class;
    const state = this.props.state;
    const search = this.state.data.search;
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Warehouse.suports(warehouse_id, cellar_id, _class, state, search, page, rows, this.state.data.list).then((result) => {
        const data = result.data;
        this.setState({
          channel: cellar_id === "ALL" ? warehouse_id : cellar_id,
          data: data,
        });
      });
    } else {
      Warehouse.suports(warehouse_id, cellar_id, _class, state, search, 1, rows, []).then((result) => {
        const data = result.data;
        this.setState({
          channel: cellar_id === "ALL" ? warehouse_id : cellar_id,
          data: data,
          change: false,
        });
      });
    }
  };

  handleSelect = (e) => {
    this.setState({ select: e, change: true });
  };

  handleSearch = () => {
    this.handleData(false);
  };

  handleScroll = (e) => {
    const h = e.target.scrollTop + e.target.clientHeight + 1;
    if (e.target.id === `${this.state._id}_scroll` && h >= e.target.scrollHeight) {
      this.handleData(true);
    }
  };

  handleUpdate = (e) => {
    if (compare(this.state.update, e)) {
      const data = updateList(this.state.data, e);
      this.setState({ data: data, change: true, update: e });
    }
  };

  handleNotify = (e) => {
    const data = notifyList(this.state.data, e);
    this.setState({ data: data, change: true });
  };

  handleSelected = (e) => {
    this.setState({ select: e });
  };

  componentDidMount() {
    Subscribe(`${this.state.name}/${this.state.channel}`, (event) => this.eventSetData(event));
    Subscribe(`document/${this.state.project_id}`, (event) => this.eventNotify(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show) {
      if (prevState.channel !== this.state.channel) {
        UnSubscribe(`${this.state.name}/${prevState.channel}`, (event) => this.eventSetData(event));
        Subscribe(`${this.state.name}/${this.state.channel}`, (event) => this.eventSetData(event));
      }
      if (prevProps.show !== this.props.show) {
        this.handleData(false);
      } else if (prevProps.project_id !== this.props.project_id) {
        this.handleData(false);
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.name}/${this.state.channel}`, (event) => this.eventSetData(event));
    UnSubscribe(`document/${this.state.project_id}`, (event) => this.eventNotify(event));
  }

  render() {
    return (
      <React.Fragment>
        <div className="view-list">
          <div className="view-list-menu">
            <div className="viewtop-search">
              <ModalSearch name="search" value={this.state.data.search} onChange={this.handleChange} onSearch={this.handleSearch} />
              <div className="grid-2 grid-l menubar s">
                <ModalPrint className="btn btn-secondary btn-sm" params={this.state.printParams}>
                  <i className="fa fa-print"></i>
                </ModalPrint>
                <ModalEnter
                  className="btn btn-primary btn-sm mr-1"
                  data={{
                    warehouse_id: this.props.warehouse_id,
                    cellar_id: this.props.cellar_id === "ALL" ? "-1" : this.props.cellar_id,
                    cellar: this.props.cellar,
                  }}
                  isNew={true}
                >
                  Entrada
                </ModalEnter>
                <ModalExit
                  className="btn btn-primary btn-sm mr-1"
                  data={{
                    warehouse_id: this.props.warehouse_id,
                    cellar_id: this.props.cellar_id === "ALL" ? "-1" : this.props.cellar_id,
                    cellar: this.props.cellar,
                  }}
                  isNew={true}
                >
                  Salida
                </ModalExit>
                <ModalRefund
                  className="btn btn-primary btn-sm mr-1"
                  data={{
                    warehouse_id: this.props.warehouse_id,
                    cellar_id: this.props.cellar_id === "ALL" ? "-1" : this.props.cellar_id,
                    cellar: this.props.cellar,
                  }}
                  isNew={true}
                >
                  Reintegro
                </ModalRefund>
                <ModalInventory
                  className={this.props.cellar_id === "ALL" ? "d-none" : "btn btn-primary btn-sm mr-1"}
                  data={{
                    warehouse_id: this.props.warehouse_id,
                    cellar_id: this.props.cellar_id === "ALL" ? "-1" : this.props.cellar_id,
                    cellar: this.props.cellar,
                  }}
                  isNew={true}
                >
                  Inventario
                </ModalInventory>
              </div>
              <div className="btn-group btn-group-sm grid-3 grid-r s">
                <p className={this.state.data.all > 0 ? "m-0" : "m-0 d-none"}>
                  <b className="mr-1">{formatInteger(this.state.data.end)}</b>
                  de
                  <b className="ml-1">{formatInteger(this.state.data.all)}</b>
                </p>
              </div>
            </div>
          </div>
          <div className="view-list-detail">
            <div className="dtable">
              <div className="dtable-head">
                <Row className="dtable-row" cols={this.props.cellar_id === "ALL" ? "90px 180px 180px auto 119px" : "90px 180px auto 119px"}>
                  <div className="dtable-th">Fecha</div>
                  <div className={this.props.cellar_id === "ALL" ? "dtable-th" : "d-none"}>Bodega</div>
                  <div className="dtable-th">Soporte</div>
                  <div className="dtable-th">Descripción</div>
                  <div className="dtable-th">Costo</div>
                </Row>
              </div>
              <div className="dtable-details" id={`${this.state._id}_scroll`} onScroll={this.handleScroll}>
                {this.state.data.list.map((item, i) => {
                  if (item._class === "SUPORT-WAREHOUSE-ENTRY") {
                    return (
                      <ModalEnter key={i} project_id={item.project_id} data={item} isFind={item.isFind || false} _id={item._id}>
                        <Row
                          className={
                            item._state === "0" ? "dtable-row inprocess" : item._state === "2" ? "dtable-row cancel" : "dtable-row"
                          }
                          cols={this.props.cellar_id === "ALL" ? "90px 180px 180px auto 115px" : "90px 180px auto 115px"}
                          data={item}
                          _id={item._id}
                          selectedId={this.state.select._id}
                          selected={this.handleSelect}
                        >
                          <div className="dtable-td t-c">{formatDate(item.date_of, "MMM d, yyyy")}</div>
                          <div className={this.props.cellar_id === "ALL" ? "dtable-td" : "d-none"}>{item._data.cellar}</div>
                          <div className="dtable-td">{item.caption}</div>
                          <div className="dtable-td">{item.description}</div>
                          <div className="dtable-td t-r">{formatMoney(item.cost)}</div>
                        </Row>
                      </ModalEnter>
                    );
                  } else if (item._class === "SUPORT-WAREHOUSE-EXIT") {
                    return (
                      <ModalExit key={i} project_id={item.project_id} data={item} isFind={item.isFind || false} _id={item._id}>
                        <Row
                          className={
                            item._state === "0" ? "dtable-row inprocess" : item._state === "2" ? "dtable-row cancel" : "dtable-row"
                          }
                          cols={this.props.cellar_id === "ALL" ? "90px 180px 180px auto 115px" : "90px 180px auto 115px"}
                          data={item}
                          _id={item._id}
                          selectedId={this.state.select._id}
                          selected={this.handleSelect}
                        >
                          <div className="dtable-td t-c">{formatDate(item.date_of, "MMM d, yyyy")}</div>
                          <div className={this.props.cellar_id === "ALL" ? "dtable-td" : "d-none"}>{item._data.cellar}</div>
                          <div className="dtable-td">{item.caption}</div>
                          <div className="dtable-td">{item.description}</div>
                          <div className="dtable-td t-r">{formatMoney(item.cost)}</div>
                        </Row>
                      </ModalExit>
                    );
                  } else if (item._class === "SUPORT-WAREHOUSE-REFUND") {
                    return (
                      <ModalRefund key={i} project_id={item.project_id} data={item} isFind={item.isFind || false} _id={item._id}>
                        <Row
                          className={
                            item._state === "0" ? "dtable-row inprocess" : item._state === "2" ? "dtable-row cancel" : "dtable-row"
                          }
                          cols={this.props.cellar_id === "ALL" ? "90px 180px 180px auto 115px" : "90px 180px auto 115px"}
                          data={item}
                          _id={item._id}
                          selectedId={this.state.select._id}
                          selected={this.handleSelect}
                        >
                          <div className="dtable-td t-c">{formatDate(item.date_of, "MMM d, yyyy")}</div>
                          <div className={this.props.cellar_id === "ALL" ? "dtable-td" : "d-none"}>{item._data.cellar}</div>
                          <div className="dtable-td">{item.caption}</div>
                          <div className="dtable-td">{item.description}</div>
                          <div className="dtable-td t-r">{formatMoney(item.cost)}</div>
                        </Row>
                      </ModalRefund>
                    );
                  } else if (item._class === "SUPORT-WAREHOUSE-INITIAL") {
                    return (
                      <ModalInventory key={i} project_id={item.project_id} data={item} isFind={item.isFind || false} _id={item._id}>
                        <Row
                          className={
                            item._state === "0" ? "dtable-row inprocess" : item._state === "2" ? "dtable-row cancel" : "dtable-row"
                          }
                          cols={this.props.cellar_id === "ALL" ? "90px 180px 180px auto 115px" : "90px 180px auto 115px"}
                          data={item}
                          _id={item._id}
                          selectedId={this.state.select._id}
                          selected={this.handleSelect}
                        >
                          <div className="dtable-td t-c">{formatDate(item.date_of, "MMM d, yyyy")}</div>
                          <div className={this.props.cellar_id === "ALL" ? "dtable-td" : "d-none"}>{item._data.cellar}</div>
                          <div className="dtable-td">{item.caption}</div>
                          <div className="dtable-td">{item.description}</div>
                          <div className="dtable-td t-r">{formatMoney(item.cost)}</div>
                        </Row>
                      </ModalInventory>
                    );
                  } else {
                    return (
                      <Row
                        key={i}
                        className={item._state === "0" ? "dtable-row inprocess" : item._state === "2" ? "dtable-row cancel" : "dtable-row"}
                        cols={this.props.cellar_id === "ALL" ? "90px 180px 180px auto 115px" : "90px 180px auto 115px"}
                        data={item}
                        _id={item._id}
                        selectedId={this.state.select._id}
                        selected={this.handleSelect}
                      >
                        <div className="dtable-td t-c">{formatDate(item.date_of, "MMM d, yyyy")}</div>
                        <div className={this.props.cellar_id === "ALL" ? "dtable-td" : "d-none"}>{item._data.cellar}</div>
                        <div className="dtable-td">{item.caption}</div>
                        <div className="dtable-td">{item.description}</div>
                        <div className="dtable-td t-r">{formatMoney(item.cost)}</div>
                      </Row>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    project: state.sistem.project,
    user_id: state.sistem.profile._id || "-1",
    user_name: state.sistem.profile.caption || "",
    _view: state.sistem.folder._view || "",
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ComponentSuports);
