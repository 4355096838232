import React from "react";
import ReactGallery from "reactive-blueimp-gallery";
import "../styles/modal.scss";
import { Subscribe, UnSubscribe, updateList, getValue, compare } from "./utilities";
import { Api as Project } from "../services/project";

class Images extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "__Images",
      title: "Imagenes",
      name: "IMG",
      show: false,
      rows: 30,
      object_id: getValue(props, "object_id", "-1"),
      data: {
        list: [],
        _class: "IMG",
        state: "0",
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      select: { _id: "-1", caption: "" },
      change: false,
      update: {}
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleSetData = (e) => {
    this.handleUpdate(e);
    this.handleSelect(e);
  };

  handleData = (scroll) => {
    const _id = this.state.object_id;
    const _class = this.state.data._class;
    const state = this.state.data.state;
    const search = this.state.data.search;
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Project.attachments(_id, _class, state, search, page, rows, this.state.data.list).then((result) => {
        const data = result.data;
        this.setState({
          show: this.props.show,
          data: data,
        });
      });
    } else {
      Project.attachments(_id, _class, state, "", 1, rows, this.state.data.list).then((result) => {
        const data = result.data;
        this.setState({
          show: this.props.show,
          data: data,
          change: false,
        });
      });
    }
  };

  handleUpload = (e) => {
    console.log(e);
  };

  handleSelect = (e) => {
    this.setState({ select: e, change: true });
  };

  handleUpdate = (e) => {
    if (this.state.show && compare(this.state.update, e)) {
      const data = updateList(this.state.data, e);
      this.setState({ data: data, change: true, update: e });
    }
  };

  componentDidMount() {
    Subscribe(`${this.state.name}/${this.props.object_id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show) {
      if (prevProps.show !== this.props.show) {
        //this.handleData(false);
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.name}/${this.props.object_id}`, (event) => this.eventSetData(event));
    this.setState({ show: false });
  }

  render() {
    const images = this.state.data.list || [];
    return (
      <React.Fragment>
        <React.Fragment>
          <h6 className="text">Imagenes</h6>
          <div className="modal_content suport-scroll small" style={{ height: this.props.height || "455px" }}>
            <ReactGallery options={{}}>
              {images.map((item, i) => {
                return <ReactGallery.Slide key={i} thumbnail={item.source}></ReactGallery.Slide>;
              })}
            </ReactGallery>
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default Images;
