import React from "react";
import { Redirect } from "react-router-dom";
import "../../styles/view.scss";
import { Loading, formatMoney, setFocus, Subscribe, UnSubscribe, updateList } from "../../components/utilities";
import { Input, Textarea, Section, City, Button } from "../../components/inputs";
import ModalPrint from "../../modals/modalPrint";
import ModalOptions from "../../modals/modalOptions";
import ComponentStock from "./components/componentStock";
import ComponentSuports from "./components/componentSuports";
import { Api as WareHouse } from "../../services/warehouse";
import { connect } from "react-redux";

class ViewCellar extends React.Component {
  constructor(props) {
    super(props);
    Loading();
    const data = this.handleScheme();
    this.state = {
      _id: "__ViewCellar",
      project_id: this.props.project_id,
      _view: {},
      params: this.props.location.state,
      old: data,
      data: data,
      select: {},
      tabs: [],
      printParams: {
        _class: "CELLAR",
        _id: "-1",
        single: true,
      },
      change: false,
    };
  }

  handleScheme = () => {
    return {
      project_id: this.props.project_id,
      warehouse_id: "-1",
      _id: "-1",
      _state: "0",
      username: "",
      caption: "Bodega",
      description: "",
      storer_id: "-1",
      cost: 0,
      header_foto: "",
      city_id: "-1",
      city: "",
      _data: {
        city: "",
      },
      stock: {
        list: [],
        search: "",
        page: 1,
        rows: 30,
        end: 0,
        all: 0,
      },
    };
  };

  eventNotifyStock = (e) => {
    setTimeout(() => {
      this.handleNotifyStock(e);
    }, 500);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeCity = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        city_id: e._id,
        city: e.caption,
      },
      change: true,
    });
  };

  handleChangeStock = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        stock: {
          ...this.state.data.stock,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  handleSetData = (e) => {
    if (!this.props.online) {
      this.handleUpdate(e);
    }
    this.handleSelect(e);
  };

  handleOk = () => {
    if (this.state.change) {
      WareHouse.setCellar(this.state.data._id, this.state.data).then((result) => {
        this.setState({ data: result.data, change: false });
      });
    }
  };

  handleData = (e) => {
    const project_id = this.props.project_id;
    const params = this.state.params;
    const id = params._id;
    const warehouse_id = params.warehouse_id;
    WareHouse.cellar(id, project_id, warehouse_id).then((result) => {
      if (result.msg === "") {
        const data = result.data;
        this.setState({
          data: data,
          old: data,
          printParams: {
            project_id: data.project_id,
            _class: data._class,
            _id: data._id,
            single: true,
          },
        });
        setFocus(`${this.state._id}_caption`);
      }
    });
  };

  handleDetails = (scroll) => {
    const _id = this.state.data._id;
    const stock = this.state.data.stock;
    const search = stock.search;
    const rows = stock.rows;
    if (scroll) {
      const page = stock.page + 1;
      WareHouse.cellarStock(_id, search, page, rows, stock.list).then((result) => {
        const data = result.data;
        this.setState({
          data: {
            ...this.state.data,
            stock: data,
          },
        });
      });
    } else {
      WareHouse.cellarStock(_id, search, 1, rows).then((result) => {
        const data = result.data;
        this.setState({
          data: {
            ...this.state.data,
            stock: data,
          },
        });
      });
    }
  };

  handleSearch = () => {
    this.handleDetails(false);
  };

  handleScroll = (e) => {
    const h = e.target.scrollTop + e.target.clientHeight + 1;
    if (e.target.id === `${this.state._id}_scroll` && h >= e.target.scrollHeight) {
      this.handleDetails(true);
    }
  };

  handleSelected = (e) => {
    this.setState({ select: e });
  };

  handleUpdate = (e) => {
    const result = updateList(this.state.data, e);
    this.setState({ data: result });
  };

  handleNotifyStock = (e) => {
    let data = this.state.data;
    let stock = data.stock;
    let page = stock.page;
    if (page <= 1) {
      this.handleDetails(false);
    }
  };

  handleGoBack = () => {
    this.props.history.push(`${this.props._view}`, {
      origen: "cellar",
    });
  };

  handleFinish = (e) => {
    if (e) {
      WareHouse.stateCellar(this.state.data._id, "1").then((result) => {
        if (result.msg === "") {
          this.handleGoBack();
        }
      });
    }
  };

  handleTab = (e) => {
    const tabs = this.state.tabs;
    if (!tabs[e]) {
      tabs[e] = true;
      this.setState({ tabs: tabs });
    }
  };

  componentDidMount() {
    Subscribe(`kadex/${this.props.project_id}`, (event) => this.eventNotifyStock(event));
    this.handleData();
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    UnSubscribe(`kadex/${this.props.project_id}`, (event) => this.eventNotifyStock(event));
  }

  render() {
    if (this.state.project_id !== this.props.project_id) {
      return <Redirect to={this.props._view} push={true} />;
    }
    return (
      <React.Fragment>
        <div className={this.state._id}>
          <div className="viewcontainer">
            <div className="view-box">
              <div className="view-object">
                <div className="view-detail">
                  <div className="view-detail-title">
                    <div className="btn btn-link btn-sm float-left" onClick={this.handleGoBack}>
                      <i className="fas fa-arrow-left"></i>
                    </div>
                    <div className="text">{this.state.data.caption}</div>
                  </div>
                  <div className="view-detail-list">
                    <nav>
                      <div className="nav nav-tabs" role="tablist">
                        <a
                          className="nav-item nav-link active"
                          id={`${this.state._id}-cellar-1-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-cellar-1`}
                          role="tab"
                          aria-selected="true"
                        >
                          Stock
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-cellar-2-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-cellar-2`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(2)}
                        >
                          Soportes
                        </a>
                      </div>
                    </nav>
                    <div className="tab-content view-main">
                      <div
                        className="tab-pane fade show active"
                        id={`${this.state._id}-cellar-1`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-cellar-1-tab`}
                      >
                        <ComponentStock
                          _id={this.state._id}
                          data={this.state.data}
                          select={this.state.select}
                          printParams={this.state.printParams}
                          changeStock={this.handleChangeStock}
                          search={this.handleSearch}
                          scrolling={this.handleScroll}
                          selected={this.handleSelected}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-cellar-2`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-cellar-2-tab`}
                      >
                        <ComponentSuports
                          project_id={this.state.data.project_id}
                          warehouse_id={this.state.data.warehouse_id}
                          cellar_id={this.state.data._id}
                          cellar={this.state.data.caption}
                          _class="ALL"
                          state="ALL"
                          _state={this.state.data._state}
                          show={this.state.tabs[2] || false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="view-master">
                  <div className="tab-pane fade show active">
                    <div className="tab-group">
                      <div className="row">
                        <h5 className="col-12 text-center p-1">Datos de bodega</h5>
                      </div>
                      <div className="form-group input-group-sm mb-2">
                        <label>Nombre</label>
                        <Section
                          id={`${this.state._id}_caption`}
                          type="text"
                          className="form-control"
                          placeholder="Nombre completo"
                          name="caption"
                          _state={this.state.data._state}
                          value={this.state.data.caption}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="form-group input-group-sm mb-2">
                        <label>Descripción</label>
                        <Textarea
                          className="form-control notresize"
                          rows={4}
                          name="description"
                          _state={this.state.data._state}
                          value={this.state.data.description}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="form-group input-group-sm mb-2">
                        <label>Ciudad</label>
                        <City
                          type="text"
                          className="form-control"
                          classNameBtn="input-group-text"
                          placeholder="Ciudad"
                          _state={this.state.data._state}
                          value={this.state.data.city}
                          city_id={this.state.data.city_id}
                          onChange={this.handleChangeCity}
                        />
                      </div>
                      <div className="form-group input-group-sm">
                        <label>Valor del inventario</label>
                        <Input
                          type="text"
                          readOnly={true}
                          className="form-control t-r"
                          placeholder="Nombre completo"
                          name="cost"
                          _state={this.state.data._state}
                          value={formatMoney(this.state.data.cost)}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="input-group input-group-sm justify-content-end">
                        <ModalOptions
                          className="btn btn-outline-secondary btn-sm mr-2"
                          params={{
                            title: "¿Terminar bodega?",
                            description: "¿Desea dejar de usar la bodega? este cambio no podrá revertirse.",
                          }}
                          change={true}
                          onClose={this.handleFinish}
                        >
                          Terminar
                        </ModalOptions>
                        <ModalPrint className="btn btn-outline-secondary btn-sm mr-2" params={this.state.printParams}>
                          Imprimir
                        </ModalPrint>
                        <Button className="btn btn-primary btn-sm" onClick={this.handleOk}>
                          Aceptar
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    _view: state.sistem.folder._view || "",
    view_rows: state.sistem.view_rows,
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ViewCellar);
