import React from "react";
import ReactDOM from "react-dom";
import "../../../styles/modal.scss";
import "../../../styles/suport.scss";
import {
  showModal,
  hideModal,
  getValue,
  genId,
  clone,
} from "../../../components/utilities";
import { Input, Section, Phone } from "../../../components/inputs";
import ModalHeader from "../../../components/modalHeader";
import ModalFooter from "../../../components/modalFooter";
import ListUsers from "../../listUsers";

class ModalOdtPersonal extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme();
    this.state = {
      _id: "__ModalOdtPersonal",
      title: "Personal",
      show: false,
      old: data,
      data: data,
      change: false,
    };
  }

  handleScheme = (data) => {
    return {
      odt_id: this.props.odt_id,
      _id: genId("-1"),
      personal_id: "-1",
      profile_tp: "KEEP_PROFILE00",
      _data: {
        name: "",
        mobile: "",
        email: "",
      },
    };
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeData = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          [e.target.name]: e.target.value,
        },
      },
      change: true,
    });
  };

  handleChangeUser = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        personal_id: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          name: getValue(e, "caption", ""),
          mobile: getValue(e, "cellphone", ""),
          email: getValue(e, "email", ""),
        },
      },
      change: true,
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData({ index: this.props.index, item: e });
    }
    this.handleHide();
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({
      show: true,
      change: false,
    });
  };

  handleClose = () => {
    if (this.state.change) {
      this.setState({ data: this.state.old, change: false });
    }
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.change) {
      this.handleExecute(this.state.data);
    } else {
      this.handleHide();
    }
  };

  handleRemove = (e) => {
    if (e) {
      const data = {
        ...this.state.data,
        odt_id: "-1",
        personal_id: "-1",
        profile_tp: "-1",
        _data: {
          name: "",
          mobile: "",
          email: "",
        },
      };
      this.handleExecute(data);
    }
  };

  handleData = () => {
    let data = clone(this.props.data);
    const _id = getValue(data, "_id", "-1");
    if (_id === "-1") {
      data = this.handleScheme(this.props.data);
    }
    this.setState({ old: data, data: data, change: false });
    showModal(this.state._id);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        this.handleData();
      }
    }
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog xs" role="dialog">
                <div className="modal-content">
                  <ModalHeader title={this.state.title} handleClose={this.handleClose} />
                  <div className="modal-body">
                    <div className="modal-box">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group input-group-sm">
                            <label>Nombre</label>
                            <div className="input-group input-group-sm">
                              <Section
                                id={`${this.state._id}_caption`}
                                className="form-control input-group-sm"
                                placeholder="Nombre"
                                project_id={this.props.project_id}
                                _state={this.props._state}
                                name="name"
                                value={this.state.data._data.name}
                                onChange={this.handleChangeData}
                              />
                              <div className="input-group-append input-group-sm">
                                <ListUsers
                                  className="input-group-text"
                                  project_id={this.props.project_id}
                                  _state={this.props._state}
                                  select={{
                                    _id: this.state.data.personal_id,
                                    caption: this.state.data._data.name,
                                  }}
                                  onSetData={this.handleChangeUser}
                                  disabled={this.props._state !== "0"}
                                >
                                  <i className="fa fa-ellipsis-h"></i>
                                </ListUsers>
                              </div>
                            </div>
                          </div>
                          <div className="form-group input-group-sm">
                            <label>Celular</label>
                            <Phone
                              className="form-control input-group-sm"
                              _state={this.props._state}
                              name="mobile"
                              value={this.state.data._data.mobile}
                              onChange={this.handleChangeData}
                            />
                          </div>
                          <div className="form-group input-group-sm">
                            <label>Correo</label>
                            <Input
                              className="form-control input-group-sm"
                              _state={this.props._state}
                              name="email"
                              value={this.state.data._data.email}
                              onChange={this.handleChangeData}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooter
                      title="¿Retirar personal?"
                      description="¿Desea retirar el personal de la ODT? este cambio no podrá revertirse."
                      _state={this.props._state}
                      onRemove={this.handleRemove}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

export default ModalOdtPersonal;
