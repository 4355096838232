import React from "react";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "../styles/datepicker.scss";
import es from "../assets/locale/es-la";
registerLocale("es", es);
setDefaultLocale("es");

function DateTime(props) {
  const dateOf = props.value === undefined || props.value === null ? new Date() : new Date(props.value);
  const _state = props._state || "0";
  const handleChange = (e) => {
    props.onChange({ target: { name: props.name, value: e } });
  };

  return (
    <React.Fragment>
      <DatePicker
        className={props.className || "form-control form-control-sm t-c"}
        name={props.name}
        selected={dateOf}
        showTimeSelect={props.showTimeSelect}
        timeIntervals={props.timeIntervals || 1}
        timeCaption={props.timeCaption || "Hora"}
        popperClassName={props.popperClassName || "datetimepicker-left"}
        popperPlacement={props.popperPlacement || "bottom-start"}
        popperModifiers={props.popperModifiers || {}}
        data-placement="right"
        timeFormat={props.timeFormat || "hh:mm aa"}
        locale="es"
        dateFormat={
          _state === "0"
          ? props.showTimeSelect
          ? "dd/MM/yyyy hh:mm aa"
          : "dd/MM/yyyy"
          : props.dateFormat || "dd/MM/yyyy hh:mm aa"
        }
        fixedHeight
        showPopperArrow={false}
        disabled={_state !== "0"}
        onChange={date => handleChange(date)}
      />
    </React.Fragment>
  );
}

export default DateTime;
