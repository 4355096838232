import React from "react";
import ReactDOM from "react-dom";
import "../../styles/modal.scss";
import { showModal, hideModal, Subscribe, UnSubscribe, getValue, emptyValue, updateList, compare } from "../../components/utilities";
import ModalHeaderList from "../../components/modalHeaderList";
import ModalSearchMenu from "../../components/modalSearchMenu";
import ModalList from "../../components/modalList";
import ModalFooterList from "../../components/modalFooterList";
import ModalClass from "./modalClass";
import ModalSelect from "../../components/modalSelect";
import { Api as Assets } from "../../services/asset";

class ListAssetClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "__ListAssetClass",
      title: "Clases",
      name: "JS_CLASS",
      equipment: props.equipment,
      show: false,
      rows: 30,
      data: {
        list: [],
        search: "",
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      select: { _id: "-1", caption: "" },
      change: false,
      update: {}
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleSetData = (e) => {
    this.handleUpdate(e);
    this.handleSelect(e);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeEquipment = (e) => {
    this.setState({
      equipment: Boolean(e),
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
    this.handleHide();
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({
      data: {
        ...this.state.data,
        search: "",
      },
      show: true,
    });
  };

  handleClose = () => {
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.change) {
      this.handleExecute(this.state.select);
    } else {
      this.handleHide();
    }
  };

  handleRemove = () => {
    this.handleSelect({});
    this.handleExecute({});
    this.handleHide();
  };

  handleData = (scroll) => {
    const project_id = this.props.project_id;
    const equipment = this.state.equipment;
    const search = this.state.data.search;
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Assets.clases(project_id, equipment, search, page, rows, this.state.data.list).then((result) => {
        const data = result.data;
        this.setState({
          data: {
            ...this.state.data,
            list: data.list,
            search: data.search,
            page: data.page,
            rows: data.rows,
            int: data.int,
            end: data.end,
            count: data.count,
            all: data.all,
          },
        });
      });
    } else {
      Assets.clases(project_id, equipment, search, 1, rows, [this.state.select]).then((result) => {
        const data = result.data;
        this.setState({
          data: {
            ...this.state.data,
            list: data.list,
            search: data.search,
            page: data.page,
            rows: data.rows,
            int: data.int,
            end: data.end,
            count: data.count,
            all: data.all,
          },
          change: false,
        });
      });
    }
  };

  handleInit = () => {
    const _id = getValue(this.props.select, "_id", "-1");
    if (_id === "-1") {
      this.handleData(false);
    } else {
      Assets.class(_id, this.props.project_id).then((result) => {
        const select = emptyValue(result, "data", { _id: "-1", caption: "" });
        this.setState({
          select: select,
          change: false
        });
        this.handleData(false);
      });
    }
  };

  handleSearch = () => {
    this.handleData(false);
  };

  handleSelect = (e) => {
    this.setState({ select: e, change: true });
  };

  handleUpdate = (e) => {
    if (compare(this.state.update, e)) {
      const data = updateList(this.state.data, e);
      this.setState({ data: data, change: true, update: e });
    }
  };

  componentDidMount() {
    Subscribe(`${this.state.name}/${this.props.project_id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        this.handleInit();
        showModal(this.state._id);
      } else if (prevState.data.state !== this.state.data.state) {
        this.handleData(false);
      } else if (prevState.equipment !== this.state.equipment) {
        this.handleData(false);
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.name}/${this.props.project_id}`, (event) => this.eventSetData(event));
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog xs __modalSingleList" role="document">
                <div className="modal-content">
                  <ModalHeaderList
                    title={this.state.title}
                    handleClose={this.handleClose}
                    state={this.state.data.state}
                    rows={this.state.data.end}
                    all={this.state.data.all}
                  />
                  <div className="modal-body">
                    <div className="modalList">
                      <div className="modalListFind">
                        <ModalSearchMenu
                          name="search"
                          value={this.state.data.search}
                          state={this.state.equipment}
                          onChange={this.handleChange}
                          onSearch={this.handleSearch}
                          onState={this.handleChangeEquipment}
                          states={[
                            { _state: true, caption: "Equipos" },
                            { _state: false, caption: "Componentes" },
                          ]}
                        />
                      </div>
                      <div className="modalListList">
                        <ModalList
                          id={`${this.state._id}_scroll`}
                          _state={this.state.data.state}
                          list={this.state.data.list}
                          select={this.state.select}
                          getData={this.handleData}
                          onSelect={this.handleSelect}
                        />
                      </div>
                      <div className="modalListSelect">
                        <ModalSelect
                          project_id={this.props.project_id}
                          select={this.state.select}
                          onSetData={this.handleSetData}
                          Modal={ModalClass}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooterList
                      project_id={this.props.project_id}
                      _state={this.state.data._state}
                      onRemove={this.handleRemove}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function AssetClass(props) {
  const select = getValue(props, "select", { _id: "-1", caption: "" });

  return (
    <React.Fragment>
      <div className="input-group input-group-sm">
        <input className={props.className || "form-control"} readOnly value={select.caption || ""} />
        <div className="input-group-append">
          <ListAssetClass
            className={props.classNameBtn || "input-group-text"}
            project_id={props.project_id}
            _state={props._state}
            equipment={props.equipment}
            select={select}
            onSetData={props.onChange}
            disabled={props._state !== "0"}
          >
            <i className="fa fa-ellipsis-h"></i>
          </ListAssetClass>
        </div>
      </div>
    </React.Fragment>
  );
}

class SelectAssetClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project_id: props.project_id,
      equipment: props.equipment,
      className: props.className || "",
      readOnly: props.readOnly || false,
      name: props.name || "",
      _state: props._state || "0",
      list: [],
    };
  }

  handleChange = (e) => {
    const list = this.state.list;
    const item = list.find((item) => item._id === e.target.value);
    const result = {
      target: {
        name: e.target.name,
        value: e.target.value,
        caption: getValue(item, "caption"),
        unity: getValue(item, "unity"),
      },
    };
    if (typeof this.props.onChange === "function") {
      this.props.onChange(result);
    }
  };

  handleData = (e) => {
    const project_id = this.state.project_id;
    const equipment = this.state.equipment;
    Assets.clases(project_id, equipment, "", 1, 100, [
      {
        _id: "-1",
        caption: "",
      },
    ]).then((result) => {
      const list = result.data.list;
      this.setState({
        list: list,
      });
    });
  };

  componentDidMount() {
    this.handleData();
  }

  render() {
    return (
      <React.Fragment>
        <select
          className={this.state.className}
          readOnly={this.state.readOnly}
          name={this.state.name}
          value={this.props.value || "-1"}
          onChange={this.handleChange}
          disabled={this.state._state !== "0"}
        >
          {this.state.list.map((item, i) => {
            return (
              <option key={i} value={item._id}>
                {item.caption}
              </option>
            );
          })}
        </select>
      </React.Fragment>
    );
  }
}

export { ListAssetClass, AssetClass, SelectAssetClass };
