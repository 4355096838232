import React from "react";
import ReactDOM from "react-dom";
import "../../styles/modal.scss";
import { showModal, hideModal, Subscribe, UnSubscribe, compare, getValue, updateList, emptyValue } from "../../components/utilities";
import ModalHeaderList from "../../components/modalHeaderList";
import ModalSearchMenu from "../../components/modalSearchMenu";
import ModalSelect from "../../components/modalSelect";
import ModalReference from "./modalReference";
import ModalFooterList from "../../components/modalFooterList";
import ComponentReferences from "./components/componentReferences";
import { SelectType } from "../../components/inputs";
import { Api as Project } from "../../services/project";
import { Api as WareHouse } from "../../services/warehouse";

class ListReferences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "__listReferences",
      title: "Referencias",
      name: "references",
      show: false,
      rows: 30,
      data: {
        list: [],
        type_id: getValue(props, "type_id", "REFERENCE_TYPE00"),
        state: getValue(props, "state", "0"),
        search: getValue(props, "search", ""),
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      select: { _id: "-1", caption: "" },
      change: false,
      update: {}
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleSetData = (e) => {
    this.handleUpdate(e);
    this.handleSelect(e);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeState = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        search: "",
        state: e,
      },
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
    this.handleHide();
  };

  handleHide = () => {
    this.setState({ show: false });
    hideModal(this.state._id);
  };

  handleShow = () => {
    this.setState({
      data: {
        ...this.state.data,
        search: "",
      },
      show: true,
    });
  };

  handleClose = () => {
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.change) {
      this.handleExecute(this.state.select);
    } else {
      this.handleHide();
    }
  };

  handleRemove = () => {
    this.handleSelect({});
    this.handleExecute({});
    this.handleHide();
  };

  handleData = (scroll) => {
    const project_id = this.props.project_id;
    const type_id = this.state.data.type_id;
    const state = this.state.data.state;
    const search = this.state.data.search;
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Project.typeReferences(project_id, type_id, state, search, page, rows, this.state.data.list).then((result) => {
        this.setState({
          data: result.data,
        });
      });
    } else {
      Project.typeReferences(project_id, type_id, state, search, 1, rows, [this.state.select]).then((result) => {
        this.setState({
          data: result.data,
          change: false,
        });
      });
    }
  };

  handleInit = () => {
    let _id = getValue(this.props.select, "_id", "-1");
    if (_id === "-1") {
      this.handleData(false);
    } else {
      WareHouse.reference(_id).then((result) => {
        const select = emptyValue(result, "data", { _id: "-1", caption: "" });
        this.setState({
          select: select,
          change: false,
        });
        this.handleData(false);
      });
    }
  };

  handleSearch = () => {
    this.handleData(false);
  };

  handleSelect = (e) => {
    this.setState({ select: e, change: true });
  };

  handleUpdate = (e) => {
    if (compare(this.state.update, e)) {
      const data = updateList(this.state.data, e);
      this.setState({ data: data, change: true, update: e });
    }
  };

  componentDidMount() {
    Subscribe(`${this.state.name}/${this.props.project_id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        this.handleInit();
        showModal(this.state._id);
      } else if (prevState.data.state !== this.state.data.state) {
        this.handleData(false);
      } else if (prevState.data.type_id !== this.state.data.type_id) {
        this.handleData(false);
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.name}/${this.props.project_id}`, (event) => this.eventSetData(event));
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={this.props.className} onClick={this.handleShow} disabled={!(this.props._state === "0" || !this.props._state)}>
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog s __modalSingleList" role="document">
                <div className="modal-content">
                  <ModalHeaderList
                    title={this.state.title}
                    handleClose={this.handleClose}
                    state={this.state.data.state}
                    rows={this.state.data.end}
                    all={this.state.data.all}
                  />
                  <div className="modal-body">
                    <div className="modalList master select">
                      <div className="modalListMaster">
                        <SelectType
                          className="form-control form-control-sm"
                          project_id={this.props.project_id}
                          _class="REFERENCE_TYPE"
                          _state={this.state.data.state}
                          name="type_id"
                          value={this.state.data.type_id}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="modalListFind">
                        <ModalSearchMenu
                          name="search"
                          value={this.state.data.search}
                          state={this.state.data.state}
                          onChange={this.handleChange}
                          onSearch={this.handleSearch}
                          onState={this.handleChangeState}
                          states={[
                            { _state: "0", caption: "Activas" },
                            { _state: "1", caption: "Terminadas" },
                          ]}
                        />
                      </div>
                      <div className="modalListList">
                        <ComponentReferences
                          id={`${this.state._id}_scroll`}
                          _state={this.state.data.state}
                          list={this.state.data.list}
                          select={this.state.select}
                          getData={this.handleData}
                          onSelect={this.handleSelect}
                        />
                      </div>
                      <div className="modalListSelect">
                        <ModalSelect
                          project_id={this.props.project_id}
                          select={this.state.select}
                          onSetData={this.handleSetData}
                          Modal={ModalReference}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooterList
                      project_id={this.props.project_id}
                      _state={this.state.data._state}
                      Modal={ModalReference}
                      onSetData={this.handleSetData}
                      onRemove={this.handleRemove}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function Reference(props) {
  const select = getValue(props, "select", {
    _id: "-1",
    code: "",
    caption: "",
  });
  if (props.type === "text") {
    return (
      <React.Fragment>
        <div className="input-group input-group-sm">
          <input className={props.className || "form-control"} readOnly value={select.caption || ""} />
          <div className="input-group-append">
            <ListReferences
              className={props.classNameBtn || "input-group-text textarea-btn"}
              project_id={props.project_id}
              _state={props._state || "0"}
              type_id={props.type_id || "REFERENCE_TYPE00"}
              select={select}
              onSetData={props.onChange}
              disabled={props._state !== "0"}
            >
              <i className="fa fa-ellipsis-h"></i>
            </ListReferences>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className="input-group input-group-sm">
          <div className={props.className || "form-control textarea"} readOnly>
            {select.caption || ""}
          </div>
          <div className="input-group-append">
            <ListReferences
              className={props.classNameBtn || "input-group-text textarea-btn"}
              project_id={props.project_id}
              _state={props._state || "0"}
              type_id={props.type_id || "REFERENCE_TYPE00"}
              select={select}
              onSetData={props.onChange}
              disabled={props._state !== "0"}
            >
              <i className="fa fa-ellipsis-h"></i>
            </ListReferences>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export { ListReferences, Reference };
