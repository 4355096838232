import React from "react";
import "../../styles/view.scss";
import "../../styles/suport.scss";
import { minicount, Subscribe, UnSubscribe, getDateFormat, getDifferenceInDays, updateList, compare } from "../../components/utilities";
import { Api as Project } from "../../services/project";
import ModalReport from "../../modals/keep/modalReport";
import { Actions as Sistem } from "../../services/actions/sistem";
import { connect } from "react-redux";

class TabReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "__TabReports",
      class_id: "SUPORT-KEEP-REPORT",
      rows: 5,
      up: Sistem.getVar(this.props.project_id, "__TabReports_Up", true),
      data: {
        list: [],
        search: "",
        page: 1,
        int: 0,
        end: 0,
        all: 0,
      },
      change: false,
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleSetData = (e) => {
    this.handleUpdate(e);
  };

  handleUpdate = (e) => {
    if (compare(this.state.update, e)) {
      const data = updateList(this.state.data, e);
      this.setState({ data: data, update: e });
    }
  };

  handleData = (scroll) => {
    const project_id = this.props.project_id;
    const _class = this.state.class_id;
    const state = "0";
    const search = this.state.data.search;
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Project.documents(project_id, _class, state, search, page, rows, this.state.data.list).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.setState({ data: data });
        }
      });
    } else {
      Project.documents(project_id, _class, state, search, 1, rows).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.setState({ data: data });
        }
      });
    }
  };

  handleScroll = (e) => {
    const h = e.target.scrollTop + e.target.clientHeight + 1;
    if (e.target.id === `${this.state._id}_Scroll` && h >= e.target.scrollHeight) {
      this.handleData(true);
    }
  };

  handleShow = (e) => {
    let up = !Boolean(this.state.up);
    up = Sistem.setVar(this.props.project_id, `${this.state._id}_Up`, up);
    this.setState({ up: up });
  };

  componentDidMount() {
    Subscribe(`${this.state._class}/${this.props.project_id}`, (event) => this.eventSetData(event));
    this.handleData(false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.project_id !== this.props.project_id) {
      UnSubscribe(`${this.state._class}/${prevProps.project_id}`, (event) => this.eventSetData(event));
      Subscribe(`${this.state._class}/${this.props.project_id}`, (event) => this.eventSetData(event));
      this.handleData(false);
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state._class}/${this.props.project_id}`, (event) => this.eventSetData(event));
  }

  render() {
    return (
      <React.Fragment>
        <div className="ibox-title">
          Reportes por atender
          {this.state.data.all === 0
            ? ""
            : this.state.data.all > this.state.rows
            ? ` (${minicount(this.state.data.end)} de ${minicount(this.state.data.all)})`
            : ` (${minicount(this.state.data.all)})`}
          <i className={Boolean(this.state.up) ? "fa fa-chevron-down" : "fa fa-chevron-up"} onClick={this.handleShow} />
        </div>
        <div
          className={Boolean(this.state.up) ? "ibox-content scroll" : "ibox-content scroll d-none"}
          id={`${this.state._id}_Scroll`}
          onScroll={this.handleScroll}
        >
          <div className="feed-activity-list">
            {this.state.data.list.map((item, i) => {
              return (
                <div key={i} className="feed-element">
                  <div className="media-body">
                    <small className="float-right">Hace {getDifferenceInDays(item, "date_of", true)}</small>
                    <strong>
                      <ModalReport className="link" project_id={this.props.project_id} data={item} onSetData={this.handleSetData}>
                        {item.caption}
                      </ModalReport>
                    </strong>
                    <p>{item.description}</p>
                    <small className="text-muted">{getDateFormat(item, "date_of", "MMM d, yyyy hh:mm aa")}</small>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    user_id: state.sistem.profile._id || "-1",
    _view: state.sistem.folder._view || "",
    view_rows: state.sistem.view_rows,
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(TabReports);
