import React from "react";
import { QRCode } from "react-qr-svg";

function ComponentQr(props) {
  const url = props.url;

  return (
    <React.Fragment>
      <div className="tab-group p-4">
        <div className="row">
          <h5 className="col-12 text-center p-1">QR access</h5>
          <div className="form-group col-12">
            <QRCode bgColor="#FFFFFF" fgColor="#343a40" level="H" style={{ width: "100%" }} value={url} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ComponentQr;
