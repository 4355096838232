import React from "react";
import ReactDOM from "react-dom";
import "../../styles/modal.scss";
import "../../styles/suport.scss";
import {
  showModal,
  hideModal,
  ShowInfo,
  msg,
  getDateFormat,
  getValue,
  getData,
  genId,
  getRow,
  ShowAlert,
  formId,
  formatDate,
  Subscribe,
  UnSubscribe,
  compareObject,
  getSource,
} from "../../components/utilities";
import { ALERT002 } from "../../components/msg";
import { Textarea, Input, Type, Section, User, Time, Phone } from "../../components/inputs";
import { Suport } from "../listSuports";
import { ListReports } from "./listReports";
import { OdtCoverage } from "./listCoverage";
import ModalHeaderSuport from "../../components/modalHeaderSuport";
import ModalFooterSuport from "../../components/modalFooterSuport";
import ModalReport from "./modalReport";
import Td from "../../components/td";
import DateTime from "../../components/dateTime";
import Operations from "./components/operations";
import Consumptions from "./components/consumptions";
import Activities from "./components/activities";
import { Api as Keep } from "../../services/keep";
import { connect } from "react-redux";
import ModalOdtPersonal from "./components/modalOdtPersonal";

class ModalOdt extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme(props.data);
    this.state = {
      form: "__ModalOdt",
      _id: "__ModalOdt",
      title: "ODT",
      name: "odt",
      show: false,
      old: data,
      data: data,
      isNew: props.isNew || false,
      change: false,
      tabs: [],
      printParams: {
        project_id: "-1",
        _class: "SUPORT-KEEP-ODT",
        _id: "-1",
        single: true,
      },
    };
  }

  handleScheme = (data) => {
    const now = new Date();
    return {
      date_make: now,
      date_update: now,
      project_id: this.props.project_id,
      _class: getValue(data, "_class", "SUPORT-KEEP-ODT"),
      _state: "0",
      _id: genId("-1"),
      date_of: formatDate(now, "yyyy-MM-dd HH:mm:ss"),
      code: "",
      caption: "ODT",
      description: "",
      observations: "",
      type_tp: getValue(data, "type_tp", "-1"),
      draft_id: getValue(data, "draft_id", "-1"),
      responsable_id: getValue(data, "responsable_id", "-1"),
      count_operations: 0,
      count_reports: 0,
      date_int: formatDate(now, "yyyy-MM-dd HH:mm:ss"),
      date_end: null,
      _time: 0,
      coverage: [],
      personal: [],
      reports: [],
      _data: {
        class_name: "",
        project: this.props.project,
        asunto: "",
        cuadrilla_id: "-1",
        cuadrilla: "",
        vehiculo_id: "-1",
        vehiculo: "",
        responsable: "",
        responsable_mobile: "",
        responsable_email: "",
      },
      prior_id: "-1",
      next_id: "-1",
      _v: 1,
    };
  };

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeData = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          [e.target.name]: e.target.value,
        },
      },
      change: true,
    });
  };

  handleChangeDate = (e) => {
    const value = formatDate(e.target.value, "yyyy-MM-dd HH:mm:ss");
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: value,
      },
      change: true,
    });
  };

  handleChangeType = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        type_tp: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          type: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeCuadrilla = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          cuadrilla_id: getValue(e, "_id", "-1"),
          cuadrilla: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeVehiculo = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          vehiculo_id: getValue(e, "_id", "-1"),
          vehiculo: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeResponsable = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        responsable_id: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          responsable: getValue(e, "caption", ""),
          responsable_mobile: getValue(e, "cellphone", ""),
          responsable_email: getValue(e, "email", ""),
        },
      },
      change: true,
    });
  };

  handleChangeCoverage = (e) => {
    e = e || [];
    let coverage = this.state.data.coverage || [];
    coverage.forEach((element, i) => {
      const index = e.findIndex((item) => item.area_id === element.area_id);
      if (index === -1) {
        coverage[i].area_id = "-1";
        coverage[i]._data.caption = "";
      }
    });
    e.forEach((element, i) => {
      const index = coverage.findIndex((item) => item.area_id === element.area_id);
      if (index === -1) {
        coverage.push({
          date_of: new Date(),
          _id: genId("-1"),
          odt_id: this.state.data._id,
          area_id: element.area_id,
          _data: {
            odt: this.state.data.code,
            caption: element.caption,
          },
        });
      }
    });
    this.setState({
      data: {
        ...this.state.data,
        coverage: coverage,
      },
      change: true,
    });
  };

  handleChangePersonal = (e) => {
    const personal = this.state.data.personal;
    personal[e.index] = e.item;
    this.setState({
      data: {
        ...this.state.data,
        personal: personal,
      },
      change: true,
    });
  };

  handleChangeReport = (e) => {
    let reports = this.state.data.reports;
    const index = e.index;
    const item = reports[index];
    const resport = e.item;
    const result = {
      date_of: new Date(),
      _id: getValue(item, "_id", "-1"),
      odt_id: this.state.data._id,
      report_id: getValue(resport, "_id", "-1"),
      description: getValue(resport, "description", ""),
      _data: {
        odt: this.state.data.code,
        date_of: getValue(resport, "date_of", ""),
        code: getValue(resport, "code", ""),
        description: getValue(resport, "description", ""),
      },
    };
    if (!reports[index]) {
      reports.push(result);
    } else {
      reports[index] = result;
    }
    this.setState({
      data: {
        ...this.state.data,
        reports: reports,
      },
      change: true,
    });
  };

  handleChangeDraft = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        draft_id: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          draft_code: getValue(e, "code", ""),
          draft_number: getValue(e, "_number", ""),
        },
      },
      change: true,
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    if (this.props.isFind) {
      const _id = getValue(this.props, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    } else if (this.props.isNew) {
      const form_id = `${this.state.form}${Date.now()}`;
      this.setState({ _id: form_id, show: true, change: false });
    } else {
      const _id = getValue(this.props.data, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    }
  };

  handleClose = (e) => {
    if (this.state.change && e) {
      this.handleOk();
    } else {
      this.handleHide();
    }
  };

  handleOk = () => {
    if (this.state.change) {
      const id = this.state.data._id;
      const params = this.state.data;
      Keep.setOdt(id, params).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false });
          this.handleExecute(data);
        }
      });
    } else {
      this.handleHide();
    }
  };

  handleState = (e) => {
    if (this.state.change) {
      ShowAlert(ALERT002);
    } else {
      const _state = getValue(this.state.data, "_state");
      if (_state !== e) {
        const id = getValue(this.state.data, "_id");
        Keep.stateOdt(id, e).then((result) => {
          if (result.msg === "") {
            const data = result.data;
            this.handleSetData({ data, change: false });
            this.handleExecute(data);
          }
        });
      }
    }
  };

  handlePrior = (e) => {
    const _id = getValue(this.state.data, "prior_id", "-1");
    if (_id === "-1") {
      ShowInfo(msg("MSG-FIRST-ROW"));
    } else {
      this.handleFind(_id);
    }
  };

  handleNext = (e) => {
    const _id = getValue(this.state.data, "next_id", "-1");
    if (_id === "-1") {
      ShowInfo(msg("MSG-END-ROW"));
    } else {
      this.handleFind(_id);
    }
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      isNew: e.isNew,
      change: e.change,
      tabs: [],
      printParams: {
        project_id: e.data.project_id,
        _class: e.data._class,
        _id: e.data._id,
        single: true,
      },
    });
    showModal(this.state._id);
  };

  handleUpdate = (e) => {
    if (compareObject(this.state.data, e)) {
      this.handleSetData({
        data: e,
        isNew: false,
        change: false,
      });
    }
  };

  handleData = () => {
    const _id = getSource(this.props.data, ["_id"], "-1");
    if (_id === "-1") {
      this.handleSetData({ data: this.props.data, isNew: false, change: false });
    } else {
      this.handleFind(_id);
    }
  };

  handleNew = () => {
    const data = this.handleScheme(this.props.data);
    this.handleSetData({ data, isNew: true, change: true, focusInt: true });
  };

  handleFind = (id) => {
    if (this.state.change) {
      ShowInfo(msg("MSG-CHANGE"));
    } else if (id !== "-1") {
      Keep.odt(id, this.props.project_id).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, change: false });
        }
      });
    }
  };

  handlePrintPersonal = (e) => {
    const items = this.state.data.personal;
    let result = [];

    for (let i = 0; i < e; i++) {
      const item = getRow(items, i);
      result.push(
        <Td key={i} className="suport-item" cols="25px auto 110px 250px">
          <div className="suport-td t-c">{i + 1}</div>
          <div className="suport-td">{getData(item, "name", "")}</div>
          <div className="suport-td t-c">{getData(item, "mobile", "")}</div>
          <div className="suport-td bt">
            {getData(item, "email", "")}
            <ModalOdtPersonal
              className="input-group-text sp"
              project_id={this.state.data.project_id}
              odt_id={this.state.data._id}
              _state={this.state.data._state}
              index={i}
              data={item}
              onSetData={this.handleChangePersonal}
            >
              <i className="fa fa-ellipsis-h"></i>
            </ModalOdtPersonal>
          </div>
        </Td>
      );
    }
    return result;
  };

  handlePrintReports = (e) => {
    const items = this.state.data.reports;
    let result = [];

    for (let i = 0; i < e; i++) {
      const item = getRow(items, i);
      result.push(
        <Td key={i} className="suport-item" cols="25px 90px 80px auto">
          <div className="suport-td t-c">{i + 1}</div>
          <div className="suport-td t-c">{getDateFormat(getValue(item, "_data", {}), "date_of", "MMM d, yyyy")}</div>
          <div className="suport-td t-c">
            <ModalReport
              className="link"
              project_id={this.state.data.project_id}
              isFind={true}
              _id={getValue(item, "report_id", "-1")}
            >
              {getData(item, "code", "")}
            </ModalReport>
          </div>
          <div className="suport-td bt">
            {getValue(item, "description", "")}
            <ListReports
              className="input-group-text sp"
              project_id={this.state.data.project_id}
              document_id={this.state.data._id}
              _state={this.state.data._state}
              state="NOT_ASSIGNED"
              index={i}
              select={{
                _id: getValue(item, "report_id", "-1"),
                code: getData(item, "code", ""),
              }}
              onSetData={(item) => this.handleChangeReport({ item, index: i })}
            >
              <i className="fa fa-ellipsis-h"></i>
            </ListReports>
          </div>
        </Td>
      );
    }
    return result;
  };

  handleTab = (e) => {
    const tabs = this.state.tabs;
    if (!tabs[e]) {
      tabs[e] = true;
      this.setState({ tabs: tabs });
    }
  };

  componentDidMount() {
    Subscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.data._id !== this.state.data._id) {
        UnSubscribe(`${prevState.data._class}/${prevState.data._id}`, (event) => this.eventSetData(event));
        Subscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
      }
      if (prevState.show !== this.state.show) {
        if (this.props.isNew) {
          this.handleNew();
        } else if (this.props.isFind) {
          this.handleFind(this.props._id);
        } else {
          this.handleData();
        }
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog __modalObject sm" role="document">
                <div className="modal-content">
                  <ModalHeaderSuport
                    title={this.state.data.caption}
                    _state={this.state.data._state}
                    _id={this.state.data._id}
                    isNew={this.state.isNew}
                    alerts={this.state.data.alerts}
                    change={this.state.change}
                    handleClose={this.handleClose}
                    handlePrior={this.handlePrior}
                    handleNext={this.handleNext}
                  />
                  <div className="modal-body-tabs">
                    <nav>
                      <div className="nav nav-tabs" role="tablist">
                        <a
                          className="nav-item nav-link active"
                          id={`${this.state._id}-nav-1-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-1`}
                          role="tab"
                          aria-selected="true"
                        >
                          General
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-2-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-2`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(2)}
                        >
                          Operaciones
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-3-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-3`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(3)}
                        >
                          Consumos de material
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-4-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-4`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(4)}
                        >
                          Actividades
                        </a>
                      </div>
                    </nav>
                    <div className="tab-content modal">
                      <div
                        className="tab-pane fade show active"
                        id={`${this.state._id}-nav-1`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-1-tab`}
                      >
                        <div className="suport">
                          <div className="suport-group">
                            <Td className="suport-row" cols="90px auto 90px 160px">
                              <div className="suport-td caption">Asunto</div>
                              <div className="suport-td">
                                <Section
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="asunto"
                                  value={this.state.data._data.asunto}
                                  onChange={this.handleChangeData}
                                />
                              </div>
                              <div className="suport-td caption">Fecha</div>
                              <div className="suport-td">
                                <DateTime
                                  className="form-control sp form-control-sm t-c"
                                  popperClassName="datepicker-right"
                                  name="date_of"
                                  value={this.state.data.date_of}
                                  _state={this.state.data._state}
                                  onChange={this.handleChangeDate}
                                  placeholder="Fecha de creación"
                                  dateFormat="MMM d, yyyy"
                                />
                              </div>
                            </Td>
                            <Td className="suport-row" cols="auto 250px">
                              <div className="suport-td">
                                <Textarea
                                  className="form-control sp notresize"
                                  _state={this.state.data._state}
                                  rows={4}
                                  name="observations"
                                  value={this.state.data.observations}
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div className="suport-td c">
                                <Td className="suport-row" cols="auto 160px">
                                  <div className="suport-td caption">Tipo</div>
                                  <div className="suport-td">
                                    <Type
                                      className="form-control sp ctr-sm t-m"
                                      classNameBtn="input-group-text sp"
                                      placeholder="Tipo"
                                      project_id={this.state.data.project_id}
                                      _class="KEEP_ODTS_TIPO"
                                      _state={this.state.data._state}
                                      title="Tipo de ODT"
                                      select={{
                                        _id: this.state.data.type_tp,
                                        caption: this.state.data._data.type,
                                      }}
                                      onChange={this.handleChangeType}
                                    />
                                  </div>
                                </Td>
                                <Td className="suport-row" cols="auto 160px">
                                  <div className="suport-td caption">Proyecto</div>
                                  <div className="suport-td">
                                    <Suport
                                      className="form-control sp ctr-sm t-m t-c"
                                      classNameBtn="input-group-text sp"
                                      project_id={this.state.data.project_id}
                                      _class="SUPORT-KEEP-DRAFT"
                                      _state={this.state.data._state}
                                      select={{
                                        _id: this.state.data.draft_id,
                                        caption: this.state.data._data.draft_code,
                                      }}
                                      onChange={this.handleChangeDraft}
                                    />
                                  </div>
                                </Td>
                                <Td className="suport-row" cols="auto 160px">
                                  <div className="suport-td caption">Cuadrilla</div>
                                  <div className="suport-td">
                                    <Type
                                      className="form-control sp ctr-sm t-m"
                                      classNameBtn="input-group-text sp"
                                      placeholder="Cuadrilla"
                                      project_id={this.state.data.project_id}
                                      _class="KEEP_ODTS_CUADRILLA"
                                      _state={this.state.data._state}
                                      title="Cuadrillas"
                                      select={{
                                        _id: this.state.data._data.cuadrilla_id,
                                        caption: this.state.data._data.cuadrilla,
                                      }}
                                      onChange={this.handleChangeCuadrilla}
                                    />
                                  </div>
                                </Td>
                                <Td className="suport-row" cols="auto 160px">
                                  <div className="suport-td caption">Vehículo</div>
                                  <div className="suport-td">
                                    <Type
                                      className="form-control sp ctr-sm t-m"
                                      classNameBtn="input-group-text sp"
                                      placeholder="Vehículo"
                                      project_id={this.state.data.project_id}
                                      _class="KEEP_ODTS_VEHICULO"
                                      _state={this.state.data._state}
                                      title="Vehículos"
                                      select={{
                                        _id: this.state.data._data.vehiculo_id,
                                        caption: this.state.data._data.vehiculo,
                                      }}
                                      onChange={this.handleChangeVehiculo}
                                    />
                                  </div>
                                </Td>
                              </div>
                            </Td>
                          </div>
                          <div className="suport-group">
                            <Td className="suport-row" cols="90px auto 90px 160px">
                              <div className="suport-td caption">Responsable</div>
                              <div className="suport-td">
                                <User
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  select={{
                                    _id: this.state.data.responsable_id,
                                    caption: this.state.data._data.responsable,
                                  }}
                                  onChange={this.handleChangeResponsable}
                                />
                              </div>
                              <div className="suport-td caption">Celular</div>
                              <div className="suport-td">
                                <Phone
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="responsable_mobile"
                                  value={this.state.data._data.responsable_mobile}
                                  onChange={this.handleChangeData}
                                />
                              </div>
                            </Td>
                            <Td className="suport-row" cols="90px auto 90px 160px">
                              <div className="suport-td caption">Cobertura</div>
                              <div className="suport-td">
                                <OdtCoverage
                                  className="form-control sp ctr-sm t-m h26"
                                  classNameBtn="input-group-text sp"
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  coverage={this.state.data.coverage}
                                  onChange={this.handleChangeCoverage}
                                />
                              </div>
                              <div className="suport-td caption">Correo</div>
                              <div className="suport-td">
                                <Input
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="responsable_email"
                                  value={this.state.data._data.responsable_email}
                                  onChange={this.handleChangeData}
                                />
                              </div>
                            </Td>
                          </div>
                          <div className="suport-group">
                            <Td className="suport-row" cols="90px auto 90px 160px 90px 160px">
                              <div className="suport-td caption">Fecha Inicio</div>
                              <div className="suport-td">
                                <DateTime
                                  className="form-control sp form-control-sm t-c"
                                  name="date_int"
                                  value={this.state.data.date_int}
                                  _state={this.state.data._state}
                                  showTimeSelect={true}
                                  onChange={this.handleChangeDate}
                                  placeholder="Fecha de inicio"
                                  dateFormat="MMM d, yyyy hh:mm aa"
                                />
                              </div>
                              <div className="suport-td caption">Terminación</div>
                              <div className="suport-td">
                                <DateTime
                                  className="form-control sp form-control-sm t-c"
                                  name="date_end"
                                  value={this.state.data.date_end}
                                  _state={this.state.data._state}
                                  showTimeSelect={true}
                                  onChange={this.handleChangeDate}
                                  placeholder="Fecha de terminación"
                                  dateFormat="MMM d, yyyy hh:mm aa"
                                />
                              </div>
                              <div className="suport-td caption">Tiempo</div>
                              <div className="suport-td">
                                <Time data={this.state.data} />
                              </div>
                            </Td>
                          </div>
                          <div className="suport-group">
                            <Td className="suport-head" cols="auto 110px 250px">
                              <div className="suport-td">Personal</div>
                              <div className="suport-td">Celular</div>
                              <div className="suport-td">Correo</div>
                            </Td>
                            {this.handlePrintPersonal(5)}
                          </div>
                          <div className="suport-group">
                            <Td className="suport-head" cols="115px 80px auto">
                              <div className="suport-td">Fecha</div>
                              <div className="suport-td">Reporte</div>
                              <div className="suport-td">Descripción</div>
                            </Td>
                            {this.handlePrintReports(25)}
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-2`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-2-tab`}
                      >
                        <Operations
                          project_id={this.state.data.project_id}
                          _state={this.state.data._state}
                          _class={this.state.data._class}
                          _id={this.state.data._id}
                          master={{
                            report_id: "-1",
                            report: "",
                            odt_id: getSource(this.state.data, ["_id"], "-1"),
                            odt: getSource(this.state.data, ["code"], ""),
                            responsable_id: getSource(this.state.data, ["responsable_id"], "-1"),
                            responsable: getSource(this.state.data, ["_data", "responsable"], ""),
                            responsable_mobile: getSource(this.state.data, ["_data", "responsable_mobile"], ""),
                            responsable_email: getSource(this.state.data, ["_data", "responsable_email"], ""),
                          }}
                          responsable_id={this.state.data.responsable_id}
                          show={this.state.tabs[2] || false}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-3`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-3-tab`}
                      >
                        <Consumptions
                          project_id={this.state.data.project_id}
                          _state={this.state.data._state}
                          _class={this.state.data._class}
                          _id={this.state.data._id}
                          show={this.state.tabs[3] || false}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-4`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-4-tab`}
                      >
                        <Activities
                          project_id={this.state.data.project_id}
                          _state={this.state.data._state}
                          _class={this.state.data._class}
                          _id={this.state.data._id}
                          show={this.state.tabs[4] || false}
                        />
                      </div>
                    </div>
                  </div>
                  <ModalFooterSuport
                    data={this.state.data}
                    change={this.state.change}
                    _state={this.state.data._state}
                    send={false}
                    isNew={this.state.isNew}
                    printParams={this.state.printParams}
                    handleState={this.handleState}
                    handleNew={this.handleNew}
                    handleClose={this.handleClose}
                    handleOk={this.handleOk}
                  />
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    project: state.sistem.project,
    _view: state.sistem.folder._view || "",
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ModalOdt);
