import React from "react";
import { connect } from "react-redux";
import { getSource } from "./utilities";

class MenuTools extends React.Component {
  render() {
    console.log(this.props.pay);
    return (
      <React.Fragment>
        <div className={this.props.pay > 0 ? "alert alert-danger r-0" : "alert alert-danger r-0 d-none"}>
          {this.props.pay === "1"
            ? "Pago pendiente"
            : this.props.pay === "3"
            ? "Servicio suspendido"
            : "Servicio en proceso de suspensión"}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    pay: getSource(state.sistem, ["pay"], 0),
  };
}

export default connect(mapStateToProps)(MenuTools);
