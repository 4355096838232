import React from "react";
import ReactDOM from "react-dom";
import "../styles/modal.scss";
import {
  showModal,
  hideModal,
  setFocus,
  getValue,
  genId,
  formId,
  getDifferenceInDays,
  chart,
} from "../components/utilities";
import ModalHeader from "../components/modalHeader";
import { Section, SelectType, Phone, City, Input } from "../components/inputs";
import { ApiTypehead } from "../services/typehead";
import { Api as Project } from "../services/project";
import { Actions as Sistem } from "../services/actions/sistem";
import ModalFooter from "../components/modalFooter";
import { connect } from "react-redux";

class ModalProject extends React.Component {
  constructor(props) {
    super(props);
    const data = this.handleScheme();
    this.state = {
      from: "__modalProject",
      _id: "__modalProject",
      title: "Projecto",
      show: false,
      old: data,
      data: data,
      change: false,
      isNew: false,
      tabs: [],
    };
  }

  handleScheme = (e) => {
    return {
      main_id: "-1",
      _class: "ENTERPRICE",
      _state: "0",
      _id: genId("-1"),
      caption: "",
      description: "",
      cellphone: "",
      phone: "",
      email: "",
      country_id: "COL",
      city_id: "-1",
      address: "",
      identification_tp: "IDENTIFICATION_TP003",
      identification: "",
      _data: {},
      modules: [],
      notices: [],
    };
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleSelCity = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        city_id: e._id,
        city: e.caption,
      },
      change: true,
    });
  };

  handleChangeChkModule = (e) => {
    const item = e.item;
    const index = e.index;
    const data = this.state.data;
    const project_id = data._id;
    const module_id = item._id;
    const modules = data.modules;
    const chk = !item.chk;
    Project.chkModules(project_id, module_id, chk).then((result) => {
      Sistem.getProfile();
      modules[index].chk = chk;
      this.setState({
        data: {
          ...this.state.data,
          modules: modules,
        },
      });
    });
  };

  handleNoticeOfPendingPayment = (e) => {
    const item = e.item;
    const index = e.index;
    const data = this.state.data;
    const project_id = data._id;
    const module_id = item._id;
    const modules = data.modules;
    const chk = !item.chk;
    Project.chkModules(project_id, module_id, chk).then((result) => {
      Sistem.getProfile();
      modules[index].chk = chk;
      this.setState({
        data: {
          ...this.state.data,
          modules: modules,
        },
      });
    });
  };

  handleNoticeServiceSuspension = (e) => {
    const item = e.item;
    const index = e.index;
    const data = this.state.data;
    const project_id = data._id;
    const module_id = item._id;
    const modules = data.modules;
    const chk = !item.chk;
    Project.chkModules(project_id, module_id, chk).then((result) => {
      Sistem.getProfile();
      modules[index].chk = chk;
      this.setState({
        data: {
          ...this.state.data,
          modules: modules,
        },
      });
    });
  };

  handleSelect = (e) => {
    const oldId = this.state.data._id;
    this.setState({
      data: {
        ...this.state.data,
        _id: getValue(e, "_id", oldId),
        caption: getValue(e, "caption", ""),
        username: getValue(e, "username", ""),
      },
      change: true,
    });
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    if (this.props.isFind) {
      const _id = getValue(this.props, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    } else if (this.props.isNew) {
      const form_id = `${this.state.form}${Date.now()}`;
      this.setState({ _id: form_id, show: true, change: false });
    } else {
      const _id = getValue(this.props.data, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    }
  };

  handleClose = () => {
    if (this.state.change) {
      this.setState({ data: this.state.old, change: false });
    }
    this.handleHide();
  };

  handleOk = () => {
    if (this.state.change) {
      const id = this.state.data._id;
      const params = this.state.data;
      Project.setProject(id, params).then((result) => {
        if (result.msg === "") {
          let data = result.data;
          this.handleSetData({ data, isNew: false, change: false, focusInt: false });
          this.handleExecute({ data: result.data, action: "set" });
        }
      });
    } else {
      this.handleHide();
    }
  };

  handleFinish = (e) => {
    if (e) {
      const id = this.state.data._id;
      Project.stateProject(id, "1").then((result) => {
        if (result.msg === "") {
          this.handleExecute({ data: this.state.data, action: "del" });
          this.handleHide();
        }
      });
    }
  };

  handleRestore = (e) => {
    if (e) {
      const id = this.state.data._id;
      Project.stateProject(id, "1").then((result) => {
        if (result.msg === "") {
          let data = result.data;
          this.handleSetData({ data, isNew: false, change: false, focusInt: false });
          this.handleExecute({ data: result.data, action: "set" });
        }
      });
    }
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      isNew: e.isNew,
      change: e.change,
    });
    showModal(this.state._id);
    setFocus(`${this.state._id}_caption`, e.focusInt);
  };

  handleData = (focusInt) => {
    const data = this.props.data;
    console.log(data);
    this.handleSetData({ data, isNew: false, change: false, focusInt });
  };

  handleNew = () => {
    const data = this.handleScheme();
    this.handleSetData({ data, isNew: true, change: true, focusInt: true });
  };

  handleTab = (e) => {
    const tabs = this.state.tabs;
    if (!tabs[e]) {
      tabs[e] = true;
      this.setState({ tabs: tabs });
    }
  };

  sourceUsers = (query) => {
    return ApiTypehead.users(query).then((result) => {
      return result;
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.show !== this.state.show) {
        if (this.props.isNew) {
          this.handleNew();
        } else {
          this.handleData(true);
        }
      }
    }
  }

  componentWillUnmount() {
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog sl __modalObject" role="dialog">
                <div className="modal-content">
                  <ModalHeader title={this.state.title} handleClose={this.handleClose} />
                  <div className="modal-body-tabs m">
                    <nav>
                      <div className="nav nav-tabs" role="tablist">
                        <a
                          className="nav-item nav-link active"
                          id={`${this.state._id}-nav-1-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-1`}
                          role="tab"
                          aria-selected="true"
                        >
                          General
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-2-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-2`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(2)}
                        >
                          Modulos
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-3-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-3`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(3)}
                        >
                          Pagos
                        </a>
                      </div>
                    </nav>
                    <div className="tab-content modal not-scroll">
                      <div
                        className="tab-pane fade show active"
                        id={`${this.state._id}-nav-1`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-1-tab`}
                      >
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group input-group-sm">
                              <label>Nombre</label>
                              <Section
                                id={`${this.state._id}_caption`}
                                type="text"
                                className="form-control"
                                placeholder="Nombre completo"
                                name="caption"
                                _state={this.state.data._state}
                                value={this.state.data.caption}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="form-row input-group-sm">
                              <div className="form-group col-md-6 input-group-sm">
                                <label>Número celular</label>
                                <Phone
                                  type="text"
                                  className="form-control"
                                  placeholder="Número celular"
                                  name="cellphone"
                                  _state={this.state.data._state}
                                  value={this.state.data.cellphone}
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div className="form-group col-md-6 input-group-sm">
                                <label>Teléfono</label>
                                <Phone
                                  type="text"
                                  className="form-control"
                                  placeholder="Teléfono"
                                  name="phone"
                                  _state={this.state.data._state}
                                  value={this.state.data.phone}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="form-row input-group-sm">
                              <div className="form-group col-md-6 input-group-sm">
                                <label>Dirección</label>
                                <Section
                                  type="text"
                                  className="form-control"
                                  placeholder="Dirección"
                                  name="address"
                                  _state={this.state.data._state}
                                  value={this.state.data.address}
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div className="form-group col-md-6 input-group-sm">
                                <label>Ciudad</label>
                                <City
                                  type="text"
                                  className="form-control"
                                  classNameBtn="input-group-text"
                                  placeholder="Ciudad"
                                  _state={this.state.data._state}
                                  value={this.state.data.city}
                                  city_id={this.state.data.city_id}
                                  onChange={this.handleSelCity}
                                />
                              </div>
                            </div>
                            <div className="form-row input-group-sm">
                              <div className="form-group col-md-6 input-group-sm">
                                <label>Tipo de identificación</label>
                                <SelectType
                                  className="custom-select"
                                  name="identification_tp"
                                  project_id={this.state.data._id}
                                  _class="IDENTIFICATION_TP"
                                  _state={this.state.data._state}
                                  value={this.state.data.identification_tp}
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div className="form-group col-md-6 input-group-sm">
                                <label>Identificación</label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  placeholder="Identificación"
                                  name="identification"
                                  _state={this.state.data._state}
                                  value={this.state.data.identification}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-2`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-2-tab`}
                      >
                        <div className="row">
                          <div className="col-12">
                            <div className="tab-list">
                              {this.state.data.modules.map((item, i) => {
                                return (
                                  <React.Fragment key={i}>
                                    <div className="tab-item">
                                      <div className="tab-item-logo">
                                        <div className="avatar">
                                          <div className="chart">{chart(item.caption, 0)}</div>
                                        </div>
                                      </div>
                                      <div className="tab-item-detail">
                                        <div className="name">{item.caption}</div>
                                        <div className="custom-control custom-switch pointer">
                                          <input
                                            className="custom-control-input"
                                            type="checkbox"
                                            id={`${item._id}_module`}
                                            name="chk"
                                            checked={item.chk}
                                            onChange={() => this.handleChangeChkModule({ item, index: i })}
                                          />
                                          <label
                                            className="custom-control-label custom-label pt-0 pl-1"
                                            htmlFor={`${item._id}_module`}
                                          >
                                            Días de servicio
                                            <strong className="ml-1">
                                              {getDifferenceInDays(item, "date_make", true)}
                                            </strong>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-3`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-3-tab`}
                      >
                        <div className="row">
                          <div className="col-12">
                            <div className="tab-list">
                              {/* Options */}
                              {/* Option A */}
                              <div className="tab-item">
                                <div className="tab-item-logo">
                                  <div className="avatar">
                                    <div className="chart">A</div>
                                  </div>
                                </div>
                                <div className="tab-item-detail">
                                  <div className="name">Aviso de pago pendiente</div>
                                  <div className="custom-control custom-switch pointer">
                                    <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id="notice-of-pending-payment"
                                      name="chk"
                                      checked={false}
                                      onChange={() => this.handleNoticeOfPendingPayment({})}
                                    />
                                    <label
                                      className="custom-control-label custom-label pt-0 pl-1"
                                      htmlFor="notice-of-pending-payment"
                                    >
                                      Muestra el aviso de pago pendiente
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {/* Option B */}
                              <div className="tab-item">
                                <div className="tab-item-logo">
                                  <div className="avatar">
                                    <div className="chart">A</div>
                                  </div>
                                </div>
                                <div className="tab-item-detail">
                                  <div className="name">Aviso de suspencion de serivicio</div>
                                  <div className="custom-control custom-switch pointer">
                                    <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id="notice-of-pending-payment"
                                      name="chk"
                                      checked={false}
                                      onChange={() => this.handleNoticeServiceSuspension({})}
                                    />
                                    <label
                                      className="custom-control-label custom-label pt-0 pl-1"
                                      htmlFor="notice-of-pending-payment"
                                    >
                                      Muestra el aviso de suspencion de serivicio
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <ModalFooter
                      single="Proyecto"
                      plural="el proyecto"
                      _state={this.state.data._state}
                      isNew={this.props.isNew}
                      onFinish={this.handleFinish}
                      onRestore={this.handleRestore}
                      onClose={this.handleClose}
                      onOk={this.handleOk}
                    />
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    _view: state.sistem.folder._view || "",
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ModalProject);
