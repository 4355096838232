import React from "react";
import { Link } from "react-router-dom";
import ItemProject from "../components/itemProject";
import { connect } from "react-redux";
import { Actions as Sistem } from "../services/actions/sistem";
import { getSource } from "./utilities";

class MenuProject extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="profile dropdown">
          <div
            className="profileEnter"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div className="profileEnterName">{this.props.project.caption || "Proyecto"}</div>
            <div className="profileEnterChevron">
              <i className="fa fa-chevron-down"></i>
            </div>
          </div>
          <div className="dropdown-menu dropdownPerson" aria-labelledby="dropdownMenuButton">
            <div className="dropdownPersonHeader">
              <div className="dropdownPersonHeaderAvatar">
                <img className="avatar" src={this.props.profile.avatar || "/avatar.svg"} alt="" />
              </div>
              <div className="dropdownPersonHeaderInfo">
                <div className="name">{this.props.profile.caption || "Mi nombre"}</div>
                <div className="user">{this.props.profile.username || "correo@correo.com"}</div>
              </div>
              <div className="dropdownPersonHeaderAlert">
                <Link className="btn btn-success btn-sm" to="/inboxes/profile">
                  <i className="fa fa-pencil-alt"></i>
                </Link>
              </div>
            </div>
            <div className="dropdownList">
              {this.props.projects.map((project, i) => {
                return (
                  <React.Fragment key={i}>
                    <ItemProject project={project} select_id={this.props.project._id || "-1"}></ItemProject>
                  </React.Fragment>
                );
              })}
              <div className={this.props.projects.length === 0 ? "d-none" : ""}>
                <Link className="dropdownItem" to="/inboxes/preferences">
                  <div className="dropdownItemLeftIcon">
                    <i className="fa fa-cog"></i>
                  </div>
                  <div className="dropdownItemContent">Preferencias</div>
                  <div className="dropdownItemRightIcon"></div>
                </Link>
                <div className="dropdownSpace"></div>
              </div>
              <Link className="dropdownItem" target="_blank" to="/terms">
                <div className="dropdownItemLeftIcon">
                  <i className="far fa-question-circle"></i>
                </div>
                <div className="dropdownItemContent">Terminos de uso</div>
                <div className="dropdownItemRightIcon"></div>
              </Link>
            </div>
            <div className="dropdownSpace"></div>
            <div className="dropdownItem dropdownItemFooter" onClick={() => Sistem.signout()}>
              <div className="dropdownItemLeftIcon">
                <i className="fa fa-sign-out-alt"></i>
              </div>
              <div className="dropdownItemContent">Cerrar sesión</div>
              <div className="dropdownItemRightIcon"></div>
            </div>
          </div>
          <div className="profileUser">
            <div className="profileUserOnline">
              <div className={this.props.online ? "onLine" : "onLine off"}></div>
            </div>
            <div className="profileUserName">{this.props.profile.caption || ""}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    online: getSource(state.sistem, ["online"], false),
    profile: getSource(state.sistem, ["profile"], {}),
    projects: getSource(state.sistem, ["profile", "projects"], []),
    project: getSource(state.sistem, ["project"], {}),
  };
}

export default connect(mapStateToProps)(MenuProject);
