import React from "react";
import ReactDOM from "react-dom";
import "../../styles/modal.scss";
import "../../styles/suport.scss";
import {
  showModal,
  hideModal,
  ShowInfo,
  msg,
  getValue,
  getRow,
  genId,
  getData,
  getNumber,
  ShowAlert,
  Subscribe,
  UnSubscribe,
  clone,
  formId,
  setValue,
  formatDate,
  compareObject,
  getSource,
} from "../../components/utilities";
import { Textarea, Input, Type, Time, Phone, City } from "../../components/inputs";
import { Report } from "./listReports";
import { ODT } from "./listOdts";
import { Vale } from "./listVales";
import { AssetClass } from "../assets/listClass";
import { AssetType } from "../assets/listAssetTypes";
import { Area } from "../assets/listAreas";
import { Asset } from "../assets/listAssets";
import { Responsable } from "./listOdtResponsable";
import { TpActivity } from "./listTpActivities";
import { ActivityStock } from "./listActivityStock";
import { ALERT002 } from "../../components/msg";
import ModalAsset from "../assets/modalAsset";
import ModalHeaderSuport from "../../components/modalHeaderSuport";
import ModalFooterSuport from "../../components/modalFooterSuport";
import ModalReport from "./modalReport";
import ModalOdt from "./modalOdt";
import ModalVale from "./modalVale";
import ModalUser from "../modalUser";
import ModalClass from "../assets/modalClass";
import ModalType from "../assets/modalType";
import ModalReference from "../warehouse/modalReference";
import ModalTpActivity from "./modalTpActivity";
import ModalInventory from "./components/operationInventory";
import ModalComponente from "./components/operationComponent";
import ComponentAtribs from "../assets/components/componentAtribs";
import ComponentConnections from "../assets/components/componentConnections";
import ComponentConsumptions from "./components/componentConsumptions";
import ComponentActivities from "./components/componentActivities";
import Td from "../../components/td";
import DateTime from "../../components/dateTime";
import { Api as Keep } from "../../services/keep";
import { connect } from "react-redux";

class ModalOperation extends React.Component {
  constructor(props) {
    super(props);
    let data = this.handleScheme(props.data);
    this.state = {
      form: "__ModalOperation",
      _id: "__ModalOperation",
      title: "Operación",
      name: "operation",
      show: false,
      old: data,
      data: data,
      isNew: props.isNew || false,
      change: false,
      tabs: [],
      printParams: {
        project_id: "-1",
        _class: "SUPORT-KEEP-OPERATION",
        _id: "-1",
        single: true,
      },
    };
  }

  handleScheme = (data) => {
    let now = new Date();
    let project_id = this.props.project_id;
    let _id = genId("-1");
    now = formatDate(now, "yyyy-MM-dd HH:mm:ss");
    return {
      date_make: now,
      date_update: now,
      project_id: project_id,
      _class: "SUPORT-KEEP-OPERATION",
      _state: "0",
      _id: _id,
      date_of: now,
      code: "",
      caption: "Operación",
      description: "",
      observations: "",
      odt_id: getSource(data, ["odt_id"], "-1"),
      report_id: getSource(data, ["report_id"], "-1"),
      vale_id: getSource(data, ["vale_id"], "-1"),
      draft_id: getSource(data, ["draft_id"], "-1"),
      responsable_id: getSource(data, ["responsable_id"], "-1"),
      type_tp: getSource(data, ["type_tp"], "-1"),
      activity_tp: getSource(data, ["activity_tp"], "KEEP_OPERATIONS_TYPE02"),
      class_id: getSource(data, ["class_id"], "-1"),
      type_id: "-1",
      reference_id: "-1",
      asset_id: "-1",
      asset_code: "",
      asset_serie: "",
      new_code: "",
      city_id: getSource(this.props.project, ["city_id"], "-1"),
      area_id: getSource(data, ["area_id"], "-1"),
      rural: getSource(data, ["rural"], false),
      cost: 0,
      total: 0,
      date_int: getSource(data, ["date_int"], now),
      date_end: null,
      _time: 0,
      atribs: {},
      components: [],
      connections: [],
      consumptions: [],
      activities: [],
      _data: {
        activity_base: 0,
        class_name: getSource(data, ["_data", "class_name"], ""),
        project: this.props.project,
        report: getSource(data, ["report"], ""),
        odt: getSource(data, ["odt"], ""),
        vale: getSource(data, ["vale"], ""),
        responsable: getSource(data, ["responsable"], ""),
        responsable_mobile: getSource(data, ["responsable_mobile"], ""),
        responsable_email: getSource(data, ["responsable_email"], ""),
        type: "",
        activity: getSource(data, ["_data", "activity_tp"], "Mantenimiento correctivo"),
        asset_class: getSource(data, ["_data", "asset_class"], ""),
        asset_type: "",
        asset_reference: "",
        city: getSource(this.props.project, ["city"], ""),
        area: "",
        address: "",
        profile_id: "-1",
        scheme: getSource(data, ["_data", "scheme"], []),
      },
      prior_id: "-1",
      next_id: "-1",
      _v: 1,
    };
  };

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      change: true,
    });
  };

  handleChangeData = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        _data: {
          ...this.state.data._data,
          [e.target.name]: e.target.value,
        },
      },
      change: true,
    });
  };

  handleChangeDate = (e) => {
    const value = formatDate(e.target.value, "yyyy-MM-dd HH:mm:ss");
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: value,
      },
      change: true,
    });
  };

  handleChangeReport = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        report_id: getValue(e, "_id", "-1"),
        odt_id: getSource(e, ["first_odt", "_id"], "-1"),
        responsable_id: getSource(e, ["first_odt", "responsable_id"], "-1"),
        _data: {
          ...this.state.data._data,
          report: getValue(e, "code", ""),
          odt: getSource(e, ["first_odt", "code"], ""),
          responsable: getSource(e, ["first_odt", "_data", "responsable"], ""),
          responsable_email: getSource(e, ["first_odt", "_data", "responsable_email"], ""),
          responsable_mobile: getSource(e, ["first_odt", "_data", "responsable_mobile"], ""),
        },
      },
      change: true,
    });
  };

  handleChangeODT = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        odt_id: getValue(e, "_id", "-1"),
        responsable_id: getValue(e, "responsable_id", "-1"),
        _data: {
          ...this.state.data._data,
          odt: getValue(e, "code", ""),
          responsable: getData(e, "responsable", ""),
          responsable_email: getData(e, "responsable_email", ""),
          responsable_mobile: getData(e, "responsable_mobile", ""),
        },
      },
      change: true,
    });
  };

  handleChangeVale = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        vale_id: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          vale: getValue(e, "code", ""),
        },
      },
      change: true,
    });
  };

  handleChangeResponsable = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        responsable_id: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          responsable: getValue(e, "caption", ""),
          responsable_email: getValue(e, "email", ""),
          responsable_mobile: getValue(e, "mobile", ""),
        },
      },
      change: true,
    });
  };

  handleChangeType = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        type_tp: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          type: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeTpActivity = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        activity_tp: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          activity: getValue(e, "caption", ""),
          activity_base: getValue(e, "_activity", 0),
        },
      },
      change: true,
    });
  };

  handleChangeClase = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        class_id: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          asset_class: getValue(e, "caption", ""),
          scheme: getData(e, "scheme_asset", []),
        },
      },
      change: true,
    });
  };

  handleChangeAsset = (e) => {
    let asset_id = this.state.data.asset_id;
    let class_id = this.state.data.class_id;
    let components = getValue(e, "components", []);
    let connections = getValue(e, "connections", []);
    let atribs = getValue(e, "atribs", {});
    components = this.handleChangeAssetComponent(components);
    connections = this.handleChangeAssetConnections(connections);
    if (asset_id === "-1") {
      this.setState({
        data: {
          ...this.state.data,
          asset_id: getValue(e, "_id", "-1"),
          asset_code: getValue(e, "code", ""),
          asset_serie: getValue(e, "serie", ""),
          class_id: getValue(e, "class_id", "-1"),
          type_id: getValue(e, "type_id", "-1"),
          reference_id: getValue(e, "reference_id", "-1"),
          cost: getValue(e, "cost", 0),
          area_id: getValue(e, "area_id", "-1"),
          rural: getValue(e, "rural", false),
          atribs: atribs,
          components: components,
          connections: connections,
          _data: {
            ...this.state.data._data,
            address: getValue(e, "address", ""),
            area: getValue(e, "area", ""),
            asset_class: getValue(e, "class", ""),
            asset_reference: getValue(e, "reference", ""),
            asset_reference_code: getValue(e, "reference_code", ""),
            asset_type: getValue(e, "type", ""),
            scheme: getValue(e, "scheme", []),
          },
        },
        change: true,
      });
    } else if (class_id !== e.class_id) {
      this.setState({
        data: {
          ...this.state.data,
          asset_id: getValue(e, "_id", "-1"),
          asset_code: getValue(e, "code", ""),
          asset_serie: getValue(e, "serie", ""),
          class_id: getValue(e, "class_id", "-1"),
          type_id: getValue(e, "type_id", "-1"),
          reference_id: getValue(e, "reference_id", "-1"),
          cost: getValue(e, "cost", 0),
          area_id: this.state.data.area_id === "-1" ? getValue(e, "area_id", "-1") : this.state.data.area_id,
          atribs: atribs,
          components: components,
          connections: connections,
          _data: {
            ...this.state.data._data,
            address: this.state.data._data.address === "" ? getValue(e, "address", "") : this.state.data._data.address,
            area: this.state.data._data.area === "" ? getValue(e, "area", "") : this.state.data._data.area,
            asset_class: getValue(e, "class", ""),
            asset_reference: getValue(e, "reference", ""),
            asset_reference_code: getValue(e, "reference_code", ""),
            asset_type: getValue(e, "type", ""),
            scheme: getValue(e, "scheme", []),
          },
        },
        change: true,
      });
    } else {
      this.setState({
        data: {
          ...this.state.data,
          asset_id: getValue(e, "_id", "-1"),
          asset_code: getValue(e, "code", ""),
          asset_serie: getValue(e, "serie", ""),
          class_id: this.state.data.class_id === "-1" ? getValue(e, "class_id", "-1") : this.state.data.class_id,
          type_id: this.state.data.type_id === "-1" ? getValue(e, "type_id", "-1") : this.state.data.type_id,
          reference_id:
            this.state.data.reference_id === "-1" ? getValue(e, "reference_id", "-1") : this.state.data.reference_id,
          cost: getValue(e, "cost", 0),
          area_id: this.state.data.area_id === "-1" ? getValue(e, "area_id", "-1") : this.state.data.area_id,
          atribs: atribs,
          components: components,
          connections: connections,
          _data: {
            ...this.state.data._data,
            address: this.state.data._data.address === "" ? getValue(e, "address", "") : this.state.data._data.address,
            area: this.state.data._data.area === "" ? getValue(e, "area", "") : this.state.data._data.area,
            asset_class:
              this.state.data.class_id === "-1" ? getValue(e, "class", "") : this.state.data._data.asset_class,
            asset_reference:
              this.state.data.reference_id === "-1"
                ? getValue(e, "reference", "")
                : this.state.data._data.asset_reference,
            asset_reference_code:
              this.state.data.reference_id === "-1"
                ? getValue(e, "reference_code", "")
                : this.state.data._data.asset_reference_code,
            asset_type: this.state.data.type_id === "-1" ? getValue(e, "type", "") : this.state.data._data.asset_type,
            scheme: getValue(e, "scheme", []),
          },
        },
        change: true,
      });
    }
  };

  handleChangeAssetComponent = (components) => {
    components = components || [];
    let data = this.state.data;
    let old_components = data.components;
    let new_components = [];
    components.forEach((item) => {
      let _old = old_components.find((element) => element["class_id"] === item["class_id"]);
      let element = {
        project_id: getValue(data, "project_id", "-1"),
        operation_id: getValue(data, "_id", "-1"),
        _state: getValue(data, "_state", "0"),
        _id: getValue(_old, "_id", genId("-1")),
        vale_id: getValue(data, "vale_id", "-1"),
        activity_tp: getValue(_old, "activity_tp", "-1"),
        class_id: getValue(item, "class_id", "-1"),
        old_type_id: getValue(item, "type_id", "-1"),
        old_reference_id: getValue(item, "reference_id", "-1"),
        old_asset_id: getValue(item, "_id", "-1"),
        old_code: getValue(item, "code", ""),
        old_serie: getValue(item, "serie", ""),
        old_cost: getValue(item, "cost", 0),
        old_quantity: getValue(item, "quantity", 0),
        type_id: getValue(item, "type_id", "-1"),
        reference_id: getValue(_old, "reference_id", "-1"),
        asset_id: getValue(_old, "asset_id", "-1"),
        asset_code: getValue(_old, "asset_code", ""),
        asset_serie: getValue(_old, "asset_serie", ""),
        quantity: getValue(_old, "quantity", 0),
        unitary: getValue(_old, "unitary", 0),
        maximum: getValue(_old, "maximum", 0),
        _data: {
          old_asset_type: getValue(item, "type", ""),
          old_reference_code: getValue(item, "reference_code", ""),
          old_reference: getValue(item, "reference", ""),
          activity: getData(_old, "activity", ""),
          activity_base: getData(_old, "activity_base", 0),
          asset_class: getValue(item, "class", ""),
          asset_type: getData(item, "asset_type", ""),
          asset_reference_code: getData(_old, "asset_reference_code", ""),
          asset_reference: getData(_old, "asset_reference", ""),
          unity: getValue(item, "unity", ""),
        },
      };
      new_components.push(element);
    });
    return new_components;
  };

  handleChangeAssetConnections = (connections) => {
    connections = connections || [];
    let data = this.state.data;
    let old_connections = data.connections;
    let new_connections = [];
    connections.forEach((item) => {
      let _old = old_connections.find((element) => element["class_id"] === item["class_id"]);
      let element = {
        _state: getValue(data, "_state", "0"),
        _id: getValue(_old, "_id", genId("-1")),
        operation_id: getValue(data, "_id", "-1"),
        class_id: getValue(item, "class_id", "-1"),
        connection_id: getValue(item, "connection_id", "-1"),
        code: getValue(item, "code", ""),
        class: getValue(item, "class", ""),
        quantity: getValue(item, "quantity", 1),
        required: getValue(item, "required", false),
        _data: {
          code: getValue(item, "code", ""),
          class: getValue(item, "class", ""),
          quantity: getValue(item, "quantity", 1),
          required: getValue(item, "required", false),
        },
      };
      new_connections.push(element);
    });
    return new_connections;
  };

  handleChangeAssetType = (e) => {
    let components = getValue(e, "components", []);
    let connections = getValue(e, "connections", []);
    components = this.handleChangeAssetTypeComponents(components);
    connections = this.handleChangeAssetTypeConnections(connections);
    this.setState({
      data: {
        ...this.state.data,
        type_id: getValue(e, "_id", "-1"),
        components: components,
        connections: connections,
        _data: {
          ...this.state.data._data,
          asset_type: getValue(e, "caption", ""),
          profile_id: getData(e, "profile_id", "-1"),
        },
      },
      change: true,
    });
  };

  handleChangeAssetTypeComponents = (components) => {
    components = components || [];
    let data = this.state.data;
    let old_components = data.components;
    let new_components = [];
    components.forEach((item) => {
      let _old = old_components.find((element) => element["class_id"] === item["class_id"]);
      let element = {
        project_id: getValue(data, "project_id", "-1"),
        operation_id: getValue(data, "_id", "-1"),
        _state: getValue(data, "_state", "0"),
        _id: getValue(_old, "_id", genId("-1")),
        vale_id: getValue(data, "vale_id", "-1"),
        activity_tp: getValue(_old, "activity_tp", "-1"),
        class_id: getValue(item, "class_id", "-1"),
        old_type_id: getValue(_old, "type_id", "-1"),
        old_reference_id: getValue(_old, "reference_id", "-1"),
        old_asset_id: getValue(_old, "_id", "-1"),
        old_code: getValue(_old, "code", ""),
        old_serie: getValue(_old, "serie", ""),
        old_cost: getValue(_old, "cost", 0),
        old_quantity: getValue(_old, "quantity", 0),
        type_id: getValue(item, "type_id", "-1"),
        reference_id: getValue(_old, "reference_id", "-1"),
        asset_id: getValue(_old, "asset_id", "-1"),
        asset_code: getValue(_old, "asset_code", ""),
        asset_serie: getValue(_old, "asset_serie", ""),
        quantity: getValue(_old, "quantity", 0),
        unitary: getValue(_old, "unitary", 0),
        maximum: getValue(item, "quantity", 0),
        _data: {
          old_asset_type: getValue(_old, "type", ""),
          old_reference_code: getValue(_old, "reference_code", ""),
          old_reference: getValue(_old, "reference", ""),
          activity: getData(_old, "activity", ""),
          activity_base: getData(_old, "activity_base", 0),
          asset_class: getValue(item, "class", ""),
          asset_type: getData(_old, "asset_type", ""),
          asset_reference_code: getData(_old, "asset_reference_code", ""),
          asset_reference: getData(_old, "asset_reference", ""),
          unity: getValue(item, "unity", ""),
        },
      };
      new_components.push(element);
    });
    return new_components;
  };

  handleChangeAssetTypeConnections = (connections) => {
    connections = connections || [];
    let data = this.state.data;
    let old_connections = data.connections;
    let new_connections = [];
    connections.forEach((item) => {
      let _old = old_connections.find((element) => element["class_id"] === item["class_id"]);
      let element = {
        _state: getValue(data, "_state", "0"),
        _id: getValue(_old, "_id", genId("-1")),
        operation_id: getValue(data, "_id", "-1"),
        class_id: getValue(item, "class_id", "-1"),
        connection_id: getValue(_old, "connection_id", "-1"),
        code: getValue(_old, "code", ""),
        class: getValue(item, "class", ""),
        quantity: getValue(item, "quantity", 1),
        required: getValue(item, "required", false),
        _data: {
          code: getValue(_old, "code", ""),
          class: getValue(item, "class", ""),
          quantity: getValue(item, "quantity", 1),
          required: getValue(item, "required", false),
        },
      };
      new_connections.push(element);
    });
    return new_connections;
  };

  handleChangeReference = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        reference_id: getValue(e, "reference_id", "-1"),
        _data: {
          ...this.state.data._data,
          asset_reference_code: getValue(e, "code", ""),
          asset_reference: getValue(e, "caption", ""),
          unity: getValue(e, "unity", ""),
        },
      },
      change: true,
    });
  };

  handleChangeCity = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        city_id: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          city: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeArea = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        area_id: getValue(e, "_id", "-1"),
        _data: {
          ...this.state.data._data,
          area: getValue(e, "caption", ""),
        },
      },
      change: true,
    });
  };

  handleChangeRural = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        rural: e.target.value === "true" ? true : false,
      },
      change: true,
    });
  };

  handleChangeComponent = (e) => {
    let components = this.state.data.components;
    components[e.index] = e.item;
    this.setState({
      data: {
        ...this.state.data,
        components: components,
      },
      change: true,
    });
  };

  handleChangeConsumption = (e) => {
    let data = this.state.data;
    let consumptions = data.consumptions;
    let index = consumptions.findIndex((element) => element._id === e._id);
    if (index === -1) {
      consumptions.push(e);
    } else {
      consumptions[index].reference_id = e.reference_id;
      consumptions[index].quantity = e.quantity;
      consumptions[index].unitary = e.unitary;
    }
    this.setState({
      data: {
        ...this.state.data,
        consumptions: consumptions,
      },
      change: true,
    });
  };

  handleChangeConnections = (e) => {
    let data = this.state.data;
    let connections = data.connections;
    let index = connections.findIndex((element) => element.class_id === e.class_id);
    if (index === -1) {
      connections.push({
        _id: genId("-1"),
        asset_id: e.asset_id,
        class: e.class,
        class_id: e.class_id,
        code: e.code,
        connection_id: e.connection_id,
        reference_id: e.reference_id,
        required: e.required,
        _data: e,
      });
    } else {
      connections[index] = {
        _id: connections[index]._id,
        asset_id: e.asset_id,
        class: e.class,
        class_id: e.class_id,
        code: e.code,
        connection_id: e.connection_id,
        reference_id: e.reference_id,
        required: e.required,
        _data: e,
      };
    }
    this.setState({
      data: {
        ...this.state.data,
        connections: connections,
      },
      change: true,
    });
  };

  handleChangeActivity = (e) => {
    let data = this.state.data;
    let activities = data.activities;
    let index = activities.findIndex((element) => element._id === e._id);
    if (index === -1) {
      activities.push(e);
    } else {
      activities[index].activity_tp = e.activity_tp;
      activities[index].unity = e.unity;
      activities[index].quantity = e.quantity;
      activities[index].unitary = e.unitary;
      activities[index].cost = e.cost;
      activities[index]._data = e._data;
    }
    this.setState({
      data: {
        ...this.state.data,
        activities: activities,
      },
      change: true,
    });
  };

  handleChangeAtrib = (e) => {
    if (e.action === "set") {
      let atrib = e.data;
      let data = this.state.data;
      let atribs = data.atribs;
      atribs[atrib.name] = atrib.value;
      this.setState({
        data: {
          ...this.state.data,
          atribs: atribs,
        },
        change: true,
      });
    }
  };

  handleExecute = (e) => {
    const _state = this.props._state || "0";
    if (typeof this.props.onSetData === "function" && _state === "0") {
      this.props.onSetData(e);
    }
  };

  handleHide = () => {
    hideModal(this.state._id);
    this.setState({ show: false });
  };

  handleShow = () => {
    if (this.props.isFind) {
      const _id = getValue(this.props, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    } else if (this.props.isNew) {
      const form_id = `${this.state.form}${Date.now()}`;
      this.setState({ _id: form_id, show: true, change: false });
    } else {
      const _id = getValue(this.props.data, "_id", "-1");
      if (_id !== "-1") {
        const form_id = formId(`${this.state.form}${_id}`);
        this.setState({ _id: form_id, show: true, change: false });
      }
    }
  };

  handleClose = (e) => {
    if (this.state.change && e) {
      this.handleOk();
    } else {
      this.handleHide();
    }
  };

  handleOk = () => {
    if (this.state.change) {
      const id = this.state.data._id;
      const params = this.state.data;
      Keep.setOperation(id, params).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false });
          this.handleExecute(data);
        }
      });
    } else {
      this.handleHide();
    }
  };

  handleAutoSave = () => {
    if (this.state.change) {
      const id = this.state.data._id;
      const params = this.state.data;
      Keep.setOperation(id, params, true).then((result) => {
        if (result.msg === "") {
          const data = result.data;
          this.handleSetData({ data, isNew: false, change: false });
          this.handleExecute(data);
        }
      });
    }
  };

  handleState = (e) => {
    if (this.state.change) {
      ShowAlert(ALERT002);
    } else {
      const _state = getValue(this.state.data, "_state");
      if (_state !== e) {
        const id = getValue(this.state.data, "_id");
        Keep.stateOperation(id, e).then((result) => {
          if (result.msg === "") {
            const data = result.data;
            this.handleSetData({ data, change: false });
            this.handleExecute(data);
          }
        });
      }
    }
  };

  handlePrior = (e) => {
    let _id = getValue(this.state.data, "prior_id", "-1");
    if (_id === "-1") {
      ShowInfo(msg("MSG-FIRST-ROW"));
    } else {
      this.handleFind(_id);
    }
  };

  handleNext = (e) => {
    let _id = getValue(this.state.data, "next_id", "-1");
    if (_id === "-1") {
      ShowInfo(msg("MSG-END-ROW"));
    } else {
      this.handleFind(_id);
    }
  };

  handleSetData = (e) => {
    this.setState({
      old: e.data,
      data: e.data,
      isNew: e.isNew,
      change: e.change,
      tabs: [],
      printParams: {
        project_id: e.data.project_id,
        _class: e.data._class,
        single: true,
      },
    });
    showModal(this.state._id);
  };

  handleUpdate = (e) => {
    if (compareObject(this.state.data, e)) {
      this.handleSetData({
        data: e,
        isNew: false,
        change: false,
      });
    }
  };

  handleData = () => {
    const _id = getSource(this.props.data, ["_id"], "-1");
    if (_id === "-1") {
      this.handleSetData({ data: this.props.data, isNew: false, change: false });
    } else {
      this.handleFind(_id);
    }
  };

  handleNew = () => {
    const data = this.handleScheme(this.props.master);
    this.handleSetData({ data, isNew: true, change: true, focusInt: true });
  };

  handleClone = () => {
    let data = clone(this.props.data);
    let now = new Date();
    let _id = genId("-1");
    let components = getSource(data, ["components"], []);
    let connections = getSource(data, ["connections"], []);
    for (let index = 0; index < components.length; index++) {
      let element = components[index];
      let _data = getSource(element, ["_data"], {});
      const unity = getSource(element, ["_data", "unity"], "");
      const element_id = genId("-1");
      element = setValue(element, "_id", element_id);
      element = setValue(element, "operation_id", _id);
      element = setValue(element, "activity_tp", "-1");
      element = setValue(element, "asset_code", "");
      element = setValue(element, "asset_id", "-1");
      element = setValue(element, "asset_serie", "");
      element = setValue(element, "costo", 0);
      element = setValue(element, "main_id", "-1");
      element = setValue(element, "odt_id", "-1");
      element = setValue(element, "old_asset_id", "-1");
      element = setValue(element, "old_code", "");
      element = setValue(element, "old_cost", 0);
      element = setValue(element, "old_equipment", null);
      element = setValue(element, "old_quantity", 0);
      element = setValue(element, "old_reference_id", "-1");
      element = setValue(element, "old_serie", "");
      element = setValue(element, "old_type_id", "-1");
      element = setValue(element, "old_unitary", 0);
      element = setValue(element, "quantity", 0);
      element = setValue(element, "reference_id", "-1");
      element = setValue(element, "type_id", "-1");
      _data = setValue(_data, "activity", "");
      _data = setValue(_data, "activity_base", "-1");
      _data = setValue(_data, "asset_caption", `0.00 ${unity}`);
      _data = setValue(_data, "asset_reference", "");
      _data = setValue(_data, "asset_reference_code", "");
      _data = setValue(_data, "asset_type", "");
      _data = setValue(_data, "old_asset_type", "");
      _data = setValue(_data, "old_reference", "");
      _data = setValue(_data, "old_reference_code", "");
      element = setValue(element, "_data", _data);
      components[index] = element;
    }
    for (let index = 0; index < connections.length; index++) {
      let element = connections[index];
      const element_id = genId("-1");
      element = setValue(element, "_id", element_id);
      element = setValue(element, "operation_id", _id);
      element = setValue(element, "asset_id", "-1");
      element = setValue(element, "code", "");
      element = setValue(element, "connection_id", "-1");
      connections[index] = element;
    }
    data = setValue(data, "_id", _id);
    data = setValue(data, "_state", "0");
    data = setValue(data, "code", "");
    data = setValue(data, "caption", "Operación");
    data = setValue(data, "date_make", now);
    data = setValue(data, "date_update", now);
    data = setValue(data, "date_of", now);
    data = setValue(data, "_time", 0);
    data = setValue(data, "cost", 0);
    data = setValue(data, "total", 0);
    data = setValue(data, "asset_id", "-1");
    data = setValue(data, "asset_code", "");
    data = setValue(data, "asset_serie", "");
    data = setValue(data, "new_code", "");
    data = setValue(data, "atribs", {});
    data = setValue(data, "components", components);
    data = setValue(data, "connections", connections);
    data = setValue(data, "consumptions", []);
    data = setValue(data, "activities", []);
    this.handleSetData({ data, isNew: true, change: true, focusInt: true });
  };

  handleFind = (id) => {
    if (this.state.change) {
      ShowInfo(msg("MSG-CHANGE"));
    } else if (id !== "-1") {
      Keep.operation(id, this.props.project_id).then((result) => {
        if (result.msg === "") {
          let data = result.data;
          this.handleSetData({ data, isNew: false, change: false });
        }
      });
    }
  };

  handlePrintComponents = (e) => {
    let items = this.state.data.components;
    let result = [];

    for (let i = 0; i < e; i++) {
      let item = getRow(items, i);
      let old_asset_caption = getValue(item, "old_code", "");
      let old_quantity = getValue(item, "old_quantity", "");
      if (old_asset_caption === "" || old_asset_caption === "00000000") {
        let unity = getData(item, "unity", "");
        old_asset_caption = `${old_quantity} ${unity}`;
      }
      let main_state = this.state.data._state;
      let item_state = getValue(item, "_state", main_state);
      result.push(
        <Td
          key={i}
          className={`suport-item _state_${main_state}_${item_state}`}
          cols="24px 121px 100px 120px auto 75px 100px"
        >
          <div className="suport-td t-c">{i + 1}</div>
          <div className="suport-td">
            <ModalClass
              className="link"
              project_id={this.state.data.project_id}
              _state={this.state.data._state}
              _id={item.class_id}
              isFind={true}
            >
              {getData(item, "asset_class", "")}
            </ModalClass>
          </div>
          <div className="suport-td t-c bt">
            {old_asset_caption}
            <ModalInventory
              className="input-group-text sp"
              project_id={this.state.data.project_id}
              operation_id={this.state.data._id}
              class_id={item.class_id}
              main_id={this.state.data.asset_id}
              _state={this.state.data._state}
              index={i}
              data={item}
              onSetData={this.handleChangeComponent}
            >
              <i className="fa fa-ellipsis-h"></i>
            </ModalInventory>
          </div>
          <div className="suport-td t-c">{getData(item, "activity", "")}</div>
          <div className="suport-td">{getData(item, "asset_reference", "")}</div>
          <div className="suport-td t-c">{getValue(item, "asset_code", "")}</div>
          <div className="suport-td t-r bt">
            {getNumber(item, "quantity", "")}
            <ModalComponente
              className="input-group-text sp"
              project_id={this.state.data.project_id}
              opereration_id={this.state.data._id}
              odt_id={this.state.data.odt_id}
              vale_id={this.state.data.vale_id}
              main_id={this.state.data.asset_id}
              _state={this.state.data._state}
              index={i}
              data={item}
              onSetData={this.handleChangeComponent}
            >
              <i className="fa fa-ellipsis-h"></i>
            </ModalComponente>
          </div>
        </Td>
      );
    }
    return result;
  };

  handleTab = (e) => {
    let tabs = this.state.tabs;
    if (!tabs[e]) {
      tabs[e] = true;
      this.setState({ tabs: tabs });
    }
  };

  componentDidMount() {
    Subscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show) {
      if (prevState.data._id !== this.state.data._id) {
        UnSubscribe(`${prevState.data._class}/${prevState.data._id}`, (event) => this.eventSetData(event));
        Subscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
      }
      if (prevState.show !== this.state.show) {
        if (this.props.isNew) {
          this.handleNew();
        } else if (this.props.isFind) {
          this.handleFind(this.props._id);
        } else {
          this.handleData();
        }
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`${this.state.data._class}/${this.state.data._id}`, (event) => this.eventSetData(event));
    hideModal(this.state._id);
  }

  render() {
    if (!this.state.show) {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            className={this.props.className}
            onClick={this.handleShow}
            disabled={!(this.props._state === "0" || !this.props._state)}
          >
            {this.props.children}
          </div>
          {ReactDOM.createPortal(
            <div className="modal fade" id={this.state._id} role="dialog">
              <div className="modal-dialog __modalObject sm" role="document">
                <div className="modal-content">
                  <ModalHeaderSuport
                    title={this.state.data.caption}
                    _state={this.state.data._state}
                    _id={this.state.data._id}
                    isNew={this.state.isNew}
                    alerts={this.state.data.alerts}
                    change={this.state.change}
                    handleClose={this.handleClose}
                    handlePrior={this.handlePrior}
                    handleNext={this.handleNext}
                  />
                  <div className="modal-body-tabs">
                    <nav>
                      <div className="nav nav-tabs" role="tablist">
                        <a
                          className="nav-item nav-link active"
                          id={`${this.state._id}-nav-1-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-1`}
                          role="tab"
                          aria-selected="true"
                        >
                          General
                        </a>
                        <a
                          className="nav-item nav-link"
                          id={`${this.state._id}-nav-2-tab`}
                          data-toggle="tab"
                          href={`#${this.state._id}-nav-2`}
                          role="tab"
                          aria-selected="false"
                          onClick={() => this.handleTab(2)}
                        >
                          Detalles
                        </a>
                      </div>
                    </nav>
                    <div className="tab-content modal">
                      <div
                        className="tab-pane fade show active"
                        id={`${this.state._id}-nav-1`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-1-tab`}
                      >
                        <div className="suport">
                          <div className="suport-group">
                            <Td className="suport-row" cols="90px 160px 90px auto">
                              <div className="suport-td caption">
                                <ModalReport
                                  className="link"
                                  isFind={true}
                                  _id={this.state.data.report_id}
                                  onChange={this.handleChangeReport}
                                >
                                  Reporte
                                </ModalReport>
                              </div>
                              <div className="suport-td">
                                <Report
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  placeholder="Reporte"
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  select={{
                                    _id: this.state.data.report_id,
                                    code: this.state.data._data.report,
                                  }}
                                  onChange={this.handleChangeReport}
                                />
                              </div>
                              <div className="suport-td caption">
                                <ModalUser
                                  className="link"
                                  isFind={true}
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  _id={this.state.data.responsable_id}
                                  onSetData={this.handleChangeResponsable}
                                >
                                  Responsable
                                </ModalUser>
                              </div>
                              <div className="suport-td">
                                <Responsable
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  project_id={this.state.data.project_id}
                                  odt_id={this.state.data.odt_id}
                                  _state={this.state.data._state}
                                  select={{
                                    _id: this.state.data.responsable_id,
                                    caption: this.state.data._data.responsable,
                                  }}
                                  onChange={this.handleChangeResponsable}
                                />
                              </div>
                            </Td>
                            <Td className="suport-row" cols="90px 160px 90px auto 90px 160px">
                              <div className="suport-td caption">
                                <ModalOdt
                                  className="link"
                                  isFind={true}
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  _id={this.state.data.odt_id}
                                  onSetData={this.handleChangeODT}
                                >
                                  ODT
                                </ModalOdt>
                              </div>
                              <div className="suport-td">
                                <ODT
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  placeholder="ODT"
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  select={{
                                    _id: this.state.data.odt_id,
                                    code: this.state.data._data.odt,
                                  }}
                                  onChange={this.handleChangeODT}
                                />
                              </div>
                              <div className="suport-td caption">Celular</div>
                              <div className="suport-td">
                                <Phone
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="responsable_mobile"
                                  value={this.state.data._data.responsable_mobile}
                                  onChange={this.handleChangeData}
                                />
                              </div>
                              <div className="suport-td caption">Tipo</div>
                              <div className="suport-td">
                                <Type
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  placeholder="Tipo"
                                  project_id={this.state.data.project_id}
                                  _class="KEEP_OPERATIONS_TIPO"
                                  _state={this.state.data._state}
                                  title="Tipo de ODT"
                                  select={{
                                    _id: this.state.data.type_tp,
                                    caption: this.state.data._data.type,
                                  }}
                                  onChange={this.handleChangeType}
                                />
                              </div>
                            </Td>
                            <Td className="suport-row" cols="90px 160px 90px auto 90px 160px">
                              <div className="suport-td caption">
                                <ModalVale
                                  className="link"
                                  isFind={true}
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  _id={this.state.data.vale_id}
                                  onSetData={this.handleChangeVale}
                                >
                                  Vale
                                </ModalVale>
                              </div>
                              <div className="suport-td">
                                <Vale
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  placeholder="RESPONSABLE"
                                  project_id={this.state.data.project_id}
                                  filter=""
                                  _state={this.state.data._state}
                                  select={{
                                    _id: this.state.data.vale_id,
                                    code: this.state.data._data.vale,
                                  }}
                                  onChange={this.handleChangeVale}
                                />
                              </div>
                              <div className="suport-td caption">Correo</div>
                              <div className="suport-td">
                                <Input
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="responsable_email"
                                  value={this.state.data._data.responsable_email}
                                  onChange={this.handleChangeData}
                                />
                              </div>
                              <div className="suport-td caption">
                                <ModalTpActivity
                                  className="link"
                                  _class="KEEP_OPERATIONS_TYPE"
                                  isFind={true}
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  _id={this.state.data.activity_tp}
                                  onSetData={this.handleChangeTpActivity}
                                >
                                  Actividad
                                </ModalTpActivity>
                              </div>
                              <div className="suport-td">
                                <TpActivity
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  placeholder="Actividad"
                                  project_id={this.state.data.project_id}
                                  _class="KEEP_OPERATIONS_TYPE"
                                  _state={this.state.data._state}
                                  select={{
                                    _id: this.state.data.activity_tp,
                                    caption: this.state.data._data.activity,
                                  }}
                                  onChange={this.handleChangeTpActivity}
                                />
                              </div>
                            </Td>
                          </div>
                          <div className="suport-group">
                            <Td className="suport-row" cols="90px auto 90px 160px">
                              <div className="suport-td caption">
                                <ModalClass
                                  className="link"
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  _id={this.state.data.class_id}
                                  isFind={true}
                                  onHide={this.handleChangeClase}
                                >
                                  Clase
                                </ModalClass>
                              </div>
                              <div className="suport-td">
                                <AssetClass
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  equipment={true}
                                  select={{
                                    _id: this.state.data.class_id,
                                    caption: this.state.data._data.asset_class,
                                  }}
                                  onChange={this.handleChangeClase}
                                />
                              </div>
                              <div className="suport-td caption">
                                <ModalAsset
                                  className="link"
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  equipment={true}
                                  isFind={true}
                                  _id={this.state.data.asset_id}
                                  onSetData={this.handleChangeAsset}
                                >
                                  Código
                                </ModalAsset>
                              </div>
                              <div className="suport-td">
                                <Asset
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  caption="code"
                                  select={{
                                    class_id: this.state.data.class_id,
                                    class: this.state.data._data.asset_class,
                                    type_id: this.state.data.type_id,
                                    type: this.state.data._data.asset_type,
                                    reference_id: this.state.data.reference_id,
                                    reference: this.state.data.asset_reference,
                                    _id: this.state.data.asset_id,
                                    code: this.state.data.asset_code,
                                    unity: "und",
                                  }}
                                  create={false}
                                  onChange={this.handleChangeAsset}
                                />
                              </div>
                            </Td>
                            <Td className="suport-row" cols="90px auto 90px 160px">
                              <div className="suport-td caption">
                                <ModalType
                                  className="link"
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  _id={this.state.data.type_id}
                                  isFind={true}
                                  onSetData={this.handleChangeAssetType}
                                >
                                  Tipo
                                </ModalType>
                              </div>
                              <div className="suport-td">
                                <AssetType
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  project_id={this.state.data.project_id}
                                  class_id={this.state.data.class_id}
                                  _state={this.state.data._state}
                                  select={{
                                    _id: this.state.data.type_id,
                                    caption: this.state.data._data.asset_type,
                                  }}
                                  onChange={this.handleChangeAssetType}
                                />
                              </div>
                              <div className="suport-td caption">Serie</div>
                              <div className="suport-td">
                                <Input
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="asset_serie"
                                  value={this.state.data.asset_serie}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </Td>
                            <Td className="suport-row" cols="90px auto 90px 160px">
                              <div className="suport-td caption">
                                <ModalReference
                                  className="link"
                                  isFind={true}
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  _id={this.state.data.reference_id}
                                  onSetData={this.handleChangeReference}
                                >
                                  Referencia
                                </ModalReference>
                              </div>
                              <div className="suport-td">
                                <ActivityStock
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  placeholder="Referencia"
                                  project_id={this.state.data.project_id}
                                  odt_id={this.state.data.odt_id}
                                  vale_id={this.state.data.vale_id}
                                  class_id={this.state.data.class_id}
                                  class_name={this.state.data._data.asset_class || ""}
                                  activity_base={this.state.data._data.activity_base}
                                  _state={this.state.data._state}
                                  select={{
                                    _id: this.state.data.reference_id,
                                    caption: this.state.data._data.asset_reference,
                                  }}
                                  onChange={this.handleChangeReference}
                                />
                              </div>
                              <div className="suport-td caption">Nuevo código</div>
                              <div className="suport-td">
                                <Input
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="new_code"
                                  value={this.state.data.new_code}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </Td>
                          </div>
                          <div className="suport-group">
                            <Td className="suport-row" cols="90px 160px 90px auto">
                              <div className="suport-td caption">Ciudad</div>
                              <div className="suport-td">
                                <City
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  _state={this.state.data._state}
                                  city_id={this.state.data.city_id}
                                  value={this.state.data._data.city}
                                  onChange={this.handleChangeCity}
                                />
                              </div>
                              <div className="suport-td caption">Área</div>
                              <div className="suport-td">
                                <Area
                                  className="form-control sp ctr-sm t-m"
                                  classNameBtn="input-group-text sp"
                                  project_id={this.state.data.project_id}
                                  _state={this.state.data._state}
                                  select={{
                                    _id: this.state.data.area_id,
                                    caption: this.state.data._data.area,
                                  }}
                                  onChange={this.handleChangeArea}
                                />
                              </div>
                            </Td>
                            <Td className="suport-row" cols="90px auto 250px">
                              <div className="suport-td caption">Sitio</div>
                              <div className="suport-td">
                                <Input
                                  className="form-control sp ctr-sm"
                                  _state={this.state.data._state}
                                  name="address"
                                  value={this.state.data._data.address}
                                  onChange={this.handleChangeData}
                                />
                              </div>
                              <div className="suport-td caption t-c">
                                <div className="custom-control custom-radio custom-control-inline">
                                  <input
                                    type="radio"
                                    className="custom-control-input"
                                    id="operation_urbano"
                                    name="rural"
                                    value={false}
                                    checked={!this.state.data.rural}
                                    onChange={this.handleChangeRural}
                                    disabled={this.state.data._state !== "0"}
                                  />
                                  <label className="custom-control-label" htmlFor="operation_urbano">
                                    Urbano
                                  </label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                  <input
                                    type="radio"
                                    className="custom-control-input"
                                    id="operation_rural"
                                    name="rural"
                                    value={true}
                                    checked={this.state.data.rural}
                                    onChange={this.handleChangeRural}
                                    disabled={this.state.data._state !== "0"}
                                  />
                                  <label className="custom-control-label" htmlFor="operation_rural">
                                    Rural
                                  </label>
                                </div>
                              </div>
                            </Td>
                          </div>
                          <div className="suport-group">
                            <Td className="suport-row" cols="90px auto 90px 160px 90px 160px">
                              <div className="suport-td caption">Fecha Inicio</div>
                              <div className="suport-td">
                                <DateTime
                                  className="form-control sp form-control-sm t-c"
                                  name="date_int"
                                  value={this.state.data.date_int}
                                  _state={this.state.data._state}
                                  showTimeSelect={true}
                                  onChange={this.handleChangeDate}
                                  placeholder="Fecha de inicio"
                                  dateFormat="MMM d, yyyy hh:mm aa"
                                />
                              </div>
                              <div className="suport-td caption">Terminación</div>
                              <div className="suport-td">
                                <DateTime
                                  className="form-control sp form-control-sm t-c"
                                  name="date_end"
                                  value={this.state.data.date_end}
                                  _state={this.state.data._state}
                                  showTimeSelect={true}
                                  onChange={this.handleChangeDate}
                                  placeholder="Fecha de terminación"
                                  dateFormat="MMM d, yyyy hh:mm aa"
                                />
                              </div>
                              <div className="suport-td caption">Tiempo</div>
                              <div className="suport-td">
                                <Time data={this.state.data} />
                              </div>
                            </Td>
                          </div>
                          <div className="suport-group">
                            <Td className="suport-head" cols="145px 100px 120px auto 75px 100px">
                              <div className="suport-td">Componente</div>
                              <div className="suport-td">Inventario</div>
                              <div className="suport-td">Actividad</div>
                              <div className="suport-td">Referencia</div>
                              <div className="suport-td">Código</div>
                              <div className="suport-td">Cantidad</div>
                            </Td>
                            {this.handlePrintComponents(20)}
                          </div>
                          <div className="suport-group">
                            <Td className="suport-row" cols="auto">
                              <div className="suport-td d">
                                <Textarea
                                  className="form-control sp notresize"
                                  _state={this.state.data._state}
                                  rows={4}
                                  name="observations"
                                  value={this.state.data.observations}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </Td>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={`${this.state._id}-nav-2`}
                        role="tabpanel"
                        aria-labelledby={`${this.state._id}-nav-2-tab`}
                      >
                        <div className="row">
                          <div className="col-7">
                            <h5>Consumos de material</h5>
                            <ComponentConsumptions
                              project_id={this.state.data.project_id}
                              _state={this.state.data._state}
                              operation_id={this.state.data._id}
                              odt_id={this.state.data.odt_id}
                              vale_id={this.state.data.vale_id}
                              consumptions={this.state.data.consumptions}
                              onSetData={this.handleChangeConsumption}
                              height="466px"
                            />
                          </div>
                          <div className="col-5">
                            <h5>Conexiones</h5>
                            <ComponentConnections
                              project_id={this.state.data.project_id}
                              profile_id={this.state.data.profile_id}
                              _state={this.state.data._state}
                              isNew={this.state.isNew}
                              data={this.state.data}
                              onChange={this.handleChangeConnections}
                              height="124px"
                            />
                            <h5>Atributos</h5>
                            <ComponentAtribs
                              project_id={this.state.data.project_id}
                              class_id={this.state.data.class_id}
                              _state={this.state.data._state}
                              scheme={this.state.data._data.scheme}
                              atribs={this.state.data.atribs}
                              onChangeClase={this.handleChangeClase}
                              onSetData={this.handleChangeAtrib}
                              height="220px"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <h5>Otras actividades</h5>
                            <ComponentActivities
                              project_id={this.state.data.project_id}
                              operation_id={this.state.data._id}
                              odt_id={this.state.data.odt_id}
                              _state={this.state.data._state}
                              activities={this.state.data.activities}
                              onSetData={this.handleChangeActivity}
                              height="320px"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ModalFooterSuport
                    data={this.state.data}
                    change={this.state.change}
                    _state={this.state.data._state}
                    send={false}
                    isNew={this.state.isNew}
                    printParams={this.state.printParams}
                    handleState={this.handleState}
                    handleNew={this.handleNew}
                    handleClone={this.handleClone}
                    handleClose={this.handleClose}
                    handleOk={this.handleOk}
                  />
                </div>
              </div>
            </div>,
            document.getElementById("rootModals")
          )}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    signin: state.sistem.signin,
    project_id: state.sistem.project._id || "-1",
    project: state.sistem.project,
    _view: state.sistem.folder._view || "",
    online: state.sistem.online,
  };
}

export default connect(mapStateToProps)(ModalOperation);
