import React from "react";
import Td from "../../../components/td";
import ModalSearch from "../../../components/modalSearch";
import { formatInteger, formatNumber, getData, formatDate, Subscribe, UnSubscribe } from "../../../components/utilities";
import { Api as Keep } from "../../../services/keep";
import ModalOperation from "../modalOperation";

class Consumptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "__Consumptions",
      title: "Consumos",
      name: "SUPORT-KEEP-CONSUMPTIONS",
      show: false,
      rows: 30,
      data: {
        list: [],
        search: "",
        page: 1,
        rows: 30,
        int: 0,
        end: 0,
        count: 0,
        all: 0,
      },
      select: { _id: "-1", caption: "" },
      change: false,
    };
  }

  eventSetData = (e) => {
    setTimeout(() => {
      this.handleUpdate(e);
    }, 500);
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleUpdate = (e) => {
    this.handleData(false);
  };

  handleSearch = () => {
    this.handleData(false);
  };

  handleData = (scroll) => {
    const _class = this.props._class;
    const _id = this.props._id;
    const search = this.state.data.search;
    const rows = this.state.rows;
    if (scroll) {
      const page = this.state.data.page + 1;
      Keep.consumptions(_class, _id, search, page, rows, this.state.data.list).then((result) => {
        const data = result.data;
        this.setState({
          data: data,
        });
      });
    } else {
      Keep.consumptions(_class, _id, search, 1, rows, []).then((result) => {
        const data = result.data;
        this.setState({
          data: data,
        });
      });
    }
  };

  handleScroll = (e) => {
    const h = e.target.scrollTop + e.target.clientHeight + 1;
    if (e.target.id === `${this.state._id}_scroll` && h >= e.target.scrollHeight) {
      this.handleData(true);
    }
  };

  componentDidMount() {
    Subscribe(`SUPORT-KEEP/${this.props._id}`, (event) => this.eventSetData(event));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show) {
      if (prevProps.show !== this.props.show) {
        this.handleData(false);
      }
    }
  }

  componentWillUnmount() {
    UnSubscribe(`SUPORT-KEEP/${this.props._id}`, (event) => this.eventSetData(event));
  }

  render() {
    return (
      <React.Fragment>
        <Td className="suport-tool" cols="34% auto">
          <ModalSearch name="search" value={this.state.data.search} onChange={this.handleChange} onSearch={this.handleSearch} />
          <div className={this.state.data.all > 0 ? "scroll-count" : "d-none"}>
            <p>
              <b className="scroll-count-end">{formatInteger(this.state.data.end)}</b>
              de
              <b className="scroll-count-all">{formatInteger(this.state.data.all)}</b>
            </p>
          </div>
        </Td>
        <div className="suport mb-0">
          <div className="suport-group mb-0">
            <Td className="suport-head" cols="100px 240px 35px 100px auto 94px">
              <div className="suport-td">Referencia</div>
              <div className="suport-td">Descripción</div>
              <div className="suport-td">Und</div>
              <div className="suport-td">Cantidad</div>
              <div className="suport-td">Operación</div>
              <div className="suport-td">Fecha</div>
            </Td>
            <div className="suport-scroll small" id={`${this.state._id}_scroll`} onScroll={this.handleScroll}>
              {this.state.data.list.map((item, i) => {
                return (
                  <ModalOperation key={i} project_id={item.project_id} isFind={true} _id={item.operation_id}>
                    <Td className="suport-item" cols="25px 75px 240px 35px 100px auto 90px">
                      <div className="suport-td t-c">{i + 1}</div>
                      <div className="suport-td t-c">{item.code}</div>
                      <div className="suport-td">{item.caption}</div>
                      <div className="suport-td t-c">{item.unity}</div>
                      <div className="suport-td t-r">{formatNumber(item.quantity)}</div>
                      <div className="suport-td">{getData(item, "operation_caption", "")}</div>
                      <div className="suport-td t-c">{formatDate(item.date_of, "MMM d, yyyy")}</div>
                    </Td>
                  </ModalOperation>
                );
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Consumptions;
